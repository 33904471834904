import React from "react";
import PropTypes from "prop-types";
import logo from "images/sac-logo.7776b610_NEW.svg";

const LoadingIndicator = ({ inline, content = <>Loading&hellip;</> }) => {
  return (
    <div
      className={inline ? "" : "fixed top-0 left-0 w-full h-full z-30 bg-white"}
    >
      <div
        className={
          inline
            ? ""
            : "p-8 border-2 rounded-lg absolute w-1/2 flex flex-col items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-lg"
        }
      >
        {!inline && (
          <img
            className="mx-auto mb-8"
            src={logo}
            alt="Shelter Animals Count - The National Database"
          />
        )}
        <div className="italic">{content}</div>
      </div>
    </div>
  );
};

LoadingIndicator.propTypes = {
  inline: PropTypes.bool,
  content: PropTypes.object,
};

LoadingIndicator.defaultProps = {
  inline: false,
  content: <>Loading&hellip;</>,
};

export default LoadingIndicator;
