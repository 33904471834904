import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Table, {
  getRowData,
  columns as defaultColumns,
  getServiceTypeAttributes,
} from "components/Table";
import { speciesMap, useHandleRequestStates } from "utils";
import { getTableColumns, getChangedAttributes } from "../../utils";

const VetServicesTable = forwardRef(
  ({ id, attributes, species, isEditing, submit }, ref) => {
    const { handleSuccess } = useHandleRequestStates();

    const columns = getTableColumns({ defaultColumns, isEditing, species });

    const setRow = ({ rowLabel, keySubString, tooltip }) =>
      getRowData({
        data: attributes,
        accessKeys: ["adult", "youth", "ageUnknown", "total"],
        rowLabel,
        keySubString,
        tooltip,
      });

    const data = [
      setRow({
        rowLabel: "Microchipping and/or Collar with ID tags",
        keySubString: "MicrochippingCollarIdTagsCount",
        tooltip:
          "Animals receiving insertion of microchip and/or application of long-wear collar with identification, as well as registering microchip/recording identification.",
      }),
      setRow({
        rowLabel: "Spay/Neuter for Owned Animals",
        keySubString: "SpayNeuterOwnedAnimalCount",
        tooltip:
          "Owned animals receiving spay/neuter services. Trap-Neuter-Return is not counted here. Count TNR above in Field Services.",
      }),
      setRow({
        rowLabel: "Wellness/Preventive Medical Care",
        keySubString: "WellnessPreventiveMedicalCareCount",
        tooltip:
          "Routine preventative care provided to healthy pets and/or same treatments provided to mildly affected pets (i.e. vaccinations, routine deworming, flea/tick treatment, heartworm prevention, etc.)",
      }),
      setRow({
        rowLabel: "Sick Animal Care/Basic Veterinary Care",
        keySubString: "BasicAnimalCareCount",
        tooltip:
          "Minor medical or surgical care provided to pets with a condition requiring single course of treatment (i.e. dentistry, tooth extraction, minor heartworm disease, routine skin allergy, ear infection, etc.)",
      }),
      setRow({
        rowLabel: "Sick Animal Care/Advanced Veterinary Care",
        keySubString: "AdvancedAnimalCareCount",
        tooltip:
          "Animal presents with illness as complaint and illness requires more than routine preventative medical or surgical care to address. Condition requires ongoing or chronic care or hospitalization to prevent death/disability (i.e. fractures, chronic diarrhea, URI, parvo, diabetes, cancer, etc.)",
      }),
      setRow({
        rowLabel: "Owner Intended Euthanasia",
        keySubString: "OwnerIntendedEuthanasiaCount",
        tooltip:
          "Limited to this definition: Owner makes an appointment with the shelter to euthanize their animal due to a medical or behavioral condition OR owner selects euthanasia as the best treatment option after consultation with a veterinarian. OIE provided to pets received at intake for OIE is not counted here.",
      }),
    ];

    const handleSubmit = async (values) => {
      handleSuccess({
        message: `Updated the ${speciesMap[
          attributes.species
        ].label.toLocaleLowerCase()} veterninary services`,
      });

      await submit(id, getChangedAttributes("vet", attributes, values));
    };

    return (
      <div className="mt-12">
        <div className="flex items-center justify-between space-x-4 mb-4">
          <h3 className="text-2xl flex items-center">
            <img src={speciesMap[species].icon} alt="" className="mr-4" />
            {speciesMap[species].label}
          </h3>
        </div>

        <Formik
          enableReinitialize
          initialValues={getServiceTypeAttributes(data, attributes)}
          onSubmit={(values) => handleSubmit(values)}
          innerRef={ref}
        >
          {() => (
            <Form>
              {/* Form Table */}
              <Table
                data={data}
                columns={columns}
                columnAlignments={[null, null, null, null, "text-right"]}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
);

VetServicesTable.propTypes = {
  id: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  species: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};

export default VetServicesTable;
