import { View, StyleSheet, Image } from "@react-pdf/renderer";
// Create styles
const styles = StyleSheet.create({
  logo: {
    width: 250,
    marginLeft: 10,
    height: 150,
  },
});

function PDFLogo() {
  return (
    <View>
      <Image
        style={styles.logo}
        source={process.env.PUBLIC_URL + "/PDF-logo.png"}
      />
    </View>
  );
}
export default PDFLogo;
