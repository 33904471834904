import React, { useState, useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { get } from "lodash";
import DatePicker from "react-multi-date-picker";
import "./MultiDatePicker.css";

const DatePickerField = ({ ...props }) => {
  const { label, required, readOnly, setDates, onlyYearPicker } = props;
  const [field, { value }, { setValue }] = useField(props);
  const { name } = field;
  const { errors, touched } = useFormikContext();
  const [fieldErrors, setFieldErrors] = useState(null);

  useEffect(() => {
    const fieldErrors = get(errors, name);
    setFieldErrors(fieldErrors && get(touched, name) ? fieldErrors : null);
  }, [name, errors, touched]);

  if (!label) {
    return (
      <DatePicker
        {...field}
        {...props}
        selected={(value && new Date(value)) || ""}
        onChange={(val) => setValue(val)}
      />
    );
  }

  return (
    <div>
      <label htmlFor={name}>
        <span
          className={`inline-block ${
            readOnly && "text-medium-gray font-body-bold "
          }`}
        >
          {label}
          {required && !readOnly && (
            <>
              {" "}
              <span className="text-sm" aria-hidden>
                *
              </span>
              <span className="sr-only">(required)</span>
            </>
          )}
        </span>
        <DatePicker
          {...field}
          {...props}
          selected={(value && new Date(value)) || ""}
          className="form-input w-full form-select"
          onChange={(val) => {
            var dates = val.map((a) => a.toDate());
            setValue(dates);
            setDates(onlyYearPicker ? val.map((a) => String(a.year)) : dates);
          }}
        />
      </label>
      {fieldErrors && (
        <div className="text-sm text-red mt-1">{fieldErrors}</div>
      )}
    </div>
  );
};

export default DatePickerField;
