export const isLastStackedBar = (data, dataKey) => {
  const bucketsWithData = Object.keys(speciesBuckets)
    .reverse()
    .reduce((arr, bucket) => {
      if (data[bucket]) arr[bucket] = data[bucket];
      return arr;
    }, {});

  return Object.keys(bucketsWithData)[0] === dataKey;
};

export const getMonthColor = (monthNum) => {
  const currentMonth = new Date().getMonth();

  if (monthNum > currentMonth + 1) {
    return "text-dashboard-medium-gray";
  }
  return "text-medium-gray";
};

export const transformGraphData = (response) => {
  const { included = [] } = response;
  // Create a config object where each key is a month of the year
  // Bootstrap each bucket of totals with zeros
  const initMonthsMap = {};
  for (let i = 1; i <= 12; i++) {
    if (!initMonthsMap[i])
      initMonthsMap[i] = { canine: 0, feline: 0, other: 0 };
  }

  const includedRecords = included.filter(
    (inc) => inc.type === "animal_intakes" || inc.type === "animal_services"
  );

  const monthsMap = includedRecords.reduce(
    (arr, item) => {
      const species = item.attributes.species;
      const monthOfRecord = item.attributes.monthOfRecord;

      // Assume the correct bucket is "other" unless the species is canine or feline
      let speciesBucket = speciesBuckets.other;
      if (
        species === speciesBuckets.canine ||
        species === speciesBuckets.feline
      ) {
        speciesBucket = species;
      }

      const total = () => {
        if (
          // totalLiveIntakeCount includes (totalOtherIntakesCount) to the total value
          item.attributes.totalLiveIntakeCount ||
          item.attributes.totalLiveIntakeCount === 0
        ) {
          return item.attributes.totalLiveIntakeCount;
        }
        return item.attributes.totalServicesCount;
      };
      arr[monthOfRecord][speciesBucket] += total();

      return arr;
    },
    { ...initMonthsMap }
  );

  // Loop through the months and construct an array
  return Object.keys(monthsMap).map((key) => {
    const { canine, feline, other } = monthsMap[key];
    return { month_of_record: parseInt(key, 10), canine, feline, other };
  });
};

export const speciesBuckets = {
  canine: "canine",
  feline: "feline",
  other: "other",
};

export const graphColors = {
  line: "#DEE7EF",
  yAxis: "728B93",
  canine: "#056AA8",
  feline: "#2EB5BC",
  other: "#C4D1DC",
  tooltipBg: "#F5F7FA",
};

export const constructEndpoint = ({
  coalitionId,
  organizationId,
  coalitionYear,
  coalitionFilters,
  action = "intakes",
}) => {
  var orgs = [];
  if (
    coalitionFilters &&
    coalitionFilters.organization_ids &&
    coalitionFilters.organization_ids.length > 0
  ) {
    orgs = coalitionFilters.organization_ids.map((a) => a.value);
  }
  return `/api/v1/locations?&include=${
    action === "intakes" ? "animal_intakes" : "animal_services"
  }&filter[coalition_data_sharing]=${coalitionId}:${
    action === "intakes"
      ? "intake_outcome_data_sharing"
      : "community_service_data_sharing"
  }${
    organizationId
      ? `&filter[organization_id][eq]=${organizationId}`
      : orgs.length > 0
      ? `&filter[organization_id][in_ids]=${orgs}`
      : ""
  }&filter[${
    action === "intakes" ? "animal_intakes" : "animal_services"
  }.year_of_record][eq]=${coalitionYear}&page[size]=1000`;
};
