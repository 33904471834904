import React from "react";
import * as Yup from "yup";
import { addNamespace } from "utils";
import FormField from "components/FormField";
import {
  OrganizationSearchableOptInTooltip,
  OrganizationCommunicationsOptInTooltip,
  OrganizationMediaOptInTooltip,
} from "components/Tooltips";

export const defaultValues = {
  communicationsOptIn: true,
  searchableOptIn: true,
  mediaOptIn: true,
};

export const validationSchema = () => ({
  communicationsOptIn: Yup.boolean(),
  searchableOptIn: Yup.boolean(),
  mediaOptIn: Yup.boolean(),
});

const DataSharingSubForm = ({ namespace }) => {
  const withNamespace = addNamespace(namespace);

  return (
    <>
      <h2>Data Sharing Preferences</h2>

      <>
        <div className="grid grid-rows-2 gap-6 mb-6">
          <FormField
            name={withNamespace("communicationsOptIn")}
            label="Do you give SAC permission to share your contact information with other SAC contributing organizations?"
            as="select"
            required
            tooltip={<OrganizationCommunicationsOptInTooltip />}
            tooltipWidthClass="w-80"
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </FormField>

          <FormField
            name={withNamespace("searchableOptIn")}
            label="Do you give permission for your organization to be searchable by other contributing organizations within the SAC portal?"
            as="select"
            required
            tooltip={<OrganizationSearchableOptInTooltip />}
            tooltipWidthClass="w-80"
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </FormField>

          <FormField
            name={withNamespace("mediaOptIn")}
            label="SAC receives inquiries from media who are interested in the work and experiences of animal shelters. Do you give SAC permission to forward contact information to media contacts?"
            as="select"
            required
            tooltip={<OrganizationMediaOptInTooltip />}
            tooltipWidthClass="w-80"
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </FormField>
        </div>
      </>
    </>
  );
};

export default DataSharingSubForm;
