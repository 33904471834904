import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { apiFetch, formatUiErrors, useHandleRequestStates } from "utils";
import {
  getDefaultAnimalSpecies,
  generateAnimalSpeciesPayload,
  updateSpeciesCache,
} from "./utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import LoadingIndicator from "components/LoadingIndicator";
import Error from "components/Error";
import ServicesMatrixSettings from "./components/ServicesMatrixSettings";
import FieldServices from "./components/FieldServices";
import VetServices from "./components/VetServices";
import BehavioralServices from "./components/BehavioralServices";
import SupportServices from "./components/SupportServices";
import Button from "components/Button";
import { transformAttributesForPayload } from "components/Table";
import { cloneDeepWith, isObject } from "lodash";

const ServicesMatrix = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { attributes, organization, isAdmin, isOrgAdmin } = useUserState();
  const queryClient = useQueryClient();
  const { handleError } = useHandleRequestStates();
  const { month, year, location, organizationId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [animalSpecies, setAnimalSpecies] = useState(["canine", "feline"]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState("");
  const [updatedData, setUpdatedData] = useState({});
  const [updatingData, setUpdatingdData] = useState(false);
  const [speciesUpdateId, setSpeciesUpdateId] = useState();
  const [newOrganization, setOrganization] = useState({});
  const [newlocation, setNewLocation] = useState({});

  const fieldForm = useRef(null);
  const vetForm = useRef(null);
  const behaviourForm = useRef(null);
  const supportForm = useRef(null);
  const isFirstRender = useRef(true);
  var locationId = attributes.locationId;
  if (isAdmin) locationId = location;

  const getOrg = async () => {
    if (organizationId) {
      const response = await apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/organizations/${organizationId}`,
      });
      setOrganization(response.data);
    }
    const response2 = await apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/locations/${locationId}`,
    });
    setNewLocation(response2.data.attributes);
  };

  useEffect(() => {
    getOrg();
  }, []);

  const setSpecies = (species) => {
    if (species === selectedSpecies) {
      setSelectedSpecies("");
    } else {
      setSelectedSpecies(species);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      updateServices();
    }
  }, [updatedData]);

  const {
    error: isStatusError,
    data: status,
    isLoading: isLoadingStatus,
  } = useQuery(
    ["servicesMatrixStatus", `${locationId}_${month}/${year}`],
    () => {
      setIsLoading(true);
      return apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/data_entry_status?filter[year][eq]=${year}&filter[month][eq]=${month}&filter[location_id][eq]=${locationId}&filter[action][eq]=services`,
      });
    },
    {
      onSettled: () => setIsLoading(false),
    }
  );

  const {
    error,
    data: response,
    refetch,
  } = useQuery(
    ["servicesMatrix", `${locationId}_${month}/${year}`],
    () => {
      setIsLoading(true);
      return apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_services?filter[year_of_record][eq]=${year}&filter[month_of_record][eq]=${month}&filter[location_id][eq]=${locationId}`,
      });
    },
    {
      onSuccess: (res) => {
        var org = organizationId ? newOrganization : organization;
        getDefaultAnimalSpecies({
          data: res.data,
          organization: org,
          setAnimalSpecies,
          entryStatus: status ? status.data[0].attributes.status : "unbegun",
        });

        isFirstRender.current = true;
        setUpdatedData(() =>
          Object.assign(
            {},
            res.data
              .map((species) => ({
                [species.id]: species.attributes,
              }))
              .reduce((acc, curr) => Object.assign(acc, curr), {})
          )
        );
      },
      onError: () => setIsLoading(false),
    }
  );

  const {
    mutate: setMatrixSpecies,
    isLoading: isLoadingTables,
    error: isTableError,
  } = useMutation(
    () => {
      const payload = generateAnimalSpeciesPayload({
        addedSpecies: animalSpecies,
        removedSpecies: [],
        locationId,
        year,
        month,
      });

      return apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/locations/${locationId}`,
        method: "PATCH",
        body: {
          data: {
            id: locationId,
            type: "locations",
            attributes: {},
            relationships: payload.relationships,
          },
          included: payload.included,
        },
      });
    },
    {
      onSuccess: (res) => {
        queryClient.setQueryData(
          ["servicesMatrix", `${locationId}_${month}/${year}`],
          (oldData) =>
            updateSpeciesCache({
              oldData,
              response: res,
              changedSpecies: { added: animalSpecies, removed: [] },
            })
        );
      },
      onSettled: () => setIsLoading(false),
    }
  );

  const { mutate: updateServices } = useMutation(
    () =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_services/${speciesUpdateId}`,
        method: "PATCH",
        body: {
          data: {
            id: speciesUpdateId,
            type: "animal_services",
            attributes: transformAttributesForPayload(
              updatedData[speciesUpdateId]
            ),
          },
        },
      }),
    {
      onSuccess: (res) => {
        // API returns updated table. But cache stores all tables and when you update you lose extra tables.
        queryClient.setQueryData(
          ["servicesMatrix", `${locationId}_${month}/${year}`],
          (oldData) =>
            cloneDeepWith(oldData, (value) => {
              if (isObject(value) && value.id === speciesUpdateId) {
                Object.assign(value, res.data);
              }
            })
        );

        queryClient.refetchQueries([
          "servicesMatrixStatus",
          `${locationId}_${month}/${year}`,
        ]);
      },
      onError: handleError,
    }
  );

  const setUpdatedAttributes = (id, attributes) => {
    setSpeciesUpdateId(id);
    var attri = {
      ...fieldForm.current.values,
      ...behaviourForm.current.values,
      ...vetForm.current.values,
      ...supportForm.current.values,
    };

    setUpdatedData(() =>
      cloneDeepWith(updatedData, (value) => {
        if (isObject(value) && value[id]) {
          Object.assign(value[id], attri);
        }
      })
    );
  };

  const handleSubmit = async () => {
    await fieldForm.current.handleSubmit();
    // await vetForm.current.handleSubmit();
    // await behaviourForm.current.handleSubmit();
    // await supportForm.current.handleSubmit();
    setIsEditing(false);
  };

  useEffect(() => {
    if (!response) {
      return;
    }

    if (!response.data.length && isOrgAdmin) {
      setMatrixSpecies();
    } else {
      setIsLoading(false);
    }
  }, [response, animalSpecies]);

  useEffect(refetch, [locationId, refetch]);
  // useEffect(refetch, []);

  const getContent = () => {
    if (isLoading || isLoadingTables || isLoadingStatus)
      return (
        <LoadingIndicator
          inline
          content={<>Loading services matrix&hellip;</>}
        />
      );

    if (error || isTableError || isStatusError)
      return (
        <Error>{formatUiErrors(error || isTableError || isStatusError)}</Error>
      );

    if (newlocation.status == "closed") {
      return (
        <div>
          <b>{newlocation.name}</b> - location is closed by system admin. If you
          want to enter data for this location please contact Shelter Animals
          Count helpdesk,{" "}
          <a href="mailto:info@shelteranimalscount.org">
            info@shelteranimalscount.org
          </a>
          .
        </div>
      );
    }
    return (
      <>
        {/* Settings */}
        <ServicesMatrixSettings
          data={response?.data || []}
          defaultSpecies={animalSpecies}
          locationName={newlocation.name}
          locationId={locationId}
          year={year}
          month={month}
          entryStatus={status.data[0].attributes.status}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setSpecies={setSpecies}
          selectedSpecies={selectedSpecies}
          handleSubmitServices={handleSubmit}
        />
        {/* Tables */}
        <FieldServices
          data={response?.data || []}
          selectedSpecies={selectedSpecies}
          isEditing={isEditing}
          submit={setUpdatedAttributes}
          ref={fieldForm}
        />
        <VetServices
          data={response?.data || []}
          selectedSpecies={selectedSpecies}
          isEditing={isEditing}
          submit={setUpdatedAttributes}
          ref={vetForm}
        />
        <BehavioralServices
          data={response?.data || []}
          selectedSpecies={selectedSpecies}
          isEditing={isEditing}
          submit={setUpdatedAttributes}
          ref={behaviourForm}
        />
        <SupportServices
          data={response?.data || []}
          selectedSpecies={selectedSpecies}
          isEditing={isEditing}
          submit={setUpdatedAttributes}
          ref={supportForm}
        />
        {selectedSpecies && (
          <div className="sac-border flex gap-1 justify-end">
            <Button
              key="edit"
              disabled={isEditing}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
            <Button key="submit" disabled={!isEditing} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <h1 className="mb-0">Enter Services Data</h1>
      <p className="mb-10">
        Please keep in mind that all data fields must be filled in, even if the
        entry is “0.” Records will be considered incomplete until all fields are
        populated with numeric values.
      </p>

      {getContent()}
    </div>
  );
};

export default ServicesMatrix;
