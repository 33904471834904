import svgs from "images";

export const CANINE = "canine";
export const FELINE = "feline";
export const RABBIT = "rabbit";
export const EQUINE = "equine";
export const SMALL_MAMMAL = "small_mammal";
export const BIRD = "bird";
export const FARM_ANIMAL = "farm_animal";
export const REPTILE_OR_AMPHIBIAN = "reptile_or_amphibian";

export const speciesMap = {
  [CANINE]: {
    label: "Canines",
    icon: svgs[CANINE],
  },
  [FELINE]: { label: "Felines", icon: svgs[FELINE] },
  [RABBIT]: { label: "Rabbits", icon: svgs[RABBIT] },
  [EQUINE]: { label: "Equines", icon: svgs[EQUINE] },
  [SMALL_MAMMAL]: {
    label: "Small Mammals",
    icon: svgs[SMALL_MAMMAL],
  },
  [BIRD]: { label: "Birds", icon: svgs[BIRD] },
  [FARM_ANIMAL]: {
    label: "Farm Animals",
    icon: svgs[FARM_ANIMAL],
  },
  [REPTILE_OR_AMPHIBIAN]: {
    label: "Reptile & Amphibians",
    icon: svgs[REPTILE_OR_AMPHIBIAN],
  },
};
