import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import DatePickerField from "components/DatePickerField";
import { currentMonth as defaultcurrentMonth } from "utils";
import { useUserState } from "context";

const DataEntryOptionsFilters = ({ currentMonth }) => {
  const {
    attributes: { locationName },
  } = useUserState();

  return (
    <div className="mb-16">
      {/* Location */}
      <div className="flex items-center space-x-4 mb-4">
        <h5 className="w-48 font-body-bold">Entering Data For:</h5>
        <div>
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="text-deepest-teal mr-1"
          />
          {locationName}
        </div>
      </div>

      {/* Date picker */}
      <div className="flex items-center space-x-4">
        <label
          htmlFor="data-entry-options__date"
          className="w-48 font-body-bold font-display text-lg text-deepest-teal"
        >
          Timeframe:
        </label>
        <div>
          <DatePickerField
            name="date"
            id="data-entry-options__date"
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            className="form-input"
            maxDate={currentMonth}
            required
          />
        </div>
      </div>
    </div>
  );
};

DataEntryOptionsFilters.propTypes = {
  currentMonth: PropTypes.instanceOf(Date),
};

DataEntryOptionsFilters.defaultProps = {
  currentMonth: defaultcurrentMonth,
};

export default DataEntryOptionsFilters;
