import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { logout } from "actions";
import { useLocation } from "react-router-dom";
import { useAuthDispatch, useUserState, useUserDispatch } from "context";
import { useMutation, useQueryClient } from "react-query";
import ProfileDropdown from "components/ProfileDropdown";
import LocationSelector from "components/LocationSelector";
import Navigation from "components/Navigation";
import Footer from "components/Footer";
import logo from "images/sac-logo.7776b610_NEW.svg";

const AppPage = ({ children }) => {
  const user = useUserState();
  const { pathname } = useLocation();

  const {
    attributes: { fullName, email, locationId },
    organization,
    locations,
    isCoalitionOnly,
  } = user;
  const dispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();
  const [showLocationSelector, setShowLocationSelector] = useState(
    pathname === "/" && locationId && locations && organization
  );
  const history = useHistory();

  const { mutate: logoutRequest } = useMutation(logout);

  const queryClient = useQueryClient();

  const handleLogout = () => {
    logoutRequest({
      dispatch,
      userDispatch,
      queryClient,
    });
    history.push("/login");
  };

  useEffect(() => {
    setShowLocationSelector(
      pathname === "/" && locationId && locations && organization
    );
  }, [locationId, locations, organization, pathname]);

  return (
    <div className="flex max-w-full overflow-x-hidden">
      <div className="w-64 lg:w-72 flex-shrink-0 z-20">
        <aside
          id="sidebar"
          className="w-64 lg:w-72 fixed z-20 bg-light-gray border-r-2 border-pale-blue p-6 flex flex-col justify-between"
        >
          <div>
            <Link to={isCoalitionOnly ? "/coalitions" : "/"}>
              <img
                className="mx-auto"
                src={logo}
                alt="Shelter Animals Count - The National Database"
              />
            </Link>
            <Navigation />
          </div>

          <Footer showAttribution />
        </aside>
      </div>
      <div className="w-full px-20 pb-20">
        <div
          id="toolbar"
          className="flex justify-end items-center space-x-2 mb-8 -mx-20 sticky z-10 pt-8 pb-4 px-20 bg-white"
        >
          {showLocationSelector && (
            <LocationSelector locationId={locationId} locations={locations} />
          )}

          <ProfileDropdown
            username={fullName || email}
            organization={organization}
            logoutMethod={handleLogout}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default AppPage;
