import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#ccc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
  },
  description: {
    width: "22%",
    height: 30,
    paddingTop: 10,
    fontSize: 10,
    paddingLeft: 10,
    borderColor: borderColor,
    borderWidth: 1,
  },
  rowWidth: {
    width: "13%",
    height: 30,
    paddingTop: 10,
    fontSize: 10,
    textAlign: "center",
    borderColor: borderColor,
    borderWidth: 1,
  },
});

const CountsTableRow = ({ items }) => {
  const rows = items.map((item, i) => {
    return (
      <View style={styles.row}>
        <Text style={styles.description}>{item.name}</Text>
        <Text style={styles.rowWidth}>{item.canine_c}</Text>
        <Text style={styles.rowWidth}>{item.canine_f}</Text>
        <Text style={styles.rowWidth}>{item.feleine_c}</Text>
        <Text style={styles.rowWidth}>{item.feleine_f}</Text>
        <Text style={styles.rowWidth}>{item.other_c}</Text>
        <Text style={styles.rowWidth}>{item.other_f}</Text>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default CountsTableRow;
