import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertModal from "components/AlertModal";
import { get, uniq } from "lodash";
import { useState } from "react";
import {
  camelToTitle,
  COALITION_MEMBERSHIP_ACTIONS,
  useManageCoalitionMemberships,
  useHandleRequestStates,
  titleCase,
} from "utils";

export const getBaseColumns = (country) => {
  return [
    {
      accessor: "attributes.name",
      Header: "Name",
      Cell: ({ cell }) => cell.value,
    },
    ...[
      country === "United States"
        ? {
            accessor: "attributes.operatingRegion",
            Header: "States",
            Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
          }
        : {
            accessor: "attributes.operatingRegion",
            Header: "Provinces/Territory",
            Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
          },
    ],
    {
      accessor: "attributes.operatingRegionType",
      Header: "Region Type",
    },
    {
      accessor: "attributes.organizationsCount",
      Header: "Shelters",
      Cell: ({ cell }) => <div className="text-center">{cell.value}</div>,
    },
    {
      accessor: "attributes.description",
      Header: "Description",
    },
    {
      accessor: "attributes.status",
      Header: "Status",
      Cell: ({ cell }) => titleCase(cell.value),
    },
  ];
};
// Columns - Map up with accessor from Data - attributes.name etc.
export const baseColumns = [
  {
    accessor: "attributes.name",
    Header: "Name",
    Cell: ({ cell }) => cell.value,
  },
  {
    accessor: "attributes.operatingRegion",
    Header: "States/Provinces",
    Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
  },
  {
    accessor: "attributes.operatingRegionType",
    Header: "Region Type",
  },
  {
    accessor: "attributes.organizationsCount",
    Header: "Shelters",
    Cell: ({ cell }) => <div className="text-center">{cell.value}</div>,
  },
  {
    accessor: "attributes.description",
    Header: "Description",
  },
  {
    accessor: "attributes.status",
    Header: "Status",
    Cell: ({ cell }) => titleCase(cell.value),
  },
];

export const baseColumnsForSharing = [
  {
    accessor: "attributes.name",
    Header: "Name",
    Cell: ({ cell }) => cell.value,
  },
  {
    accessor: "attributes.organizationsCount",
    Header: "Shelters",
    Cell: ({ cell }) => <div className="text-center">{cell.value}</div>,
  },
  {
    accessor: "attributes.description",
    Header: "Description",
  },
  {
    accessor: "attributes.status",
    Header: "Status",
    Cell: ({ cell }) => titleCase(cell.value),
  },
];
/**
 * Constructs array of a user's roles in a given coalition
 * @param {Object} params
 * @param {string} params.coalitionId
 * @param {Object} params.coalitions
 * @return arr Returns array of roles
 */
export const getCoalitionRoles = ({ coalitionId, coalitions }) => {
  if (!coalitions || !coalitions[coalitionId]) return [];

  const memberships = coalitions[coalitionId];
  return Object.keys(memberships).map((key) => {
    const membership = memberships[key];
    let role =
      camelToTitle(membership.attributes.role) || "Coalition Contributor";

    if (
      membership.attributes.status === "requested" ||
      membership.attributes.status === "reminded"
    ) {
      role += ` (${
        membership.attributes.statusActor === "Organization"
          ? "requested"
          : "invited"
      })`;
    } else if (membership.attributes.status === "rejected") {
      role += ` (${
        membership.attributes.statusActor === "Organization"
          ? "rejected by organization"
          : "rejected by coalition"
      })`;
    }

    return role;
  });
};

export const getDataSharing = ({ coalitionId, coalitions, type }) => {
  if (!coalitions || !coalitions[coalitionId]) return [];

  const memberships = coalitions[coalitionId];
  return Object.keys(memberships).map((key) => {
    const membership = memberships[key];
    let role = membership.attributes.intakeOutcomeDataSharing;
    if (type == "community")
      role = membership.attributes.communityServiceDataSharing;
    return role;
  });
};

export const getDataSharingType = ({ coalitionId, coalitions, type }) => {
  if (!coalitions || !coalitions[coalitionId]) return "None";

  const memberships = coalitions[coalitionId];
  const orgMembership = memberships["orgMembership"];

  let status = false;

  if (type === "community")
    status = orgMembership?.attributes.communityServiceDataSharing;
  else status = orgMembership?.attributes.intakeOutcomeDataSharing;

  return status ? "All" : "None";
};

/**
 * Returns markup for My Role column based on user's roles in a coalition
 * @param {Object} params
 * @param {string} params.coalitionId
 * @param {Object} params.coalitions
 * @return JSX
 */
export const coalitionRoleColumn = ({ coalitionId, coalitions }) => {
  const roles = getCoalitionRoles({ coalitionId, coalitions });

  if (!roles.length) return "--";
  return (
    <div className="flex flex-col space-y-2">
      {roles.map((role, i) => {
        return <div key={i}>{role}</div>;
      })}
    </div>
  );
};

export const coalitionSharing = ({
  coalitionId,
  coalitions,
  type,
  openModel,
}) => {
  const roles = getDataSharing({ coalitionId, coalitions, type });
  const coaRoles = getCoalitionRoles({ coalitionId, coalitions });

  return (
    <div className="flex space-y-2">
      {roles.includes(true) ? "Yes" : "No"}&nbsp;&nbsp;&nbsp;
      {(coaRoles.includes("Coalition Contributor") ||
        coaRoles.includes("Coalition Contributor (invited)")) && (
        <FontAwesomeIcon
          icon={faEdit}
          className="mr-2"
          onClick={() =>
            openModel({ id: coalitionId, type, state: roles.includes(true) })
          }
        />
      )}
    </div>
  );
};

/**
 * Hook that handles success of respond to coalition invite request
 * @param {Object} params
 * @param {Object} params.response API response
 * @param {Object} params.organization User's organization
 * @param {Array} params.coalition
 * @param {string} params.action "accept" || "reject"
 * @return {function}
 */
export const useHandleRespondToInviteSuccess = () => {
  const { createContributorMembership } = useManageCoalitionMemberships();
  const { handleSuccess } = useHandleRequestStates();

  return ({ response, organization, coalition, action }) => {
    const updatedMembershipRecord = response.data;
    const coalitionName = get(coalition, "attributes.name");

    createContributorMembership(updatedMembershipRecord);
    const message =
      action === COALITION_MEMBERSHIP_ACTIONS.ACCEPT
        ? `${organization.attributes.name} is now an active contributor of ${coalitionName}`
        : `Declined invitation to join ${coalitionName}`;

    handleSuccess({
      message,
      queries: [
        {
          key: "myCoalitions",
          config: {
            refetchActive: true,
          },
        },
        {
          key: "coalitions",
        },
        {
          key: ["coalitionContributors", coalition.id],
        },
        {
          key: ["searchOrganizations", { coalitionId: coalition.id }],
        },
      ],
    });
  };
};

/**
 * Hook that handles success of request access to coalition request
 * @param {Object} params
 * @param {Object} params.response API response
 * @param {Array} params.coalition
 * @return {function}
 */
export const useHandleRequestAccessSuccess = () => {
  const { createContributorMembership } = useManageCoalitionMemberships();
  const { handleSuccess } = useHandleRequestStates();

  return ({ response, coalition }) => {
    const newMembershipRecord = response.data;
    const coalitionId = String(newMembershipRecord.attributes.coalitionId);

    createContributorMembership(newMembershipRecord);

    handleSuccess({
      message: `A request email has been sent to the members of ${get(
        coalition,
        "attributes.name"
      )}`,
      queries: [
        {
          key: "coalitions",
          config: {
            refetchActive: true,
          },
        },
        {
          key: "myCoalitions",
        },
        {
          key: ["coalitionContributors", coalition.id],
        },
        {
          key: ["searchOrganizations", { coalitionId: coalition.id }],
        },
      ],
    });
  };
};

/**
 * Hook that handles success of leave coalition request
 * @param {Object} coalition
 * @return {function}
 */
export const useHandleLeaveCoalitionSuccess = () => {
  const { deleteContributorMembership } = useManageCoalitionMemberships();
  const { handleSuccess } = useHandleRequestStates();

  return (coalition) => {
    deleteContributorMembership(coalition.id);

    handleSuccess({
      message: `Your organization has been successfully removed from ${get(
        coalition,
        "attributes.name"
      )}`,
      queries: [
        {
          key: "myCoalitions",
          config: {
            refetchActive: true,
          },
        },
        {
          key: "coalitions",
        },
        {
          key: ["coalitionContributors", coalition.id],
        },
        {
          key: ["searchOrganizations", { coalitionId: coalition.id }],
        },
      ],
    });
  };
};
