import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAuthState, useAuthDispatch } from "context";
import { apiFetch, formatUiErrors } from "utils";
import FormField from "components/FormField";
import Button from "components/Button";
import RouteLeavingGuard from "components/RouteLeavingGuard";

const AddUser = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { coalitionId } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);

  const UserSchema = Yup.object().shape({
    fullName: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    confirmEmail: Yup.string()
      .email("Invalid email")
      .oneOf([Yup.ref("email")], "Emails do not match")
      .required("Confirm the email"),
  });

  const { mutate: addUser, isLoading } = useMutation(
    ({ fullName, role, email }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/user_membership_invites`,
        method: "POST",
        body: {
          data: {
            type: "user_membership_invites",
            attributes: {
              resourceId: coalitionId,
              resourceType: "coalition",
              name: fullName,
              role,
              email,
            },
          },
        },
      }),
    {
      onSuccess: () => {
        setIsSuccess(true);
        toast.success("Saved successfully", {
          autoClose: 3000,
        });
        queryClient.invalidateQueries(["coalitionUsers", { coalitionId }], {
          refetchActive: true,
        });
        history.push(`/coalitions/${coalitionId}/users`);
      },
      onError: (error) => {
        setIsSuccess(false);
        toast.error(() => formatUiErrors(error), {
          autoClose: 5000,
        });
      },
    }
  );

  return (
    <>
      <h2>Invite User</h2>

      <Formik
        initialValues={{ fullName: "", email: "", confirmEmail: "", role: "" }}
        validationSchema={UserSchema}
        validateOnMount
        onSubmit={addUser}
      >
        {({ handleSubmit }) => (
          <Form>
            <div className="grid grid-cols-2 gap-6 mb-6">
              {/* Full Name */}
              <FormField name="fullName" label="Full Name" required />

              {/* Role */}
              <FormField name="role" label="Role" required as="select">
                <option disabled value="" hidden>
                  -- Select a Role --
                </option>
                <option value="coalition_viewer">Viewer</option>
                <option value="coalition_manager">Manager</option>
              </FormField>
            </div>

            <div className="grid grid-cols-2 gap-6 mb-6">
              {/* Email */}
              <FormField name="email" label="Email" type="email" required />

              {/* Confirm Email */}
              <FormField
                name="confirmEmail"
                label="Confirm Email"
                type="email"
                required
              />
            </div>

            <div className="flex justify-end space-x-4">
              <Button
                to={`/coalitions/${coalitionId}/users`}
                emphasis="transparent"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                loadingText={<>Saving&hellip;</>}
                onClick={handleSubmit}
              >
                Invite
              </Button>
            </div>

            <FormContext isSuccess={isSuccess} setIsSuccess={setIsSuccess} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddUser;

const FormContext = ({ isSuccess, setIsSuccess }) => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    if (dirty) {
      setIsSuccess(false);
    }
  }, [dirty, setIsSuccess]);

  return (
    <RouteLeavingGuard
      when={!isSuccess && dirty}
      shouldBlockNavigation={() => !isSuccess && dirty}
    />
  );
};
