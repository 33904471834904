import React from "react";
import PropTypes from "prop-types";
import ButtonDecrement from "components/ButtonDecrement";
import ButtonIncrement from "components/ButtonIncrement";
import IntakeOverviewFilter from "components/IntakeOverviewFilter";

const DateFilter = ({ setYear, year }) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="flex">
      <ButtonDecrement onClick={() => setYear(year - 1)} />
      <IntakeOverviewFilter year={year} />
      <ButtonIncrement
        disabled={currentYear === year}
        onClick={() => setYear(year + 1)}
      />
    </div>
  );
};

DateFilter.propTypes = {
  setYear: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
};

export default DateFilter;
