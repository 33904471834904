import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import fontBold from "../../../fonts/Cera-Pro-Bold.woff";
import PDFLogo from "./PDFLogo";
import TopHeader from "./TopHeader";
import SpecieTable from "./Shared/SpecieTable";
import {
  getSPServices,
  SP_BEHAVIORAL_DATA_KEYS,
  SP_FIELD_DATA_KEYS,
  SP_SUPPORT_DATA_KEYS,
  SP_VETERINAY_DATA_KEYS,
} from "./helpers/PDFTableKeys";
import SideTextServices from "./Shared/SideTextServices";

Font.register({
  src: fontBold,
  family: "Cera Pro Bold",
});

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  container: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  rightCol: {
    flexDirection: "column",
    flexGrow: 9,
    paddingLeft: 10,
    paddingRight: 20,
    fontSize: 15,
    textTransform: "uppercase",
  },
  container2: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    height: 30,
    paddingTop: 7,
    textAlign: "center",
    flexGrow: 1,
  },
  container3: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    paddingTop: 7,
    textAlign: "center",
  },
  container4: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    marginTop: "-5px",
    textAlign: "center",
  },
  leftColumn: {
    width: 400,
    paddingRight: 10,
  },
  leftColumn2: {
    width: 350,
    paddingRight: 0,
    paddingLeft: 20,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 900,
  },
  header: {
    color: "#0F6165",
    fontSize: "24px",
    paddingLeft: 10,
    fontFamily: "Cera Pro Bold",
    fontWeight: 900,
  },
});

// Create Document Component
const CustomSpeciesService = (props) => {
  var fieldData = [],
    vetData = [],
    behaviorlData = [],
    supportData = [],
    counts = [];
  if (props && props.data) {
    fieldData = getSPServices(
      props.data.services.data,
      props.sp,
      SP_FIELD_DATA_KEYS
    );
    vetData = getSPServices(
      props.data.services.data,
      props.sp,
      SP_VETERINAY_DATA_KEYS
    );
    behaviorlData = getSPServices(
      props.data.services.data,
      props.sp,
      SP_BEHAVIORAL_DATA_KEYS
    );
    supportData = getSPServices(
      props.data.services.data,
      props.sp,
      SP_SUPPORT_DATA_KEYS
    );
  }

  return (
    <Page size="A3">
      <View style={styles.section}></View>
      <View style={styles.container}>
        <View style={styles.rightCol}>
          <PDFLogo />
        </View>
        <View style={styles.leftColumn}>
          <TopHeader
            timeframe={props.timeframe}
            orgName={props.orgName}
            action={props.action}
            headerName={props.title}
          />
        </View>
      </View>
      <Text style={styles.header}>{props.title}</Text>
      <View style={styles.container}>
        <View style={styles.rightCol}>
          <Text>{"\n"}</Text>
          <SpecieTable
            data={fieldData}
            styles={props.styles}
            name={"FIELD SERVICES"}
          />
          <Text>{"\n"}</Text>
          <Text>{"\n"}</Text>
          <SpecieTable
            data={vetData}
            styles={props.styles}
            name={"VETERINARY SERVICES"}
          />
          <Text>{"\n"}</Text>
          <Text>{"\n"}</Text>
          <SpecieTable
            data={behaviorlData}
            styles={props.styles}
            name={"BEHAVIORAL SERVICES"}
          />
          <Text>{"\n"}</Text>
          <Text>{"\n"}</Text>
          <SpecieTable
            data={supportData}
            styles={props.styles}
            name={"SUPPORT SERVICES"}
          />
        </View>
        <View style={styles.leftColumn2}>
          <SideTextServices hideOther={true} />
        </View>
      </View>
    </Page>
  );
};
export default CustomSpeciesService;
