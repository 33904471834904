import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

const ButtonIncrement = ({ onClick, disabled }) => {
  return (
    <button
      className="px-4 py-2 border rounded-lg bg-super-light-gray text-sm text-center mb-3 ml-1"
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretRight} />
    </button>
  );
};

export default ButtonIncrement;
