import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AnimalEntryDetailsForm from "./AnimalEntryDetailsForm";
import AnimalEntryDetailsSelected from "./AnimalEntryDetailsSelected";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Button";
import { useAuthDispatch, useAuthState, useUserState } from "context";
import { apiFetch, speciesMap } from "utils";
import PropTypes from "prop-types";

const AnimalEntryDetails = ({
  defaultSpecies,
  entryStatus,
  selectedSpecies,
  setSpecies,
  isEditing,
  setIsEditing,
  handleSubmit,
  location,
  handleSubmitAll,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const { isOrgAdmin } = useUserState();

  const { month, year } = useParams();
  const date = new Date(`${year}/${month}/1`);
  const formattedDate = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
  }).format(date);

  return (
    <div>
      <div className={`${isEditable ? "bg-light-gray" : ""} sac-border mb-4`}>
        <div className="flex">
          <div>
            {!isEditable ? (
              <span className="circle bg-dashboard-medium-gray mr-4">
                <FontAwesomeIcon icon={faCheck} className="text-white" />
              </span>
            ) : (
              <span className="circle bg-dashboard-blue mr-4">1</span>
            )}
          </div>
          <div className="w-full">
            <div className="flex justify-between mb-6">
              <h2 className="text-2xl">Details</h2>

              {!isEditable && isOrgAdmin ? (
                <Button onClick={() => setIsEditable(true)}>Edit</Button>
              ) : null}
            </div>

            {!isEditable ? (
              <AnimalEntryDetailsSelected
                formattedDate={formattedDate}
                locationName={location.attributes?.name}
                defaultSpecies={defaultSpecies}
                entryStatus={entryStatus}
              />
            ) : (
              <AnimalEntryDetailsForm
                formattedDate={formattedDate}
                setIsEditable={setIsEditable}
                locationName={location.attributes?.name}
                defaultSpecies={defaultSpecies}
                entryStatus={entryStatus}
              />
            )}
          </div>
        </div>
      </div>
      <div className="sac-border mb-4">
        <div className="flex justify-between">
          <div>
            <h2 className="text-xl">
              <div>
                1. Click species button below to edit/enter all data for that
                species.
              </div>
              <div>
                2. Click save to save all data for that species. Any empty
                fields will be highlighted as incomplete.
              </div>
              <div>
                3. Click species button again and then submit button to submit
                all data for all species.
              </div>
            </h2>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-1">
            {defaultSpecies.map((species, index) => {
              return (
                <Button
                  emphasis={`${species === selectedSpecies ? "gold" : "teal"}`}
                  key={index}
                  disabled={isEditing || !isOrgAdmin}
                  onClick={() => setSpecies(species)}
                >
                  {speciesMap[species].label}
                </Button>
              );
            })}
          </div>
          {selectedSpecies ? (
            <div className="flex gap-1">
              <Button disabled={isEditing} onClick={() => setIsEditing(true)}>
                Edit
              </Button>
              <Button disabled={!isEditing} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          ) : (
            <Button disabled={!isOrgAdmin} onClick={handleSubmitAll}>
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

AnimalEntryDetails.propTypes = {
  defaultSpecies: PropTypes.array.isRequired,
  entryStatus: PropTypes.string.isRequired,
  selectedSpecies: PropTypes.string.isRequired,
  setSpecies: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitAll: PropTypes.func.isRequired,
};

export default AnimalEntryDetails;
