import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik, Form, useFormikContext } from "formik";
import FormField from "components/FormField";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import CountyDropdown from "components/CountyDropdown";
import StateDropdown from "components/StateDropdown";
import * as Yup from "yup";
import { useMutation } from "react-query";
import {
  apiFetch,
  FORM_MODES,
  formatPhoneNumberForFormFields,
  formatPhoneNumberForApi,
} from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Button from "components/Button";

const LocationForm = ({
  location,
  formMode,
  cancelRoute,
  onUpdateSuccess,
  onUpdateError,
}) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { orgId } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const initialValues = getInitialValues({ location, formMode });
  const { isAdmin } = useUserState();

  const LocationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    country: Yup.string().required("Country is required"),
    addressLine1: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    usState: Yup.string().when("country", {
      is: "United States",
      then: Yup.string().required("State is required"),
    }),
    provinceOrTerritory: Yup.string().when("country", {
      is: "Canada",
      then: Yup.string().required("Province or territory is required"),
    }),
    zipCode: Yup.string().when("country", {
      is: "United States",
      then: Yup.string()
        .matches(/^(\d{5})(?:[-. ]?(\d{4}))?$/, "Invalid zip code")
        .required("Zip code is required"),
    }),
    postalCode: Yup.string().when("country", {
      is: "Canada",
      then: Yup.string()
        .matches(/^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/, "Invalid postal code")
        .required("Postal code is required"),
    }),
    usCounty: Yup.string().when("country", {
      is: "United States",
      then: Yup.string().required("County is required"),
    }),
    contactPhoneNumber: Yup.string()
      .matches(
        /^\(?([2-9]\d\d)\)?[-. ]?(\d{3})[-. ]?(\d{4})$/,
        "Invalid phone number"
      )
      .required("Phone number is required"),
  });

  const { mutate: addLocation, isLoading: isLoadingAdd } = useMutation(
    ({
      name,
      country,
      addressLine1,
      addressLine2,
      city,
      usState,
      provinceOrTerritory,
      zipCode,
      postalCode,
      usCounty,
      contactPhoneNumber,
      extension,
      primary,
    }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/locations`,
        method: "POST",
        body: {
          data: {
            type: "locations",
            attributes: {
              organizationId: orgId,
              name,
              country,
              address_line_1: addressLine1,
              address_line_2: addressLine2,
              city,
              provinceOrTerritory:
                country === "Canada" ? provinceOrTerritory : null,
              usState: country === "United States" ? usState : null,
              zipCode: country === "United States" ? zipCode : null,
              postalCode: country === "Canada" ? postalCode : null,
              usCountyId: country === "United States" ? usCounty : null,
              contactPhoneNumber: formatPhoneNumberForApi({
                phoneNumber: contactPhoneNumber,
                extension: extension,
              }),
              primary,
            },
          },
        },
      }),
    {
      onSuccess: (data) => {
        setIsSuccess(true);
        onUpdateSuccess(data);
      },
      onError: (e) => {
        setIsSuccess(false);
        onUpdateError(e);
      },
    }
  );

  const { mutate: updateLocationDetails, isLoading } = useMutation(
    ({
      name,
      country,
      addressLine1,
      addressLine2,
      city,
      usState,
      provinceOrTerritory,
      zipCode,
      postalCode,
      usCounty,
      contactPhoneNumber,
      extension,
      primary,
      status,
    }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/locations/${location.id}`,
        method: "PATCH",
        body: {
          data: {
            id: location.id,
            type: "locations",
            attributes: {
              name,
              country,
              address_line_1: addressLine1,
              address_line_2: addressLine2,
              city,
              provinceOrTerritory:
                country === "Canada" ? provinceOrTerritory : null,
              usState: country === "United States" ? usState : null,
              zipCode: country === "United States" ? zipCode : null,
              postalCode: country === "Canada" ? postalCode : null,
              usCountyId: country === "United States" ? usCounty : null,
              contactPhoneNumber: formatPhoneNumberForApi({
                phoneNumber: contactPhoneNumber,
                extension: extension,
              }),
              primary,
              status: status ? "closed" : "active",
            },
          },
        },
      }),
    {
      onSuccess: (data) => {
        setIsSuccess(true);
        onUpdateSuccess(data);
      },
      onError: (e) => {
        setIsSuccess(false);
        onUpdateError(e);
      },
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={LocationSchema}
      onSubmit={
        formMode === FORM_MODES.ADD ? addLocation : updateLocationDetails
      }
    >
      {({ handleSubmit, values }) => (
        <Form>
          <div className="grid lg:grid-cols-2 gap-6 mb-6">
            {/* Name */}
            <FormField
              name="name"
              label="Location Name"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />

            <FormField
              name="country"
              label="Country"
              as="select"
              data-testid="country"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            >
              <option disabled value="" hidden>
                -- Select a Country --
              </option>
              <option value="United States">United States</option>
              <option value="Canada">Canada</option>
            </FormField>
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Address Line 1 */}
            <FormField
              name="addressLine1"
              label="Street Address"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />

            {/* Address Line 2 */}
            <FormField
              name="addressLine2"
              label="Street Address 2"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
            />
          </div>

          <div className="grid grid-cols-3 gap-6 mb-6">
            {/* City */}
            <FormField
              name="city"
              label="City"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />

            {/* State | Province */}
            {values.country === "United States" ? (
              <StateDropdown
                name="usState"
                label="State"
                data-testid="usState"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              />
            ) : (
              <FormField
                name="provinceOrTerritory"
                label="Province/Territory"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                as="select"
                required
              >
                <option disabled value="" hidden>
                  -- Select a Province --
                </option>
                <option value="AB">AB</option>
                <option value="BC">BC</option>
                <option value="MB">MB</option>
                <option value="NB">NB</option>
                <option value="NL">NL</option>
                <option value="NT">NT</option>
                <option value="NS">NS</option>
                <option value="NU">NU</option>
                <option value="ON">ON</option>
                <option value="PE">PE</option>
                <option value="QC">QC</option>
                <option value="SK">SK</option>
                <option value="YT">YT</option>
              </FormField>
            )}

            {/* Zip Code | Postal code */}
            {values.country === "United States" ? (
              <FormField
                name="zipCode"
                label="Zip Code"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              />
            ) : (
              <FormField
                name="postalCode"
                label="Postal Code"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              />
            )}
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* County */}
            {values.country === "United States" && (
              <CountyDropdown
                fieldName="usCounty"
                usState={values.usState}
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              />
            )}
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Contact Phone Number */}
            <FormField
              name="contactPhoneNumber"
              label="Contact Phone Number"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />

            {/* Extension */}
            <FormField
              name="extension"
              label="Extension"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
            />
          </div>

          {/* Primary */}
          <div className="grid grid-cols-2 gap-6 mb-6">
            <FormField
              name="primary"
              label="Primary Location"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              type="checkbox"
            />

            {formMode !== FORM_MODES.ADD && isAdmin && (
              <FormField
                name="status"
                label="Close Location"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                type="checkbox"
              />
            )}
          </div>

          {formMode !== FORM_MODES.VIEW && (
            <div className="flex justify-end space-x-4">
              <Button to={cancelRoute} emphasis="transparent">
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={isLoading || isLoadingAdd}
                isLoading={isLoading || isLoadingAdd}
                loadingText={<>Saving&hellip;</>}
                onClick={handleSubmit}
              >
                {formMode === FORM_MODES.ADD ? "Add" : "Update"}
              </Button>
            </div>
          )}

          <FormContext
            formMode={formMode}
            location={location}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
          />
        </Form>
      )}
    </Formik>
  );
};

LocationForm.propTypes = {
  location: PropTypes.object.isRequired,
  formMode: PropTypes.string,
  cancelRoute: PropTypes.string.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  onUpdateError: PropTypes.func.isRequired,
};

LocationForm.defaultProps = {
  formMode: FORM_MODES.VIEW,
};

export default LocationForm;

const FormContext = ({ formMode, location, isSuccess, setIsSuccess }) => {
  const { resetForm, dirty } = useFormikContext();

  useEffect(() => {
    resetForm({
      values: getInitialValues({ location, formMode }),
    });
  }, [formMode, location, resetForm]);

  useEffect(() => {
    if (dirty) {
      setIsSuccess(false);
    }
  }, [dirty, setIsSuccess]);

  return (
    <RouteLeavingGuard
      when={!isSuccess && dirty}
      shouldBlockNavigation={() => !isSuccess && dirty}
    />
  );
};

const getInitialValues = ({ location, formMode }) => {
  const isViewMode = formMode === FORM_MODES.VIEW;
  const contactPhoneNumber = formatPhoneNumberForFormFields(
    location.attributes.contactPhoneNumber
  );

  return {
    name: location.attributes.name || (isViewMode ? "--" : ""),
    country:
      location.attributes.country || (isViewMode ? "--" : "United States"),
    addressLine1: location.attributes.addressLine1 || (isViewMode ? "--" : ""),
    addressLine2: location.attributes.addressLine2 || (isViewMode ? "--" : ""),
    city: location.attributes.city || (isViewMode ? "--" : ""),
    usState: location.attributes.usState || (isViewMode ? "--" : ""),
    zipCode: location.attributes.zipCode || (isViewMode ? "--" : ""),
    provinceOrTerritory:
      location.attributes.provinceOrTerritory || (isViewMode ? "--" : ""),
    postalCode: location.attributes.postalCode || (isViewMode ? "--" : ""),
    usCounty: location.attributes.usCountyId || (isViewMode ? "--" : ""),
    contactPhoneNumber:
      contactPhoneNumber.phoneNumber || (isViewMode ? "--" : ""),
    extension: contactPhoneNumber.extension || (isViewMode ? "--" : ""),
    primary: location.attributes.primary || false,
    status: location.attributes.status === "closed" || false,
  };
};
