import React from "react";
import PropTypes from "prop-types";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatUiErrors } from "utils";
import Button from "components/Button";

const ConfirmationModal = ({
  prompt,
  isLoading,
  error,
  closeModal,
  confirmModal,
}) => {
  return (
    <div className="text-center">
      {isLoading ? (
        <div className="italic">Loading&hellip;</div>
      ) : (
        <>
          {error ? (
            <>
              <h3 className="text-2xl mb-4 text-red">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-2"
                />
                Error
              </h3>
              <div className="mb-6">{formatUiErrors(error)}</div>
            </>
          ) : (
            <h3 className="text-2xl mb-6">{prompt}</h3>
          )}

          <div className="flex items-center justify-center space-x-4">
            <Button onClick={closeModal} emphasis="secondary">
              Cancel
            </Button>
            <Button onClick={confirmModal}>
              {error ? "Try Again" : "Confirm"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

ConfirmationModal.propTypes = {
  prompt: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  closeModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
  isLoading: false,
  error: null,
};

export default ConfirmationModal;
