import { useEffect, useRef } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import { getQueryParams } from "./queryParams";
import { useRouteGuard, formatUiErrors } from "utils";
import { useUserDispatch, useUserState } from "context";
import { USER_UPDATE } from "actions";

export function useQueryParams() {
  const { search } = useLocation();
  return getQueryParams(search);
}

export function useEvent(event, handler, passive = false) {
  useEffect(() => {
    // initiate the event handler
    window.addEventListener(event, handler, passive);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, handler);
    };
  });
}

/**
 * Gets route guards and returns permission functions
 * @example
 * const permissions = usePermissions();
 * // returns true if user is a system admin, false if they are not
 * permissions[ROUTE_GUARDS.ADMIN]();
 * @return {object} permissions object
 * @param {string} [key] each key returned is a property on the ROUTE_GUARDS constant
 */
export function usePermissions() {
  const match = useRouteMatch();
  const routeGuards = useRouteGuard(match);

  const perms = Object.keys(routeGuards).reduce((perms, key) => {
    // The permission version of the routeGuard should cast the routeGuard result
    // to a boolean value and return the opposite. So if a routeGuard returns `"/coalitions"`
    // as the redirect value, the corresponding permission should return `false`.
    // The logic is that a truthy routeGuard redirect value will render a <Redirect />
    // component in the PrivateRoute component, and a `false` permission will suppress
    // whatever UI element(s) it's wrapping. Both keep the user from seeing content they
    // should not be allowed to see.
    perms[key] = (...args) => !routeGuards[key](...args);
    return perms;
  }, {});
  return perms;
}

export function useInvalidateQueries() {
  const queryClient = useQueryClient();

  return function (queries) {
    if (!queries) return;
    queries.forEach((query) => {
      queryClient.invalidateQueries(query.key, { ...query.config });
    });
  };
}

export function useHandleRequestStates() {
  const invalidateQueries = useInvalidateQueries();

  return {
    handleSuccess: ({ message, queries }) => {
      toast.success(message, {
        autoClose: 3000,
      });

      if (queries && queries.length) invalidateQueries(queries);
    },
    handleError: (error) => {
      toast.error(() => formatUiErrors(error), {
        autoClose: 5000,
      });
    },
  };
}

export function useManageCoalitionMemberships() {
  const { coalitions } = useUserState();
  const dispatch = useUserDispatch();

  return {
    createContributorMembership: (membership) => {
      dispatch({
        type: USER_UPDATE,
        payload: {
          coalitions: createNewOrgMembership({ coalitions, membership }),
        },
      });
    },
    deleteContributorMembership: (coalitionId) => {
      dispatch({
        type: USER_UPDATE,
        payload: {
          coalitions: deleteOrgMembership({ coalitions, coalitionId }),
        },
      });
    },
  };
}

const createNewOrgMembership = ({ coalitions, membership }) => {
  const newCoalitions = cloneDeep(coalitions);
  const coalitionId = String(membership.attributes.coalitionId);

  if (newCoalitions[coalitionId]) {
    newCoalitions[coalitionId].orgMembership = membership;
  } else {
    newCoalitions[coalitionId] = {
      orgMembership: membership,
    };
  }

  return newCoalitions;
};

const deleteOrgMembership = ({ coalitions, coalitionId }) => {
  const newCoalitions = cloneDeep(coalitions);
  delete newCoalitions[coalitionId].orgMembership;
  return newCoalitions;
};

/**
 * Sets up an interval and clears it after unmounting
 * @param callback  callback function
 * @param delay     interval delay in millseconds
 */
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
