import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePickerField from "components/DatePickerField";
import { Formik, Form } from "formik";
import { API_URL, ALLSTATES } from "utils";
import { useAuthState } from "context";
import { decamelizeKeys } from "humps";
import Button from "components/Button";
import { endOfMonth, startOfMonth } from "date-fns";
import { MultiSelect } from "react-multi-select-component";

const options = ALLSTATES;

const CoalitionFilter = ({ coalitionId, filters, setFilters, searchClick }) => {
  const currentYear = new Date().getFullYear();

  const [allOrgs, setallOrgs] = useState([]);
  const { token } = useAuthState();
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState([]);
  const [selectedOrgType, setSelectedOrgType] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [selected, setSelected] = useState([]);

  const fetchOrgs = async (orgTypes) => {
    const { accessToken, client, expiry, uid } = token;
    var orgType = orgTypes.map((a) => a.value);
    try {
      const response = await fetch(
        `${API_URL}/api/v1/organizations/coalition_organization`,
        {
          method: "POST",
          headers: {
            "access-token": accessToken,
            "token-type": "Bearer",
            "Content-Type": "application/json",
            client,
            expiry,
            uid,
          },
          body: JSON.stringify(
            decamelizeKeys({
              coalitionId: coalitionId,
              counties: selectedCounty,
              orgType: orgType,
            })
          ),
        }
      );
      const res = await response.json();
      var orgs = res.map((a) => {
        return { label: a.name, value: a.id };
      });
      setallOrgs(orgs.sort((a, b) => a.label.localeCompare(b.label)));
    } catch (error) {}
  };
  const fetchCounties = async (usState) => {
    var states = usState.map((a) => a.value);
    if (states.length == 0) return "";
    var cont = [];
    for (let index = 1; index < 5; index++) {
      const response = await fetch(
        // Page size is 260 because the max number of counties for a given state is 254
        `${API_URL}/api/v1/us_counties?filter[us_state][eq]=${states}&page[size]=1000&page[number]=${index}`,
        {
          method: "GET",
        }
      );
      const {
        data,
        error: apiError,
        errors: apiErrors,
      } = await response.json();

      if (!response.ok) {
        const resErrors = apiErrors || apiError;
        throw resErrors;
      }
      var newStates = data.map((a) => {
        return { label: a.attributes.name, value: a.id };
      });
      cont = cont.concat(newStates);
    }
    setCounties(cont);
  };

  useEffect(() => {
    fetchCounties(selected);
  }, [selected]);

  useEffect(() => {
    fetchOrgs(selectedOrgType);
  }, [selectedOrgType]);

  useEffect(() => {
    setFilters(filters);
  }, [setFilters, filters]);

  /**
   * FORM METHODS
   */
  const onSubmitFilters = (values) => {
    if (values.year instanceof Date) {
      const yearAsFourDigits = String(values.year.getFullYear());
      values.year = yearAsFourDigits;
    }
    values.organization_ids = selectedOrg;
    setFilters(values);
    localStorage.setItem(
      "filters",
      JSON.stringify({
        orgName: filters.orgName,
        year: String(currentYear),
      })
    );
    searchClick(true);
  };

  return (
    <div className="sac-border mb-6">
      <div className="">
        <div className="grid grid-cols-4 gap-6 mb-6">
          <div className="">
            State<span className="text-sm required">*</span>
            <MultiSelect
              options={options}
              value={selected}
              className="grid gap-6 mb-6"
              onChange={setSelected}
              labelledBy="-- Select a State --"
            />
          </div>
          <div className="">
            Counties<span className="text-sm required">*</span>
            <MultiSelect
              options={counties}
              value={selectedCounty}
              className="grid gap-6 mb-6"
              onChange={setSelectedCounty}
              labelledBy="-- Select a Country --"
            />
          </div>

          <div className="">
            Organization Type<span className="text-sm required">*</span>
            <MultiSelect
              options={[
                {
                  label: "All Organization Types",
                  value: "",
                },
                {
                  label: "Government Animal Services",
                  value: "Government Animal Services",
                },
                {
                  label: "Animal Shelter with a Government Contract",
                  value: "Animal Shelter with a Government Contract",
                },
                {
                  label: "Animal Rescue with a Government Contract",
                  value: "Animal Rescue with a Government Contract",
                },
                {
                  label: "Shelter without a Government Contract",
                  value: "Shelter without a Government Contract",
                },
                {
                  label: "Rescue without a Government Contract",
                  value: "Rescue without a Government Contract",
                },
              ]}
              value={selectedOrgType}
              className="grid gap-6 mb-6"
              onChange={setSelectedOrgType}
            />
          </div>

          <div className="">
            Organization Name<span className="text-sm required">*</span>
            <MultiSelect
              options={allOrgs}
              value={selectedOrg}
              className="grid gap-6 mb-6"
              onChange={setSelectedOrg}
            />
          </div>
        </div>
      </div>
      <Formik initialValues={filters} onSubmit={onSubmitFilters}>
        {({ handleSubmit, values }) => {
          return (
            <Form>
              <div className="flex items-end space-x-10">
                <DatePickerField
                  label="Year"
                  name="year"
                  dateFormat="yyyy"
                  showMonthYearPicker={false}
                  showYearPicker={true}
                  maxDate={endOfMonth(new Date())}
                  minDate={startOfMonth(
                    new Date(2010, 1, 1, 0, 0)
                  )} /* Must be set to 2010 for 2011 to be the min year in UI */
                  required
                />
                <div className="flex items-center space-x-2">
                  <Button type="submit" onClick={handleSubmit}>
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      values.orgName = "";
                      values.year = String(currentYear);
                      localStorage.setItem(
                        "filters",
                        JSON.stringify({
                          orgName: "",
                          year: String(currentYear),
                        })
                      );
                      setFilters({
                        orgName: "",
                        year: String(currentYear),
                      });
                      setCounties([]);
                      setSelectedCounty([]);
                      setSelectedOrgType([]);
                      setSelected([]);
                    }}
                    emphasis="transparent"
                  >
                    Reset Filters
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

CoalitionFilter.propTypes = {
  coalitionId: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    orgName: PropTypes.string,
    year: PropTypes.string,
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default CoalitionFilter;
