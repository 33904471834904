import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Table, { getRowData, columns } from "components/Table";
import { animalOutcomes } from "components/Table/__mocks__";
import AnimalOutcomeForm from "./AnimalOutcomeForm";
import { speciesMap } from "utils";

const AnimalOutcome = forwardRef(
  ({ attributes, type, dataId, isEditing }, ref) => {
    const { species, locationId } = attributes;

    let data = [
      {
        rowLabel: "Adoption",
        keySubString: "AdoptionCount",
        tooltip: `Final adoptions only, having permanently left the agency's possession. For example, it does NOT include animals placed in foster care or on overnight "trial" stays.`,
      },
      {
        rowLabel: "Returned to owner",
        keySubString: "ReturnedToOwnerCount",
        tooltip: "Stray or Owner Relinquished animals returned to their owner",
      },
      {
        rowLabel: "Transferred in state",
        keySubString: "TransferredInstateToAnotherAgencyCount",
        tooltip:
          "Transferred out of the agency's possession to another entity within the same state or territory.",
      },
      {
        rowLabel: "Transferred out of state",
        keySubString: "TransferredOutOfStateToAnotherAgencyCount",
        tooltip:
          "Transferred out of the agency's possession to another entity in a different state or territory.",
      },
      {
        rowLabel: "Transferred internationally",
        keySubString: "TransferredInternationallyToAnotherAgencyCount",
        tooltip:
          "Transferred out of the agency's possession to another entity outside the US.",
      },
      {
        rowLabel: "Transfers Out (undesignated)",
        keySubString: "LegacyTransferredToAnotherAgencyCount",
        tooltip:
          "Transfer in to agency (undesignated) represents Transfer In data prior to when agency location break outs.",
      },
      {
        rowLabel: "Returned to field",
        keySubString: "ReturnedToFieldCount",
        tooltip:
          "Animals included in intake, already altered or altered after intake, and returned to stray capture location to be released (often referred to as SNR). This is not TNR.",
      },
      {
        rowLabel: "Other live outcome",
        keySubString: "OtherLiveOutcomeCount",
        tooltip: "Other types of live outcome not captured above.",
      },
    ].map(({ rowLabel, keySubString, tooltip }) =>
      getRowData({
        data: attributes,
        accessKeys: ["adult", "youth", "ageUnknown", "total"],
        rowLabel,
        keySubString,
        tooltip,
      })
    );

    if (type === "other") {
      data = [
        {
          rowLabel: "Died in care",
          keySubString: "DiedInCareCount",
          tooltip: "Animals who die, unassisted, while sheltered or fostered.",
        },
        {
          rowLabel: "Lost in care",
          keySubString: "LostInCareCount",
          tooltip:
            "Animals whose outcome is unknown (may have escaped the shelter, outcome was not recorded and unknown).",
        },
        {
          rowLabel: "Shelter euthanasia",
          keySubString: "ShelterEuthanasiaCount",
          tooltip:
            "All euthanasia other than that performed by the definition below as owner intended euthanasia.",
        },
        {
          rowLabel: "Owner intended euthanasia",
          keySubString: "OwnerIntendedEuthanasiaCount",
          tooltip:
            "Animals included in intake whose owner brought the pet to the shelter with the INTENT of requesting euthanasia. OIE provided as a service outside of intake is not counted here.",
        },
      ].map(({ rowLabel, keySubString, tooltip }) =>
        getRowData({
          data: attributes,
          accessKeys: ["adult", "youth", "ageUnknown", "total"],
          rowLabel,
          keySubString,
          tooltip,
        })
      );
    }

    if (type === "other") {
      const arr = [
        "locationId",
        "monthOfRecord",
        "yearOfRecord",
        "dateOfRecord",
        "archivedAt",
        "createdAt",
        "updatedAt",
        "DiedInCareCount",
        "LostInCareCount",
        "ShelterEuthanasiaCount",
        "OwnerIntendedEuthanasiaCount",
        "species",
      ];
      let resultObj = {};
      const getAllKeys = Object.keys(attributes);
      arr.forEach(function (item) {
        getAllKeys.forEach(function (keyName) {
          if (keyName.indexOf(item) !== -1) {
            resultObj[keyName] = attributes[keyName];
          }
        });
      });
      attributes = resultObj;
    } else {
      const arr = [
        "DiedInCareCount",
        "LostInCareCount",
        "ShelterEuthanasiaCount",
        "OwnerIntendedEuthanasiaCount",
      ];
      let resultObj = {};
      const getAllKeys = Object.keys(attributes);
      getAllKeys.forEach(function (keyName) {
        if (!arr.some((v) => keyName.includes(v))) {
          resultObj[keyName] = attributes[keyName];
        }
      });
      attributes = resultObj;
    }
    return (
      <div className="mt-12">
        <div className="flex items-center justify-between space-x-4 mb-4">
          <h3 className="text-2xl flex items-center">
            <img src={speciesMap[species].icon} alt="" className="mr-4" />
            {speciesMap[species].label}
          </h3>
        </div>
        {!isEditing ? (
          <Table
            data={data}
            columns={columns}
            columnAlignments={[null, null, null, null, "text-right"]}
          />
        ) : (
          <div>
            <AnimalOutcomeForm
              dataId={dataId}
              key={`${species}-${type}-outcome-${locationId}`}
              type={type}
              attributes={attributes}
              isEditing={isEditing}
              ref={ref}
            />
          </div>
        )}
      </div>
    );
  }
);

AnimalOutcome.propTypes = {
  attributes: PropTypes.array,
  dataId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["live", "other"]).isRequired,
  isEditing: PropTypes.bool.isRequired,
};

AnimalOutcome.defaultProps = {
  attributes: animalOutcomes,
};

AnimalOutcome.propTypes = {
  type: PropTypes.oneOf(["live", "other"]),
  attributes: PropTypes.shape({
    species: PropTypes.string.isRequired,
    adultAdoptionCount: PropTypes.number,
    youthAdoptionCount: PropTypes.number,
    ageUnknownAdoptionCount: PropTypes.number,
    adultReturnedToOwnerCount: PropTypes.number,
    youthReturnedToOwnerCount: PropTypes.number,
    ageUnknownReturnedToOwnerCount: PropTypes.number,
    adultReturnedToFieldCount: PropTypes.number,
    youthReturnedToFieldCount: PropTypes.number,
    ageUnknownReturnedToFieldCount: PropTypes.number,
    adultOtherLiveOutcomeCount: PropTypes.number,
    youthOtherLiveOutcomeCount: PropTypes.number,
    ageUnknownOtherLiveOutcomeCount: PropTypes.number,
    adultDiedInCareCount: PropTypes.number,
    youthDiedInCareCount: PropTypes.number,
    ageUnknownDiedInCareCount: PropTypes.number,
    adultLostInCareCount: PropTypes.number,
    youthLostInCareCount: PropTypes.number,
    ageUnknownLostInCareCount: PropTypes.number,
    adultShelterEuthanasiaCount: PropTypes.number,
    youthShelterEuthanasiaCount: PropTypes.number,
    ageUnknownShelterEuthanasiaCount: PropTypes.number,
    adultOwnerIntendedEuthanasiaCount: PropTypes.number,
    youthOwnerIntendedEuthanasiaCount: PropTypes.number,
    ageUnknownOwnerIntendedEuthanasiaCount: PropTypes.number,
    adultTransferredInstateToAnotherAgencyCount: PropTypes.number,
    youthTransferredInstateToAnotherAgencyCount: PropTypes.number,
    ageUnknownTransferredInstateToAnotherAgencyCount: PropTypes.number,
    adultTransferredOutOfStateToAnotherAgencyCount: PropTypes.number,
    youthTransferredOutOfStateToAnotherAgencyCount: PropTypes.number,
    ageUnknownTransferredOutOfStateToAnotherAgencyCount: PropTypes.number,
    adultTransferredInternationallyToAnotherAgencyCount: PropTypes.number,
    youthTransferredInternationallyToAnotherAgencyCount: PropTypes.number,
    ageUnknownTransferredInternationallyToAnotherAgencyCount: PropTypes.number,
    adultLegacyTransferredToAnotherAgencyCount: PropTypes.number,
    youthLegacyTransferredToAnotherAgencyCount: PropTypes.number,
    ageUnknownLegacyTransferredToAnotherAgencyCount: PropTypes.number,
    totalAdoptionCount: PropTypes.number,
    totalReturnedToOwnerCount: PropTypes.number,
    totalTransferredInstateToAnotherAgencyCount: PropTypes.number,
    totalTransferredOutOfStateToAnotherAgencyCount: PropTypes.number,
    totalTransferredInternationallyToAnotherAgencyCount: PropTypes.number,
    totalTransferredToAnotherAgencyCount: PropTypes.number,
    totalReturnedToFieldCount: PropTypes.number,
    totalOtherLiveOutcomeCount: PropTypes.number,
    totalDiedInCareCount: PropTypes.number,
    totalLostInCareCount: PropTypes.number,
    totalShelterEuthanasiaCount: PropTypes.number,
    totalOwnerIntendedEuthanasiaCount: PropTypes.number,
    totalAdultOutcomesCount: PropTypes.number,
    totalYouthOutcomesCount: PropTypes.number,
    totalAgeUnknownOutcomesCount: PropTypes.number,
    totalOutcomesCount: PropTypes.number,
  }),
};

export default AnimalOutcome;
