import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import {
  apiFetch,
  getMonthName,
  getMonthAbbr,
  formatUiErrors,
  camelToTitle,
} from "utils";
import {
  isLastStackedBar,
  getMonthColor,
  transformGraphData,
  speciesBuckets,
  graphColors,
  constructEndpoint,
} from "./utils";
// TODO: Upgrade recharts to 2.0.x when this issue is fixed:
// https://github.com/recharts/recharts/issues/2360
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import IntakeOverviewGraphLegend from "./IntakeOverviewGraphLegend";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import DateFilter from "components/IntakeOverviewGraphFilters/DateFilter";

const IntakeOverviewGraph = ({
  coalitionId,
  coalitionPage,
  coalitionFilters,
  searchClick,
  setSearchClick,
  action,
  adminLocation,
}) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { attributes } = useUserState();

  var locationId = attributes.locationId;
  if (!!adminLocation) locationId = adminLocation;

  const [year, setYear] = useState(new Date().getFullYear());
  const dashboardEndpoint =
    action === "services"
      ? `/api/v1/locations/${locationId}?include=animal_services&filter[animal_services.year_of_record][eq]=${year}`
      : `/api/v1/locations/${locationId}?include=animal_intakes&filter[animal_intakes.year_of_record][eq]=${year}`;

  const { orgName: organizationId, year: coalitionYear } = coalitionFilters;

  const coalitionEndpoint = constructEndpoint({
    coalitionId,
    organizationId,
    coalitionFilters,
    coalitionYear,
    action,
  });

  const {
    isLoading,
    error,
    data: response,
    refetch,
  } = useQuery(`intakeOverviewGraph${action}`, async () => {
    const req = await apiFetch({
      token,
      dispatch,
      endpoint: coalitionPage ? coalitionEndpoint : dashboardEndpoint,
    });
    if (setSearchClick) setSearchClick(false);
    return transformGraphData(req);
  });

  useEffect(refetch, [
    locationId,
    year,
    organizationId,
    isLoading,
    coalitionFilters,
    searchClick,
    refetch,
    action,
  ]);

  if (isLoading) {
    return (
      <LoadingIndicator inline content={<>Loading intake graph&hellip;</>} />
    );
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  return (
    <>
      {/* Filters */}
      {!coalitionPage && <DateFilter setYear={setYear} year={year} />}

      {/* Legend */}
      <IntakeOverviewGraphLegend />
      {/* Graph */}
      <div data-testid="intake-overview-graph">
        <ResponsiveContainer width="100%" height={430}>
          <BarChart
            data={response}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid stroke={graphColors.line} vertical={false} />
            <XAxis
              stroke={graphColors.line}
              tickLine={false}
              axisLine={{ stroke: graphColors.line }}
              tick={<CustomXTick />}
              dataKey="month_of_record"
            />
            <YAxis
              stroke={graphColors.yAxis}
              axisLine={false}
              tickLine={false}
              tickSize={0}
              tick={<CustomYTick />}
            />
            <Tooltip
              cursor={{ fill: graphColors.tooltipBg }}
              labelFormatter={(val) => (
                <span className="uppercase">{getMonthName(val)}</span>
              )}
              formatter={(val, name) => [val, camelToTitle(name)]}
              itemStyle={{ marginBottom: "-6px" }}
            />
            <Bar
              dataKey={speciesBuckets.canine}
              stackId="a"
              fill={graphColors.canine}
              shape={<CustomBar dataKey={speciesBuckets.canine} />}
            />
            <Bar
              dataKey={speciesBuckets.feline}
              stackId="a"
              fill={graphColors.feline}
              shape={<CustomBar dataKey={speciesBuckets.feline} />}
            />
            <Bar
              dataKey={speciesBuckets.other}
              stackId="a"
              fill={graphColors.other}
              shape={<CustomBar dataKey={speciesBuckets.other} />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

IntakeOverviewGraph.propTypes = {
  coalitionId: PropTypes.string,
  coalitionPage: PropTypes.bool,
  coalitionFilters: PropTypes.shape({
    orgName: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }),
  action: PropTypes.string,
};

IntakeOverviewGraph.defaultProps = {
  coalitionId: "",
  coalitionPage: false,
  coalitionFilters: {
    orgName: "",
    year: String(new Date().getFullYear()),
  },
  action: "data-entry",
};

export default IntakeOverviewGraph;

const CustomBar = (props) => {
  const { dataKey, x, y, fill, width, height, payload } = props;

  return (
    <>
      <rect
        fill={fill}
        rx={isLastStackedBar(payload, dataKey) ? 2 : 0}
        width={width}
        height={height}
        x={x}
        y={y}
        className="recharts-rectangle"
      ></rect>

      {/* "Fakes" radius=0 on the bottom 2 corners since <rect> elements
      can only have a radius value that applies to all 4 corners */}
      {isLastStackedBar(payload, dataKey) ? (
        <rect
          fill={fill}
          width={width}
          height={2}
          x={x}
          y={y + height - 2}
          className="recharts-rectangle"
        ></rect>
      ) : null}
    </>
  );
};

const CustomXTick = (props) => {
  const { x, y, height, textAnchor, payload } = props;

  return (
    <g className={`${getMonthColor(payload.value)} text-xs`}>
      <text
        x={x}
        y={y}
        dy={height / 2}
        textAnchor={textAnchor}
        className="uppercase fill-current"
      >
        {getMonthAbbr(payload.value)}
      </text>
    </g>
  );
};

const CustomYTick = (props) => {
  const { index, y, width, payload } = props;

  return (
    <g className="text-dashboard-pale-blue text-xs">
      {/* Tick line */}
      <rect
        x={0}
        y={y - 0.5}
        width={width}
        height={1}
        className="fill-current"
      />

      {/* Y axis value */}
      {index !== 0 ? (
        <text
          x={-width}
          y={y}
          dy={20}
          dx={width}
          className="text-dashboard-pale-gray fill-current"
        >
          {payload.value}
        </text>
      ) : null}
    </g>
  );
};
