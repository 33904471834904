import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, useFormikContext } from "formik";
import FormField from "components/FormField";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import StateDropdown from "components/StateDropdown";
import * as Yup from "yup";
import { useMutation } from "react-query";
import {
  apiFetch,
  FORM_MODES,
  COALITION_TYPES,
  constructCoalitionOperatingRegion,
} from "utils";
import { useAuthState, useAuthDispatch } from "context";
import Button from "components/Button";

const CoalitionForm = ({
  coalition,
  formMode,
  cancelRoute,
  onUpdateSuccess,
  onUpdateError,
}) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const initialValues = getInitialValues({ coalition, formMode });
  const [showState, setShowState] = useState(false);

  const CoalitionSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    country: Yup.string().required("Country is required"),
    description: Yup.string().required("Description is required"),
    dataUsed: Yup.string().required("This is required"),
    dataUseAgreement: Yup.boolean().oneOf([true], "This is required"),
    operatingRegionType: Yup.string().required("Geographic area is required"),
    operatingRegion: Yup.lazy((val) => {
      const isRequired = document.getElementById("operatingRegion");
      if (Array.isArray(val) && isRequired) {
        return Yup.array()
          .of(Yup.string())
          .required("State/Province is required");
      } else if (Array.isArray(val)) {
        return Yup.array().of(Yup.string());
      } else if (isRequired) {
        return Yup.string().required("State/Province is required");
      }
      return Yup.string();
    }),
    allIntakeOutcomeDataRequest: Yup.boolean(),
    allCommunityServiceDataRequest: Yup.boolean(),
  });

  const { mutate: addCoalition, isLoading } = useMutation(
    ({
      name,
      country,
      description,
      dataUsed,
      dataUseAgreement,
      operatingRegionType,
      operatingRegion,
      allIntakeOutcomeDataRequest,
      allCommunityServiceDataRequest,
    }) =>
      apiFetch({
        token,
        dispatch,
        endpoint:
          formMode === FORM_MODES.EDIT
            ? "/api/v1/coalitions/" + coalition.id
            : "/api/v1/coalitions",
        method: formMode === FORM_MODES.EDIT ? "PUT" : "POST",
        body: {
          data: {
            id: coalition.id,
            type: "coalitions",
            attributes: {
              name,
              country,
              description,
              operatingRegionType,
              dataUsed,
              dataUseAgreement,
              operatingRegion:
                constructCoalitionOperatingRegion(operatingRegion),
              allIntakeOutcomeDataRequest,
              allCommunityServiceDataRequest,
            },
          },
        },
      }),
    {
      onSuccess: (data) => {
        setIsSuccess(true);
        if (typeof onUpdateSuccess === "function") onUpdateSuccess(data);
      },
      onError: (e) => {
        setIsSuccess(false);
        if (typeof onUpdateError === "function") onUpdateError(e);
      },
    }
  );

  const stateContent = ({ operatingRegion, operatingRegionType, country }) => {
    // Suppress State field/value if showState is false (operatingRegionType === "National")
    if (!showState) return null;

    if (formMode === FORM_MODES.VIEW) {
      // Has multiple states (operatingRegionType === "Multi-State (2 or more)")
      if (Array.isArray(operatingRegion) && !!operatingRegion.length) {
        return (
          <div>
            <div className="text-sm text-medium-gray font-body-bold">
              {country === "United States" ? "State" : "Province/Territory"}
            </div>
            <div>{operatingRegion.join(", ")}</div>
          </div>
        );
      }
      // Has one state (operatingRegionType === "Statewide" || operatingRegionType === "Community")
      if (typeof operatingRegion === "string" && !!operatingRegion) {
        return (
          <div>
            <div className="text-sm text-medium-gray font-body-bold">
              {country === "United States" ? "State" : "Province/Territory"}
            </div>
            <div>{operatingRegion}</div>
          </div>
        );
      }
      // No state value
      return (
        <div>
          <div className="text-sm text-medium-gray font-body-bold">State</div>
          <div>--</div>
        </div>
      );
    }

    if (country === "Canada") {
      return (
        <FormField
          name="operatingRegion"
          label="Province/Territory"
          multiple={operatingRegionType === COALITION_TYPES.MULTIPROVINCE}
          as="select"
          required
          tooltip={
            <div>
              To select multiple provinces, hold down the Ctrl key (Windows) or
              the Command key (Mac) and click on each province.
            </div>
          }
          tooltipWidthClass="w-80"
        >
          <option disabled value="" hidden>
            -- Select a Province --
          </option>
          <option value="AB">AB</option>
          <option value="BC">BC</option>
          <option value="MB">MB</option>
          <option value="NB">NB</option>
          <option value="NL">NL</option>
          <option value="NT">NT</option>
          <option value="NS">NS</option>
          <option value="NU">NU</option>
          <option value="ON">ON</option>
          <option value="PE">PE</option>
          <option value="QC">QC</option>
          <option value="SK">SK</option>
          <option value="YT">YT</option>
        </FormField>
      );
    }

    return (
      <StateDropdown
        name="operatingRegion"
        label="State"
        multiple={operatingRegionType === COALITION_TYPES.MULTISTATE}
        required
        tooltip={
          <div>
            To select multiple states, hold down the Ctrl key (Windows) or the
            Command key (Mac) and click on each state.
          </div>
        }
        tooltipWidthClass="w-80"
      />
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={CoalitionSchema}
      onSubmit={addCoalition}
    >
      {({
        handleSubmit,
        values: {
          operatingRegion,
          operatingRegionType,
          country,
          allIntakeOutcomeDataRequest,
          allCommunityServiceDataRequest,
        },
        setFieldValue,
      }) => (
        <Form>
          <div className="grid lg:grid-cols-2 gap-6 mb-6">
            {/* Name */}
            <FormField
              name="name"
              label="Coalition Name"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />
          </div>

          <div className="grid lg:grid-cols-2 gap-6 mb-6">
            {/* Country */}
            <FormField
              name="country"
              label="Country"
              as="select"
              data-testid="country"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            >
              <option disabled value="" hidden>
                -- Select a Country --
              </option>
              <option value="United States">United States</option>
              <option value="Canada">Canada</option>
            </FormField>
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Type */}
            <FormField
              name="operatingRegionType"
              label="Geographic Area"
              as="select"
              data-testid="operatingRegionType"
              tooltip={
                <div>
                  Please select the state your coalition serves, or select
                  multi-state (for more than one) or national.
                </div>
              }
              tooltipWidthClass="w-80"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
              onChange={(e) => {
                const fieldValue = e.target.value;
                setFieldValue("operatingRegionType", fieldValue);

                // Reset field2 if field1 meets certain condition
                if (
                  fieldValue === "International" ||
                  fieldValue === "National"
                ) {
                  setFieldValue("operatingRegion", "");
                }
              }}
            >
              <option disabled value="" hidden>
                -- Select a Geographic Area --
              </option>
              <option value={COALITION_TYPES.INTERNATIONAL}>
                International
              </option>
              <option value={COALITION_TYPES.NATIONAL}>National</option>
              {country === "United States" ? (
                <option value={COALITION_TYPES.MULTISTATE}>
                  Multi-State (2 or more)
                </option>
              ) : (
                <option value={COALITION_TYPES.MULTIPROVINCE}>
                  Multi-Province (2 or more)
                </option>
              )}
              {country === "United States" ? (
                <option value={COALITION_TYPES.STATEWIDE}>Statewide</option>
              ) : (
                <option value={COALITION_TYPES.PROVINCEWIDE}>
                  Provincewide
                </option>
              )}
              <option value={COALITION_TYPES.COMMUNITY}>Community</option>
            </FormField>

            {/* State */}
            {stateContent({ operatingRegion, operatingRegionType, country })}
          </div>

          <div className="mb-6">
            {/* Requested Data */}
            <p className="text-medium-gray font-body-bold text-sm">
              Requested Data
            </p>

            <div className="grid grid-rows-2 gap-6">
              <div className="flex items-center gap-3">
                <p className="text-black mb-0">Intake/Outcome Data</p>
                <FormField
                  name="allIntakeOutcomeDataRequest"
                  label=""
                  checked={allIntakeOutcomeDataRequest}
                  type="checkbox"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                />
              </div>
              <div className="flex items-center gap-3">
                <p className="text-black mb-0">Community Service Data</p>
                <FormField
                  name="allCommunityServiceDataRequest"
                  checked={allCommunityServiceDataRequest}
                  type="checkbox"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                />
              </div>
            </div>
          </div>

          <div className="mb-6">
            {/* Description */}
            {formMode === FORM_MODES.VIEW ? (
              <>
                <FormField
                  name="description1"
                  label="Description"
                  tooltip={
                    <div>
                      Description should contain your coalition&rsquo;s mission
                      and focus (e.g. dog, cat, breed, or issue-specific, etc.).
                    </div>
                  }
                  tooltipWidthClass="w-80"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                />
                {coalition.attributes.description}
              </>
            ) : (
              <FormField
                name="description"
                label="Description"
                as="textarea"
                tooltip={
                  <div>
                    Description should contain your coalition&rsquo;s mission
                    and focus (e.g. dog, cat, breed, or issue-specific, etc.).
                  </div>
                }
                tooltipWidthClass="w-80"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              />
            )}
          </div>

          <div className="mb-6">
            {formMode === FORM_MODES.VIEW ? (
              <>
                <FormField
                  name="dataUsed2"
                  label="How will data be used by coalition?"
                  tooltipWidthClass="w-80"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                />
                {coalition.attributes.dataUsed}
              </>
            ) : (
              <FormField
                name="dataUsed"
                label="How will data be used by coalition?"
                as="textarea"
                tooltipWidthClass="w-80"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              />
            )}
          </div>

          <div className="mb-6">
            <div>
              Data Use Agreement
              <br />
              <br />
              <div style={{ width: "100%", height: 200, overflow: "scroll" }}>
                <b>Shelter Animals Count - Data Use Policy</b>
                <span className="my-2 block">
                  Shelter Animals Count (“SAC”) collects and shares information
                  and data on Shelters & Rescues, Intake/Outcome Data, Community
                  Services Data and other information relating to the operation
                  of animal shelters for the benefit of animals and communities.
                  SAC also provides a platform for the formation of groups of
                  shelters/rescues through its Coalition Builder tool to foster
                  collaboration and provide data tracking and reporting among
                  these groups at local, regional, and national levels (a
                  “Coalition”). For purposes of this Coalition Data Use
                  Agreement, all of the information and data collected and made
                  available by SAC, including information that is shared by an
                  SAC member organization with other Coalition members on the
                  SAC platform, is referred to as “Data” and the assembled Data
                  in any format maintained by SAC is referred to as the
                  “Database.”{" "}
                </span>
                <b className="underline">Backgroud</b>
                <span className="my-2 block">
                  In the spirit of the SAC mission statement, “to create, share
                  and steward the national database of sheltered animals that
                  provides facts and enables insights to save lives”, the SAC
                  Board of Directors has established a policy and agreement to
                  ensure we can both share Data and be good stewards of the Data
                  and its use.
                </span>
                <ul className="block list-disc my-2">
                  <li>
                    <span className="text-4xl mr-1">.</span>It is the
                    responsibility of those requesting access to Data and
                    forming Coalitions to take the time to fully understand the
                    limitations of the dataset (i.e. the Data may be incomplete
                    for a particular period of time, community-related Data may
                    not represent the bulk of animal intakes in that community,
                    etc.).
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Data alone is not a
                    complete representation of the work of an organization. To
                    truly understand the impact and value of an organization you
                    must seek out context for the numbers and explore the work
                    and impact of an organization beyond the numbers.
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Individuals,
                    institutions, or others seeking to utilize the Database by
                    downloading Data from the SAC website, forming a Coalition,
                    or sharing Data among Coalition members must adhere to this
                    Coalition Data Use Agreement.
                  </li>
                </ul>

                <b className="underline">Data Use</b>
                <span className="my-2 block">
                  This Coalition Data Use Agreement (“Agreement”) is between
                  you, the manager/owner of the Coalition and user of the Data
                  (“Recipient”), and SAC. Recipient agrees to comply with this
                  Agreement for each Coalition that you form and for all Data
                  that you collect from, use, or distribute to Coalition
                  members.
                </span>
                <span className="my-2 block font-medium">
                  If you are requesting Data on behalf of an entity or
                  organization, this Agreement applies to the entire
                  organization, and “Recipient” means you and that organization.
                  You represent and agree that you have the authority, and will
                  ensure that, all employees and contractors of your entity or
                  organization understand and will abide by this Agreement.
                </span>
                <span className="my-2 block">
                  After the Effective Date of this Agreement, Recipient's use or
                  Publication (as defined below) of Data that was received by
                  Recipient prior to the Effective Date shall become subject to
                  the terms of this Agreement.
                </span>

                <b className="underline">Term and Termination</b>
                <span className="my-2 block">
                  This Agreement will remain in full force and effect until
                  terminated as set forth herein. This Agreement will terminate
                </span>
                <span className="my-2 block">
                  (1) automatically without notice from SAC if Recipient fails
                  to comply with the provisions of this Agreement, or
                </span>
                <span className="my-2 block">
                  (2) immediately upon written notice by either Recipient or SAC
                  to the other. Recipient agrees that upon termination of this
                  Agreement, it shall return or destroy all copies of any Data,
                  in any and all media, in Recipient's custody or control;
                  provided that Recipient may retain a copy of the Data on its
                  system-wide back-up storage media, 931 Monroe Dr NE | Ste A102
                  #109 | Atlanta, GA 30308on the condition that any such
                  retained copies are not generally accessible and remain
                  subject to the terms of this Agreement. Recipient shall
                  certify as to its return or destruction of Data upon request
                  by SAC. Within 10 days after termination of this Agreement,
                  SAC shall have the right to terminate the Coalition and notify
                  all Coalition members of the termination.
                </span>
                <span className="my-2 block font-medium">
                  SAC reserves the right to limit or deny access to the Data,
                  Database, downloads, or Coalition Builder tool and to require
                  return or destruction of Data by Recipient, with or without
                  stated cause, at any time.
                </span>

                <b className="underline">Use of Data</b>
                <span className="my-2 block">
                  SAC hereby grants to the Recipient a non-exclusive, revocable,
                  limited, non-transferable license to use the Data solely for
                  (1) research, reporting, scholarly, or academic purposes, or
                  (2) Recipient's internal, non-commercial use. Recipient shall
                  not disclose any Data except as permitted by this Agreement
                  and subject to the following requirements:
                </span>
                <span className="my-2 block font-medium">
                  SAC reserves the right to limit or deny access to the Data,
                  Database, downloads, or Coalition Builder tool and to require
                  return or destruction of Data by Recipient, with or without
                  stated cause, at any time.
                </span>
                <ul className="block  list-disc my-2">
                  <li className="font-medium">
                    Data that is included in scholarly, research, or academic
                    publications or in any news reporting, websites, email,
                    social media, written communication, or video (collectively,
                    “Publication”) must not identify any person, individual
                    animal, organization, or shelter.
                  </li>
                  <li className="font-medium">
                    Data that is included in any Publication must include only
                    aggregated or summarized Data and shall not include the
                    complete or original Data set provided by SAC.
                  </li>
                  <li className="font-medium">
                    No Publication may disparage or otherwise portray in a
                    negative light SAC or any contributor to the Data. SAC has
                    adopted a purposefully positive view of the efforts and
                    intentions of individuals and agencies in animal welfare and
                    control. As a result, Recipient must, in the context of the
                    use of the Data, treat the member organizations of the
                    Database with respect, regardless of Recipient's personal
                    opinions.
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Each Publication
                    shall include an acknowledgement of “Shelter Animals Count”
                    as the source of the Data and include the following
                    legend:“The information contained herein was derived from
                    data supplied by Shelter Animals Count. Shelter Animals
                    Count specifically disclaims all responsibility for any
                    analysis, interpretations, conclusions and opinions
                    contained in or derived from the information presented.”
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Recipient shall
                    promptly notify SAC of any Publication in news reporting,
                    website, social media, promotional email, or video form and
                    provide the appropriate citation or location of such
                    Publication.
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Recipient must
                    promptly report to SAC any use or disclosure of the Data
                    that is not permitted by this Agreement of which it becomes
                    aware.
                  </li>
                </ul>

                <b className="underline">Prohibited Uses</b>
                <span className="my-2 block">
                  Except as set forth herein, Recipient may not reproduce,
                  lease, loan, distribute, or sublicense, disclose, or otherwise
                  transfer any Data, in whole or in part, to any other person or
                  entity. In addition, Recipient shall not:
                </span>
                <ul className="block  list-disc my-2">
                  <li>
                    <span className="text-4xl mr-1">.</span>Use Data to rate,
                    rank, score, or compare a shelter, organization, or Data
                    contributor to any other shelter, organization, or Data
                    contributor
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Use Data as
                    justification for criticizing, making negative statements
                    about, or vilifying the work of any individual,
                    organization, or shelter
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Use the Data to
                    create any derived product for resale, lease, or license
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Use the Data for any
                    commercial purpose without prior written consent from SAC
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Use the Data for any
                    illegal purpose
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Introduce into the
                    Data or the SAC website viruses, worms, or other material
                    that is malicious or technologically harmful
                  </li>
                  <li>
                    <span className="text-4xl mr-1">.</span>Upload illegal,
                    infringing, or defamatory content.{" "}
                  </li>
                </ul>
                <span className="my-2 block">
                  Recipient shall take all reasonable measures to prevent the
                  disclosure of the Data except as set forth in this Agreement
                  or as may be required by law. If Recipient receives a request
                  pursuant to court order or governmental proceeding to disclose
                  Data, then, prior to any such disclosure, Recipient will
                  provide SAC with reasonable prior notice and will use
                  reasonable efforts assist SAC in obtaining a protective order
                  or confidential treatment of the Data.{" "}
                </span>
                <span className="my-2 block">
                  SAC has taken measures to ensure that the Data is devoid of
                  information that could be used to identify individual animals,
                  persons, or organizations. If Recipient discovers any
                  individual-identifying or organization-identifying information
                  in the Data, Recipient shall immediately notify SAC and delete
                  such identifying information.
                </span>

                <b className="underline">SAC Representations and Warranties</b>
                <span className="my-2 block">
                  SAC REPRESENTS THAT IT HAS ALL RIGHTS REQUIRED TO MAKE
                  AVAILABLE AND DISTRIBUTE THE DATA ON THE TERMS SET FORTH IN
                  THIS AGREEMENT. EXCEPT FOR SUCH REPRESENTATION, THE DATA IS
                  PROVIDED “AS IS” AND “AS AVAILABLE” AND WITHOUT WARRANTY OF
                  ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                  NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                  PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF
                  PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
                  DISCLAIMED. WITHOUT LIMITING THE FOREGOING, SAC DOES NOT
                  WARRANT THAT: (A) THE DATA IS ACCURATE, COMPLETE, RELIABLE, OR
                  CORRECT; (B) THE DATA WILL BE AVAILABLE AT ANY PARTICULAR TIME
                  OR LOCATION; (C) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (D)
                  THE DATA IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR
                  (E) THE RESULTS OF USING THE DATA WILL MEET RECIPIENT'S
                  REQUIREMENTS. RECIPIENT'S USE OF THE DATA IS SOLELY AT
                  RECIPIENT'S OWN RISK.
                </span>

                <b className="underline">Recipient's Representations</b>
                <span className="my-2 block">
                  Recipient represents and warrants that it is in compliance
                  with all applicable local, state, tribal, and federal laws,
                  regulations and other requirements to which Recipient is
                  subject. The individual signing this Agreement as, or on
                  behalf of, Recipient is duly authorized to enter into this
                  Agreement. This Agreement is binding and enforceable on
                  Recipient in accordance with its terms.
                </span>

                <b className="underline">Limitation of Liability</b>
                <span className="my-2 block">
                  IN NO EVENT SHALL SAC BE LIABLE TO RECIPIENT OR ANY THIRD
                  PARTY, UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR
                  ANY OTHER LEGAL THEORY WITH RESPECT TO THE DATA FOR ANY (A)
                  DIRECT DAMAGES, OR (B) LOST PROFITS OR SPECIAL, INDIRECT,
                  INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND
                  WHATSOEVER, INCLUDING WITHOUT LIMITATION DAMAGES FOR THE
                  INABILITY TO USE EQUIPMENT OR ACCESS DATA, LOSS OF BUSINESS,
                  LOSS OF REVENUE OR PROFITS, BUSINESS INTERRUPTIONS, LOSS OF
                  INFORMATION OR DATA, OR OTHER FINANCIAL LOSS, ARISING OUT OF
                  THE USE OF, OR INABILITY TO USE, THE DATA, EVEN IF SAC HAS
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </span>

                <b className="underline">Indemnification</b>
                <span className="my-2 block">
                  Recipient shall defend, indemnify, and hold harmless SAC,
                  including its respective affiliates, directors, officers,
                  managers, employees, representatives, agents, assigns, and
                  successors, for, from, and against all costs, claims, losses,
                  liabilities, property damage, bodily injury or death, or
                  intellectual property infringement (including reasonable
                  attorneys' fees and expenses), incident to or arising out of
                  Recipient's: (a) breach of this Agreement; (b) infringement of
                  any third-party intellectual property right; (c) violation of
                  applicable law; and (d) use of the Data and any Publication.
                </span>

                <b className="underline">Equitable Relief</b>
                <span className="my-2 block">
                  Recipient recognizes and acknowledges that a breach of this
                  Agreement may cause SAC irreparable damage which cannot be
                  readily remedied in monetary damages in an action at law. In
                  the event of any default or breach by Recipient of this
                  Agreement, SAC shall be entitled to immediate injunctive
                  relief and/or specific performance as a remedy, without the
                  necessity of posting a bond or proving the inadequacy of money
                  damages. Such remedy shall be in addition to any other
                  remedies available.
                </span>

                <b className="underline">Miscellaneous</b>
                <span className="my-2 block">
                  (a) SAC may assign, transfer, or delegate any of its rights
                  and obligations hereunder at any time. Recipient shall not
                  transfer, assign, or sublicense, in whole or in part, any of
                  its rights or obligations under this Agreement without the
                  prior written consent of SAC.
                </span>
                <span className="my-2 block">
                  (b) No agency, partnership, joint venture, or employment
                  relationship is created as a result of the Agreement and
                  neither party has any authority of any kind to bind the other
                  in any respect outside of the terms described within this
                  Agreement.
                </span>
                <span className="my-2 block">
                  (c) This Agreement represents the entire agreement between
                  Recipient and SAC with respect to the Data and the Database
                  and supersedes all prior or contemporaneous communications and
                  proposals (whether oral, written or electronic). If any
                  provision of this Agreement is found to be unenforceable or
                  invalid, that provision will be limited or eliminated to the
                  minimum extent necessary so that the Agreement will otherwise
                  remain in full force and effect and enforceable, and the
                  invalid or unenforceable provision shall be modified to the
                  extent necessary to allow for enforceability and to give
                  effect to the original intent of the parties to the extent
                  possible. The failure of either party to insist upon the
                  performance of any term or provision of this Agreement or to
                  exercise any right herein conferred shall not be construed as
                  a waiver or relinquishment to any extent of such party's right
                  to assert or rely upon any such term or right on any future
                  occasion. This Agreement shall not be construed for or against
                  any party on the basis of which party drafted this Agreement,
                  and each party acknowledges that it has had the opportunity to
                  review this Agreement with its respective legal counsel to the
                  party's satisfaction.
                </span>
                <span className="my-2 block">
                  (d) All provisions that logically ought to survive termination
                  shall survive of this Agreement, including without limitation
                  provisions relating to obligations of non-disclosure,
                  limitation on liability, indemnity provisions, and choice of
                  law and dispute resolution.
                </span>
                <span className="my-2 block">
                  (e) SAC may modify the terms of this Agreement at any time.
                  SAC shall make reasonable efforts to inform Recipient of the
                  modifications. Continued management/ownership of a Coalition,
                  creation of new Coalitions, and use of Data subsequent to such
                  modification shall be subject to the modified terms.
                </span>

                <span className="my-2 block">
                  (f) Notices shall be deemed served when received by addressee
                  or, if delivery fails by reason of some fault or action of the
                  addressee, when tendered for delivery. All notices shall be in
                  writing, reference this Agreement, and be delivered by
                  electronic mail, hand, prepaid courier, or registered or
                  certified mail (postage prepaid) to the address provided at
                  the time the parties enter into this Agreement.
                </span>
                <span className="my-2 block">
                  (g) This Agreement shall be governed by and construed in
                  accordance with the laws of the United States of America and
                  the District of Columbia, excluding conflict of law
                  provisions. Recipient expressly agrees to jurisdiction for any
                  claim or action arising out of or relating to this Agreement
                  in the state or federal courts located in the District of
                  Columbia, and further agrees and submits to the exercise of
                  personal jurisdiction of those courts for the purpose of
                  litigating any such claim or action. SAC may seek injunctive
                  or equitable relief in any court of competent jurisdiction. In
                  any action or proceeding to enforce rights or obligations
                  under the Agreement, the prevailing party will be entitled to
                  recover costs and attorneys' fees.
                </span>
                <span className="my-2 block">
                  (h) Recipient shall not use the name or trademarks of SAC in
                  any advertising, publicity, endorsement, promotion, or other
                  publicly available document without SAC's prior written
                  consent, except for inclusion of the required legend as
                  described above under “Use of Data.”
                </span>
                <span className="my-2 block">
                  BY CLICKING “OUR COALITION AGREES TO THE DATA USE AGREEMENT
                  ABOVE” YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND
                  AGREE TO BE BOUND BY THESE TERMS. THESE TERMS ARE A LEGAL
                  CONTRACT THAT WILL BIND BOTH OF US AS SOON AS YOU CLICK THE
                  ASSENT BUTTON. IF YOU REJECT THE PROPOSED TERMS, YOU WILL BE
                  DENIED ACCESS TO THE FORMATION OF A COALITION AND/OR THE DATA
                  THAT YOU ARE REQUESTING.
                </span>
              </div>
            </div>
          </div>

          <div className="mb-6">
            {
              <FormField
                name="dataUseAgreement"
                label="Our coalition agrees to the data use agreement above"
                type="checkbox"
                tooltipWidthClass="w-80"
                checked={coalition.attributes.dataUseTimestamp}
                readOnly={
                  formMode === FORM_MODES.VIEW || formMode === FORM_MODES.EDIT
                }
                disabled={
                  formMode === FORM_MODES.VIEW || formMode === FORM_MODES.EDIT
                }
                required
              />
            }
            <div style={{ fontSize: 13, color: "#948d8d" }}>
              {formMode === FORM_MODES.VIEW &&
                coalition.attributes.dataUseTimestamp && (
                  <>
                    Last agreement :{" "}
                    {new Date(
                      coalition.attributes.dataUseTimestamp
                    ).toLocaleString()}
                  </>
                )}
            </div>
          </div>

          {formMode !== FORM_MODES.VIEW && (
            <div className="flex justify-end space-x-4">
              <Button to={cancelRoute} emphasis="transparent">
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                loadingText={<>Saving&hellip;</>}
                onClick={handleSubmit}
              >
                {formMode === FORM_MODES.EDIT ? "Update" : "Add"}
              </Button>
            </div>
          )}

          <FormContext
            formMode={formMode}
            coalition={coalition}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            setShowState={setShowState}
          />
        </Form>
      )}
    </Formik>
  );
};

CoalitionForm.propTypes = {
  coalition: PropTypes.object.isRequired,
  formMode: PropTypes.string,
  cancelRoute: PropTypes.string,
  onUpdateSuccess: PropTypes.func,
  onUpdateError: PropTypes.func,
};

CoalitionForm.defaultProps = {
  formMode: FORM_MODES.VIEW,
};

export default CoalitionForm;

const FormContext = ({
  formMode,
  coalition,
  isSuccess,
  setIsSuccess,
  setShowState,
}) => {
  const {
    resetForm,
    dirty,
    values: { operatingRegionType },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    resetForm({
      values: getInitialValues({ coalition, formMode }),
    });
  }, [formMode, coalition, resetForm]);

  useEffect(() => {
    if (dirty) {
      setIsSuccess(false);
    }
  }, [dirty, setIsSuccess]);

  useEffect(() => {
    setShowState(
      !!operatingRegionType &&
        operatingRegionType !== COALITION_TYPES.NATIONAL &&
        operatingRegionType !== COALITION_TYPES.INTERNATIONAL
    );
  }, [operatingRegionType, setFieldValue]);

  return (
    <RouteLeavingGuard
      when={!isSuccess && dirty}
      shouldBlockNavigation={() => !isSuccess && dirty}
    />
  );
};

const getInitialValues = ({ coalition, formMode }) => {
  const isViewMode = formMode === FORM_MODES.VIEW;
  return {
    name: coalition.attributes.name || (isViewMode ? "--" : ""),
    country:
      coalition.attributes.country || (isViewMode ? "--" : "United States"),
    description: coalition.attributes.description || (isViewMode ? "--" : ""),
    dataUsed: coalition.attributes.dataUsed || (isViewMode ? "--" : ""),
    dataUseAgreement: coalition.attributes.dataUseAgreement || false,
    dataUseTimestamp: coalition.attributes.dataUseTimestamp || "",
    operatingRegionType:
      coalition.attributes.operatingRegionType || (isViewMode ? "--" : ""),
    operatingRegion:
      coalition.attributes.operatingRegion ||
      (coalition.attributes.operatingRegionType === COALITION_TYPES.MULTISTATE
        ? []
        : ""),
    allIntakeOutcomeDataRequest:
      formMode === FORM_MODES.ADD
        ? true
        : coalition.attributes.allIntakeOutcomeDataRequest,
    allCommunityServiceDataRequest:
      formMode === FORM_MODES.ADD
        ? true
        : coalition.attributes.allCommunityServiceDataRequest,
  };
};
