import React, { useState } from "react";
import ApiMappingTable from "components/ApiMappingTable";

export default function ApiMapping() {
  const [formMode, setFormMode] = useState("VIEW");

  return (
    <div>
      <ApiMappingTable formMode={formMode} setFormMode={setFormMode} />
    </div>
  );
}
