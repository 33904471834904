/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useUserState } from "context";

export default function Rehome() {
  const { organization, isAdmin } = useUserState();

  const tin = () => {
    if (isAdmin) {
      return "";
    }

    return organization.attributes.country === "United States"
      ? organization.attributes.ein
      : organization.attributes.businessNumber;
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://public.tableau.com/javascripts/api/viz_v1.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="tableauPlaceholder"
      id="viz1717524691574"
      style={{ position: "relative" }}
    >
      <noscript>
        <a href="#">
          <img
            alt="Individual Reports for AWOs"
            src="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;52&#47;52824SACTableauPublic&#47;SACAAPRehomeReport&#47;1_rss.png"
            style={{ border: "none" }}
          />
        </a>
      </noscript>
      <object
        className="tableauViz"
        style={{ display: "none", width: 1200, height: 900 }}
      >
        <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
        <param name="embed_code_version" value="3" />
        <param name="site_root" value="" />
        <param name="name" value="52824SACTableauPublic/SACAAPRehomeReport" />
        <param name="tabs" value="no" />
        <param name="toolbar" value="yes" />
        <param
          name="static_image"
          value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;52&#47;52824SACTableauPublic&#47;SACAAPRehomeReport&#47;1.png"
        />
        <param name="filter" value={`TIN=${tin()}`} />
        <param name="animate_transition" value="yes" />
        <param name="display_static_image" value="yes" />
        <param name="display_spinner" value="yes" />
        <param name="display_overlay" value="yes" />
        <param name="display_count" value="yes" />
        <param name="language" value="en-US" />
      </object>
    </div>
  );
}
