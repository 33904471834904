export const formatCsvValidationErrors = (errors, exampleCSV) => {
  if (
    !errors ||
    (!Array.isArray(errors) &&
      typeof errors !== "string" &&
      !(errors instanceof Error))
  ) {
    return "There was an error loading the application. Please contact Shelter Animals Count for support.";
  } else if (Array.isArray(errors)) {
    return (
      <ul>
        {errors.map((e, index) => (
          <li key={index}>{e.toString()}</li>
        ))}
      </ul>
    );
  } else if (errors.toString().includes("Invalid CSV Headers")) {
    // invalid CSV Headers is the error title returned from the csv validation service
    // check csv_header_validator.rb in the jsonapi_errors method
    return (
      <div>
        <p className="text-red">
          In order to process this CSV file, the data must be labeled to match
          this example:
        </p>
        <a
          className="btn btn-gold text-center w-1/3 mb-3 btn"
          type="button"
          download
          href={exampleCSV}
        >
          Download Example CSV
        </a>
        <p className="text-red">
          Files cannot be processed if they are missing expected columns or have
          incorrectly labeled columns.
        </p>
        <p className="text-red">
          Check this error message to see which column header(s) need to be
          updated:
        </p>
        {errors.toString()}
      </div>
    );
  } else {
    return errors.toString();
  }
};
