import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import Table from "components/Table";
import Button from "components/Button";
import FormField from "components/FormField";
import Pagination from "components/Pagination";
import ActionsMenu from "components/ActionsMenu";
import {
  apiFetch,
  formatEin,
  formatBusinessNumber,
  formatDateTime,
  getTotalPages,
  titleCase,
  formatUiErrors,
  getOrganizations,
} from "utils";
import { useAuthState, useAuthDispatch } from "context";
import { MenuItem, FocusableItem } from "@szhsin/react-menu";
import { get, uniq } from "lodash";
import { toast } from "react-toastify";

const Organizations = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");

  // Initial query
  const {
    data: response = { data: [], included: [] },
    error,
    isLoading,
  } = useQuery(
    ["organizations", { page, search }],
    () => getOrganizations({ token, dispatch, page, search }),
    {
      keepPreviousData: true,
    }
  );
  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["organizations", { page: page + 1, search }],
        () => getOrganizations({ token, dispatch, page: page + 1, search })
      );
    }
  }, [response, page, search, queryClient]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    {
      accessor: "attributes.createdAt",
      Header: "Date Created",
      Cell: ({ cell }) => formatDateTime(cell.value),
    },
    {
      accessor: "attributes.locationsCount",
      Header: "Number of Locations",
    },
    {
      accessor: "attributes.ein",
      Header: "EIN",
      Cell: ({ cell }) => formatEin(cell.value),
    },
    {
      accessor: "attributes.businessNumber",
      Header: "Business Number",
      Cell: ({ cell }) => formatBusinessNumber(cell.value),
    },
    {
      accessor: "attributes.locationsUsStates",
      Header: "States",
      Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
    },
    {
      accessor: "attributes.locationsProvinces",
      Header: "Province/Territory",
      Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
    },
    {
      accessor: "attributes.status",
      Header: "Status",
      Cell: ({ cell }) => titleCase(cell.value) || "",
    },
    {
      accessor: "id",
      Header: "",
      Cell: ({ row: { original }, cell }) => (
        <Menu
          id={cell.value}
          organization={original}
          deleteOrg={deleteOrg}
          resendAuthorizationEmail={resendAuthorizationEmail}
        />
      ),
    },
  ];

  const deleteOrg = (orgId) => {
    // TODO: Delete
  };

  const { mutate: resendAuthorizationEmail } = useMutation(
    (orgId) =>
      apiFetch({
        token,
        dispatch,
        method: "POST",
        endpoint: "/api/v1/resend_authorization_emails",
        body: {
          data: {
            type: "resend_authorization_emails",
            attributes: {
              organizationId: orgId,
            },
          },
        },
      }),
    {
      onSuccess: () => {
        toast.success("Resent authorization email successfully", {
          autoClose: 3000,
        });
      },
      onError: (error) => {
        toast.error(() => formatUiErrors(error), {
          autoClose: 5000,
        });
      },
    }
  );

  const handleSubmit = (values) => {
    if (values.search !== search) {
      setSearch(values.search);
    }
  };

  return (
    <>
      <h1>Organizations</h1>

      <div className="sac-border mb-6">
        <Formik initialValues={{ search }} onSubmit={handleSubmit}>
          {({ setFieldValue }) => (
            <Form className="flex items-end space-x-6">
              <div className="w-80">
                <FormField
                  name="search"
                  label="Search"
                  placeholder="Name, EIN, or Business number"
                />
              </div>

              <div className="flex items-center space-x-2">
                <Button type="submit">Search</Button>
                <Button
                  onClick={() => {
                    setFieldValue("search", "");
                    setSearch("");
                  }}
                  emphasis="transparent"
                >
                  Clear Search
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <Table
        data={response.data || []}
        columns={columns}
        columnWidths={[null, null, null, null, null, null, null, null, "w-16"]}
        columnAlignments={[
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          "text-right",
        ]}
        noResultsText="No organizations found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default Organizations;

const Menu = ({ id, organization, deleteOrg, resendAuthorizationEmail }) => {
  return (
    <ActionsMenu>
      {/* View */}
      <FocusableItem>
        {({ ref }) => (
          <Link to={`/organizations/${id}`} ref={ref}>
            View Details
          </Link>
        )}
      </FocusableItem>
      {/* Edit */}
      <FocusableItem>
        {({ ref }) => (
          <Link to={`/organizations/${id}/edit`} ref={ref}>
            Edit Details
          </Link>
        )}
      </FocusableItem>
      {/* Delete */}
      <MenuItem onClick={() => deleteOrg(id)}>Delete Organization</MenuItem>
      {/* Resend authorization */}
      {organization.attributes.status !== "confirmed" && (
        <MenuItem onClick={() => resendAuthorizationEmail(id)}>
          Resend Authorization Email
        </MenuItem>
      )}
      {/* Export */}
      <FocusableItem>
        {({ ref }) => (
          <Link to={`/organizations/${id}/export`} ref={ref}>
            Export Data
          </Link>
        )}
      </FocusableItem>
    </ActionsMenu>
  );
};
