import React from "react";
import IntakeOverviewGraph from "components/IntakeOverviewGraph";
import IntakeOverviewTable from "components/IntakeOverviewTable";
import RecordStatusTable from "components/RecordStatusTable";
import { useUserState } from "context";
import Button from "components/Button";

const DataPortalDashboard = () => {
  const {
    attributes: { fullName, locationId },
    isOrgAdmin,
  } = useUserState();

  return (
    <>
      <h1>Welcome{fullName ? `, ${fullName}` : null}!</h1>
      {locationId ? (
        <div className="sac-border flex flex-wrap xl:flex-nowrap align-items-center divide-y-2 divide-x-0 xl:divide-y-0 xl:divide-x-2 divide-grey">
          {/* Intake Outcome Database */}
          <div className="w-full xl:w-1/2 xl:mb-4 xl:pr-4 pt-4 xl:pt-0">
            <h2 className="text-center text-deep-teal">
              Intake and Outcome Database (IOD)
            </h2>
            {/* Data Matrix CTA */}
            <div className="sac-border p-10 mb-4 flex items-center justify-between">
              {isOrgAdmin ? (
                <Button className="btn-sm" to="/data-entry/upload">
                  Enter Data: CSV Upload
                </Button>
              ) : null}
              {isOrgAdmin ? (
                <Button className="btn-sm" to="/data-entry">
                  Enter Data: Manual
                </Button>
              ) : null}
              <Button
                className="btn-sm"
                emphasis="gold"
                to="/data-entry/export"
              >
                Export Data
              </Button>
            </div>
            {/* Records */}
            <div className="w-full sac-border mb-4 xl:mr-4">
              <div className="flex flex-row mb-2">
                <h3 className="text-deep-teal w-1/3 mb-0">IOD Data Records</h3>
                <div className="flex flex-row w-1/2 justify-between text-sm h-1/2">
                  <span className="bg-teal text-white px-1">COMPLETE</span>
                  <span className="bg-gold px-1 md:mx-2">INCOMPLETE</span>
                  <span className="bg-dashboard-blue text-white px-1">
                    UNBEGUN
                  </span>
                </div>
              </div>
              <RecordStatusTable />
            </div>

            {/* Graph */}
            <div className="w-full sac-border mb-4">
              <h3 className="text-deep-teal">IOD Overview by Species</h3>
              <IntakeOverviewGraph />
            </div>

            {/* Table */}
            <div className="sac-border mb-4">
              <h3 className="text-deep-teal">IOD Overview by Category</h3>
              <IntakeOverviewTable />
            </div>
          </div>
          {/* Services Database */}
          <div className="w-full xl:w-1/2 xl:mb-4 xl:pl-4 pt-4 xl:pt-0">
            <h2 className="text-center text-deep-teal">
              Community Services Database (CSD)
            </h2>
            {/* Services Matrix CTA */}
            <div className="sac-border p-10 mb-4 flex items-center justify-between">
              {isOrgAdmin ? (
                <Button className="btn-outline btn-sm" to="/services/upload">
                  Enter Data: CSV Upload
                </Button>
              ) : null}
              {isOrgAdmin ? (
                <Button className="btn-outline btn-sm" to="/services">
                  Enter Data: Manual
                </Button>
              ) : null}
              <Button
                className="btn-outline-gold btn-sm"
                emphasis="gold"
                to="/services/export"
              >
                Export Data
              </Button>
            </div>
            {/* Records */}
            <div className="w-full sac-border mb-4 xl:mr-4">
              <div className="flex flex-row mb-2">
                <h3 className="text-deep-teal w-1/3 mb-0">CSD Data Records</h3>
                <div className="flex flex-row w-1/2 justify-between text-sm h-1/2">
                  <span className="bg-teal-outline px-1">COMPLETE</span>
                  <span className="bg-gold-outline px-1 md:mx-2">
                    INCOMPLETE
                  </span>
                  <span className="bg-blue-outline px-1">UNBEGUN</span>
                </div>
              </div>
              <RecordStatusTable action="services" />
            </div>

            {/* Graph */}
            <div className="w-full sac-border mb-4">
              <h3 className="text-deep-teal">CSD Overview by Species</h3>
              <IntakeOverviewGraph action="services" />
            </div>

            {/* Table */}
            <div className="sac-border mb-4">
              <h3 className="text-deep-teal">CSD Overview by Category</h3>
              <IntakeOverviewTable action="services" />
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-4">
          you must be associated with an organization. Contact your organization
          to be added as a user or email info@shelteranimalscount.org for
          assistance.
        </div>
      )}
    </>
  );
};

export default DataPortalDashboard;
