import React, { useEffect, useState } from "react";
import { endOfMonth, setMonth, subYears, format } from "date-fns";
import PropTypes from "prop-types";
import { Formik, Form, FieldArray, useFormikContext } from "formik";
import FormField from "components/FormField";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import {
  apiFetch,
  formatEin,
  formatBusinessNumber,
  speciesMap,
  FORM_MODES,
  formatPhoneNumberForFormFields,
  formatPhoneNumberForApi,
  formatDateObject,
  usePermissions,
  ROUTE_GUARDS,
} from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Button from "components/Button";
import DatePickerField from "components/DatePickerField";
import SpeciesCheckbox from "components/SpeciesCheckbox";
import {
  OrganizationTypeTooltip,
  OrganizationEinTooltip,
  OrganizationAnnualBudgetTooltip,
  OrganizationStatusTooltip,
  OrganizationBusinessNumberTooltip,
} from "components/Tooltips";

const OrganizationForm = ({
  organization,
  formMode,
  onUpdateSuccess,
  onUpdateError,
  orgId,
}) => {
  const { isAdmin, isOrgAdmin } = useUserState();
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [annualTotal, setAnnualTotal] = useState(0);
  const lastYear = subYears(new Date(), 1);
  const initialValues = getInitialValues({ organization, formMode });
  const [initialIodApiOption] = useState(initialValues.iodApiOption);
  const permissions = usePermissions();
  const isOrgMember = permissions[ROUTE_GUARDS.CAN_VIEW_ORG]();

  const OrganizationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    country: Yup.string().required("Country is required"),
    organizationType: Yup.string().required("Select an organization type"),
    status: Yup.string().required("Select an organization Status"),
    websiteUrl: Yup.string().url(
      "Invalid URL: Include https:// prefix to your URL"
    ),
    incorporatedOn: Yup.string().required("Date is required"),
    ein: Yup.string().when("country", {
      is: "United States",
      then: Yup.string()
        .matches(/^\d{9}$/, "Invalid EIN")
        .required("EIN is required"),
    }),
    businessNumber: Yup.string().when("country", {
      is: "Canada",
      then: Yup.string()
        .matches(/^\d{9}$/, "Invalid business number")
        .required("Business number is required"),
    }),
    annualIntake: Yup.string().required("Animal intake is required"),
    annualOperatingBudget: Yup.string().required(
      "Annual operating budget is required"
    ),
    authorizerName: Yup.string().required("Authorizer name is required"),
    authorizerTitle: Yup.string().required("Authorizer title is required"),
    authorizerEmail: Yup.string()
      .email("Invalid email")
      .required("Authorizer email is required"),
    authorizerConfirmEmail: Yup.lazy(() =>
      showConfirmEmail
        ? Yup.string()
            .email("Invalid email")
            .oneOf([Yup.ref("authorizerEmail")], "Emails do not match")
            .required("Confirm the authorizer email")
        : Yup.string()
    ),
    authorizerPhoneNumber: Yup.string()
      .matches(
        /^\(?([2-9]\d\d)\)?[-. ]?(\d{3})[-. ]?(\d{4})$/,
        "Invalid phone number"
      )
      .required("Phone number is required"),
    iodApiOption: Yup.string(),
    externalId: Yup.string().when("iodApiOption", {
      is: "yes",
      then: Yup.string().required("Software Unique Identifier is required"),
    }),
    shelterSoftware: Yup.string().required("Shelter software is required"),
    aapId: Yup.string(),
    notes: Yup.string(),
  });

  const { mutate: updateOrganizationDetails, isLoading } = useMutation(
    ({
      name,
      country,
      organizationType,
      websiteUrl,
      incorporatedOn,
      ein,
      businessNumber,
      animalSpecies,
      annualIntake,
      annualOperatingBudget,
      shelterSoftware,
      externalId,
      iodApiOption,
      iodApiActive,
      authorizerName,
      authorizerTitle,
      status,
      authorizerEmail,
      authorizerPhoneNumber,
      authorizerExtension,
      aapId,
      notes,
    }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/organizations/${organization.id}`,
        method: "PATCH",
        body: {
          data: {
            id: organization.id,
            type: "organizations",
            attributes: {
              name,
              country,
              organizationType,
              status,
              websiteUrl,
              incorporatedOn: format(incorporatedOn, "dd/MM/yyyy"),
              ...(isAdmin ? { ein: ein.replace(/\D/g, "") } : {}),
              ...(isAdmin
                ? { businessNumber: businessNumber.replace(/\D/g, "") }
                : {}),
              animalSpecies: Object.keys(animalSpecies).reduce((array, key) => {
                if (!!animalSpecies[key]) {
                  array.push(key);
                }
                return array;
              }, []),
              annualIntake,
              annualOperatingBudget,
              externalId: externalId.trim(),
              iodApiOption,
              iodApiActive,
              shelterSoftware,
              ...(isOrgAdmin
                ? {
                    authorizerName,
                    authorizerTitle,
                    ...(showConfirmEmail ? { authorizerEmail } : {}),
                    authorizerPhoneNumber: formatPhoneNumberForApi({
                      phoneNumber: authorizerPhoneNumber,
                      extension: authorizerExtension,
                    }),
                  }
                : {}),
              ...(initialIodApiOption !== iodApiOption
                ? { iodApiOptionUpdated: new Date() }
                : {}),
              ...(isAdmin ? { aapId } : {}),
              ...(isAdmin ? { notes } : {}),
            },
          },
        },
      }),
    {
      onSuccess: (data) => {
        setIsSuccess(true);
        onUpdateSuccess(data);
      },
      onError: (e) => {
        setIsSuccess(false);
        onUpdateError(e);
      },
    }
  );

  useQuery(
    ["organizationAnnualIntakeTotal", orgId],
    () =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/organizations/annual_intake_totals?filter[organization_id][eq]=${orgId}`,
      }),
    {
      onSuccess: ({ data }) => {
        data > 0 ? setAnnualTotal(data) : setAnnualTotal(0);
      },
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={OrganizationSchema}
      onSubmit={updateOrganizationDetails}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form>
          <div className="w-1/2 mb-6">
            {/* Name */}
            <FormField
              name="name"
              label="Organization Name"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />
          </div>
          <div className="hidden">
            <FormField
              name="country"
              label="Country"
              as="select"
              data-testid="country"
              readOnly
              disabled
              required
            >
              <option disabled value="" hidden>
                -- Select a Country --
              </option>
              <option value="United States">United States</option>
              <option value="Canada">Canada</option>
            </FormField>
          </div>
          <div className="w-1/2 mb-6">
            {/* Status */}
            {formMode === FORM_MODES.VIEW && isOrgMember && (
              <FormField
                name="status"
                label="Organization Status"
                readOnly
                disabled
              />
            )}
          </div>
          <div className="w-1/2 mb-6">
            {isOrgAdmin && formMode === FORM_MODES.EDIT && (
              <FormField
                name="status"
                label="Organization Status"
                as="select"
                tooltip={<OrganizationStatusTooltip />}
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              >
                <option disabled value="" hidden>
                  -- Select an Organization Status --
                </option>
                {formMode === FORM_MODES.VIEW && <option value="--">--</option>}
                <option value="registered">Registered</option>
                <option value="confirmed">Confirmed</option>
                <option value="closed">Closed</option>
                <option value="paused">Paused</option>
              </FormField>
            )}
          </div>

          <div className="w-1/2 mb-6">
            {/* Org Type */}
            <FormField
              name="organizationType"
              label="Organization Type"
              as="select"
              tooltip={<OrganizationTypeTooltip />}
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            >
              <option disabled value="" hidden>
                -- Select an Organization Type --
              </option>
              {formMode === FORM_MODES.VIEW && <option value="--">--</option>}
              <option value="Government Animal Services">
                Government Animal Services
              </option>
              <option value="Animal Shelter with a Government Contract">
                Animal Shelter with a Government Contract
              </option>
              <option value="Animal Rescue with a Government Contract">
                Animal Rescue with a Government Contract
              </option>
              <option value="Shelter without a Government Contract">
                Shelter without a Government Contract
              </option>
              <option value="Rescue without a Government Contract">
                Rescue without a Government Contract
              </option>
              <option value="Non-sheltering Service Provider">
                Non-sheltering Service Provider
              </option>
            </FormField>
          </div>
          <div className="w-1/2 mb-6">
            {/* Website */}
            <FormField
              name="websiteUrl"
              label="Website URL"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
            />
          </div>
          <div className="w-1/2 mb-6">
            {/* Date Started */}
            {formMode === FORM_MODES.VIEW &&
            !organization.attributes.incorporatedOn ? (
              <FormField
                label="Date of Organization Incorporation"
                name="incorporatedOn"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              />
            ) : (
              <DatePickerField
                label="Date of Organization Incorporation"
                name="incorporatedOn"
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                className="form-input w-full"
                maxDate={endOfMonth(setMonth(new Date(), 11))}
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
                required
              />
            )}
          </div>
          <div className="w-1/2 mb-6">
            {/* EIN */}

            {(isOrgAdmin || formMode === FORM_MODES.VIEW) &&
              initialValues.country === "United States" && (
                <FormField
                  name="ein"
                  label="Employer Identification Number (EIN)"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                  tooltip={<OrganizationEinTooltip />}
                  required
                />
              )}
          </div>
          <div className="w-1/2 mb-6">
            {/* Business Number */}
            {(isOrgAdmin || formMode === FORM_MODES.VIEW) &&
              initialValues.country === "Canada" && (
                <FormField
                  name="businessNumber"
                  label="Business Number"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                  tooltip={<OrganizationBusinessNumberTooltip />}
                  required
                />
              )}
          </div>
          <div className="mb-6">
            {/* Animal Species */}
            <div
              id="animalSpecies"
              className={`mb-1 text-sm ${
                formMode === FORM_MODES.VIEW &&
                "text-medium-gray font-body-bold"
              }`}
            >
              What animal species do you work with?
              {formMode === FORM_MODES.EDIT && " (Select all that apply)"}
            </div>
            <div
              role="group"
              aria-labelledby="animalSpecies"
              className="grid grid-cols-2 lg:grid-cols-4 gap-4"
            >
              <FieldArray
                name="animalSpecies"
                render={() =>
                  Object.keys(values.animalSpecies).map((key) => (
                    <SpeciesCheckbox
                      key={key}
                      species={key}
                      fieldName={`animalSpecies.${key}`}
                      label={speciesMap[key].label}
                      selected={values.animalSpecies[key]}
                      disabled={formMode === FORM_MODES.VIEW}
                    />
                  ))
                }
              />
            </div>
          </div>
          {isOrgMember && (
            <>
              <div className="grid lg:grid-cols-2 gap-6 mb-6">
                {/* Annual Intake */}
                <div>
                  <FormField
                    name="annualIntake"
                    label={`Annual Intake of animals for ${lastYear.getFullYear()}`}
                    tooltip={
                      <div>
                        Click the auto calculate button to fill in your total
                        intake count from the previous year. Edit the value if
                        your organization didn't enter a full year of data last
                        year. Please make your best approximation of annual
                        intake of cats and dogs
                      </div>
                    }
                    tooltipWidthClass="w-80"
                    readOnly={formMode === FORM_MODES.VIEW}
                    disabled={formMode === FORM_MODES.VIEW}
                    required
                  />
                  {formMode === FORM_MODES.EDIT && (
                    <Button
                      className={"auto_calculate"}
                      type="button"
                      onClick={() => {
                        setFieldValue("annualIntake", annualTotal);
                      }}
                    >
                      Auto Calculate
                    </Button>
                  )}
                </div>

                {/* Annual Budget */}
                <FormField
                  name="annualOperatingBudget"
                  label={`Annual Operating Budget for ${lastYear.getFullYear()}`}
                  tooltip={<OrganizationAnnualBudgetTooltip />}
                  tooltipWidthClass="w-80"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                  required
                />
              </div>

              <div className="grid lg:grid-cols-2 gap-6 mb-6">
                {/* Software Provider */}
                <FormField
                  name="shelterSoftware"
                  label="Do you use any of the following software?"
                  as="select"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                  required
                >
                  <option disabled value="" hidden>
                    -- Select Software Provider --
                  </option>
                  {formMode === FORM_MODES.VIEW && (
                    <option value="--">--</option>
                  )}
                  <option value="Adopt-A-Friend">Adopt-A-Friend</option>
                  <option value="Animal Shelter Net">Animal Shelter Net</option>
                  <option value="AnimalsFirst">AnimalsFirst</option>
                  <option value="Ark Software">Ark Software</option>
                  <option value="Barrk">Barrk</option>
                  <option value="Buzz to the Rescue">Buzz to the Rescue</option>
                  <option value="Chameleon">Chameleon</option>
                  <option value="Companion Connect">Companion Connect</option>
                  <option value="iShelters">iShelters</option>
                  <option value="Multiple Options">Multiple Options</option>
                  <option value="Pawlytics">Pawlytics</option>
                  <option value="PawTrax">PawTrax</option>
                  <option value="Petlogix">Petlogix</option>
                  <option value="PetPoint">PetPoint</option>
                  <option value="Petstablished">Petstablished</option>
                  <option value="Rescue Spot">Rescue Spot</option>
                  <option value="RescueConnection">RescueConnection</option>
                  <option value="RescueGroups">RescueGroups</option>
                  <option value="Salesforce">Salesforce</option>
                  <option value="Shelter Boss">Shelter Boss</option>
                  <option value="Shelter Manager">Shelter Manager</option>
                  <option value="Shelter Pro">Shelter Pro</option>
                  <option value="ShelterBuddy">ShelterBuddy</option>
                  <option value="ShelterLuv">ShelterLuv</option>
                  <option value="ShelterTrack">ShelterTrack</option>
                  <option value="Smart Pet Health">Smart Pet Health</option>
                  <option value="Sparkie">Sparkie</option>
                  <option value="Trackabeast">Trackabeast</option>
                  <option value="Vestafy">Vestafy</option>
                  <option value="Visibility">Visibility</option>
                  <option value="Custom Database">Custom Database</option>
                  <option value="Spreadsheet">Spreadsheet</option>
                  <option value="Paper">Paper</option>
                  <option value="Other">Other</option>
                </FormField>

                {/* AAP ID */}
                {isAdmin && (
                  <FormField
                    name="aapId"
                    label="AAP ID"
                    readOnly={formMode === FORM_MODES.VIEW}
                    disabled={formMode === FORM_MODES.VIEW}
                  />
                )}
              </div>

              {(isOrgAdmin || formMode === FORM_MODES.VIEW) && (
                <div className="grid lg:grid-cols-2 gap-6 mb-6">
                  <FormField
                    name="iodApiOption"
                    label="Our organization has opted-in to an API with the software company above to send Intake/Outcome data to SAC automatically each month"
                    as="select"
                    readOnly={formMode === FORM_MODES.VIEW}
                    disabled={formMode === FORM_MODES.VIEW}
                  >
                    <option disabled value="" hidden>
                      -- API Opted-in --
                    </option>
                    {formMode === FORM_MODES.VIEW && (
                      <option value="--">--</option>
                    )}
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </FormField>
                </div>
              )}

              <div className="grid lg:grid-cols-2 gap-6 mb-6">
                <FormField
                  name="externalId"
                  required
                  label="Software Unique Identifier (for API use only)"
                  readOnly={formMode === FORM_MODES.VIEW}
                  disabled={formMode === FORM_MODES.VIEW}
                />
              </div>

              {(isAdmin || formMode === FORM_MODES.VIEW) && (
                <div className="grid lg:grid-cols-2 gap-6 mb-6">
                  <FormField
                    name="iodApiActive"
                    label="API Live"
                    as="select"
                    readOnly={formMode === FORM_MODES.VIEW}
                    disabled={formMode === FORM_MODES.VIEW}
                  >
                    <option disabled value="" hidden>
                      -- API Active --
                    </option>
                    {formMode === FORM_MODES.VIEW && (
                      <option value="--">--</option>
                    )}
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </FormField>
                </div>
              )}

              <div className="mb-6">
                {/* Notes */}
                {isAdmin && (
                  <FormField
                    name="notes"
                    label="Notes"
                    readOnly={formMode === FORM_MODES.VIEW}
                    disabled={formMode === FORM_MODES.VIEW}
                  />
                )}
              </div>

              {(isOrgAdmin || formMode === FORM_MODES.VIEW) && (
                <>
                  <h3>Authorizer Information</h3>
                  <div className="grid lg:grid-cols-2 gap-6 mb-6">
                    {/* Authorizer Name */}
                    <FormField
                      name="authorizerName"
                      label="Authorizer Name"
                      readOnly={formMode === FORM_MODES.VIEW}
                      disabled={formMode === FORM_MODES.VIEW}
                      required
                    />

                    {/* Authorizer Title */}
                    <FormField
                      name="authorizerTitle"
                      label="Authorizer Title"
                      readOnly={formMode === FORM_MODES.VIEW}
                      disabled={formMode === FORM_MODES.VIEW}
                      required
                    />
                  </div>

                  <div className="grid lg:grid-cols-2 gap-6 mb-6">
                    {/* Email */}
                    <FormField
                      name="authorizerEmail"
                      label="Authorizer Email"
                      readOnly={formMode === FORM_MODES.VIEW}
                      disabled={formMode === FORM_MODES.VIEW}
                      type="email"
                      required
                    />

                    {/* Confirm Email */}
                    {formMode === FORM_MODES.EDIT && showConfirmEmail && (
                      <FormField
                        name="authorizerConfirmEmail"
                        label="Confirm Authorizer Email"
                        type="email"
                        required
                      />
                    )}
                  </div>

                  <div className="grid lg:grid-cols-2 gap-6 mb-6">
                    {/* Authorizer Phone Number */}
                    <FormField
                      name="authorizerPhoneNumber"
                      label="Authorizer Primary Phone Number"
                      readOnly={formMode === FORM_MODES.VIEW}
                      disabled={formMode === FORM_MODES.VIEW}
                      required
                    />

                    {/* Extension */}
                    <FormField
                      name="authorizerExtension"
                      readOnly={formMode === FORM_MODES.VIEW}
                      disabled={formMode === FORM_MODES.VIEW}
                      label="Extension"
                    />
                  </div>
                </>
              )}
            </>
          )}

          {formMode !== FORM_MODES.VIEW && (
            <div className="flex justify-end space-x-4">
              <Button
                to={`/organizations/${organization.id}`}
                emphasis="transparent"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                loadingText={<>Saving&hellip;</>}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          )}

          <FormContext
            formMode={formMode}
            organization={organization}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            setShowConfirmEmail={setShowConfirmEmail}
          />
        </Form>
      )}
    </Formik>
  );
};

OrganizationForm.propTypes = {
  organization: PropTypes.object.isRequired,
  formMode: PropTypes.string,
  onUpdateSuccess: PropTypes.func.isRequired,
  onUpdateError: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
};

OrganizationForm.defaultProps = {
  formMode: FORM_MODES.VIEW,
};

export default OrganizationForm;

const FormContext = ({
  formMode,
  organization,
  isSuccess,
  setIsSuccess,
  setShowConfirmEmail,
}) => {
  const {
    resetForm,
    dirty,
    values: { authorizerEmail },
  } = useFormikContext();
  const [emailClone, setEmailClone] = useState(
    organization.attributes.authorizerEmail
  );

  useEffect(() => {
    setEmailClone(organization.attributes.authorizerEmail);
  }, [organization]);

  useEffect(() => {
    resetForm({
      values: getInitialValues({ organization, formMode }),
    });
  }, [formMode, organization, resetForm]);

  useEffect(() => {
    if (dirty) {
      setIsSuccess(false);
    }
  }, [dirty, setIsSuccess]);

  useEffect(() => {
    setShowConfirmEmail(authorizerEmail !== emailClone);
  }, [emailClone, authorizerEmail, setShowConfirmEmail]);

  return (
    <RouteLeavingGuard
      when={!isSuccess && dirty}
      shouldBlockNavigation={() => !isSuccess && dirty}
    />
  );
};

const getInitialValues = ({ organization, formMode }) => {
  const isViewMode = formMode === FORM_MODES.VIEW;
  const authorizerPhoneNumber = formatPhoneNumberForFormFields(
    organization.attributes.authorizerPhoneNumber
  );
  return {
    name: organization.attributes.name || (isViewMode ? "--" : ""),
    status: organization.attributes.status || (isViewMode ? "--" : ""),
    country: organization.attributes.country || (isViewMode ? "--" : ""),
    organizationType:
      organization.attributes.organizationType || (isViewMode ? "--" : ""),
    websiteUrl: organization.attributes.websiteUrl || (isViewMode ? "--" : ""),
    incorporatedOn:
      formatDateObject(organization.attributes.incorporatedOn) ||
      (isViewMode ? "--" : ""),
    businessNumber: getFormattedBusinessNumber({
      businessNumber: organization.attributes.businessNumber,
      formMode,
    }),
    ein: getFormattedEin({ ein: organization.attributes.ein, formMode }),
    animalSpecies: Object.keys(speciesMap).reduce((species, key) => {
      species[key] = organization.attributes.animalSpecies.indexOf(key) > -1;
      return species;
    }, {}),
    annualIntake:
      organization.attributes.annualIntake || (isViewMode ? "--" : ""),
    annualOperatingBudget:
      organization.attributes.annualOperatingBudget || (isViewMode ? "--" : ""),
    externalId: organization.attributes.externalId || (isViewMode ? "--" : ""),
    iodApiActive:
      organization.attributes.iodApiActive || (isViewMode ? "--" : ""),
    iodApiOption:
      organization.attributes.iodApiOption || (isViewMode ? "--" : ""),
    shelterSoftware:
      organization.attributes.shelterSoftware || (isViewMode ? "--" : ""),
    authorizerName:
      organization.attributes.authorizerName || (isViewMode ? "--" : ""),
    authorizerTitle:
      organization.attributes.authorizerTitle || (isViewMode ? "--" : ""),
    authorizerEmail:
      organization.attributes.authorizerEmail || (isViewMode ? "--" : ""),
    authorizerConfirmEmail: "",
    authorizerPhoneNumber:
      authorizerPhoneNumber.phoneNumber || (isViewMode ? "--" : ""),
    authorizerExtension:
      authorizerPhoneNumber.extension || (isViewMode ? "--" : ""),
    aapId: organization.attributes.aapId || (isViewMode ? "--" : ""),
    notes: organization.attributes.notes || (isViewMode ? "--" : ""),
  };
};

const getFormattedEin = ({ ein, formMode }) => {
  if (formMode === FORM_MODES.VIEW) {
    return ein ? formatEin(ein) : "--";
  }
  return formatEin(ein || "");
};

const getFormattedBusinessNumber = ({ businessNumber, formMode }) => {
  if (formMode === FORM_MODES.VIEW) {
    return businessNumber ? formatBusinessNumber(businessNumber) : "--";
  }
  return formatBusinessNumber(businessNumber || "");
};
