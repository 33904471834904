export const EXPORT_DATE_FORMATS = {
  MONTH: "MMMM yyyy",
  YEAR: "yyyy",
};

export const organizationHeaders = [
  { label: "Organization ID", key: "id" },
  { label: "Organization Name", key: "name" },
  { label: "Organization Date Created", key: "createdAt" },
  { label: "Organization Status", key: "status" },
  { label: "Organization Start Date", key: "incorporatedOn" },
  { label: "EIN", key: "ein" },
  { label: "Organization Type", key: "organizationType" },
  { label: "Annual Intake", key: "annualIntake" },
  { label: "Animal Species", key: "animalSpecies" },
  { label: "Budget", key: "annualOperatingBudget" },
  { label: "Software Provider", key: "shelterSoftware" },
  { label: "Website", key: "websiteUrl" },
  { label: "Registration Referral", key: "referringOrganizationName" },
  { label: "Authorizer Name", key: "authorizerName" },
  { label: "Authorizer Title", key: "authorizerTitle" },
  { label: "Authorizer Email", key: "authorizerEmail" },
  { label: "Authorizer Phone Number", key: "authorizerPhoneNumber" },
];

export const transformOrganizationsData = (response) => {
  if (!response || !response.data) return [];
  return response.data.map((record) => {
    const {
      ein,
      createdAt,
      incorporatedOn,
      animalSpecies,
      authorizerPhoneNumber,
      ...attributes
    } = record.attributes;
    return Object.assign(record, {
      ...attributes,
      ein: formatEin(ein),
      createdAt: formatDateTime(createdAt),
      incorporatedOn: new Date(incorporatedOn).toLocaleDateString(),
      animalSpecies: formatAnimalSpecies(animalSpecies),
      authorizerPhoneNumber: formatPhoneNumberForDisplay(authorizerPhoneNumber),
    });
  });
};

export const locationHeaders = [
  { label: "Location ID", key: "id" },
  { label: "Location Name", key: "name" },
  { label: "Location Date Created", key: "createdAt" },
  { label: "Address Line 1", key: "addressLine1" },
  { label: "Address Line 2", key: "addressLine2" },
  { label: "City", key: "city" },
  { label: "State", key: "usState" },
  { label: "Zip Code", key: "zipCode" },
  { label: "County", key: "usCountyName" },
  { label: "Organzation ID", key: "organizationId" },
  { label: "Is Primary Location", key: "primary" },
  { label: "Contact Phone Number", key: "contactPhoneNumber" },
];

export const transformLocationsData = (response) => {
  if (!response || !response.data) return [];
  return response.data.map((record) => {
    const { createdAt, primary, contactPhoneNumber, ...attributes } =
      record.attributes;
    return Object.assign(record, {
      ...attributes,
      createdAt: formatDateTime(createdAt),
      primary: primary ? String(primary) : "false",
      contactPhoneNumber: formatPhoneNumberForDisplay(contactPhoneNumber),
    });
  });
};

export const userAccountHeaders = [
  { label: "User ID", key: "id" },
  { label: "Full Name", key: "fullName" },
  { label: "User Date Created", key: "createdAt" },
  { label: "Title", key: "title" },
  { label: "Email", key: "email" },
];

export const transformUserAccountsData = (response) => {
  if (!response || !response.data) return [];
  return response.data.map((record) => {
    const { createdAt, ...attributes } = record.attributes;
    return Object.assign(record, {
      ...attributes,
      createdAt: formatDateTime(createdAt),
    });
  });
};

export const coalitionHeaders = [
  { label: "Coalition ID", key: "id" },
  { label: "Coalition Name", key: "name" },
  { label: "Description", key: "description" },
  { label: "Operating Region", key: "operatingRegion" },
  { label: "Region Type", key: "operatingRegionType" },
];

export const transformCoalitionsData = (response) => {
  if (!response || !response.data) return [];
  return response.data.map((record) => {
    const { createdAt, ...attributes } = record.attributes;
    return Object.assign(record, {
      ...attributes,
      createdAt: formatDateTime(createdAt),
    });
  });
};

export const formatDateTime = (date) => {
  if (!date) return "";
  return new Date(date).toLocaleString();
};

export const formatDateObject = (date) => {
  if (!date) return "";
  const newDate = new Date(date);
  newDate.setTime(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const formatEin = (ein) => {
  if (!ein || !String(ein).trim()) return "";
  return ein;
};

export const formatBusinessNumber = (bn) => {
  if (!bn || !String(bn).trim()) return "";
  return bn;
};

export const formatAnimalSpecies = (animalSpecies) => {
  if (!animalSpecies || animalSpecies === "[]") {
    return null;
  }
  return animalSpecies ? animalSpecies.join(", ") : null;
};

const api_phone_regex = /^[+]?1(\d{10})(\sx(\d*))?$/;
const phone_regex = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;

/**
 * Converts phone number string form API into an string for display value with formatting
 * i.e. "+15555555555 x1234" becomes "(555) 555-5555 x1234"
 * @param {string} phoneNumber
 * @returns {string}
 */
export const formatPhoneNumberForDisplay = (phoneNumber) => {
  if (!phoneNumber || !String(phoneNumber).trim()) return "";

  const matches = api_phone_regex.exec(phoneNumber.trim());
  if (!matches) return "";

  let formattedPhone = matches[1].replace(phone_regex, "($1) $2-$3");
  if (matches[3]) {
    formattedPhone += ` x${matches[3]}`;
  }
  return formattedPhone;
};

/**
 * Converts phone number string form API into an object with phoneNumber and extension values
 * i.e. "+15555555555 x1234" becomes {
 *  phoneNumber: "(555) 555-5555"
 *  extension: "1234"
 * }
 * @param {string} phoneNumber
 * @returns {Object} phoneNumberFields
 * @returns {string} phoneNumberFields.phoneNumber
 * @returns {string} phoneNumberFields.extension
 */
export const formatPhoneNumberForFormFields = (phoneNumber) => {
  const defaultReturn = { phoneNumber: "", extension: "" };
  if (!phoneNumber || !String(phoneNumber).trim()) return defaultReturn;

  const matches = api_phone_regex.exec(phoneNumber.trim());

  if (!matches) return defaultReturn;

  return {
    phoneNumber: matches[1].replace(phone_regex, "($1) $2-$3"),
    extension: matches[3] || "",
  };
};

/**
 * Converts phoneNumber and extension values into an string API consumption
 *  * i.e. {
 *  phoneNumber: "(555) 555-5555"
 *  extension: "1234"
 * } becomes "15555555555 x1234"
 * @param {Object} phoneNumberFields
 * @param {string} phoneNumberFields.phoneNumber
 * @param {string} phoneNumberFields.extension
 * @returns {string}
 */
export const formatPhoneNumberForApi = ({
  phoneNumber = "",
  extension = "",
}) => {
  if (!phoneNumber) return "";

  return `1${phoneNumber.replace(/\D/g, "")}${
    extension ? ` x${extension.replace(/\D/g, "")}` : ""
  }`;
};
