import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import BehavioralServicesTable from "../BehavioralServicesTable";

const BehavioralServices = forwardRef(
  ({ data, selectedSpecies, isEditing, submit }, ref) => {
    return (
      <div className="sac-border mb-4">
        <div className="flex">
          <div>
            <span className="circle bg-dashboard-blue mr-4">4</span>
          </div>

          <div className="w-full">
            <h2 className="text-2xl mt-1">Behavioral Services</h2>
            {data.map(({ id, attributes, attributes: { species } }) => {
              if (species === selectedSpecies || !selectedSpecies) {
                return (
                  <BehavioralServicesTable
                    key={id}
                    id={id}
                    attributes={attributes}
                    species={species}
                    isEditing={isEditing}
                    submit={submit}
                    ref={ref}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
);

BehavioralServices.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditing: PropTypes.bool.isRequired,
  selectedSpecies: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default BehavioralServices;
