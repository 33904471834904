import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#ccc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
  },
  description: {
    width: "45%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    paddingLeft: 8,
    borderColor: borderColor,
    borderWidth: 1,
  },
  canine: {
    width: "11%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    textAlign: "center",
    borderColor: borderColor,
    borderWidth: 1,
  },
  feline: {
    width: "11%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    textAlign: "center",
    borderColor: borderColor,
    borderWidth: 1,
  },
  subtotal: {
    width: "11%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    textAlign: "center",
    borderColor: borderColor,
    borderWidth: 1,
  },
  other: {
    width: "11%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    textAlign: "center",
    borderColor: borderColor,
    borderWidth: 1,
  },
  total: {
    width: "11%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    textAlign: "center",
    borderColor: borderColor,
    borderWidth: 1,
  },
});

const TableRow = ({ items }) => {
  const rows = items.map((item, i) => {
    return i + 1 === items.length ? (
      <View style={styles.row}>
        <Text
          style={[styles.description, { border: 1, borderColor: "#1f6165" }]}
        >
          {item.name}
        </Text>
        <Text style={[styles.canine, { border: 1, borderColor: "#1f6165" }]}>
          {item.canine}
        </Text>
        <Text style={[styles.feline, { border: 1, borderColor: "#1f6165" }]}>
          {item.feline}
        </Text>
        <Text style={[styles.subtotal, { border: 1, borderColor: "#1f6165" }]}>
          {item.feline + item.canine}
        </Text>
        <Text style={[styles.other, { border: 1, borderColor: "#1f6165" }]}>
          {item.other}
        </Text>
        <Text
          style={[
            styles.total,
            { border: 1, borderColor: "#1f6165", backgroundColor: "#e6e7e8" },
          ]}
        >
          {item.feline + item.canine + item.other}
        </Text>
      </View>
    ) : (
      <View style={styles.row}>
        <Text style={styles.description}>{item.name}</Text>
        <Text style={styles.canine}>{item.canine}</Text>
        <Text style={styles.feline}>{item.feline}</Text>
        <Text style={styles.subtotal}>{item.feline + item.canine}</Text>
        <Text style={styles.other}>{item.other}</Text>
        <Text style={styles.total}>
          {item.feline + item.canine + item.other}
        </Text>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
