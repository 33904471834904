import React from "react";

export const OrganizationTypeTooltip = () => (
  <>
    <div className="font-bold mb-4">Government animal services</div>
    <dl className="mb-4">
      <dt className="font-bold">Animal shelter with a government contract</dt>
      <dd>
        (private/nonprofit agency with a physical facility and a contract for
        animal control or housing services)
      </dd>
    </dl>
    <dl className="mb-4">
      <dt className="font-bold">Animal rescue with a government contract</dt>
      <dd>
        (private/nonprofit and foster based agency with a contract for animal
        control or housing services)
      </dd>
    </dl>
    <dl className="mb-4">
      <dt className="font-bold">Shelter without a government contract</dt>
      <dd>(private/nonprofit agency with physical facility)</dd>
    </dl>
    <dl className={"mb-4"}>
      <dt className="font-bold">Rescue without a government contract</dt>
      <dd>(private/nonprofit agency that’s foster-based)</dd>
    </dl>
    <dl>
      <dt className="font-bold">Non-sheltering service provider</dt>
      <dd>(agency that provides direct or partnered services)</dd>
    </dl>
  </>
);

export const OrganizationStatusTooltip = () => (
  <>
    <div className="font-bold mb-4">Organization Status</div>
    <dl className="mb-4">
      <dt className="font-bold">Registered</dt>
      <dd>
        (New organization, has not clicked the “confirm” link in the
        confirmation email)
      </dd>
    </dl>
    <dl className="mb-4">
      <dt className="font-bold">Confirmed</dt>
      <dd>(Organization registered and confirmed)</dd>
    </dl>
    <dl className="mb-4">
      <dt className="font-bold">Closed </dt>
      <dd>(Organization is closed)</dd>
    </dl>
    <dl className="mb-4">
      <dt className="font-bold">Paused </dt>
      <dd>(Organization participation is paused)</dd>
    </dl>
  </>
);

export const OrganizationEinTooltip = () => (
  <>
    <div>Employee Identification Number Format _________</div>
  </>
);

export const OrganizationBusinessNumberTooltip = () => (
  <>
    <div>Business Number Format _________</div>
  </>
);

export const OrganizationAnnualIntakeTooltip = () => (
  <>
    <div>
      This field is for administrative use only; please make your best
      approximation of annual intake of cats and dogs.
    </div>
  </>
);

export const OrganizationAnnualBudgetTooltip = () => (
  <>
    <div>
      This field is for administrative use only; please provide an estimate of
      your annual operating budget.
    </div>
  </>
);

export const OrganizationMemberRoleTooltip = () => (
  <>
    <div>Organization Managers have full access to the portal.</div>
    <div>Organization Viewers have view only access to the portal.</div>
  </>
);

export const OrganizationCommunicationsOptInTooltip = () => (
  <>
    <div>
      By opting into this communication, it fosters additional collaboration
      between organizations. It allows SAC staff to forward your organization's
      contact information to other organizations that might be experiencing
      similar issues data related or otherwise.
    </div>
  </>
);

export const OrganizationSearchableOptInTooltip = () => (
  <>
    <div>
      By opting into this communication, it fosters additional collaboration
      between organizations. It allows SAC contributing organizations to search
      for your organization by name, business number/ein, organization type,
      organization region or state
    </div>
  </>
);

export const OrganizationMediaOptInTooltip = () => (
  <>
    <div>
      At Shelter Animals Count, we understand the importance of sharing the
      stories behind the numbers. We often receive inquiries from journalists
      who are interested in shedding light on the work and experiences of animal
      shelters across the country. If you're a regular SAC contributor and would
      like to be contacted by journalists for potential interviews or features,
      please select "yes" in the drop down menu to provide your consent.
    </div>
  </>
);
