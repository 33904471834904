import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { isNumber } from "lodash";

/**
 * Takes object array and returns all the unique keys found in dataset.
 * @param {[object]]} data
 * @return arr
 */
export const getUniqueKeys = (data) =>
  Array.from(new Set([...data.map((entity) => Object.keys(entity)).flat()]));

const getRelatedKeys = ({ data, keySubString, accessKeys }) =>
  Object.keys(data)
    .filter((key) => key.includes(keySubString))
    .sort((a, b) => {
      const aIndex = accessKeys.findIndex((key) => a.indexOf(key) > -1);
      const bIndex = accessKeys.findIndex((key) => b.indexOf(key) > -1);
      if (aIndex > bIndex) return 1;
      if (aIndex < bIndex) return -1;
      return 0;
    });

/**
 * Constructs table data based on API record
 * @param {object} data key/value pairs to parse out and group
 * @param {string} rowLabel label used on screen for row
 * @param {string} keySubString keys to select from data
 * @param {array} accessKeys array of "accessor" strings set by the columns
 */
export const getRowData = ({
  data,
  rowLabel,
  keySubString,
  accessKeys,
  tooltip,
}) => {
  return getRelatedKeys({ data, keySubString, accessKeys }).reduce(
    (acc, key, i) =>
      !accessKeys[i]
        ? acc
        : {
            ...acc,
            [accessKeys[i]]: data[key],
            attributeKeys: acc["attributeKeys"]
              ? [...acc["attributeKeys"], key]
              : [key],
          },
    { rowLabel, tooltip }
  );
};

/**
 * Gets classes for each th element
 * @param {number} index index of the current header
 * @param {array} columns array of column values
 * @param {array} [columnWidths=null] array of widths for each column
 * @param {boolean} [dashboard=false] flags if table is dashboard variant
 * @param {array} [columnAlignments=null] array of alignment classes for each column
 * @return {string} classes
 */
export const getTableHeaderClasses = ({
  index,
  columnWidths = [],
  dashboard = false,
  columnAlignments = [],
}) => {
  const defaultClasses = "px-4 py-2 text-left font-normal text-table";
  const textClasses = !dashboard
    ? "bg-table-header-gray"
    : `${index !== 0 ? "bg-table-header-gray text-center" : ""}`;
  const widthClass = columnWidths[index] || "";
  const alignmentClass = columnAlignments[index] || "";
  return `${defaultClasses} ${textClasses} ${widthClass} ${alignmentClass}`
    .trim()
    .replace(/\s{2,}/g, " ");
};

/**
 * Gets classes for each td element
 * @param {number} index index of the current cell
 * @param {boolean} [dashboard=false] flags if table is dashboard variant
 * @param {array} [columnAlignments=null] array of alignment classes for each column
 * @return {string} classes
 */
export const getTableCellClasses = ({
  index,
  dashboard = false,
  columnAlignments = [],
}) => {
  const defaultClasses = "px-4 py-2 text-table";
  const borderClasses = `${!dashboard ? "border" : "border-t border-b"} ${
    index !== 0 ? "border-l" : ""
  } ${index !== 0 && dashboard ? "text-center" : ""}`;
  const alignmentClass = columnAlignments[index] || "";
  return `${defaultClasses} ${borderClasses} ${alignmentClass}`
    .trim()
    .replace(/\s{2,}/g, " ");
};

/**
 * Constructs JSX for a table row label
 * @param {object}
 * @param {boolean} value index of the current cell
 * @param {object} cell.row.original original row data object
 * @return {jsx} row label markup with optional tooltip
 */
export const rowLabelCell = ({
  value,
  cell: {
    row: { original },
  },
}) => {
  const uuid = uuidv4();
  return (
    <div className="flex items-center space-x-2">
      <span>{value}</span>
      {original.tooltip && (
        <>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="text-dashboard-medium-gray"
            data-tip
            data-for={uuid}
          />
          <ReactTooltip place="right" className="w-48" id={uuid}>
            {original.tooltip}
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

// This function returns a cell with a red border if the value of the
// cell has not been added
const getCell = ({ value }) => {
  const withValue = value !== null && value !== undefined;
  return withValue ? (
    value
  ) : (
    <div className="border border-red h-6">&nbsp;</div>
  );
};

// Columns are identified statically since the data contains more than we want
// the data does not match the "accessor" so the getRowData utility parses out keys containing the accessor
export const columns = [
  {
    accessor: "rowLabel",
    Header: "",
    Cell: rowLabelCell,
    Footer: "Subtotal",
  },
  {
    accessor: "adult",
    Header: "Adult",
    Cell: getCell,
    Footer: (data) => {
      // ternary operator because some fields use -1
      const adultTotal = data.rows.reduce(
        (sum, row) => (row.values.adult > 0 ? row.values.adult : 0) + sum,
        0
      );
      return <>{adultTotal}</>;
    },
  },
  {
    accessor: "youth",
    Header: "Up to 5 mos.",
    Cell: getCell,
    Footer: (data) => {
      const youthTotal = data.rows.reduce(
        (sum, row) => (row.values.youth > 0 ? row.values.youth : 0) + sum,
        0
      );
      return <>{youthTotal}</>;
    },
  },
  {
    accessor: "ageUnknown",
    Header: "Age Unknown",
    Cell: getCell,
    Footer: (data) => {
      const ageUnknownTotal = data.rows.reduce(
        (sum, row) =>
          (row.values.ageUnknown > 0 ? row.values.ageUnknown : 0) + sum,
        0
      );
      return <>{ageUnknownTotal}</>;
    },
  },
  {
    accessor: "total",
    Header: "Subtotal",
    Footer: (data) => {
      const total = data.rows.reduce(
        (sum, row) => (row.values.total > 0 ? row.values.total : 0) + sum,
        0
      );
      return <>{total}</>;
    },
  },
];

/**
 * Constructs default form values based on API response
 * @param {object} attributes key/value pairs to parse out and group
 * @return {object} key/value pairs, if the value is not a number, defaults to an empty string ("")
 */
export const getFormAttributes = (attributes) => {
  return Object.keys(attributes).reduce((acc, item) => {
    if (
      item.includes("total") ||
      item.includes("dateOfRecord") ||
      item.includes("createdAt") ||
      item.includes("updatedAt") ||
      item.includes("archivedAt") ||
      item.includes("legacy")
    ) {
      return acc;
    }
    const val = attributes[item];
    return { ...acc, [item]: isNumber(val) ? val : 0 };
  }, {});
};

/**
 * Constructs payload attributes based on form values
 * @param {object} attributes key/value pairs to parse out and group
 * @return {object} key/value pairs, if the value is not a number, defaults to an empty string ("")
 */
export const transformAttributesForPayload = (attributes) => {
  return Object.keys(attributes).reduce((acc, item) => {
    if (
      item.includes("adult") ||
      item.includes("ageUnknown") ||
      item.includes("youth") ||
      item.includes("beginning") ||
      item.includes("ending")
    ) {
      const val = attributes[item];
      return {
        ...acc,
        [item]: isNumber(val) ? val : null,
      };
    }

    return acc;
  }, {});
};

/**
 * Eliminates table rows that are not needed for a service type
 * @param {array} rows array of table row data with needed attribute keys
 * @param {object} attributes key/value pairs to parse out and group
 * @return {object} key/value pairs that are needed for a service type
 */

export const getServiceTypeAttributes = (rows, attributes) => {
  const rowData = rows.map((row) => row.attributeKeys);
  const combinedRows = [].concat(...rowData);
  const rowsObj = combinedRows.reduce((acc, curr, index) => {
    acc[curr] = null;
    return acc;
  }, {});

  const filteredAttributes = Object.fromEntries(
    Object.entries(attributes).filter(([key, value]) =>
      combinedRows.includes(key)
    )
  );

  return getFormAttributes({ ...rowsObj, ...filteredAttributes });
};
