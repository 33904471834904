import { apiFetch } from "utils";

export const getOrganizations = async ({
  token,
  dispatch,
  page = 1,
  search = "",
  userId = "",
}) => {
  return await apiFetch({
    token,
    dispatch,
    endpoint: `/api/v1/organizations?page[number]=${page}&extra_fields[organizations]=locations_count,locations_us_states,locations_provinces&stats[total]=count&sort=name${
      search ? `&filter[search]=${search}` : ""
    }${userId ? `&filter[user_id]=${userId}` : ""}`,
    includeMeta: true,
  });
};

export const getLocations = async ({
  token,
  dispatch,
  page = 1,
  search = "",
  userId = "",
}) => {
  return await apiFetch({
    token,
    dispatch,
    endpoint: `api/v1/locations?filter[organization_id][eq]=${search}&extra_fields[locations]=us_county_name&page[number]=1&stats[total]=count&sort=name`,
    includeMeta: true,
  });
};

const constructGetCoalitionsEndpoint = ({
  page = 1,
  search = "",
  operatingRegions = "",
  userId = "",
  orgId = "",
  region = "",
  country = "",
}) => {
  let endpoint = `/api/v1/coalitions?page[number]=${page}&extra_fields[coalitions]=organizations_count&stats[total]=count&filter[status][not_eq]=closed`;

  if (search) endpoint += `&filter[search]=${search}`;
  if (operatingRegions)
    endpoint += `&filter[operating_regions]=["${operatingRegions}"]`;
  if (userId && orgId)
    endpoint += `&filter[for_user][eq]=${userId}&filter[user_memberships.user_id][eq]=${userId}&filter[coalition_memberships.organization_id][eq]=${orgId}&include=user_memberships,coalition_memberships`;
  if (userId && !orgId)
    endpoint += `&filter[for_user][eq]=${userId}&filter[user_memberships.user_id][eq]=${userId}&include=user_memberships`;
  if (!userId && orgId)
    endpoint += `&filter[for_organization][eq]=${orgId}&filter[coalition_memberships.organization_id][eq]=${orgId}&include=coalition_memberships`;
  if (region) endpoint += `&filter[operating_region_type]=${region}`;
  if (country) endpoint += `&filter[country]=${country}`;

  return endpoint;
};

export const getCoalitions = ({
  token,
  dispatch,
  page = 1,
  search = "",
  operatingRegions = "",
  userId = "",
  orgId = "",
  region = "",
  country = "",
}) =>
  apiFetch({
    token,
    dispatch,
    endpoint: constructGetCoalitionsEndpoint({
      page,
      search,
      operatingRegions,
      userId,
      orgId,
      region,
      country,
    }),
    includeMeta: true,
  });

const constructGetOrganizationsEndpoint = ({
  page = 1,
  search = "",
  state = "",
  organizationType = "",
  country = "",
}) => {
  let endpoint = `/api/v1/organizations?page[number]=${page}&extra_fields[organizations]=locations_us_states,locations_provinces&stats[total]=count&sort=name&filter[searchable_organization][eq]=true`;

  if (search) endpoint += `&filter[search]=${search}`;
  if (state) endpoint += `&filter[locations_us_state]=${state}`;
  if (organizationType)
    endpoint += `&filter[organization_type]=${organizationType}`;
  if (country) endpoint += `&filter[country]=${country}`;

  return endpoint;
};

export const getOrganizationsForUsers = ({
  token,
  dispatch,
  page = 1,
  search = "",
  state = "",
  organizationType = "",
  country = "",
}) =>
  apiFetch({
    token,
    dispatch,
    endpoint: constructGetOrganizationsEndpoint({
      page,
      search,
      state,
      organizationType,
      country,
    }),
    includeMeta: true,
  });
