import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, useFormikContext } from "formik";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep, isNumber } from "lodash";
import Table, { transformAttributesForPayload } from "components/Table";
import { useAuthDispatch, useAuthState, useUserState } from "context";
import {
  speciesMap,
  getMonthName,
  apiFetch,
  useHandleRequestStates,
} from "utils";
import Button from "components/Button";

const BEGINNING_COUNT = "beginningAnimalCount";
const ENDING_COUNT = "endingAnimalCount";
const BEGINNING_FOSTER_COUNT = "beginningFosterAnimalCount";
const ENDING_FOSTER_COUNT = "endingFosterAnimalCount";

const AnimalCountForm = forwardRef(
  (
    {
      dataId,
      isEditing,
      species,
      beginningAnimalCount,
      setRecalculate,
      previousEndingAnimalCount,
      previousEndingFosterAnimalCount,
      endingAnimalCount,
      beginningFosterAnimalCount,
      endingFosterAnimalCount,
    },
    ref
  ) => {
    const { token } = useAuthState();
    const dispatch = useAuthDispatch();
    const queryClient = useQueryClient();
    const { year, month } = useParams();
    const { handleSuccess, handleError } = useHandleRequestStates();
    // const formProps = useFormikContext();

    const {
      attributes: { locationId },
    } = useUserState();

    const { mutate: updateAnimalCounts } = useMutation(
      (attributes) =>
        apiFetch({
          token,
          dispatch,
          endpoint: `/api/v1/animal_counts/${dataId}`,
          method: "PATCH",
          body: {
            data: {
              id: dataId,
              type: "animal_counts",
              attributes: transformAttributesForPayload(attributes),
            },
          },
        }),
      {
        onSuccess: (response) => {
          handleSuccess({
            message: `Updated the ${speciesMap[
              species
            ].label.toLocaleLowerCase()} counts`,
          });

          return queryClient.setQueryData(
            ["dataEntry", `${locationId}_${month}/${year}`],
            (oldData = {}) => {
              const newData = cloneDeep(oldData);
              if (!newData.counts) newData.counts = { data: [] };
              const speciesIndex = newData.counts.data.findIndex(
                (countsRecord) => {
                  return countsRecord.id === dataId;
                }
              );
              newData.counts.data[speciesIndex] = response.data;
              return newData;
            }
          );
        },
        onError: handleError,
      }
    );

    const speciesLabel = speciesMap[species].label;
    const date = `${getMonthName(parseInt(month, 10))} ${year}`;
    const data = [
      {
        rowLabel: `Number of ${speciesLabel.toLowerCase()} in care at the beginning of ${date}`,
        key: BEGINNING_COUNT,
        total: beginningAnimalCount,
        action: "a",
        tooltip:
          "Total animals in care including in shelter, at offsite locations, and in foster care",
      },
      {
        rowLabel: `Number of ${speciesLabel.toLowerCase()} in care at the end of ${date}`,
        key: ENDING_COUNT,
        total: endingAnimalCount,
        action: "a",
        tooltip:
          "Total animals in care including in shelter, at offsite locations, and in foster care",
      },
      {
        rowLabel: `Number of foster ${speciesLabel.toLowerCase()} in care at the beginning of ${date}`,
        key: BEGINNING_FOSTER_COUNT,
        total: beginningFosterAnimalCount,
        action: "a",
        tooltip: "Animals in foster care",
      },
      {
        rowLabel: `Number of foster ${speciesLabel.toLowerCase()} in care at the end of ${date}`,
        key: ENDING_FOSTER_COUNT,
        total: endingFosterAnimalCount,
        tooltip: "Animals in foster care",
      },
    ];

    const getRowLabelCell = ({
      value,
      cell: {
        row: { original },
      },
    }) => {
      const uuid = uuidv4();
      return (
        <div className="flex items-center space-x-2">
          <span>
            {isEditing ? (
              <label htmlFor={`${species}-${original.key}`}>{value}</label>
            ) : (
              value
            )}
          </span>
          {original.tooltip && (
            <>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-dashboard-medium-gray"
                data-tip
                data-for={uuid}
              />
              <ReactTooltip place="right" className="w-48" id={uuid}>
                {original.tooltip}
              </ReactTooltip>
            </>
          )}
        </div>
      );
    };

    const getActionCell = ({
      value,
      cell: {
        row: { original },
      },
    }) => {
      const uuid = uuidv4();
      return (
        <div className="flex items-center space-x-2">
          {isEditing && !!original.action && (
            <Button
              className={"auto_calculate"}
              type="button"
              onClick={() => {
                var key = original.key;
                var val = original.total;
                if (key == "beginningAnimalCount")
                  val = previousEndingAnimalCount;
                else if (key == "endingAnimalCount") val = 1;
                else if (key == "beginningFosterAnimalCount")
                  val = previousEndingFosterAnimalCount;
                setRecalculate(key, val);
              }}
            >
              Auto Calculate
            </Button>
          )}
        </div>
      );
    };

    const getTotalCell = ({
      value,
      cell: {
        row: { original },
      },
    }) => {
      if (isEditing)
        return (
          <Field
            name={original.key}
            id={`${species}-${original.key}`}
            type="number"
            className="form-input w-full"
            style={{ appearance: "textfield" }}
            min={0}
          />
        );
      const withValue = value !== null && value !== undefined;

      return withValue ? value : <div className="border border-red h-6"></div>;
    };

    const columns = [
      {
        accessor: "rowLabel",
        Header: "",
        Cell: getRowLabelCell,
      },
      {
        accessor: "action",
        Header: "",
        Cell: getActionCell,
      },
      {
        accessor: "total",
        Header: "Total",
        Cell: getTotalCell,
      },
    ];

    return (
      <Formik
        enableReinitialize
        initialValues={{
          beginningAnimalCount: isNumber(beginningAnimalCount)
            ? beginningAnimalCount
            : 0,
          endingAnimalCount: isNumber(endingAnimalCount)
            ? endingAnimalCount
            : 0,
          beginningFosterAnimalCount: isNumber(beginningFosterAnimalCount)
            ? beginningFosterAnimalCount
            : 0,
          endingFosterAnimalCount: isNumber(endingFosterAnimalCount)
            ? endingFosterAnimalCount
            : 0,
        }}
        onSubmit={(values) => updateAnimalCounts(values)}
        innerRef={ref}
      >
        <Form>
          <Table
            data={data}
            columns={columns}
            columnWidths={["", "w-1/5", "w-1/5"]}
            columnAlignments={[null, "text-right"]}
          />
        </Form>
      </Formik>
    );
  }
);

AnimalCountForm.propTypes = {
  dataId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  species: PropTypes.string.isRequired,
  beginningAnimalCount: PropTypes.number,
  endingAnimalCount: PropTypes.number,
  beginningFosterAnimalCount: PropTypes.number,
  endingFosterAnimalCount: PropTypes.number,
};

export default AnimalCountForm;
