import canine from "./canine.svg";
import feline from "./feline.svg";
import rabbit from "./rabbit.svg";
import equine from "./equine.svg";
import small_mammal from "./small_mammal.svg";
import bird from "./bird.svg";
import reptile_or_amphibian from "./reptile_or_amphibian.svg";
import farm_animal from "./farm_animal.svg";
import upload_icon from "./upload_icon.svg";
import logo from "./sac-logo.svg";

import add_user_to_org from "./add_user_to_org.svg";
import create_a_coalition from "./create_a_coalition.svg";
import enter_data_manually from "./enter_data_manually.svg";
import enter_data_with_csv from "./enter_data_with_csv.svg";
import invite_org_to_coalition from "./invite_org_to_coalition.svg";
import register_an_org from "./register_an_org.svg";
import request_to_join_coalition from "./request_to_join_coalition.svg";
import share_data_with_coalition from "./share_data_with_coalition.svg";

import data_contributing_member from "./marketing_resources/data_contributing_member.png";

const images = {
  canine,
  feline,
  rabbit,
  equine,
  small_mammal,
  bird,
  reptile_or_amphibian,
  farm_animal,
  upload_icon,
  logo,
};

export const videoThumbnails = {
  add_user_to_org,
  create_a_coalition,
  enter_data_manually,
  enter_data_with_csv,
  invite_org_to_coalition,
  register_an_org,
  request_to_join_coalition,
  share_data_with_coalition,
};

export const marketingResources = [
  {
    id: "data_contributing_member",
    icon: data_contributing_member,
  },
];

export default images;
