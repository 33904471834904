import React from "react";
import { useMutation } from "react-query";
import { Formik, Form } from "formik";
import { useAuthState, useAuthDispatch } from "context";
import { apiFetch, formatUiErrors } from "utils";
import Button from "components/Button";
import moment from "moment";
import FormField from "components/FormField";
import * as Yup from "yup";
import { endOfMonth } from "date-fns";
import DatePickerField from "components/DatePickerField";
import { toast } from "react-toastify";

const DataSchema = Yup.object().shape({
  orgId: Yup.string(),
  date: Yup.string().required("Date is required"),
});

function Aggregation() {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();

  const { mutate: aggregateData, isLoading } = useMutation(
    ({ orgId, date }) =>
      apiFetch({
        token,
        dispatch,
        method: "POST",
        endpoint: "/api/v1/shelterluv_aggregations",
        body: {
          orgId: orgId.trim(),
          month: moment(date).format("M"),
          year: moment(date).format("YYYY"),
        },
      }),
    {
      onSuccess: () => {
        toast.success(`Aggregation job has been queued successfully`, {
          autoClose: 3000,
        });
      },
      onError: (error) => {
        toast.error(() => formatUiErrors(error), {
          autoClose: 5000,
          className: "toast-center",
        });
      },
    }
  );
  return (
    <>
      <div className="p-5">
        <h1>ShelterLuv Aggregation</h1>
      </div>

      <div className="bg-white rounded p-6 border">
        <Formik
          initialValues={{ orgId: "", date: "" }}
          validationSchema={DataSchema}
          onSubmit={aggregateData}
        >
          {({ isValid, dirty }) => (
            <Form>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div>
                  <FormField
                    name="orgId"
                    label="Org ID"
                    tooltip={
                      <div>
                        Type a single OrgID to aggregate data for a specific
                        organization. Leave blank to aggregate data for all
                        organizations.
                      </div>
                    }
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mb-6">
                <DatePickerField
                  label="Month/Year"
                  name="date"
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  className="form-input w-full"
                  maxDate={endOfMonth(new Date())}
                  required
                />
              </div>

              <div className="flex justify-end">
                <Button
                  type="submit"
                  disabled={!isValid || !dirty}
                  isLoading={isLoading}
                  loadingText={<>Aggregating Data&hellip;</>}
                >
                  Aggregate Data
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Aggregation;
