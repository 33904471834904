import React, { useReducer, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { UserReducer, initialUserState } from "reducers";
import { useAuthState, useAuthDispatch } from "context";
import { USER_CREATE, UPDATE_TOKEN, UPDATE_USER_ID, logout } from "actions";
import LoadingIndicator from "components/LoadingIndicator";
import AppPage from "components/AppPage";
import {
  apiFetch,
  AUTH_TOKEN_KEY,
  USER_ID_KEY,
  useQueryParams,
  useEvent,
  formatUiErrors,
} from "utils";
import { getCoalitions } from "reducers/user";

export const UserStateContext = React.createContext();
export const UserDispatchContext = React.createContext();

export function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    return "";
  }

  return context;
}

export function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }

  return context;
}

export const OrgStateContext = React.createContext();
export const OrgDispatchContext = React.createContext();

export function useOrgState() {
  const context = useContext(OrgStateContext);
  if (context === undefined) {
    throw new Error("useOrgState must be used within a OrgProvider");
  }

  return context;
}

export function useOrgDispatch() {
  const context = useContext(OrgDispatchContext);
  if (context === undefined) {
    throw new Error("useOrgDispatch must be used within a OrgProvider");
  }

  return context;
}

export const UserProvider = ({ children }) => {
  const [user, dispatch] = useReducer(UserReducer, initialUserState);
  const { token, userId } = useAuthState();
  const authDispatch = useAuthDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [error, setError] = useState(null);
  const [needsRefresh, setNeedsRefresh] = useState(false);

  // Get the query params from the url
  const {
    uid,
    accountConfirmationSuccess,
    alreadyConfirmed,
    orgAuthorizationSuccess,
    orgAlreadyConfirmed,
  } = useQueryParams();

  // User is logged in when their userId and token are truthy
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(userId) && Boolean(token?.accessToken)
  );

  // isLoading should be set to true if the user is not logged in
  const [isLoading, setIsLoading] = useState(isLoggedIn);

  const [isConfirmingAccount, setIsConfirmingAccount] = useState(
    accountConfirmationSuccess === "true"
  );

  const { mutate: logoutRequest } = useMutation(logout);
  const handleLogout = (error) => {
    logoutRequest({
      dispatch: authDispatch,
      userDispatch: dispatch,
      queryClient,
      error,
    });
  };

  const getUserData = async () => {
    // If the user is logged in we need to make a request to the /users/:userId endpoint
    // If confirm = true the getUser function will first make a request to refresh the auth token

    setIsLoading(true);
    const confirmToken = { ...token, uid };

    if (!!isConfirmingAccount)
      await apiFetch({
        token: confirmToken,
        dispatch: authDispatch,
        endpoint: "/refresh_token",
      });

    const user = await apiFetch({
      token: !!isConfirmingAccount ? confirmToken : token,
      dispatch: authDispatch,
      endpoint: `/api/v1/users/${userId}?include=locations,user_memberships,organizations,organizations.coalition_memberships`,
    });

    const pendingCoalitionContributorRequests =
      await getPendingCoalitionContributorRequests({
        ...user,
        token,
        dispatch: authDispatch,
      });

    return { ...user, pendingCoalitionContributorRequests };
  };

  const handleUserRequestSuccess = (response) => {
    dispatch({ type: USER_CREATE, payload: response || {} });

    if (!!isConfirmingAccount) {
      history.replace("/?account_confirmation_success=true");
    }
    if (alreadyConfirmed === "true") {
      history.replace("/?already_confirmed=true");
    }
    if (orgAuthorizationSuccess === "true") {
      history.replace("/?org_authorization_success=true");
    }
    if (orgAlreadyConfirmed === "true") {
      history.replace("/?org_already_confirmed=true");
    }

    setIsLoading(false);
  };

  const handleUserRequestError = (error) => {
    setError(null);
    handleLogout(error);
    history.replace("/login");
  };

  const { refetch } = useQuery("user", getUserData, {
    enabled: isLoggedIn,
    onSuccess: handleUserRequestSuccess,
    onError: handleUserRequestError,
  });

  // Listen to updates from localStorage and update the token in the auth state
  // if the new token is different from the existing one. This makes sure that the user's
  // session is refreshed after they confirm their email change.
  // NOTE: UPDATE_TOKEN differs from REFRESH_TOKEN in that it only updates the auth state
  // but doesn't resave the token to localStorage. That happens during the confirmation flow
  // when the auth token is refreshed with the apiFetch utility function.
  const handleLocalStorageChange = ({ key, newValue, oldValue }) => {
    if (key === AUTH_TOKEN_KEY && newValue !== oldValue) {
      authDispatch({
        type: UPDATE_TOKEN,
        payload: { token: newValue ? JSON.parse(newValue) : {} },
      });
      setNeedsRefresh(true);
    }
    if (key === USER_ID_KEY && newValue !== oldValue) {
      authDispatch({
        type: UPDATE_USER_ID,
        payload: { userId: newValue },
      });
      setNeedsRefresh(true);
    }
  };
  useEvent("storage", handleLocalStorageChange);

  // Update the isLoggedIn state
  useEffect(() => {
    setIsLoggedIn(Boolean(userId) && Boolean(token?.accessToken));
  }, [userId, token]);

  // Update the isConfirmingAccount state
  useEffect(() => {
    setIsConfirmingAccount(accountConfirmationSuccess === "true");
  }, [accountConfirmationSuccess]);

  useEffect(() => {
    // Get the user data if the user is logged in
    isLoggedIn ? refetch() : setIsLoading(false);
  }, [isLoggedIn, refetch]);

  useEffect(() => {
    // Refresh if the local storage has changed and both auth values are set
    if (token?.accessToken && userId && needsRefresh) {
      refetch();
      setNeedsRefresh(false);
    }
  }, [token, userId, needsRefresh, refetch]);

  if (isLoading) return <LoadingIndicator />;

  return (
    <UserStateContext.Provider value={user}>
      <UserDispatchContext.Provider value={dispatch}>
        {error ? <AppPage>{formatUiErrors(error)}</AppPage> : children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const getPendingCoalitionContributorRequests = async ({
  included,
  token,
  dispatch,
}) => {
  const coalitions = getCoalitions(included) || [];

  const coalitionsUserManages = Object.keys(coalitions).filter(
    (key) =>
      get(coalitions[key].userMembership, "attributes.role") ===
      "coalition_manager"
  );

  // If the user is not a manager of any coalitions, return an empty array
  // because we don't need to make the request to see if any of their coalitions
  // have pending contributor requests
  if (!coalitionsUserManages.length) return [];

  const contributorRequests = constructPendingContributorRequestConfigs({
    coalitionIds: coalitionsUserManages,
    token,
    dispatch,
  });

  const coalitionsWithPendingRequests = await Promise.all(
    contributorRequests.map((config) => apiFetch(config))
  );

  return coalitionsWithPendingRequests.reduce((pendingRequests, res) => {
    if (!!res.included) {
      pendingRequests.push(res.data);
    }
    return pendingRequests;
  }, []);
};

const constructPendingContributorRequestConfigs = ({
  coalitionIds,
  token,
  dispatch,
}) => {
  return coalitionIds.map((coalitionId) => {
    return {
      token,
      dispatch,
      endpoint: `/api/v1/coalitions/${coalitionId}?include=coalition_memberships&filter[coalition_memberships.status]=requested&filter[coalition_memberships.status_actor]=Organization`,
    };
  });
};
