import { Field } from "formik";
import { cloneDeep, get } from "lodash";

/**
 * Determines if the table cell should be editable
 * @param {string} key key/value pairs to parse out and group
 * @return {boolean}
 */
export const isEditableKey = (key) => {
  return ["adult", "youth", "ageUnknown"].indexOf(key) > -1;
};

/**
 * Generates columns array for the table
 * Conditionally renders Field components when table is in edit mode
 * @param {array} params.defaultColumns array of default columns
 * @param {boolean} params.isEditing if table is in editMode
 * @param {string} params.species table species
 * @return {array} table columns
 */
export const getTableColumns = ({ defaultColumns, isEditing, species }) => {
  return defaultColumns.map((col) => {
    const showField = isEditing && isEditableKey(col.accessor);

    if (showField) {
      return {
        ...col,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          const key = original.attributeKeys.find((key) =>
            key.includes(col.accessor)
          );
          return (
            <Field
              name={key}
              id={`${species}-${key}`}
              data-testid={`${species}-${key}`}
              type="number"
              className="form-input w-full"
              min={0}
            />
          );
        },
      };
    }

    return col;
  });
};

/**
 * Returns the initial animal species
 * @param {array} params.data API response data array
 * @param {object} params.organization user's organization
 * @param {function} params.setAnimalSpecies function to update the animalSpecies state
 */
export const getDefaultAnimalSpecies = ({
  data,
  organization,
  setAnimalSpecies,
  entryStatus,
}) => {
  const orgSpecies = get(organization, "attributes.animalSpecies");

  if (!data.length) {
    if (!!orgSpecies && !!orgSpecies.length) {
      setAnimalSpecies(orgSpecies);
    }
  } else {
    const dataToSpeciesArray = data.reduce((speciesArray, item) => {
      const {
        attributes: { species },
      } = item;

      if (speciesArray.indexOf(species) === -1) {
        speciesArray.push(species);
      }

      return speciesArray;
    }, []);
    setAnimalSpecies(dataToSpeciesArray);
  }
};

/**
 * Generates payload for updating the animal species records
 * for a given location/year/month combination
 * @param {array} params.addedSpecies array of species to add
 * @param {array} params.removedSpecies array of species to remove
 * @param {string} params.locationId
 * @param {string} params.year
 * @param {string} params.month
 * @return {object} payload for updating the animal species
 */
export const generateAnimalSpeciesPayload = ({
  addedSpecies,
  removedSpecies,
  locationId,
  year,
  month,
}) => {
  const changedSpecies = [];
  addedSpecies.forEach((speciesKey) =>
    changedSpecies.push({ speciesKey, method: "create" })
  );
  removedSpecies.forEach((species) =>
    changedSpecies.push({
      speciesKey: species.key,
      ...species,
      method: "destroy",
    })
  );

  // Loop over ADDED species and build relationships object & Included array
  return changedSpecies.reduce(
    (payloadMap, species) => {
      const speciesKey = species.speciesKey;

      const includedAttributes = {
        location_id: locationId,
        species: speciesKey,
        month_of_record: month,
        year_of_record: year,
      };

      if (species.method === "create") {
        payloadMap.relationships.animal_services.data.push({
          type: "animal_services",
          method: "create",
          "temp-id": species.speciesKey,
        });
        payloadMap.included.push({
          type: "animal_services",
          "temp-id": species.speciesKey,
          attributes: includedAttributes,
        });
      } else {
        if (species.id) {
          payloadMap.relationships.animal_services.data.push({
            type: "animal_services",
            method: "destroy",
            id: species.id,
          });
        }
      }

      return payloadMap;
    },
    cloneDeep({
      relationships: {
        animal_services: {
          data: [],
        },
      },
      included: [],
    })
  );
};

/**
 * Generates updated data for the services matrix cache
 * for a given location/year/month combination
 * @param {object} params.oldData existing cache data
 * @param {object} params.response API response
 * @param {object} params.changedSpecies object containing the species to add and remove
 * @return {object} updated cache value
 */
export const updateSpeciesCache = ({
  oldData = { data: [] },
  response,
  changedSpecies,
}) => {
  let newData = cloneDeep(oldData);

  // Add new species to the cached data
  Object.assign(newData, {
    ...(!!changedSpecies.added.length
      ? addSpeciesToCache({ oldData, response })
      : {}),
  });

  // Remove species from the cached data
  if (!!Object.keys(changedSpecies.removed).length) {
    newData = removeSpeciesFromCache({
      response: newData,
      removedSpecies: changedSpecies.removed,
    });
  }

  return newData;
};

/**
 * Generates updated data for the services matrix cache
 * for the species to add to a given location/year/month combination
 * @param {object} params.oldData existing cache data
 * @param {object} params.response API response
 * @return {object} updated cache value
 */
export const addSpeciesToCache = ({ oldData = { data: [] }, response }) => {
  const newData = cloneDeep(oldData);

  response.included.forEach((incRecord) => {
    newData.data.push(incRecord);
  });

  return newData;
};

/**
 * Generates updated data for the services matrix cache
 * for the species to remove from a given location/year/month combination
 * @param {object} params.oldData existing cache data
 * @param {object} params.response API response
 * @return {object} updated cache value
 */
export const removeSpeciesFromCache = ({ response, removedSpecies }) => {
  const newData = cloneDeep(response);

  removedSpecies.forEach((removed) => {
    const index = newData.data.findIndex((record) => {
      return record.id === removed.id;
    });
    if (index > -1) newData.data.splice(index, 1);
  });

  return newData;
};

/**
 * Generates attributes to be updated for the service matrix cache
 * @param {string} params.form name of form to get required attributes
 * @param {array} params.attributes attributes passed tn the form
 * @param {object} params.newAttributes all attributes with values
 * @return {object} only updated attributes
 */
export const getChangedAttributes = (form, attributes, newAttributes) => {
  const { [form]: allowedAttrs } = formAttributes();
  return Object.fromEntries(
    Object.entries(newAttributes).filter(
      ([key, val]) =>
        key in attributes &&
        allowedAttrs.includes(key) &&
        (attributes[key] !== val || (attributes[key] === 0 && val === 0))
    )
  );
};

/**
 * Generates keys for form attributes
 * @return {object} Object contains a list of attributes in the form
 */
export const formAttributes = () => ({
  field: [
    "adultTrapNeuterReturnCount",
    "youthTrapNeuterReturnCount",
    "ageUnknownTrapNeuterReturnCount",
    "adultReturnToOwnerCount",
    "youthReturnToOwnerCount",
    "ageUnknownReturnToOwnerCount",
    "totalTrapNeuterReturnCount",
    "totalReturnToOwnerCount",
  ],
  vet: [
    "adultMicrochippingCollarIdTagsCount",
    "youthMicrochippingCollarIdTagsCount",
    "ageUnknownMicrochippingCollarIdTagsCount",
    "adultSpayNeuterOwnedAnimalCount",
    "youthSpayNeuterOwnedAnimalCount",
    "ageUnknownSpayNeuterOwnedAnimalCount",
    "adultWellnessPreventiveMedicalCareCount",
    "youthWellnessPreventiveMedicalCareCount",
    "ageUnknownWellnessPreventiveMedicalCareCount",
    "adultBasicAnimalCareCount",
    "youthBasicAnimalCareCount",
    "ageUnknownBasicAnimalCareCount",
    "adultAdvancedAnimalCareCount",
    "youthAdvancedAnimalCareCount",
    "ageUnknownAdvancedAnimalCareCount",
    "adultOwnerIntendedEuthanasiaCount",
    "youthOwnerIntendedEuthanasiaCount",
    "ageUnknownOwnerIntendedEuthanasiaCount",
    "totalMicrochippingCollarIdTagsCount",
    "totalSpayNeuterOwnedAnimalCount",
    "totalWellnessPreventiveMedicalCareCount",
    "totalBasicAnimalCareCount",
    "totalAdvancedAnimalCareCount",
    "totalOwnerIntendedEuthanasiaCount",
  ],
  support: [
    "adultPetFoodCount",
    "youthPetFoodCount",
    "ageUnknownPetFoodCount",
    "adultPetSuppliesCount",
    "youthPetSuppliesCount",
    "ageUnknownPetSuppliesCount",
    "adultGroomingCount",
    "youthGroomingCount",
    "ageUnknownGroomingCount",
    "adultTemporaryHousingOwnedAnimalsCount",
    "youthTemporaryHousingOwnedAnimalsCount",
    "ageUnknownTemporaryHousingOwnedAnimalsCount",
    "adultTemporaryHousingPeopleAndAnimalsCount",
    "youthTemporaryHousingPeopleAndAnimalsCount",
    "ageUnknownTemporaryHousingPeopleAndAnimalsCount",
    "adultOtherPetRetentionServicesCount",
    "youthOtherPetRetentionServicesCount",
    "ageUnknownOtherPetRetentionServicesCount",
    "adultRehomingSupportCount",
    "youthRehomingSupportCount",
    "ageUnknownRehomingSupportCount",
    "totalPetFoodCount",
    "totalPetSuppliesCount",
    "totalGroomingCount",
    "totalTemporaryHousingOwnedAnimalsCount",
    "totalTemporaryHousingPeopleAndAnimalsCount",
    "totalOtherPetRetentionServicesCount",
    "totalRehomingSupportCount",
  ],
  behavior: [
    "adultBehaviorConsultationCount",
    "youthBehaviorConsultationCount",
    "ageUnknownBehaviorConsultationCount",
    "adultGroupTrainingCount",
    "youthGroupTrainingCount",
    "ageUnknownGroupTrainingCount",
    "adultPrivateTrainingCount",
    "youthPrivateTrainingCount",
    "ageUnknownPrivateTrainingCount",
    "adultBehaviorModificationProgramCount",
    "youthBehaviorModificationProgramCount",
    "ageUnknownBehaviorModificationProgramCount",
    "totalBehaviorConsultationCount",
    "totalGroupTrainingCount",
    "totalPrivateTrainingCount",
    "totalBehaviorModificationProgramCount",
  ],
});
