import React from "react";
import { Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Tabs from "components/Tabs";
import Comparison from "./components/Comparison";
import AnalyticsAndForecast from "./components/AnalyticsAndForecast";
import Rehome from "./components/Rehome";
import CommunityServicesTotals from "./components/CommunityServicesTotals";
import IntakeOutcomeTotals from "./components/IntakeOutcomeTotals";

const ResourceCenter = () => {
  const { pathname } = useLocation();
  const tabs = [
    {
      to: "/dashboards/comparison",
      label: "Comparison",
      exact: false,
      isActive: () => {
        return pathname === "/dashboards/comparison";
      },
    },
    {
      to: "/dashboards/analytics-and-forecast",
      label: "Analytics & Forecast",
      exact: false,
      isActive: () => {
        return pathname === "/dashboards/analytics-and-forecast";
      },
    },
    {
      to: "/dashboards/rehome",
      label: "Rehome",
      exact: false,
      isActive: () => {
        return pathname === "/dashboards/rehome";
      },
    },
    {
      to: "/dashboards/community-services-totals",
      label: "Community Services Totals",
      exact: false,
      isActive: () => {
        return pathname === "/dashboards/community-services-totals";
      },
    },
    {
      to: "/dashboards/intake-outcome-totals",
      label: "Intake and Outcome Totals",
      exact: false,
      isActive: () => {
        return pathname === "/dashboards/intake-outcome-totals";
      },
    },
  ];
  return (
    <>
      <h1>Dashboards</h1>

      <Tabs tabs={tabs} />

      <div className="tab__content">
        <Switch>
          <Route path="/dashboards/comparison" exact component={Comparison} />
          <Route
            path="/dashboards/analytics-and-forecast"
            exact
            component={AnalyticsAndForecast}
          />
          <Route path="/dashboards/rehome" exact component={Rehome} />
          <Route
            path="/dashboards/community-services-totals"
            exact
            component={CommunityServicesTotals}
          />
          <Route
            path="/dashboards/intake-outcome-totals"
            exact
            component={IntakeOutcomeTotals}
          />
        </Switch>
      </div>
    </>
  );
};

export default ResourceCenter;
