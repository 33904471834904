import React, { useEffect, useState } from "react";
import DataEntryOption from "./DataEntryOption";
import {
  dataEntryOptions,
  dataEntryOptionsMap,
} from "../../utils/entryOptions";
import { Formik, Form, useFormikContext } from "formik";
import DataEntryOptionsFilters from "./DataEntryOptionsFilters";
import DataEntryOptionsCta from "./DataEntryOptionsCta";
import { previousMonth as defaultPreviousMonth } from "utils";
import PropTypes from "prop-types";
import EntryLogs from "./EntryLogs";

const DataEntryOptions = ({ label, action }) => {
  const [isCsv, setIsCsv] = useState(false);
  const previousMonth = defaultPreviousMonth;

  const GetFormValues = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      setIsCsv(values.dataEntryOption === dataEntryOptions.CSV);
    }, [values]);
    return null;
  };

  return (
    <div data-testid="data-entry-options">
      <h1>{`${
        action === "services" ? "Services" : "Intakes and Outcomes"
      }: Get Started`}</h1>
      <p className="mb-8">
        Let’s get started by uploading data for the selected month.
      </p>
      <h5 className="mb-3">How would you like to enter data?</h5>

      <Formik
        initialValues={{
          dataEntryOption: "",
          date: previousMonth,
        }}
      >
        {({ values }) => (
          <Form>
            {/* Options */}
            <div className="flex items-stretch space-x-4 mb-12">
              {Object.keys(dataEntryOptionsMap).map((optionKey, index) => {
                const data = dataEntryOptionsMap[optionKey];
                return (
                  <div
                    className={`w-1/${
                      Object.keys(dataEntryOptionsMap).length
                    } flex flex-col`}
                    key={index}
                  >
                    <DataEntryOption
                      id={optionKey}
                      icon={data.icon}
                      heading={data.heading}
                      content={data.content}
                      selected={values.dataEntryOption === optionKey}
                    />
                  </div>
                );
              })}
            </div>

            {values.dataEntryOption === dataEntryOptions.MANUAL && (
              <DataEntryOptionsFilters previousMonth={previousMonth} />
            )}

            <GetFormValues />

            {!!values.dataEntryOption && (
              <DataEntryOptionsCta
                date={values.date}
                isCsv={isCsv}
                label={label}
                action={action}
              />
            )}
          </Form>
        )}
      </Formik>

      {action !== "services" ? <EntryLogs /> : null}
    </div>
  );
};

DataEntryOptions.propTypes = {
  label: PropTypes.string,
  action: PropTypes.string,
};

DataEntryOptions.defaultProps = {
  label: "Enter Data",
  action: "data-entry",
};

export default DataEntryOptions;
