import React from "react";
import FormField from "components/FormField";
import { REGION_TYPES } from "./utils";

const RegionDropDown = ({ ...props }) => {
  const { country } = props;
  return (
    <FormField as="select" {...props}>
      <option value="">{REGION_TYPES.ALL}</option>
      <option value={REGION_TYPES.NATIONAL}>{REGION_TYPES.NATIONAL}</option>
      {country === "United States" ? (
        <option value={REGION_TYPES.MULTISTATE}>
          {REGION_TYPES.MULTISTATE}
        </option>
      ) : (
        <option value={REGION_TYPES.MULTIPROVINCE}>
          {REGION_TYPES.MULTIPROVINCE}
        </option>
      )}
      {country === "United States" ? (
        <option value={REGION_TYPES.STATEWIDE}>{REGION_TYPES.STATEWIDE}</option>
      ) : (
        <option value={REGION_TYPES.PROVINCEWIDE}>
          {REGION_TYPES.PROVINCEWIDE}
        </option>
      )}
      <option value={REGION_TYPES.COMMUNITY}>{REGION_TYPES.COMMUNITY}</option>
    </FormField>
  );
};

export default RegionDropDown;
