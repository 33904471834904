import { subMonths, startOfMonth } from "date-fns";

export const previousMonth = subMonths(startOfMonth(new Date()), 1);
export const currentMonth = subMonths(startOfMonth(new Date()), 0);

export const getMonthName = (monthNum) => {
  return getDateByMonth(monthNum).toLocaleString("default", { month: "long" });
};

export const getMonthAbbr = (monthNum) => {
  return getDateByMonth(monthNum).toLocaleString("default", { month: "short" });
};

export const getDateByMonth = (monthNum) => {
  if (
    isNaN(monthNum) ||
    typeof monthNum !== "number" ||
    monthNum > 12 ||
    monthNum < 1
  )
    return "";
  const year = new Date().getFullYear();
  // Need -1 because the Date function is expecting a 0-indexed month
  return new Date(year, monthNum - 1, 1);
};
