import React, { useEffect, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import FormField from "components/FormField";
import * as Yup from "yup";
import { useAuthDispatch, useAuthState, useUserState } from "context";
import { FORM_MODES, apiFetch, formatUiErrors } from "utils";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import { toast } from "react-toastify";
import Button from "components/Button";

export default function SharingPermissions() {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { coalitions } = useUserState();
  const { coalitionId } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [formMode, setFormMode] = useState("VIEW");
  const history = useHistory();
  const initialValues = getDataSharing({ coalitionId, coalitions });

  const sharingPermissionsSchema = Yup.object().shape({
    intakeOutcomeDataSharing: Yup.boolean(),
    communityServiceDataSharing: Yup.boolean(),
  });

  const handleUpdateSuccess = () => {
    history.push(`/coalitions/${coalitionId}/sharing-permissions`);
    setFormMode("VIEW");
    window.location.reload();
    toast.success("Saved successfully", {
      autoClose: 3000,
    });
  };
  const handleUpdateError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  const { mutate: updateSharing, isLoading } = useMutation(
    ({ intakeOutcomeDataSharing, communityServiceDataSharing }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/coalition_memberships/${initialValues.id}`,
        method: "PATCH",
        body: {
          data: {
            id: initialValues.id,
            type: "coalition_memberships",
            attributes: {
              intakeOutcomeDataSharing,
              communityServiceDataSharing,
            },
          },
        },
      }),
    {
      onSuccess: (data) => {
        setIsSuccess(true);
        handleUpdateSuccess(data);
      },
      onError: (e) => {
        setIsSuccess(false);
        handleUpdateError(e);
      },
    }
  );

  return (
    <Formik
      validationSchema={sharingPermissionsSchema}
      validateOnMount
      initialValues={initialValues}
      onSubmit={updateSharing}
    >
      {({
        handleSubmit,
        values: { intakeOutcomeDataSharing, communityServiceDataSharing },
        setFieldValue,
      }) => (
        <Form>
          <div className="mb-6">
            {/* Requested Data */}
            <p className="text-black">Shared Data</p>

            <div className="grid grid-cols-2 gap-6">
              <FormField
                name="intakeOutcomeDataSharing"
                label="All Intake/Outcome Data"
                checked={intakeOutcomeDataSharing}
                onChange={() => setFieldValue("intakeOutcomeDataSharing", true)}
                type="checkbox"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
              />

              <FormField
                name="intakeOutcomeDataSharing"
                label="No Intake/Outcome Data"
                checked={!intakeOutcomeDataSharing}
                onChange={() =>
                  setFieldValue("intakeOutcomeDataSharing", false)
                }
                type="checkbox"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
              />

              <FormField
                name="communityServiceDataSharing"
                label="All Community Services Data"
                checked={communityServiceDataSharing}
                onChange={() =>
                  setFieldValue("communityServiceDataSharing", true)
                }
                type="checkbox"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
              />
              <FormField
                name="communityServiceDataSharing"
                label="No Community Services Data"
                checked={!communityServiceDataSharing}
                onChange={() =>
                  setFieldValue("communityServiceDataSharing", false)
                }
                type="checkbox"
                readOnly={formMode === FORM_MODES.VIEW}
                disabled={formMode === FORM_MODES.VIEW}
              />
            </div>
          </div>

          {formMode !== FORM_MODES.VIEW && (
            <div className="flex justify-end space-x-4">
              <Button
                to={`/coalitions/${coalitionId}/sharing-permissions`}
                emphasis="transparent"
                onClick={() => {
                  setFormMode("VIEW");
                }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                loadingText={<>Updating&hellip;</>}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          )}

          {formMode === FORM_MODES.VIEW && (
            <div className="flex justify-end space-x-4">
              <Button
                onClick={() => {
                  setFormMode("EDIT");
                }}
              >
                Edit
              </Button>
            </div>
          )}

          <FormContext
            formMode={formMode}
            coalitionId={coalitionId}
            coalitions={coalitions}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
          />
        </Form>
      )}
    </Formik>
  );
}

const FormContext = ({
  formMode,
  coalitionId,
  coalitions,
  isSuccess,
  setIsSuccess,
}) => {
  const { resetForm, dirty } = useFormikContext();

  useEffect(() => {
    resetForm({
      values: getDataSharing({ coalitions, coalitionId }),
      formMode,
    });
  }, [formMode, coalitions, coalitionId, resetForm]);

  useEffect(() => {
    if (dirty) {
      setIsSuccess(false);
    }
  }, [dirty, setIsSuccess]);

  return (
    <RouteLeavingGuard
      when={!isSuccess && dirty}
      shouldBlockNavigation={() => !isSuccess && dirty}
    />
  );
};

const getDataSharing = ({ coalitionId, coalitions }) => {
  if (!coalitions || !coalitions[coalitionId]) return {};

  const memberships = coalitions[coalitionId];
  const orgMembership = memberships["orgMembership"];

  return {
    id: orgMembership.id,
    intakeOutcomeDataSharing: orgMembership.attributes.intakeOutcomeDataSharing,
    communityServiceDataSharing:
      orgMembership.attributes.communityServiceDataSharing,
  };
};
