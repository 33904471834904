import { apiFetch, EXPORT_DATE_FORMATS } from "utils";
import moment from "moment";

export const dataEntryHeaders = [
  { label: "Location ID", key: "locationId" },
  { label: "Record Year", key: "yearOfRecord" },
  { label: "Record Month", key: "monthOfRecord" },
  { label: "Species", key: "species" },
  { label: "Stray At Large Adult", key: "adultStrayAtLargeCount" },
  { label: "Stray At Large Up To 5 Months", key: "youthStrayAtLargeCount" },
  {
    label: "Stray At Large Age Unknown",
    key: "ageUnknownStrayAtLargeCount",
  },
  {
    label: "Relinquished By Owner Adult",
    key: "adultRelinquishedByOwnerCount",
  },
  {
    label: "Relinquished By Owner Up To 5 Months",
    key: "youthRelinquishedByOwnerCount",
  },
  {
    label: "Relinquished By Owner Age Unknown",
    key: "ageUnknownRelinquishedByOwnerCount",
  },
  {
    label: "Intake Owner-Intended Euthanasia Adult",
    key: "adultOwnerIntendedEuthanasiaCount",
  },
  {
    label: "Intake Owner-Intended Euthanasia Up To 5 Months",
    key: "youthOwnerIntendedEuthanasiaCount",
  },
  {
    label: "Intake Owner-Intended Euthanasia Age Unknown",
    key: "ageUnknownOwnerIntendedEuthanasiaCount",
  },
  {
    label: "Legacy Transferred In From Agency Adult",
    key: "adultLegacyTransferredInFromAgencyCount",
  },
  {
    label: "Legacy Transferred In From Agency Youth",
    key: "youthLegacyTransferredInFromAgencyCount",
  },
  {
    label: "Legacy Transferred In From Agency Age Unknown",
    key: "ageUnknownLegacyTransferredInFromAgencyCount",
  },
  {
    label: "Transferred In From Instate Agency Adult",
    key: "adultTransferredInstateInFromAgencyCount",
  },
  {
    label: "Transferred In From International Agency Adult",
    key: "adultTransferredInternationallyInFromAgencyCount",
  },
  {
    label: "Transferred In From Out of State Agency Adult",
    key: "adultTransferredOutOfStateInFromAgencyCount",
  },
  {
    label: "Transferred In From Instate Agency Up To 5 Months",
    key: "youthTransferredInstateInFromAgencyCount",
  },
  {
    label: "Transferred In From International Agency Up To 5 Months",
    key: "youthTransferredInternationallyInFromAgencyCount",
  },
  {
    label: "Transferred In From Out of State Agency Up To 5 Months",
    key: "youthTransferredOutOfStateInFromAgencyCount",
  },
  {
    label: "Transferred In From Instate Agency Age Unknown",
    key: "ageUnknownTransferredInstateInFromAgencyCount",
  },
  {
    label: "Transferred In From International Agency Age Unknown",
    key: "ageUnknownTransferredInternationallyInFromAgencyCount",
  },
  {
    label: "Transferred In From Out of State Agency Age Unknown",
    key: "ageUnknownTransferredOutOfStateInFromAgencyCount",
  },
  {
    label: "Seized Or Impounded Adult",
    key: "adultSeizedOrImpoundedCount",
  },
  {
    label: "Seized Or Impounded Up To 5 Months",
    key: "youthSeizedOrImpoundedCount",
  },
  {
    label: "Seized Or Impounded Age Unknown",
    key: "ageUnknownSeizedOrImpoundedCount",
  },
  { label: "Other Intakes Adult", key: "adultOtherIntakesCount" },
  { label: "Other Intakes Up To 5 Months", key: "youthOtherIntakesCount" },
  {
    label: "Other Intakes Age Unknown",
    key: "ageUnknownOtherIntakesCount",
  },
  { label: "Adoption Adult", key: "adultAdoptionCount" },
  { label: "Adoption Up To 5 Months", key: "youthAdoptionCount" },
  { label: "Adoption Age Unknown", key: "ageUnknownAdoptionCount" },
  { label: "Returned To Owner Adult", key: "adultReturnedToOwnerCount" },
  {
    label: "Returned To Owner Up To 5 Months",
    key: "youthReturnedToOwnerCount",
  },
  {
    label: "Returned To Owner Age Unknown",
    key: "ageUnknownReturnedToOwnerCount",
  },
  {
    label: "Legacy Transferred Out To Agency Adult",
    key: "adultLegacyTransferredToAnotherAgencyCount",
  },
  {
    label: "Legacy Transferred Out To Agency Youth",
    key: "youthLegacyTransferredToAnotherAgencyCount",
  },
  {
    label: "Legacy Transferred Out To Agency Age Unknown",
    key: "ageUnknownLegacyTransferredToAnotherAgencyCount",
  },

  {
    label: "Transferred Out To Instate Agency Adult",
    key: "adultTransferredInstateToAnotherAgencyCount",
  },
  {
    label: "Transferred Out To International Agency Adult",
    key: "adultTransferredInternationallyToAnotherAgencyCount",
  },
  {
    label: "Transferred Out To Out of State Agency Adult",
    key: "adultTransferredOutOfStateToAnotherAgencyCount",
  },
  {
    label: "Transferred Out To Instate Agency Up To 5 Months",
    key: "youthTransferredInstateToAnotherAgencyCount",
  },
  {
    label: "Transferred Out To International Agency Up To 5 Months",
    key: "youthTransferredInternationallyToAnotherAgencyCount",
  },
  {
    label: "Transferred Out To Out of State Agency Up To 5 Months",
    key: "youthTransferredOutOfStateToAnotherAgencyCount",
  },
  {
    label: "Transferred Out To Instate Agency Age Unknown",
    key: "ageUnknownTransferredInstateToAnotherAgencyCount",
  },
  {
    label: "Transferred Out To International Agency Age Unknown",
    key: "ageUnknownTransferredInternationallyToAnotherAgencyCount",
  },
  {
    label: "Transferred Out To Out of State Agency Age Unknown",
    key: "ageUnknownTransferredOutOfStateToAnotherAgencyCount",
  },
  { label: "Returned To Field Adult", key: "adultReturnedToFieldCount" },
  {
    label: "Returned To Field Up To 5 Months",
    key: "youthReturnedToFieldCount",
  },
  {
    label: "Returned To Field Age Unknown",
    key: "ageUnknownReturnedToFieldCount",
  },
  { label: "Other Live Outcome Adult", key: "adultOtherLiveOutcomeCount" },
  {
    label: "Other Live Outcome Up To 5 Months",
    key: "youthOtherLiveOutcomeCount",
  },
  {
    label: "Other Live Outcome Age Unknown",
    key: "ageUnknownOtherLiveOutcomeCount",
  },
  { label: "Died In Care Adult", key: "adultDiedInCareCount" },
  { label: "Died In Care Up To 5 Months", key: "youthDiedInCareCount" },
  { label: "Died In Care Age Unknown", key: "ageUnknownDiedInCareCount" },
  { label: "Lost In Care Adult", key: "adultLostInCareCount" },
  { label: "Lost In Care Up To 5 Months", key: "youthLostInCareCount" },
  { label: "Lost In Care Age Unknown", key: "ageUnknownLostInCareCount" },
  { label: "Shelter Euthanasia Adult", key: "adultShelterEuthanasiaCount" },
  {
    label: "Shelter Euthanasia Up To 5 Months",
    key: "youthShelterEuthanasiaCount",
  },
  {
    label: "Shelter Euthanasia Age Unknown",
    key: "ageUnknownShelterEuthanasiaCount",
  },
  {
    label: "Outcome Owner-Intended Euthanasia Adult",
    key: "adultOwnerIntendedEuthanasiaCountOutcome",
  },
  {
    label: "Outcome Owner-Intended Euthanasia Up To 5 Months",
    key: "youthOwnerIntendedEuthanasiaCountOutcome",
  },
  {
    label: "Outcome Owner-Intended Euthanasia Age Unknown",
    key: "ageUnknownOwnerIntendedEuthanasiaCountOutcome",
  },
  { label: "Beginning Count", key: "beginningAnimalCount" },
  { label: "Beginning Foster Animal Count", key: "beginningFosterAnimalCount" },
  { label: "Beginning Count Date", key: "beginningAnimalCountDate" },
  { label: "Ending Count", key: "endingAnimalCount" },
  { label: "Ending Foster Animal Count", key: "endingFosterAnimalCount" },
  { label: "Ending Count Date", key: "endingAnimalCountDate" },
];

const animalCountKeys = [
  "beginningAnimalCount",
  "beginningFosterAnimalCount",
  "beginningAnimalCountDate",
  "endingAnimalCount",
  "endingFosterAnimalCount",
  "endingAnimalCountDate",
  "locationId",
  "yearOfRecord",
  "monthOfRecord",
  "species",
];

export const serviceHeaders = [
  { label: "Location ID", key: "locationId" },
  { label: "Record Year", key: "yearOfRecord" },
  { label: "Record Month", key: "monthOfRecord" },
  { label: "Species", key: "species" },
  { label: "Adult Trap Neuter Return", key: "adultTrapNeuterReturnCount" },
  { label: "Youth Trap Neuter Return", key: "youthTrapNeuterReturnCount" },
  {
    label: "Age Unknown Trap Neuter Return",
    key: "ageUnknownTrapNeuterReturnCount",
  },
  { label: "Adult Return To Owner", key: "adultReturnToOwnerCount" },
  { label: "Youth Return To Owner", key: "youthReturnToOwnerCount" },
  {
    label: "Age Unknown Return To Owner",
    key: "ageUnknownReturnToOwnerCount",
  },
  {
    label: "Adult Microchipping Collar Id Tags",
    key: "adultMicrochippingCollarIdTagsCount",
  },
  {
    label: "Youth Microchipping Collar Id Tags",
    key: "youthMicrochippingCollarIdTagsCount",
  },
  {
    label: "Age Unknown Microchipping Collar Id Tags",
    key: "ageUnknownMicrochippingCollarIdTagsCount",
  },
  {
    label: "Adult Spay Neuter Owned Animal",
    key: "adultSpayNeuterOwnedAnimalCount",
  },
  {
    label: "Youth Spay Neuter Owned Animal",
    key: "youthSpayNeuterOwnedAnimalCount",
  },
  {
    label: "Age Unknown Spay Neuter Owned Animal",
    key: "ageUnknownSpayNeuterOwnedAnimalCount",
  },
  {
    label: "Adult Wellness Preventive Medical Care",
    key: "adultWellnessPreventiveMedicalCareCount",
  },
  {
    label: "Youth Wellness Preventive Medical Care",
    key: "youthWellnessPreventiveMedicalCareCount",
  },
  {
    label: "Age Unknown Wellness Preventive Medical Care",
    key: "ageUnknownWellnessPreventiveMedicalCareCount",
  },
  { label: "Adult Basic Animal Care", key: "adultBasicAnimalCareCount" },
  { label: "Youth Basic Animal Care", key: "youthBasicAnimalCareCount" },
  {
    label: "Age Unknown Basic Animal Care",
    key: "ageUnknownBasicAnimalCareCount",
  },
  {
    label: "Adult Advanced Animal Care",
    key: "adultAdvancedAnimalCareCount",
  },
  {
    label: "Youth Advanced Animal Care",
    key: "youthAdvancedAnimalCareCount",
  },
  {
    label: "Age Unknown Advanced Animal Care",
    key: "ageUnknownAdvancedAnimalCareCount",
  },
  {
    label: "Adult Owner Intended Euthanasia",
    key: "adultOwnerIntendedEuthanasiaCount",
  },
  {
    label: "Youth Owner Intended Euthanasia",
    key: "youthOwnerIntendedEuthanasiaCount",
  },
  {
    label: "Age Unknown Owner Intended Euthanasia",
    key: "ageUnknownOwnerIntendedEuthanasiaCount",
  },
  {
    label: "Adult Behavior Consultation",
    key: "adultBehaviorConsultationCount",
  },
  {
    label: "Youth Behavior Consultation",
    key: "youthBehaviorConsultationCount",
  },
  {
    label: "Age Unknown Behavior Consultation",
    key: "ageUnknownBehaviorConsultationCount",
  },
  { label: "Adult Group Training", key: "adultGroupTrainingCount" },
  { label: "Youth Group Training", key: "youthGroupTrainingCount" },
  {
    label: "Age Unknown Group Training",
    key: "ageUnknownGroupTrainingCount",
  },
  { label: "Adult Private Training", key: "adultPrivateTrainingCount" },
  { label: "Youth Private Training", key: "youthPrivateTrainingCount" },
  {
    label: "Age Unknown Private Training",
    key: "ageUnknownPrivateTrainingCount",
  },
  {
    label: "Adult Behavior Modification Program",
    key: "adultBehaviorModificationProgramCount",
  },
  {
    label: "Youth Behavior Modification Program",
    key: "youthBehaviorModificationProgramCount",
  },
  {
    label: "Age Unknown Behavior Modification Program",
    key: "ageUnknownBehaviorModificationProgramCount",
  },
  { label: "Adult Pet Food", key: "adultPetFoodCount" },
  { label: "Youth Pet Food", key: "youthPetFoodCount" },
  { label: "Age Unknown Pet Food", key: "ageUnknownPetFoodCount" },
  { label: "Adult Pet Supplies", key: "adultPetSuppliesCount" },
  { label: "Youth Pet Supplies", key: "youthPetSuppliesCount" },
  { label: "Age Unknown Pet Supplies", key: "ageUnknownPetSuppliesCount" },
  { label: "Adult Grooming", key: "adultGroomingCount" },
  { label: "Youth Grooming", key: "youthGroomingCount" },
  { label: "Age Unknown Grooming", key: "ageUnknownGroomingCount" },
  {
    label: "Adult Temporary Housing Owned Animals",
    key: "adultTemporaryHousingOwnedAnimalsCount",
  },
  {
    label: "Youth Temporary Housing Owned Animals",
    key: "youthTemporaryHousingOwnedAnimalsCount",
  },
  {
    label: "Age Unknown Temporary Housing Owned Animals",
    key: "ageUnknownTemporaryHousingOwnedAnimalsCount",
  },
  {
    label: "Adult Temporary Housing People And Animals",
    key: "adultTemporaryHousingPeopleAndAnimalsCount",
  },
  {
    label: "Youth Temporary Housing People And Animals",
    key: "youthTemporaryHousingPeopleAndAnimalsCount",
  },
  {
    label: "Age Unknown Temporary Housing People And Animals",
    key: "ageUnknownTemporaryHousingPeopleAndAnimalsCount",
  },
  {
    label: "Adult Other Pet Retention Services",
    key: "adultOtherPetRetentionServicesCount",
  },
  {
    label: "Youth Other Pet Retention Services",
    key: "youthOtherPetRetentionServicesCount",
  },
  {
    label: "Age Unknown Other Pet Retention Services",
    key: "ageUnknownOtherPetRetentionServicesCount",
  },
  { label: "Adult Rehoming Support", key: "adultRehomingSupportCount" },
  { label: "Youth Rehoming Support", key: "youthRehomingSupportCount" },
  {
    label: "Age Unknown Rehoming Support",
    key: "ageUnknownRehomingSupportCount",
  },
];

export const generateExport = async ({
  formValues: { location, dateFormat, date },
  organizationId,
  token,
  dispatch,
  action,
}) => {
  var dates = date;
  var newResponse = {};
  if (action === "data-entry") {
    newResponse = {
      counts: { data: [] },
      intakes: { data: [] },
      outcomes: { data: [] },
    };
  } else {
    newResponse = {
      services: { data: [] },
    };
  }

  for (let index = 0; index < dates.length; index++) {
    date = dates[index];
    const year = new Date(date).getFullYear();
    const month =
      dateFormat === EXPORT_DATE_FORMATS.MONTH
        ? new Date(date).getMonth() + 1
        : "";
    const dateFilters = `filter[year_of_record][eq]=${year}${
      dateFormat === EXPORT_DATE_FORMATS.MONTH
        ? `&filter[month_of_record][eq]=${month}`
        : ""
    }`;
    const locationFilter = !!location.trim()
      ? `&filter[location_id][eq]=${location}`
      : `&filter[organization_id][eq]=${organizationId}`;
    if (action === "data-entry") {
      var counts = await apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_counts?${dateFilters}${locationFilter}&page[size]=1000`,
      });
      var intakes = await apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_intakes?${dateFilters}${locationFilter}&page[size]=1000`,
      });
      var outcomes = await apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_outcomes?${dateFilters}${locationFilter}&page[size]=1000`,
      });
      newResponse.counts.data = newResponse.counts.data.concat(counts.data);
      newResponse.intakes.data = newResponse.intakes.data.concat(intakes.data);
      newResponse.outcomes.data = newResponse.outcomes.data.concat(
        outcomes.data
      );
    } else {
      let services = await apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_services?${dateFilters}${locationFilter}&page[size]=1000`,
      });
      newResponse.services.data = newResponse.services.data.concat(
        services.data
      );
    }
  }

  const fYear = new Date(dates[0]).getFullYear();
  const lYear = new Date(dates[dates.length - 1]).getFullYear();

  const fMonth = new Date(dates[0]).getFullYear();
  return { ...newResponse, year: fYear + " - " + lYear, month: fMonth };
};

export const transformCsvData = (response) => {
  if (!response) return [];

  const allItems = Object.entries(response)
    .filter(([key]) => key !== "month" && key !== "year")
    .map((key) => {
      if (key[0] === "outcomes") {
        key[1].data.forEach((data) =>
          renameOutcomeEuthanasiaAttributes(data.attributes)
        );
      }
      return key;
    })
    .map((key) => key[1].data)
    .flat();

  const dataAtrributes = allItems
    .map((data) => data.attributes)
    .map((obj) => {
      const newObj = {};
      if (isSumGreaterThanZero(obj)) {
        for (const key in obj) {
          let value = 0;
          if (key.includes("legacy")) {
            value = 0;
          } else if (animalCountKeys.includes(key)) {
            value = obj[key];
          } else {
            value = obj[key] || 0;
          }

          newObj[key] = value;
        }
      } else {
        for (const key in obj) {
          let value = 0;
          if (animalCountKeys.includes(key)) {
            value = obj[key];
          } else {
            value = obj[key] || 0;
          }

          newObj[key] = value;
        }
      }
      return newObj;
    });

  const commonKeys = ["locationId", "yearOfRecord", "monthOfRecord", "species"];

  // Use reduce to group the objects based on the common keys
  const groupedData = dataAtrributes.reduce((acc, obj) => {
    // Generate a key based on the common keys
    const key = commonKeys.map((k) => obj[k]).join("-");

    // Create a new object for this group if it doesn't exist
    if (!acc[key]) {
      acc[key] = {};
      commonKeys.forEach((k) => {
        acc[key][k] = obj[k];
      });
    }

    // Merge the values of the current object into the group object
    Object.keys(obj).forEach((k) => {
      if (!commonKeys.includes(k)) {
        acc[key][k] = obj[k];
      }
    });

    return acc;
  }, {});

  // Convert the grouped data back into an array
  const mergedData = Object.values(groupedData);
  return mergedData;
};

// There are attributes in the intake daata with the same name as the outcome data
// This function renames the outcome attributes to avoid grouping both intake and outcome data together
const renameOutcomeEuthanasiaAttributes = (attributes) => {
  attributes["adultOwnerIntendedEuthanasiaCountOutcome"] =
    attributes["adultOwnerIntendedEuthanasiaCount"];
  attributes["ageUnknownOwnerIntendedEuthanasiaCountOutcome"] =
    attributes["ageUnknownOwnerIntendedEuthanasiaCount"];
  attributes["youthOwnerIntendedEuthanasiaCountOutcome"] =
    attributes["youthOwnerIntendedEuthanasiaCount"];

  delete attributes["adultOwnerIntendedEuthanasiaCount"];
  delete attributes["ageUnknownOwnerIntendedEuthanasiaCount"];
  delete attributes["youthOwnerIntendedEuthanasiaCount"];

  return attributes;
};

const isSumGreaterThanZero = (obj) => {
  const keys = [
    "adultTransferredInstateInFromAgencyCount",
    "youthTransferredInstateInFromAgencyCount",
    "ageUnknownTransferredInstateInFromAgencyCount",
    "adultTransferredOutOfStateInFromAgencyCount",
    "youthTransferredOutOfStateInFromAgencyCount",
    "ageUnknownTransferredOutOfStateInFromAgencyCount",
    "adultTransferredInternationallyInFromAgencyCount",
    "youthTransferredInternationallyInFromAgencyCount",
    "ageUnknownTransferredInternationallyInFromAgencyCount",

    "adultTransferredInstateToAnotherAgencyCount",
    "youthTransferredInstateToAnotherAgencyCount",
    "ageUnknownTransferredInstateToAnotherAgencyCount",
    "adultTransferredOutOfStateToAnotherAgencyCount",
    "youthTransferredOutOfStateToAnotherAgencyCount",
    "ageUnknownTransferredOutOfStateToAnotherAgencyCount",
    "adultTransferredInternationallyToAnotherAgencyCount",
    "youthTransferredInternationallyToAnotherAgencyCount",
    "ageUnknownTransferredInternationallyToAnotherAgencyCount",
  ];
  const sum = keys.reduce((total, key) => total + (obj[key] || 0), 0);

  const year = obj.yearOfRecord;
  const month = obj.monthOfRecord;

  const priorDate = new Date(2021, 2);
  const currentDate = new Date(year, month - 1);

  const isPriorDate = currentDate < priorDate;
  return sum > 0 && isPriorDate;
};

export const transformExportDates = (dates) => {
  if (dates.length < 1) return "";

  if (dates.length === 1) {
    const date = dates[0];
    if (date.length === 4) {
      return `Jan ${date} - Dec ${date}`;
    }
    const monthYearString = moment(date).format("MMM YYYY");
    return monthYearString;
  }

  const firstDate = dates[0];
  const lastDate = dates[dates.length - 1];
  if (firstDate.length === 4) {
    return `Jan ${firstDate} - Dec ${lastDate}`;
  }

  const firstMonthYearString = moment(firstDate).format("MMM YYYY");
  const lastMonthYearString = moment(lastDate).format("MMM YYYY");
  return `${firstMonthYearString} - ${lastMonthYearString}`;
};
