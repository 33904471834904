import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const Error = ({ showIcon, size, className, children }) => {
  return (
    <div
      className={`bg-pink ${
        size === "small"
          ? "text-red pl-6 pr-4 py-3 border-red border-l-6"
          : "text-deepest-teal rounded-lg p-6 flex items-center space-x-4"
      } ${className}`}
      role="alert"
    >
      {showIcon && (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-4xl text-red"
        />
      )}
      <div>{children}</div>
    </div>
  );
};

Error.propTypes = {
  size: PropTypes.oneOf(["small", "large"]),
  showIcon: PropTypes.bool,
  className: PropTypes.string,
};

Error.defaultProps = {
  size: "small",
};

export default Error;
