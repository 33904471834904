import React from "react";
import * as Yup from "yup";
import { addNamespace } from "utils";
import FormField from "components/FormField";

export const defaultValues = {
  fullName: "",
  title: "",
  email: "",
  confirmEmail: "",
  password: "",
  confirmPassword: "",
};

export const validationSchema = () => ({
  fullName: Yup.string().required("Name is required"),
  title: Yup.string().required("Title is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  confirmEmail: Yup.string()
    .email("Invalid email")
    .oneOf([Yup.ref("email")], "Emails do not match")
    .required("Confirm the email"),
  password: Yup.string()
    .min(8, "Invalid password")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .min(8, "Invalid password")
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Confirm your password"),
});

const AccountSubForm = ({ namespace }) => {
  const withNamespace = addNamespace(namespace);

  return (
    <>
      <h2>Your Information</h2>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Full Name */}
        <FormField
          name={withNamespace("fullName")}
          label="Full Name"
          data-testid="full-name"
          required
        />

        {/* Title */}
        <FormField name={withNamespace("title")} label="Title" required />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Email */}
        <FormField
          name={withNamespace("email")}
          label="Email"
          type="email"
          required
        />

        {/* Confirm Email */}
        <FormField
          name={withNamespace("confirmEmail")}
          label="Confirm Email"
          type="email"
          required
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Password */}
        <FormField
          name={withNamespace("password")}
          label="Password"
          type="password"
          tooltip={<PasswordTooltip />}
          tooltipWidthClass="w-80"
          required
        />

        {/* Confirm Password */}
        <FormField
          name={withNamespace("confirmPassword")}
          label="Confirm Password"
          type="password"
          required
        />
      </div>
    </>
  );
};

export default AccountSubForm;

const PasswordTooltip = () => (
  <>
    <p className="text-white">Passwords must be between 8 and 18 characters.</p>
    <p className="mb-0 text-white">
      We recommend using a combination of uppercase letters, lowercase letters,
      numbers, and special characters.
    </p>
  </>
);
