import React from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { apiFetch, formatUiErrors, FORM_MODES } from "utils";
import { useAuthState, useAuthDispatch } from "context";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import Button from "components/Button";
import LocationForm from "components/LocationForm";
import BackLink from "components/BackLink";

const LocationDetails = ({ formMode = FORM_MODES.VIEW }) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { orgId, locId } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { isLoading, error, data: response } = useQuery(
    ["location", locId],
    () =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/locations/${locId}`,
      })
  );

  const handleUpdateSuccess = (data) => {
    history.push(`/organizations/${orgId}/locations/${locId}`);

    toast.success("Saved successfully", {
      autoClose: 3000,
    });

    queryClient.invalidateQueries(["organizationLocations", { orgId }], {
      refetchActive: true,
    });

    return queryClient.setQueryData(["location", locId], data);
  };

  const handleUpdateError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  if (isLoading) {
    return <LoadingIndicator inline content={<>Loading location&hellip;</>} />;
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  return (
    <>
      <div className="mb-8">
        <BackLink
          link={`/organizations/${orgId}/locations`}
          text="Back to locations listing"
        />
        <h2>
          {formMode === FORM_MODES.EDIT && "Editing "}
          {response.data.attributes.name}
        </h2>

        {formMode === FORM_MODES.VIEW && (
          <div className="flex space-x-2 mb-8">
            <Button to={`/organizations/${orgId}/locations/${locId}/edit`}>
              Edit Location
            </Button>
          </div>
        )}
      </div>

      <LocationForm
        location={response.data}
        formMode={formMode}
        cancelRoute={`/organizations/${orgId}/locations/${locId}`}
        onUpdateSuccess={handleUpdateSuccess}
        onUpdateError={handleUpdateError}
      />
    </>
  );
};

export default LocationDetails;
