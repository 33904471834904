import { camelizeKeys, decamelizeKeys } from "humps";
import { get, throttle } from "lodash";
import { REFRESH_TOKEN } from "actions";

export const apiFetch = async ({
  token,
  dispatch,
  endpoint,
  method = "GET",
  body = null,
  includeMeta = false,
}) => {
  if (!token) {
    return null;
  }

  const { accessToken, client, expiry, uid } = token;

  try {
    const response = await fetch(`${API_URL}${endpoint}`, {
      method,
      headers: {
        "access-token": accessToken,
        "token-type": "Bearer",
        "Content-Type": "application/json",
        client,
        expiry,
        uid,
      },
      body: body && JSON.stringify(decamelizeKeys(body)),
    });

    const headers = await response.headers;

    const responseJson = await response.json();
    const { data, meta, included, errors, error } = responseJson;

    if (!response.ok) {
      throw formatErrors(errors || error);
    }

    const newToken = {
      accessToken: headers.get("access-token") || accessToken,
      client: headers.get("client") || client,
      uid: headers.get("uid") || uid,
      expiry: headers.get("expiry") || expiry,
    };

    dispatch({
      type: REFRESH_TOKEN,
      payload: { token: newToken },
    });

    const formattedData = Object.assign(
      { data: camelizeKeys(data || {}) },
      {
        ...(responseJson.hasOwnProperty("included")
          ? { included: included ? camelizeKeys(included) : [] }
          : {}),
      }
    );

    return includeMeta ? { ...formattedData, meta } : formattedData;
  } catch (error) {
    throw new Error(formatErrors(error));
  }
};

export const getAllRecords = async ({
  token,
  dispatch,
  endpoint,
  queryParams = [],
  perPage = 1000,
  page = 1,
}) => {
  return new Promise(async (resolve, reject) => {
    let collectedResponses = { data: [], included: [] };
    let total = 0;
    let pageNumber = page;
    let hasMoreRecords = true;
    let numberOfRequests = 0;
    let hasError = false;

    const MAKE_REQUEST = throttle(async () => {
      try {
        const response = await apiFetch({
          token,
          dispatch,
          endpoint: `${endpoint}?page[size]=${perPage}&page[number]=${pageNumber}&stats[total]=count${
            queryParams.length ? `&${queryParams.join("&")}` : ""
          }`,
          includeMeta: true,
        });
        total = get(response, "meta.stats.total.count" || 0);
        collectedResponses.data = collectedResponses.data.concat(response.data);
        collectedResponses.included = collectedResponses.included.concat(
          response.included
        );

        numberOfRequests++;
        pageNumber++;
        hasMoreRecords = total > collectedResponses.data.length;
      } catch (error) {
        hasError = true;
        reject(error);
      }
    }, 250);

    while (!hasError && hasMoreRecords && numberOfRequests <= 200) {
      await MAKE_REQUEST();
    }

    resolve(collectedResponses);
  });
};

export const formatErrors = (errors) => {
  if (!errors || errors instanceof SyntaxError)
    return `There was an error making the request. We've notified our engineers and hope to address this issue shortly.`;

  if (typeof errors === "string") return errors;

  if (Array.isArray(errors)) {
    const errorArray = errors.map((error) => {
      if (typeof error === "string") return error;
      const title = error?.title || "";
      const detail = error?.detail || "";
      return `${title}${title && detail && ": "}${detail}`;
    });
    if (errorArray.length === 1) return errorArray[0];
    return errorArray;
  }

  if (String(errors)) return String(errors);

  return `There was an error making the request. We've notified our engineers and hope to address this issue shortly.`;
};

export const API_URL = process.env.REACT_APP_API_URL || "";

export const AUTH_TOKEN_KEY = "@sac-token";
export const USER_ID_KEY = "@sac-user-id";

export const TOKEN = localStorage.getItem(AUTH_TOKEN_KEY)
  ? JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY))
  : {};

export const USER_ID = localStorage.getItem(USER_ID_KEY) || null;

export const saveToken = (token) =>
  localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(token));

export const saveUserId = (id) => localStorage.setItem(USER_ID_KEY, id);

export const ALLSTATES = [
  { label: "AK", value: "AK" },
  { label: "AL", value: "AL" },
  { label: "AR", value: "AR" },
  { label: "AS", value: "AS" },
  { label: "AZ", value: "AZ" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DC", value: "DC" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "GU", value: "GU" },
  { label: "HI", value: "HI" },
  { label: "IA", value: "IA" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "MA", value: "MA" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MO", value: "MO" },
  { label: "MP", value: "MP" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "NE", value: "NE" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NV", value: "NV" },
  { label: "NY", value: "NY" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "PR", value: "PR" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VA", value: "VA" },
  { label: "VI", value: "VI" },
  { label: "VT", value: "VT" },
  { label: "WA", value: "WA" },
  { label: "WI", value: "WI" },
  { label: "WV", value: "WV" },
  { label: "WY", value: "WY" },
];
