import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { apiFetch, formatUiErrors } from "utils";
import { useAuthState, useAuthDispatch } from "context";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import Table from "components/Table";

const CoalitionManagers = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { coalitionId } = useParams();

  const {
    isLoading,
    error,
    data: response,
  } = useQuery(["coalitionManagers", coalitionId], () =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/users?&filter[coalition_managers][eq]=${coalitionId}`,
    })
  );

  if (isLoading) {
    return (
      <LoadingIndicator
        inline
        content={<>Loading coalition managers&hellip;</>}
      />
    );
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  const columns = [
    {
      accessor: "attributes.fullName",
      Header: "Name",
    },
    {
      accessor: "attributes.email",
      Header: "Email",
    },
  ];

  return (
    <>
      <h3>Coalition Managers</h3>
      <Table
        data={response?.data || []}
        columns={columns}
        noResultsText="No managers found"
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};

export default CoalitionManagers;
