import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import AlertModal from "components/AlertModal";
import Button from "components/Button";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RouteLeavingGuard = ({ when, shouldBlockNavigation }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <AlertModal
        isModalOpen={modalVisible}
        closeModal={closeModal}
        shouldCloseOnOverlayClick={false}
      >
        <div className="text-center">
          <h3 className="text-2xl mb-4 text-gold">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            Warning
          </h3>

          <p className="mb-6">
            You have unsaved changes. If you proceed, these changes will be
            lost.
          </p>

          <div className="flex items-center justify-center space-x-4">
            <Button onClick={closeModal} emphasis="secondary">
              Cancel
            </Button>
            <Button onClick={handleConfirmNavigationClick}>Confirm</Button>
          </div>
        </div>
      </AlertModal>
    </>
  );
};
export default RouteLeavingGuard;
