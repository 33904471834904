import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { usePermissions, ROUTE_GUARDS } from "utils";
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuHeader,
  FocusableItem,
} from "@szhsin/react-menu";

const ProfileDropdown = ({ username, organization, logoutMethod }) => {
  const permissions = usePermissions();

  const orgId = organization?.id || null;
  const orgName = organization?.name || null;

  const chevron = ({ open }) => {
    const icon = open ? faAngleUp : faAngleDown;
    return <FontAwesomeIcon icon={icon} className="ml-2" />;
  };

  const buttonCopy = ({ open }) => {
    return (
      <>
        <FontAwesomeIcon icon={faUser} className="text-teal mr-2" />
        {username || "My Account"}
        {chevron({ open })}
      </>
    );
  };

  return (
    <Menu
      menuButton={({ open }) => (
        <MenuButton className="profile-dropdown__trigger">
          {buttonCopy({ open })}
        </MenuButton>
      )}
      className="profile-dropdown"
      data-testid="user-profile-dropdown"
      align="end"
    >
      {/* Username/Organization */}
      <MenuHeader className="break-words">
        {username || "My Account"}
        {orgName && <div className="text-sm uppercase">{orgName}</div>}
      </MenuHeader>

      {/* Organization */}
      {permissions[ROUTE_GUARDS.ORG_MEMBER]() ? (
        <FocusableItem>
          {({ ref, closeMenu }) => (
            <Link to={`/organizations/${orgId}`} ref={ref} onClick={closeMenu}>
              Organization
            </Link>
          )}
        </FocusableItem>
      ) : null}

      {/* Profile */}
      <FocusableItem>
        {({ ref, closeMenu }) => (
          <Link to="/profile" ref={ref} onClick={closeMenu}>
            Profile
          </Link>
        )}
      </FocusableItem>

      {/* Logout */}
      <MenuItem onClick={logoutMethod}>Logout</MenuItem>
    </Menu>
  );
};

ProfileDropdown.propTypes = {
  username: PropTypes.string,
  organization: PropTypes.object,
};

ProfileDropdown.defaultProps = {
  username: "My Account",
  organization: null,
};

export default ProfileDropdown;
