import React from "react";
import Input from "components/Input";

const TableFormInput = (cellInfo) => {
  const inputName = cellInfo.cell.row.original.attributeKeys.find((key) =>
    key.toUpperCase().includes(cellInfo.cell.column.id.toUpperCase())
  );
  return (
    <Input
      name={inputName}
      id={inputName}
      label={cellInfo.row.values.rowLabel}
      type="number"
      min={0}
    />
  );
};
export default TableFormInput;
