import { getMonthName, formatDateTime } from "utils";

export const EXPORT_STATUS = {
  STARTED: "started",
  COMPLETED: "completed",
  FAILED: "failed",
};

export const ALLSTATES = [
  { label: "AK", value: "AK" },
  { label: "AL", value: "AL" },
  { label: "AR", value: "AR" },
  { label: "AS", value: "AS" },
  { label: "AZ", value: "AZ" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DC", value: "DC" },
  { label: "DE", value: "DE" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "GU", value: "GU" },
  { label: "HI", value: "HI" },
  { label: "IA", value: "IA" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "MA", value: "MA" },
  { label: "MD", value: "MD" },
  { label: "ME", value: "ME" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MO", value: "MO" },
  { label: "MP", value: "MP" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "NE", value: "NE" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NV", value: "NV" },
  { label: "NY", value: "NY" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "PR", value: "PR" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VA", value: "VA" },
  { label: "VI", value: "VI" },
  { label: "VT", value: "VT" },
  { label: "WA", value: "WA" },
  { label: "WI", value: "WI" },
  { label: "WV", value: "WV" },
  { label: "WY", value: "WY" },
];

export const OrgTypes = [
  { label: "Government Animal Services", value: "Government Animal Services" },
  {
    label: "Animal Shelter with a Government Contract",
    value: "Animal Shelter with a Government Contract",
  },
  {
    label: "Animal Rescue with a Government Contract",
    value: "Animal Rescue with a Government Contract",
  },
  {
    label: "Shelter without a Government Contract",
    value: "Shelter without a Government Contract",
  },
  {
    label: "Rescue without a Government Contract",
    value: "Rescue without a Government Contract",
  },
];

export const columns = [
  {
    accessor: "attributes.createdAt",
    Header: "Created At",
    Cell: ({ cell }) => formatDateTime(cell.value),
  },
  {
    accessor: "attributes.monthOfRecord",
    Header: "Month of Record",
    Cell: ({ cell }) => {
      if (!cell.value) return "--";
      return getMonthName(cell.value);
    },
  },
  {
    accessor: "attributes.yearOfRecord",
    Header: "Year of Record",
  },
  {
    accessor: "attributes.exportFor",
    Header: "Export",
    Cell: ({ cell }) => {
      if (!cell.value) return "--";
      if (cell.value === "intake_outcome") {
        return "Intake/Outcome Export";
      } else if (cell.value === "community") {
        return "Community Services Data";
      } else {
        return "Contributors Data";
      }
    },
  },
  {
    accessor: "attributes.updatedAt",
    Header: "Completed At",
    Cell: ({ cell }) => {
      if (!cell.value) return "--";
      return formatDateTime(cell.value);
    },
  },
  {
    accessor: "attributes.status",
    Header: "",
    Cell: ({ cell, row: { original } }) => {
      if (cell.value === EXPORT_STATUS.STARTED) {
        return (
          <span className="italic" data-testid="csv-status">
            Generating CSV&hellip;
          </span>
        );
      }
      if (cell.value === EXPORT_STATUS.FAILED) {
        return (
          <span className="text-red" data-testid="csv-status">
            Export failed
          </span>
        );
      }
      return (
        <a
          href={original.attributes.attachedFilePath}
          download
          data-testid="csv-status"
        >
          Download CSV
        </a>
      );
    },
  },
];
