import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { endOfMonth, setMonth, format } from "date-fns";
import { cloneDeep } from "lodash";
import PageWrapper from "components/PageWrapper";
import Button from "components/Button";
import FormField from "components/FormField";
import DatePickerField from "components/DatePickerField";
import LoadingIndicator from "components/LoadingIndicator";
import { OrganizationEinTooltip } from "components/Tooltips";
import {
  useAuthState,
  useAuthDispatch,
  useUserState,
  useUserDispatch,
} from "context";
import { USER_UPDATE } from "actions";
import { apiFetch, formatUiErrors, formatDateObject, formatEin } from "utils";

const LegacyOrganizationDashboard = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { organization } = useUserState();
  const userDispatch = useUserDispatch();
  const history = useHistory();

  const initialValues = {
    websiteUrl: organization.attributes.websiteUrl || "",
    incorporatedOn: organization.attributes.incorporatedOn
      ? formatDateObject(organization.attributes.incorporatedOn)
      : "",
    ein: formatEin(organization.attributes.ein) || "",
  };

  const OrganizationSchema = Yup.object().shape({
    websiteUrl: Yup.string().url("Invalid URL"),
    incorporatedOn: Yup.string().required("Date is required"),
    ein: Yup.string()
      .matches(/^\d{2}?-\d{7}$/, "Invalid EIN")
      .required("EIN is required"),
  });

  const { mutate: updateOrganizationDetails, isLoading } = useMutation(
    ({ websiteUrl, incorporatedOn, ein }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: "/api/v1/organization_import_finalizers",
        method: "POST",
        body: {
          data: {
            type: "organization_import_finalizers",
            attributes: {
              organizationId: organization.id,
              websiteUrl,
              incorporatedOn: format(incorporatedOn, "dd/MM/yyyy"),
              ein: ein.replace(/\D/g, ""),
            },
          },
        },
      }),
    {
      onSuccess: (response) => {
        const newOrg = cloneDeep(organization);
        Object.assign(newOrg.attributes, {
          ...response.attributes,
          status: "registered",
        });
        userDispatch({
          type: USER_UPDATE,
          payload: {
            organization: newOrg,
          },
        });
        toast.success("Saved successfully", {
          autoClose: 3000,
        });
        history.push("/");
      },
      onError: (error) => {
        toast.error(() => formatUiErrors(error), {
          autoClose: 5000,
          className: "toast-center",
        });
      },
    }
  );

  if (!organization) return <LoadingIndicator />;

  return (
    <PageWrapper showLogout>
      <h1>{organization.attributes.name}</h1>
      <p className="mb-8">
        We weren&rsquo;t able to import this information from the legacy system
        for your organization. Please provide your organization&rsquo;s start
        date, EIN, and optional website url.
      </p>

      <Formik
        initialValues={initialValues}
        validateOnMount
        validationSchema={OrganizationSchema}
        onSubmit={updateOrganizationDetails}
      >
        {({ handleSubmit }) => (
          <Form>
            <div className="grid grid-cols-2 gap-6 mb-6">
              {/* Date Started */}
              <DatePickerField
                label="Date of Organization Incorporation"
                name="incorporatedOn"
                dateFormat="MMMM yyyy"
                showMonthYearPicker
                className="form-input w-full"
                maxDate={endOfMonth(setMonth(new Date(), 11))}
                required
              />

              {/* EIN */}
              <FormField
                name="ein"
                label="Employer Identification Number (EIN)"
                tooltip={<OrganizationEinTooltip />}
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-6 mb-6">
              {/* Website */}
              <FormField name="websiteUrl" label="Website URL" />
            </div>

            <div className="flex justify-end space-x-4">
              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                loadingText={<>Saving&hellip;</>}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PageWrapper>
  );
};

export default LegacyOrganizationDashboard;
