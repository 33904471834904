import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#ccc";
const backColor = "#e6e7e8";
const styles = StyleSheet.create({
  live_intake_n: {
    width: "28%",
    height: 30,
    paddingTop: 7,
    backgroundColor: "#1f6165",
    borderColor: borderColor,
    borderWidth: 1,
    color: "white",
  },
  canine: {
    width: "18%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  feline: {
    width: "18%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  subtotal: {
    width: "18%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  total: {
    width: "18%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
});

const SpecieYOYHeader = (props) => (
  <View style={props.styles.container2}>
    <Text style={styles.live_intake_n}>YOY</Text>
    <Text style={styles.canine}>LIVE INTAKES</Text>
    <Text style={styles.feline}>Live OUTCOMES</Text>
    <Text style={styles.subtotal}>OTHER OUTCOMES</Text>
    <Text style={styles.total}>TOTAL OUTCOMES</Text>
  </View>
);

export default SpecieYOYHeader;
