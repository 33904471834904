import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import ReactTooltip from "react-tooltip";
import { Formik, Form } from "formik";
import { get, uniq, isEqual } from "lodash";
import {
  usePermissions,
  ROUTE_GUARDS,
  apiFetch,
  getTotalPages,
  formatEin,
  titleCase,
  useHandleRequestStates,
  COALITION_MEMBERSHIP_ACTIONS,
} from "utils";
import {
  useAuthState,
  useAuthDispatch,
  useUserState,
  useUserDispatch,
} from "context";
import Button from "components/Button";
import Table from "components/Table";
import Pagination from "components/Pagination";
import FormField from "components/FormField";
import OrganizationTypeDropDown from "components/OrganizationTypeDropDown";
import StateDropdown from "components/StateDropdown";
import { USER_UPDATE } from "actions";
import {
  getOrgMembershipStatus,
  mergeIncludesIntoData,
  constructEndpoint,
  COALITION_MEMBERSHIP_STATUSES,
} from "./utils";
import { format } from "date-fns";
import { toast } from "react-toastify";

const Contributors = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { pendingCoalitionContributorRequests } = useUserState();
  const userDispatch = useUserDispatch();
  const { coalitionId } = useParams();
  const permissions = usePermissions();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { handleSuccess, handleError } = useHandleRequestStates();
  const { pathname } = useLocation();
  const [contributorPage, setContributorPage] = useState("");

  const initialFilters = {
    search: "",
    status:
      contributorPage === "requested" ? "requested,reminded" : contributorPage,
    organizationType: "",
    state: "",
  };

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    switch (pathname) {
      case `/coalitions/${coalitionId}/contributors/active`:
        setContributorPage("active");
        filters.status = "active";
        break;
      case `/coalitions/${coalitionId}/contributors/pending`:
        setContributorPage("requested");
        filters.status = "requested,reminded";
        break;
      case `/coalitions/${coalitionId}/contributors/declined`:
        setContributorPage("rejected");
        filters.status = "rejected";
        break;
      default:
        setContributorPage("active");
    }
    setFilters(filters);
    getOrganizations({ page, ...filters });
  }, [pathname]);

  const getOrganizations = async ({
    page = 1,
    search = "",
    status = "",
    organizationType = "",
    state = "",
  }) => {
    // gets called on Submit
    // need tup update this to get it to work
    const response = await apiFetch({
      token,
      dispatch,
      endpoint: constructEndpoint({
        coalitionId,
        page,
        search,
        status,
        organizationType,
        state,
      }),
      includeMeta: true,
    });
    response.data.forEach((org) => {
      if (org.type === "organizations") {
        // convert the updatedAt to a date object instead of a string here instead of the cell so sort will work
        org.attributes.updatedAt = new Date(org.attributes.updatedAt);
      }
    });
    return mergeIncludesIntoData({ response, coalitionId });
  };

  // Initial query
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    ["coalitionContributors", coalitionId, { page, ...filters }],
    () => getOrganizations({ page, ...filters }),
    {
      keepPreviousData: true,
    }
  );

  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["coalitionContributors", coalitionId, { page: page + 1, ...filters }],
        () => getOrganizations({ page: page + 1, ...filters })
      );
    }
  }, [response, page, filters, queryClient]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    {
      accessor: "attributes.locationsUsStates",
      Header: "States",
      Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
    },
    {
      accessor: "attributes.ein",
      Header: "EIN",
      Cell: ({ cell }) => formatEin(cell.value),
    },
    {
      accessor: "attributes.dataStatus",
      Header: () => {
        return (
          <>
            <div className="flex items-center space-x-2">
              <span>Data Status</span>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-dashboard-medium-gray"
                data-tip
                data-for="dataStatus"
              />
            </div>
            <ReactTooltip place="right" className="w-48" id="dataStatus">
              Data status is calculated for the latest 12 months
            </ReactTooltip>
          </>
        );
      },
      Cell: ({ cell }) => titleCase(cell.value) || null,
    },
    {
      accessor: "membership",
      Header: "Org Status",
      Cell: ({ cell }) => getOrgMembershipStatus(cell.value),
    },
  ];

  const activeContributorColumns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    {
      accessor: "attributes.ein",
      Header: "EIN",
      Cell: ({ cell }) => formatEin(cell.value),
    },
    {
      accessor: "attributes.intakeOutcomeDataSharing",
      Header: "Intake Outcome Data Sharing",
      Cell: ({ cell }) => {
        var sharing = response.included.filter(
          (a) =>
            a.id ==
            cell.row.original.relationships.coalitionMemberships.data[0].id
        );
        if (sharing.length > 0) {
          return sharing[0].attributes.intakeOutcomeDataSharing ? "Yes" : "No";
        } else {
          return "No";
        }
      },
    },
    {
      accessor: "attributes.communityServiceDataSharing",
      Header: "Community Service Data Sharing",
      Cell: ({ cell }) => {
        var sharing = response.included.filter(
          (a) =>
            a.id ==
            cell.row.original.relationships.coalitionMemberships.data[0].id
        );
        if (sharing.length > 0) {
          return sharing[0].attributes.communityServiceDataSharing
            ? "Yes"
            : "No";
        } else {
          return "No";
        }
      },
    },
    {
      accessor: "attributes.dataStatus",
      Header: () => {
        return (
          <>
            <div className="flex items-center space-x-2">
              <span>Data Status</span>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-dashboard-medium-gray"
                data-tip
                data-for="dataStatus"
              />
            </div>
            <ReactTooltip place="right" className="w-48" id="dataStatus">
              Data status is calculated for the latest 12 months
            </ReactTooltip>
          </>
        );
      },
      Cell: ({ cell }) => titleCase(cell.value) || null,
    },
    {
      accessor: "membership",
      Header: "Org Status",
      Cell: ({ cell }) => getOrgMembershipStatus(cell.value),
    },
  ];

  if (contributorPage === "requested") {
    const dateRequestedColumn = {
      accessor: "membership.updatedAt",
      Header: "Date Invited/Requested",
      Cell: ({ cell }) => format(new Date(cell.value), "MM-dd-yyyy"),
    };

    const actionsColumns = {
      accessor: "id",
      Header: "",
      Cell: ({ row: { original } }) =>
        getActions({
          organization: original,
          respondToRequest,
        }),
    };

    columns.push(dateRequestedColumn);
    columns.push(actionsColumns);
  }

  if (contributorPage === "active") {
    const dateAcceptedColumn = {
      accessor: "membership.statusUpdatedAt",
      Header: "Date Accepted",
      sortType: "datetime",
      sortDescFirst: true,
      Cell: ({ cell }) => {
        const formattedDate = cell.value
          ? format(new Date(cell.value), "MM-dd-yyyy")
          : "N/A";
        return (
          <>
            {cell.value &&
            cell.value.toDateString() !== new Date(1970).toDateString() ? (
              <span>{formattedDate}</span>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    };

    const removeOrgColumn = {
      accessor: "id",
      Header: "",
      Cell: ({ cell }) => {
        const orgId = cell.row.original.membership.id;
        const { mutate: handleSubmit } = useMutation(
          async () => {
            return await apiFetch({
              token,
              dispatch,
              method: "PATCH",
              endpoint: `/api/v1/coalition_memberships/${orgId}`,
              body: {
                data: {
                  id: orgId,
                  type: "coalition_memberships",
                  attributes: {
                    status: "rejected",
                    statusActor: "Organization",
                  },
                },
              },
            });
          },
          {
            onSuccess: () => {
              handleSuccess({
                message: `Contributor removed`,
                queries: [
                  {
                    key: ["coalitionContributors", coalitionId],
                    config: {
                      refetchActive: true,
                    },
                  },
                  {
                    key: ["searchOrganizations", { coalitionId }],
                  },
                  {
                    key: "coalitions",
                  },
                  {
                    key: "myCoalitions",
                  },
                ],
              });
            },
            onError: (err) => {
              toast.error(`Failed to remove contributor`, {
                autoClose: 3000,
              });
            },
          }
        );

        return (
          <div className="flex content-center justify-center">
            <Button type="submit" className="text-xs" onClick={handleSubmit}>
              Remove Org
            </Button>
          </div>
        );
      },
    };

    columns.push(dateAcceptedColumn, removeOrgColumn);
    activeContributorColumns.push(dateAcceptedColumn, removeOrgColumn);
  }

  if (contributorPage === "rejected") {
    const dateDeclinedColumn = {
      accessor: "membership.statusUpdatedAt",
      Header: "Date Declined",
      sortType: "datetime",
      sortDescFirst: true,
      Cell: ({ cell }) => {
        const formattedDate = cell.value
          ? format(new Date(cell.value), "MM-dd-yyyy")
          : "N/A";
        return (
          <>
            {cell.value &&
            cell.value.toDateString() !== new Date(1970).toDateString() ? (
              <span>{formattedDate}</span>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    };

    columns.push(dateDeclinedColumn);
  }

  const { mutate: respondToRequest } = useMutation(
    ({ organization, action }) =>
      apiFetch({
        token,
        dispatch,
        method: "PATCH",
        endpoint: `/api/v1/coalition_memberships/${organization.membership.id}`,
        body: {
          data: {
            id: organization.membership.id,
            type: "coalition_memberships",
            attributes: {
              statusChangeAction: action,
              statusActor: "Coalition",
            },
          },
        },
      }),
    {
      onSuccess: (response, { action }) => {
        const membership = response.data;
        const membershipCoalitionId = String(membership.attributes.coalitionId);

        userDispatch({
          type: USER_UPDATE,
          payload: {
            pendingCoalitionContributorRequests:
              pendingCoalitionContributorRequests.filter(
                (coalition) => coalition.id !== String(membershipCoalitionId)
              ),
          },
        });
        const actionMessage = (action) => {
          switch (action) {
            case COALITION_MEMBERSHIP_ACTIONS.REJECT:
              return "Declined request successfully";
            case COALITION_MEMBERSHIP_ACTIONS.ACCEPT:
              return "Accepted request successfully";
            case COALITION_MEMBERSHIP_ACTIONS.REMIND:
              return "Re-invite request successfully";
            default:
              return "";
          }
        };

        handleSuccess({
          message: actionMessage(action),
          queries: [
            {
              key: ["coalitionContributors", coalitionId],
              config: {
                refetchActive: true,
              },
            },
            {
              key: ["searchOrganizations", { coalitionId }],
            },
            {
              key: "coalitions",
            },
            {
              key: "myCoalitions",
            },
          ],
        });
      },
      onError: handleError,
    }
  );

  /**
   * FORM METHODS
   */
  const onSubmitFilters = (values) => {
    if (!isEqual(values, filters)) {
      setFilters(values);
    }
  };

  return (
    <>
      <div className="mb-8">
        <h2>Coalition Contributors</h2>

        {permissions[ROUTE_GUARDS.COALITION_MANAGER]() && (
          <div className="flex space-x-2">
            <Button
              to={`/coalitions/${coalitionId}/contributors/add`}
              data-testid="add-contributor-btn"
            >
              Invite Contributor
            </Button>
          </div>
        )}
      </div>

      <div className="sac-border mb-6">
        <Formik initialValues={initialFilters} onSubmit={onSubmitFilters}>
          {({ resetForm, handleSubmit }) => (
            <Form>
              <div className="items-end xl:flex xl:space-x-6">
                <div className="mb-4 space-y-2 lg:flex lg:space-x-2 lg:space-y-0 xl:w-auto xl:mb-0">
                  <FormField
                    name="search"
                    label="Search"
                    placeholder="Name or EIN"
                  />

                  <FormField name="status" label="Status" as="select">
                    {contributorPage === "active" && (
                      <option value={COALITION_MEMBERSHIP_STATUSES.ACTIVE}>
                        {COALITION_MEMBERSHIP_STATUSES.ACTIVE}
                      </option>
                    )}
                    {contributorPage === "requested" && (
                      <>
                        <option
                          value={
                            COALITION_MEMBERSHIP_STATUSES.COALITION_PENDING
                          }
                        >
                          {COALITION_MEMBERSHIP_STATUSES.COALITION_PENDING}
                        </option>
                        <option
                          value={
                            COALITION_MEMBERSHIP_STATUSES.ORG_INVITE_PENDING
                          }
                        >
                          {COALITION_MEMBERSHIP_STATUSES.ORG_INVITE_PENDING}
                        </option>
                        <option
                          value={
                            COALITION_MEMBERSHIP_STATUSES.COALITION_APPROVAL_PENDING
                          }
                        >
                          {
                            COALITION_MEMBERSHIP_STATUSES.COALITION_APPROVAL_PENDING
                          }
                        </option>
                        <option
                          value={
                            COALITION_MEMBERSHIP_STATUSES.COALITION_REINVITED
                          }
                        >
                          {COALITION_MEMBERSHIP_STATUSES.COALITION_REINVITED}
                        </option>
                      </>
                    )}
                    {contributorPage === "rejected" && (
                      <>
                        <option value="rejected">All Declined</option>
                        <option
                          value={COALITION_MEMBERSHIP_STATUSES.ORG_DECLINED}
                        >
                          {COALITION_MEMBERSHIP_STATUSES.ORG_DECLINED}
                        </option>
                        <option
                          value={
                            COALITION_MEMBERSHIP_STATUSES.COALITION_DECLINED
                          }
                        >
                          {COALITION_MEMBERSHIP_STATUSES.COALITION_DECLINED}
                        </option>
                      </>
                    )}
                  </FormField>
                  <OrganizationTypeDropDown
                    name="organizationType"
                    label="Organization Type"
                  />
                  <StateDropdown name="state" label="State" />
                </div>

                <div className="flex items-center space-x-2">
                  <Button type="submit" onClick={handleSubmit}>
                    Search
                  </Button>
                  <Button
                    onClick={() => {
                      resetForm();
                      setFilters(initialFilters);
                    }}
                    emphasis="transparent"
                  >
                    Reset Filters
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <Table
        data={response?.data || []}
        columns={
          contributorPage === "active" ? activeContributorColumns : columns
        }
        noResultsText="No organizations found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response?.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default Contributors;

const getActions = ({ organization, respondToRequest }) => {
  if (
    organization.membership.status === "requested" &&
    organization.membership.statusActor === "Organization"
  ) {
    return (
      <div className="flex space-x-2 ml-2.5">
        {/* Accept Contributor Request */}
        <Button
          className="text-xs btn-sm"
          onClick={() =>
            respondToRequest({
              organization,
              action: COALITION_MEMBERSHIP_ACTIONS.ACCEPT,
            })
          }
        >
          Accept
        </Button>

        {/* Decline Contributor Request */}
        <Button
          className="text-xs btn-sm"
          emphasis={"gold"}
          onClick={() =>
            respondToRequest({
              organization,
              action: COALITION_MEMBERSHIP_ACTIONS.REJECT,
            })
          }
        >
          Decline
        </Button>
      </div>
    );
  } else if (
    organization.membership.status === "requested" &&
    organization.membership.statusActor === "Coalition"
  ) {
    return (
      <Button
        className="ml-4 btn-sm"
        onClick={() =>
          respondToRequest({
            organization,
            action: COALITION_MEMBERSHIP_ACTIONS.REMIND,
          })
        }
      >
        Resend Invite
      </Button>
    );
  } else if (
    organization.membership.status === "reminded" &&
    organization.membership.statusActor === "Coalition"
  ) {
    const startDate = organization.membership.statusUpdatedAt;
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return (
      <div className="ml-6 italic">
        Invite Resent. You'll be able to send another reminder in{" "}
        {60 - diffDays} days
      </div>
    );
  }
  // TODO: Deactivate - https://app.asana.com/0/1179331329125530/1199405047540888/f
  // return (
  //   <Button className="btn-sm" emphasis="transparent">
  //     Deactivate Contributor
  //   </Button>
  // );

  return null;
};
