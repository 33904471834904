import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Redirect, useHistory } from "react-router-dom";
import { useUserState } from "context";
import { useQueryParams } from "utils";
import AdminDashboard from "./AdminDashboard";
import DataPortalDashboard from "./DataPortalDashboard";

const Dashboard = () => {
  const { isAdmin, isCoalitionOnly } = useUserState();
  const history = useHistory();
  const {
    accountConfirmationSuccess,
    alreadyConfirmed,
    orgAuthorizationSuccess,
    orgAlreadyConfirmed,
  } = useQueryParams();

  useEffect(() => {
    const accountConfirmed =
      accountConfirmationSuccess === "true" || alreadyConfirmed === "true";
    const orgAuthorized =
      orgAuthorizationSuccess === "true" || orgAlreadyConfirmed === "true";
    if (accountConfirmed || orgAuthorized) {
      toast.success(
        accountConfirmed
          ? "Your email address has been confirmed."
          : "Your organization has been authorized.",
        {
          autoClose: 3000,
        }
      );
      history.replace("/");
    }
  }, [
    accountConfirmationSuccess,
    alreadyConfirmed,
    orgAuthorizationSuccess,
    orgAlreadyConfirmed,
    history,
  ]);

  if (isCoalitionOnly)
    return (
      <Redirect
        to={{
          pathname: "/coalitions",
        }}
      />
    );

  return isAdmin ? <AdminDashboard /> : <DataPortalDashboard />;
};

export default Dashboard;
