import React from "react";
import { Text, StyleSheet } from "@react-pdf/renderer";
import { format } from "date-fns";

const styles = StyleSheet.create({
  mainHeading: {
    color: "#0F6165",
    fontSize: "24px",
    fontFamily: "Cera Pro Bold",
    fontWeight: 900,
  },
  subHeading: {
    color: "#0F6165",
    fontFamily: "Cera Pro Bold",
    fontSize: "22px",
    fontWeight: 900,
  },
  orgName: {
    fontFamily: "Cera Pro Bold",
    fontSize: "15px",
    fontWeight: 900,
  },
});

const TopHeader = ({ orgName, headerName, timeframe, action }) => (
  <Text style={{ textAlign: "right" }}>
    <Text style={styles.mainHeading}>{headerName}</Text>
    {"\n"}
    <Text style={styles.subHeading}>
      {action === "services"
        ? "COMMUNITY SERVICES DATABASE"
        : "INTAKE & OUTCOME DATABASE"}
    </Text>
    {"\n"}
    <Text style={{ fontSize: 15 }}>
      <Text style={styles.orgName}>{orgName}</Text>
      {"\n"}
      <Text>{timeframe}</Text>
      {"\n"}
      <Text>REPORT GENERATED: {format(new Date(), "MM/dd/yyyy")}</Text>
      {"\n"}
    </Text>
  </Text>
);
export default TopHeader;
