export const formatUiErrors = (errors) => {
  if (
    !errors ||
    (!Array.isArray(errors) &&
      typeof errors !== "string" &&
      !(errors instanceof Error))
  ) {
    return "There was an error loading the application. Please contact Shelter Animals Count for support.";
  }

  return Array.isArray(errors) ? (
    <ul>
      {errors.map((e, index) => (
        <li key={index}>{e.toString()}</li>
      ))}
    </ul>
  ) : (
    errors.toString()
  );
};
