import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

const borderColor = "#ccc";
const backColor = "#e6e7e8";
const styles = StyleSheet.create({
  live_intake_n: {
    width: "45%",
    height: 30,
    paddingTop: 7,
    backgroundColor: "#1f6165",
    borderColor: borderColor,
    borderWidth: 1,
    color: "white",
  },
  canine: {
    width: "11%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  feline: {
    width: "11%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  subtotal: {
    width: "11%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  other: {
    width: "11%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  total: {
    width: "11%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
});

const TableHeader = (props) => (
  <View style={props.styles.container2}>
    <Text style={styles.live_intake_n}>{props.name}</Text>
    <Text style={styles.canine}>CANINE</Text>
    <Text style={styles.feline}>FELINE</Text>
    <Text style={styles.subtotal}>SUB TOTAL</Text>
    <Text style={styles.other}>OTHER*</Text>
    <Text style={styles.total}>TOTAL</Text>
  </View>
);

export default TableHeader;
