import React from "react";
import PropTypes from "prop-types";
import svgs from "images";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const SpeciesCheckbox = ({ species, fieldName, label, selected, disabled }) => {
  const defaultClasses =
    "border-2 focus:border-dotted rounded-md flex flex-col p-6 justify-around items-center";
  const selectedClasses = selected
    ? "border-teal bg-dashboard-light-green"
    : "bg-white";
  const disabledClasses = disabled ? "opacity-75" : "";
  const disabledSelectedClasses =
    disabled && selected ? "border-pale-teal" : "";

  return (
    <label
      className={`${defaultClasses} ${selectedClasses} ${disabledClasses} ${disabledSelectedClasses}`}
    >
      <div className="mb-4 h-11">
        <img
          src={svgs[species]}
          alt={label}
          className={disabled ? "opacity-75" : ""}
        />
      </div>

      <div className="mb-4 text-deep-teal leading-tight text-center">
        {label}
      </div>

      <Field
        type="checkbox"
        id={fieldName}
        name={fieldName}
        value={species}
        checked={selected}
        disabled={disabled}
        className="sr-only"
        data-testid={`species-checkbox-${species}`}
        readOnly
      />

      <div
        className={`${
          selected ? "bg-teal border-teal" : "bg-white  border-dashboard-gray"
        } ${
          disabled && selected ? "bg-pale-teal border-pale-teal" : ""
        } flex justify-center items-center rounded-full h-6 w-6 border-2`}
      >
        {selected && <FontAwesomeIcon icon={faCheck} className="text-white" />}
      </div>
    </label>
  );
};

SpeciesCheckbox.propTypes = {
  species: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
};

export default SpeciesCheckbox;
