import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import fontBold from "../../../fonts/Cera-Pro-Bold.woff";
import PDFLogo from "./PDFLogo";
import TopHeader from "./TopHeader";
import SideText from "./Shared/SideText";
import SpecieTable from "./Shared/SpecieTable";
import SpecieCountTable from "./Shared/SpecieCountTable";
import SpecieYOYTable from "./Shared/SpecieYOYTable";
import {
  generateCountsDataSP,
  getSPIntakes,
  SP_LIVE_INTAKE_KEYS,
  SP_LIVE_OUTCOMES_KEYS,
  SP_OTHER_OUTCOMES,
} from "./helpers/PDFTableKeys";

Font.register({
  src: fontBold,
  family: "Cera Pro Bold",
});

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  container: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  rightCol: {
    flexDirection: "column",
    flexGrow: 9,
    paddingLeft: 10,
    paddingRight: 20,
    fontSize: 15,
    textTransform: "uppercase",
  },
  container2: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    height: 30,
    paddingTop: 7,
    textAlign: "center",
    flexGrow: 1,
  },
  container3: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    paddingTop: 7,
    textAlign: "center",
  },
  container4: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    marginTop: "-5px",
    textAlign: "center",
  },
  leftColumn: {
    width: 400,
    paddingRight: 10,
  },
  leftColumn2: {
    width: 350,
    paddingRight: 0,
    paddingLeft: 20,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 900,
  },
  header: {
    color: "#0F6165",
    fontSize: "24px",
    paddingLeft: 10,
    fontFamily: "Cera Pro Bold",
    fontWeight: 900,
  },
});

// Create Document Component
const CustomSpeciesPage = (props) => {
  var spIntakes = [],
    spOut2 = [],
    spOut = [],
    // YOYData = [],
    counts = [];
  if (props && props.data) {
    spIntakes = getSPIntakes(
      props.data.intakes.data,
      props.sp,
      SP_LIVE_INTAKE_KEYS
    );
    spOut = getSPIntakes(
      props.data.outcomes.data,
      props.sp,
      SP_LIVE_OUTCOMES_KEYS
    );
    spOut2 = getSPIntakes(
      props.data.outcomes.data,
      props.sp,
      SP_OTHER_OUTCOMES
    );
    counts = generateCountsDataSP(
      props.data.counts.data,
      props.sp,
      props.data.month
    );
  }
  return (
    <Page size="A3">
      <View style={styles.section}></View>
      <View style={styles.container}>
        <View style={styles.rightCol}>
          <PDFLogo />
        </View>
        <View style={styles.leftColumn}>
          <TopHeader
            orgName={props.orgName}
            action={props.action}
            headerName={props.title}
          />
        </View>
      </View>
      <Text style={styles.header}>{props.title}</Text>
      <View style={styles.container}>
        <View style={styles.rightCol}>
          <SpecieTable
            data={spIntakes}
            styles={props.styles}
            name={"LIVE INTAKES"}
          />
          <SpecieTable
            data={spOut}
            styles={props.styles}
            name={"LIVE OUTCOMES"}
          />
          <SpecieTable
            data={spOut2}
            styles={props.styles}
            name={"OTHER OUTCOMES"}
          />
          <View style={{ marginTop: 30 }}>
            <SpecieCountTable
              data={counts}
              styles={props.styles}
              name={"OTHER OUTCOMES"}
            />
          </View>
          {/* <SpecieYOYTable data={YOYData} styles={props.styles} name={"YOY"} /> */}
        </View>
        <View style={styles.leftColumn2}>
          <SideText />
        </View>
      </View>
    </Page>
  );
};
export default CustomSpeciesPage;
