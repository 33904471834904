import React, { forwardRef } from "react";
import AnimalIntake from "./AnimalIntake";
import PropTypes from "prop-types";

const AnimalIntakes = forwardRef(
  ({ animalData, selectedSpecies, isEditing }, ref) => {
    return (
      <div className="sac-border mb-4">
        <div className="flex">
          <div>
            <span className="circle bg-dashboard-blue mr-4">2</span>
          </div>
          <div>
            <div className="flex justify-between">
              <h2 className="text-2xl mt-1">Live Intake</h2>
            </div>

            {animalData.map(
              ({ id, attributes, attributes: { species, locationId } }) => {
                if (species === selectedSpecies || !selectedSpecies) {
                  return (
                    <AnimalIntake
                      key={`${species}-intake-${locationId}`}
                      attributes={attributes}
                      dataId={id}
                      isEditing={isEditing}
                      ref={ref}
                    />
                  );
                }
              }
            )}
          </div>
        </div>
      </div>
    );
  }
);

AnimalIntakes.propTypes = {
  animalData: PropTypes.array.isRequired,
  selectedSpecies: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default AnimalIntakes;
