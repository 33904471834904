export const animalOutcomes = {
  adultAdoptionCount: 0,
  youthAdoptionCount: 0,
  ageUnknownAdoptionCount: 0,
  adultReturnedToOwnerCount: 0,
  youthReturnedToOwnerCount: 0,
  ageUnknownReturnedToOwnerCount: 0,
  adultReturnedToFieldCount: 0,
  youthReturnedToFieldCount: 0,
  ageUnknownReturnedToFieldCount: 0,
  adultOtherLiveOutcomeCount: 0,
  youthOtherLiveOutcomeCount: 0,
  ageUnknownOtherLiveOutcomeCount: 0,
  adultDiedInCareCount: 0,
  youthDiedInCareCount: 0,
  ageUnknownDiedInCareCount: 0,
  adultLostInCareCount: 0,
  youthLostInCareCount: 0,
  ageUnknownLostInCareCount: 0,
  adultShelterEuthanasiaCount: 0,
  youthShelterEuthanasiaCount: 0,
  ageUnknownShelterEuthanasiaCount: 0,
  adultOwnerIntendedEuthanasiaCount: 0,
  youthOwnerIntendedEuthanasiaCount: 0,
  ageUnknownOwnerIntendedEuthanasiaCount: 0,
  adultTransferredInstateToAnotherAgencyCount: 0,
  youthTransferredInstateToAnotherAgencyCount: 0,
  ageUnknownTransferredInstateToAnotherAgencyCount: 0,
  adultTransferredOutOfStateToAnotherAgencyCount: 0,
  youthTransferredOutOfStateToAnotherAgencyCount: 0,
  ageUnknownTransferredOutOfStateToAnotherAgencyCount: 0,
  adultTransferredInternationallyToAnotherAgencyCount: 0,
  youthTransferredInternationallyToAnotherAgencyCount: 0,
  ageUnknownTransferredInternationallyToAnotherAgencyCount: 0,
  totalAdoptionCount: 0,
  totalReturnedToOwnerCount: 0,
  totalTransferredInstateToAnotherAgencyCount: 0,
  totalTransferredOutOfStateToAnotherAgencyCount: 0,
  totalTransferredInternationallyToAnotherAgencyCount: 0,
  totalReturnedToFieldCount: 0,
  totalOtherLiveOutcomeCount: 0,
  subtotalAdultLiveOutcomesCount: 0,
  subtotalYouthLiveOutcomesCount: 0,
  subtotalAgeUnknownLiveOutcomesCount: 0,
  subtotalLiveOutcomesCount: 0,
  totalDiedInCareCount: 0,
  totalLostInCareCount: 0,
  totalShelterEuthanasiaCount: 0,
  totalOwnerIntendedEuthanasiaCount: 0,
  subtotalAdultOtherOutcomesCount: 0,
  subtotalYouthOtherOutcomesCount: 0,
  subtotalAgeUnknownOtherOutcomesCount: 0,
  subtotalOtherOutcomesCount: 0,
  totalAdultOutcomesCount: 0,
  totalYouthOutcomesCount: 0,
  totalAgeUnknownOutcomesCount: 0,
  totalOutcomesCount: 0,
};
