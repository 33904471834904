import React from "react";
import { useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { formatUiErrors, FORM_MODES } from "utils";
import BackLink from "components/BackLink";
import LocationForm from "components/LocationForm";

const AddLocation = () => {
  const { orgId } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleUpdateSuccess = () => {
    history.push(`/organizations/${orgId}/locations`);

    toast.success("Saved successfully", {
      autoClose: 3000,
    });

    queryClient.invalidateQueries(["organizationLocations", { orgId }], {
      refetchActive: true,
    });
  };

  const handleUpdateError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  return (
    <>
      <div className="mb-8">
        <BackLink
          link={`/organizations/${orgId}/locations`}
          text="Back to locations listing"
        />
        <h2>Add Location</h2>
      </div>

      <LocationForm
        location={{ attributes: {} }}
        formMode={FORM_MODES.ADD}
        cancelRoute={`/organizations/${orgId}/locations`}
        onUpdateSuccess={handleUpdateSuccess}
        onUpdateError={handleUpdateError}
      />
    </>
  );
};

export default AddLocation;
