import { useAuthDispatch, useAuthState, useUserState } from "context";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getTotalPages, getOrganizations, getLocations } from "utils";
import { get, uniq } from "lodash";
import { Form, Formik } from "formik";
import FormField from "components/FormField";
import Button from "components/Button";
import RecordStatusTable from "components/RecordStatusTable";
import IntakeOverviewGraph from "components/IntakeOverviewGraph";
import IntakeOverviewTable from "components/IntakeOverviewTable";

const OrganizationData = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const {
    attributes: { fullName },
  } = useUserState();
  const queryClient = useQueryClient();
  const queryClient2 = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [orgId, setOrgId] = useState("");
  const [locationId, setLocationId] = useState("");

  // Initial query
  const {
    data: response = { data: [], included: [] },
    error,
    isLoading,
  } = useQuery(
    ["organizations", { page, search }],
    () => getOrganizations({ token, dispatch, page, search }),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: response2 = { data: [], included: [] },
    error2,
    isLoading2,
  } = useQuery(
    ["organizations2", { page, search: orgId }],
    () => getLocations({ token, dispatch, page: 1, search: orgId }),
    {
      keepPreviousData: true,
    }
  );
  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["organizations", { page: page + 1, search }],
        () => getOrganizations({ token, dispatch, page: page + 1, search })
      );
    }
  }, [response, page, search, queryClient]);

  useEffect(() => {
    queryClient2.prefetchQuery(
      ["organizations2", { page: page + 1, search: orgId }],
      () => getLocations({ token, dispatch, page: 1, search: orgId })
    );
  }, [orgId]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const handleSubmit = (values) => {
    if (values.search !== search) {
      setSearch(values.search);
    }
  };

  return (
    <>
      <div className="sac-border mb-6" style={{ marginTop: 20 }}>
        <h2>Intake and Outcome Records</h2>
        <Formik initialValues={{ search }} onSubmit={handleSubmit}>
          {({ setFieldValue }) => (
            <Form className="flex items-end space-x-6">
              <div className="w-80">
                <FormField
                  name="search"
                  placeholder="EIN"
                  style={{ height: "70px" }}
                />
              </div>
              <select
                placeholder="Organizations"
                name="organization"
                className="form-select text-sm sac-border"
                disabled={response.data.length == 0}
                onChange={(event) => {
                  setOrgId(event.target.value);
                }}
                value={orgId}
                style={{ width: 300 }}
              >
                <option value="0">Organization Name</option>
                {response.data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.attributes.name}
                  </option>
                ))}
              </select>

              <select
                name="Locations"
                className="form-select text-sm sac-border"
                onChange={(event) => {
                  setLocationId(event.target.value);
                }}
                style={{ width: 300 }}
                value={locationId}
              >
                <option value="0">Location</option>
                {response2.data.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.attributes.name}
                  </option>
                ))}
              </select>
              <div className="flex items-center space-x-2">
                <Button type="submit">Search</Button>
                <Button
                  onClick={() => {
                    setFieldValue("search", "");
                    setSearch("");
                  }}
                  emphasis="transparent"
                >
                  Clear Search
                </Button>
              </div>
            </Form>
          )}
        </Formik>

        {!!locationId && (
          <div className="sac-border flex flex-wrap xl:flex-nowrap align-items-center divide-y-2 divide-x-0 xl:divide-y-0 xl:divide-x-2 divide-grey">
            {/* Intake Outcome Database */}
            <div className="w-full xl:w-1/2 xl:mb-4 xl:pr-4 pt-4 xl:pt-0">
              <h2 className="text-center text-deep-teal">
                Intake and Outcome Database (IOD)
              </h2>
              {/* Data Matrix CTA */}
              <div className="sac-border p-10 mb-4 flex items-center justify-between">
                <Button
                  className="btn-sm xl:btn-full"
                  to={`/data-entry/upload/${locationId}/${orgId}`}
                >
                  Enter Data: CSV Upload
                </Button>
                <Button
                  className="btn-sm xl:btn-full"
                  to={`/data-entry-admin/${locationId}/${orgId}`}
                >
                  Enter Data: Manual
                </Button>
                <Button
                  className="btn-sm xl:btn-full"
                  emphasis="gold"
                  to={`/data-entry/export/${locationId}/${orgId}`}
                >
                  Export Data
                </Button>
              </div>

              {/* Records */}
              <div className="w-full sac-border mb-4 xl:mr-4">
                <div className="flex flex-row mb-2">
                  <h3 className="text-deep-teal w-1/3 mb-0">
                    IOD Data Records
                  </h3>
                  <div className="flex flex-row w-1/2 justify-between text-sm h-1/2">
                    <span className="bg-teal text-white px-1">COMPLETE</span>
                    <span className="bg-gold px-1 md:mx-2">INCOMPLETE</span>
                    <span className="bg-dashboard-blue text-white px-1">
                      UNBEGUN
                    </span>
                  </div>
                </div>
                <RecordStatusTable
                  adminLocation={locationId}
                  adminOrganization={orgId}
                />
              </div>

              {/* Graph */}
              <div className="w-full sac-border mb-4">
                <h3 className="text-deep-teal">IOD Overview by Species</h3>
                <IntakeOverviewGraph
                  adminLocation={locationId}
                  adminOrganization={orgId}
                />
              </div>

              {/* Table */}
              <div className="sac-border mb-4">
                <h3 className="text-deep-teal">IOD Overview by Category</h3>
                <IntakeOverviewTable
                  adminLocation={locationId}
                  adminOrganization={orgId}
                />
              </div>
            </div>
            {/* Services Database */}
            <div className="w-full xl:w-1/2 xl:mb-4 xl:pl-4 pt-4 xl:pt-0">
              <h2 className="text-center text-deep-teal">
                Community Services Database (CSD)
              </h2>
              {/* Services Matrix CTA */}
              <div className="sac-border p-10 mb-4 flex items-center justify-between">
                <Button
                  className="btn-outline btn-sm xl:btn-full"
                  to={`/services/upload/${locationId}/${orgId}`}
                >
                  Enter Data: CSV Upload
                </Button>
                <Button
                  className="btn-outline btn-sm xl:btn-full"
                  to={`/admin-services/${locationId}/${orgId}`}
                >
                  Enter Data: Manual
                </Button>
                <Button
                  className="btn-outline-gold btn-sm xl:btn-full"
                  emphasis="gold"
                  to={`/services/export/${locationId}/${orgId}`}
                >
                  Export Data
                </Button>
              </div>

              {/* Records */}
              <div className="w-full sac-border mb-4 xl:mr-4">
                <div className="flex flex-row mb-2">
                  <h3 className="text-deep-teal w-1/3 mb-0">
                    CSD Data Records
                  </h3>
                  <div className="flex flex-row w-1/2 justify-between text-sm h-1/2">
                    <span className="bg-teal-outline px-1">COMPLETE</span>
                    <span className="bg-gold-outline px-1 md:mx-2">
                      INCOMPLETE
                    </span>
                    <span className="bg-blue-outline px-1">UNBEGUN</span>
                  </div>
                </div>
                <RecordStatusTable
                  action="services"
                  adminLocation={locationId}
                  adminOrganization={orgId}
                />
              </div>

              {/* Graph */}
              <div className="w-full sac-border mb-4">
                <h3 className="text-deep-teal">CSD Overview by Species</h3>
                <IntakeOverviewGraph
                  action="services"
                  adminLocation={locationId}
                  adminOrganization={orgId}
                />
              </div>

              {/* Table */}
              <div className="sac-border mb-4">
                <h3 className="text-deep-teal">CSD Overview by Category</h3>
                <IntakeOverviewTable
                  action="services"
                  adminLocation={locationId}
                  adminOrganization={orgId}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrganizationData;
