import React, { forwardRef } from "react";
import AnimalCount from "./AnimalCount";
import PropTypes from "prop-types";

const AnimalCounts = forwardRef(
  (
    {
      animalData,
      selectedSpecies,
      isEditing,
      endingCounts,
      recalculate,
      setRecalculate,
    },
    ref
  ) => {
    return (
      <div className="sac-border mb-4">
        <div className="flex">
          <div>
            <span className="circle bg-dashboard-blue mr-4">5</span>
          </div>

          <div>
            <h2 className="text-2xl mt-1">Animal Counts</h2>
            {animalData.map(
              ({
                id,
                attributes: {
                  species,
                  beginningAnimalCount,
                  previousEndingAnimalCount,
                  previousEndingFosterAnimalCount,
                  beginningFosterAnimalCount,
                  endingFosterAnimalCount,
                },
              }) => {
                if (species === selectedSpecies || !selectedSpecies) {
                  return (
                    <AnimalCount
                      dataId={id}
                      key={species}
                      species={species}
                      beginningAnimalCount={beginningAnimalCount}
                      previousEndingAnimalCount={previousEndingAnimalCount}
                      endingAnimalCount={endingCounts[species]}
                      beginningFosterAnimalCount={beginningFosterAnimalCount}
                      previousEndingFosterAnimalCount={
                        previousEndingFosterAnimalCount
                      }
                      endingFosterAnimalCount={endingFosterAnimalCount}
                      isEditing={isEditing}
                      setRecalculate={setRecalculate}
                      ref={ref}
                    />
                  );
                }
              }
            )}
          </div>
        </div>
      </div>
    );
  }
);
AnimalCounts.propTypes = {
  animalData: PropTypes.array.isRequired,
  selectedSpecies: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  endingCounts: PropTypes.object.isRequired,
};

export default AnimalCounts;
