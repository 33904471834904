import React, { useState } from "react";
import { useQuery } from "react-query";
import { API_URL } from "utils";
import { camelizeKeys } from "humps";
import { useFormikContext } from "formik";
import FormField from "components/FormField";

const CountyDropdown = ({
  fieldName,
  usState,
  disabled,
  handleSuccess,
  handleError,
  required,
  ...props
}) => {
  const { values, setFieldError, setFieldValue, setFieldTouched } =
    useFormikContext();
  const fieldValue = values[fieldName];
  const [initLoad, setInitLoad] = useState(true);

  const {
    isLoading,
    error,
    data = [],
  } = useQuery(
    ["counties", usState],
    async () => {
      const response = await fetch(
        // Page size is 260 because the max number of counties for a given state is 254
        `${API_URL}/api/v1/us_counties?filter[us_state][eq]=${usState}&page[size]=260`,
        {
          method: "GET",
        }
      );
      const {
        data,
        error: apiError,
        errors: apiErrors,
      } = await response.json();

      if (!response.ok) {
        const resErrors = apiErrors || apiError;
        throw resErrors;
      }

      return camelizeKeys(data || []);
    },
    {
      enabled: !!usState,
      onSuccess: () => {
        setFieldValue(fieldName, initLoad ? fieldValue || "" : "");
        setFieldTouched(fieldName, false);
        setInitLoad(false);
      },
      onError: () => {
        setFieldError(fieldName, `Unable to load counties for ${usState}.`);
        setFieldTouched(fieldName);
      },
    }
  );

  return (
    <FormField
      {...props}
      name={fieldName}
      label="County"
      as="select"
      disabled={!usState || isLoading || error || disabled}
      className={isLoading && "italic"}
      data-testid="county-dropdown"
      required={required}
    >
      {isLoading ? (
        <option disabled hidden value={fieldValue}>
          Loading&hellip;
        </option>
      ) : (
        <>
          <option disabled value="" hidden>
            -- Select a County --
          </option>
          {data.map((county, index) => (
            <option value={county.id} key={index}>
              {county.attributes.name}
            </option>
          ))}
        </>
      )}
    </FormField>
  );
};

export default CountyDropdown;
