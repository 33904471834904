import React, { useEffect } from "react";
import { useQuery } from "react-query";
import {
  useParams,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { apiFetch, formatUiErrors, usePermissions, ROUTE_GUARDS } from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import Tabs from "components/Tabs";
import CoalitionDetails from "./components/CoalitionDetails";
import Contributors from "./components/Contributors";
import AddContributor from "./components/AddContributor";
import SharingPermissions from "./components/SharingPermissions";
import DataStatusTable from "./components/DataStatusTable";
import ExportData from "./components/ExportData";
import Users from "./components/Users";
import AddUser from "./components/AddUser";
import Home from "./components/Home";
import Settings from "./components/Settings";
import CoalitionAnalytics from "./components/CoalitionAnalytics";

const Coalition = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { coalitionId } = useParams();
  const { url, path } = useRouteMatch();
  const cleanUrl = url.replace(/\/$/, "");
  const permissions = usePermissions();
  const { isAdmin } = useUserState();

  const canViewCoalition = permissions[ROUTE_GUARDS.COALITION_VIEWER]();
  const isCoalitionManager = permissions[ROUTE_GUARDS.COALITION_MANAGER]();
  const isActiveCoalitionContributor =
    permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() &&
    permissions[ROUTE_GUARDS.COALITION_CONTRIBUTOR]();

  if (!localStorage.getItem("filters")) {
    localStorage.setItem(
      "filters",
      JSON.stringify({
        orgName: "",
        year: String(new Date().getFullYear()),
      })
    );
  }

  useEffect(() => {
    return () => {
      window.addEventListener(
        "beforeunload",
        localStorage.removeItem("filters")
      );
      window.addEventListener(
        "beforeunload",
        localStorage.removeItem("defaultOrg")
      );
    };
  }, []);

  const tabs =
    canViewCoalition || isCoalitionManager
      ? [
          {
            to: cleanUrl,
            label: "Home",
          },
          { to: `${cleanUrl}/detail`, label: "Detail" },
          { to: `${cleanUrl}/data-status`, label: "Data Status Table" },
          {
            to: `${cleanUrl}/coalition-analytics`,
            label: "Coalition Analytics",
          },
          { to: `${cleanUrl}/export`, label: "Export Data" },
          ...(isCoalitionManager
            ? [
                {
                  to: `${cleanUrl}/contributors/active`,
                  label: "Active Contrib.",
                },
                {
                  to: `${cleanUrl}/contributors/pending`,
                  label: "Pending Contrib.",
                },
                {
                  to: `${cleanUrl}/contributors/declined`,
                  label: "Declined Contrib.",
                },
              ]
            : []),
          ...(isActiveCoalitionContributor
            ? [
                {
                  to: `${cleanUrl}/sharing-permissions`,
                  label: "Sharing Permissions",
                },
              ]
            : []),
          ...(isCoalitionManager
            ? [{ to: `${cleanUrl}/users`, label: "Users", exact: false }]
            : []),
          ...(isAdmin
            ? [{ to: `${cleanUrl}/settings`, label: "Settings" }]
            : []),
        ]
      : [
          { to: `${cleanUrl}`, label: "Detail" },
          ...(isActiveCoalitionContributor
            ? [
                {
                  to: `${cleanUrl}/sharing-permissions`,
                  label: "Sharing Permissions",
                },
              ]
            : []),
        ];

  const {
    isLoading,
    error,
    data: response,
  } = useQuery(["coalition", coalitionId], () =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/coalitions/${coalitionId}`,
    })
  );

  if (isLoading) {
    return <LoadingIndicator inline content={<>Loading coalition&hellip;</>} />;
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  if (response.data.attributes.status === "closed" && !isAdmin) {
    return (
      <>
        <h1>{response.data.attributes.name}</h1>
        <p className="text-lg">
          This Coalition has been closed, contact{" "}
          <a href="mailto:info@shelteranimalscount.org">
            info@shelteranimalscount.org
          </a>{" "}
          for more information.
        </p>
      </>
    );
  }

  return (
    <>
      <h1>{response.data.attributes.name}</h1>

      <Tabs tabs={tabs} />

      <div className="tab__content">
        {canViewCoalition || isCoalitionManager ? (
          <Switch>
            {/* Home */}
            <Route path={`${path}`} exact component={Home} />

            {/* Details */}
            <Route path={`${path}/detail`} exact component={CoalitionDetails} />

            {/* Coalition Analytics */}
            <Route
              path={`${path}/coalition-analytics`}
              exact
              component={CoalitionAnalytics}
            />

            {/* Active contributors table */}
            {isCoalitionManager && (
              <Route
                path={`${path}/contributors/active`}
                exact
                component={Contributors}
              />
            )}

            {/* Pending contributors table */}
            {isCoalitionManager && (
              <Route
                path={`${path}/contributors/pending`}
                exact
                component={Contributors}
              />
            )}

            {/* Declined contributors table */}
            {isCoalitionManager && (
              <Route
                path={`${path}/contributors/declined`}
                exact
                component={Contributors}
              />
            )}

            {isActiveCoalitionContributor && (
              <Route
                path={`${path}/sharing-permissions`}
                exact
                component={SharingPermissions}
              />
            )}

            {/* Add Contributor */}
            {isCoalitionManager && (
              <Route
                path={`${path}/contributors/add`}
                exact
                component={AddContributor}
              />
            )}

            {/* Data status */}
            {canViewCoalition || isCoalitionManager ? (
              <Route
                path={`${path}/data-status`}
                exact
                component={DataStatusTable}
              />
            ) : null}

            {/* Export coalition data */}
            {canViewCoalition || isCoalitionManager ? (
              <Route path={`${path}/export`} exact component={ExportData} />
            ) : null}

            {/* Coalition Settings */}
            {isAdmin && (
              <Route path={`${path}/settings`} exact component={Settings} />
            )}

            {/* Users, Add User */}
            {isCoalitionManager && (
              <>
                <Route path={`${path}/users`} exact component={Users} />
                <Route path={`${path}/users/add`} exact component={AddUser} />
              </>
            )}

            <Redirect to={cleanUrl} />
          </Switch>
        ) : (
          <Switch>
            <Route path={path} exact component={CoalitionDetails} />
            {isActiveCoalitionContributor && (
              <Route
                path={`${path}/sharing-permissions`}
                exact
                component={SharingPermissions}
              />
            )}
          </Switch>
        )}
      </div>
    </>
  );
};

export default Coalition;
