import React from "react";
import { graphColors } from "./utils";

const IntakeOverviewGraphLegend = () => {
  return (
    <div className="flex flex-wrap space-x-4 text-sm">
      {/* Legend */}
      <div className="flex space-x-4">
        <IntakeOverviewGraphLegendItem
          backgroundColor={graphColors.canine}
          label="Canine"
        />
        <IntakeOverviewGraphLegendItem
          backgroundColor={graphColors.feline}
          label="Feline"
        />
        <IntakeOverviewGraphLegendItem
          backgroundColor={graphColors.other}
          label="Other"
        />
      </div>
    </div>
  );
};

export default IntakeOverviewGraphLegend;

const IntakeOverviewGraphLegendItem = (props) => {
  const { backgroundColor, label } = props;
  return (
    <div className="flex items-center">
      <div
        style={{ backgroundColor }}
        className="w-3 h-3 rounded-sm mr-2"
      ></div>
      {label}
    </div>
  );
};
