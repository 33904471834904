import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { dataEntryStatuses } from "utils";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useUserState } from "context";

const RecordStatusIndicator = ({
  month,
  year,
  status,
  action,
  location,
  adminOrganization,
}) => {
  const isService = action === "services";
  const { isAdmin } = useUserState();

  switch (status) {
    case dataEntryStatuses.complete:
    case dataEntryStatuses.complete_services_provided:
    case dataEntryStatuses.complete_no_services_provided:
      return (
        <Link
          to={
            isAdmin
              ? `/${action}/${year}/${month}/${location}/${adminOrganization}`
              : `/${action}/${year}/${month}/`
          }
          className={classnames("btn btn-sm btn-full text-xs", {
            "bg-teal text-white": !isService,
            "bg-white text-teal": isService,
          })}
          data-testid="record-status-table-item__complete"
        >
          Complete
        </Link>
      );
    case dataEntryStatuses.incomplete:
    case dataEntryStatuses.in_progress:
      return (
        <Link
          to={
            isAdmin
              ? `/${action}/${year}/${month}/${location}/${adminOrganization}`
              : `/${action}/${year}/${month}`
          }
          className={classnames("btn btn-sm btn-full text-xs", {
            "btn-record-status-indicator ": !isService,
            "bg-white text-gold": isService,
          })}
          style={!isService ? { width: "inherit" } : {}}
          data-testid="record-status-table-item__incomplete"
        >
          In Progress
        </Link>
      );
    case dataEntryStatuses.unbegun:
      return (
        <Link
          to={
            isAdmin
              ? `/${action}/${year}/${month}/${location}/${adminOrganization}`
              : `/${action}/${year}/${month}`
          }
          className={classnames("btn btn-sm btn-full text-xs", {
            "bg-dashboard-blue text-white": !isService,
            "bg-white text-dashboard-blue": isService,
          })}
          data-testid="record-status-table-item__unbegun"
        >
          Enter
        </Link>
      );
    case dataEntryStatuses.unavailable:
      return (
        <FontAwesomeIcon
          icon={faCircle}
          className={classnames("text-white text-2xl")}
          data-testid="record-status-table-item__unavailable"
        />
      );
    default:
      return null;
  }
};

export default RecordStatusIndicator;
