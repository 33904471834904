import React from "react";
import { useParams } from "react-router-dom";
import { useQueryClient, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { apiFetch, formatUiErrors, FORM_MODES } from "utils";
import { useAuthState, useAuthDispatch } from "context";
import Error from "components/Error";
import Button from "components/Button";
import UserProfileForm from "components/UserProfileForm";
import LoadingIndicator from "components/LoadingIndicator";
import BackLink from "components/BackLink";
import UserOrganizations from "./components/UserOrganizations";
import UserCoalitions from "./components/UserCoalitions";

const UserDetails = ({ formMode = FORM_MODES.VIEW }) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { userId } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { isLoading, error, data: response } = useQuery(["user", userId], () =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/users/${userId}`,
    })
  );

  const handleUpdateSuccess = (response) => {
    history.push(`/users/${userId}`);

    toast.success(
      <div>
        {!!response.data.attributes.unconfirmedEmail ? (
          <div className="mt-2">
            An email was sent to the account for `
            {response.data.attributes.unconfirmedEmail}` to confirm the new
            email address.
          </div>
        ) : (
          "Saved successfully"
        )}
      </div>,
      {
        autoClose: !!response.data.attributes.unconfirmedEmail ? 5000 : 3000,
      }
    );

    queryClient.invalidateQueries(["users"], {
      refetchActive: true,
    });

    return queryClient.setQueryData(["user", userId], response);
  };

  const handleUpdateError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  if (isLoading) {
    return <LoadingIndicator inline content={<>Loading user&hellip;</>} />;
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  return (
    <>
      <div className="mb-8">
        <BackLink link="/users" text="Back to users listing" />
        <h1>
          {formMode === FORM_MODES.EDIT && "Editing "}
          {response.data.attributes.fullName}
        </h1>

        {formMode === FORM_MODES.VIEW && (
          <div className="flex space-x-2">
            <Button to={`/users/${userId}/edit`}>Edit User</Button>
          </div>
        )}
      </div>

      <UserProfileForm
        user={response.data}
        formMode={formMode}
        cancelRoute={`/users/${userId}`}
        onUpdateSuccess={handleUpdateSuccess}
        onUpdateError={handleUpdateError}
      />

      {formMode === FORM_MODES.VIEW && (
        <>
          <hr className="my-8" />
          <UserOrganizations userId={userId} />
          <UserCoalitions userId={userId} />
        </>
      )}
    </>
  );
};

export default UserDetails;
