import { COOKIE_CONSENT_UPDATE, COOKIE_CONSENT_CLEAR } from "actions";
import { get, set, remove } from "js-cookie";

const cookieValue = get("CookieConsent");

export const initialCookieConsentState = {
  cookieValue,
  showCookieConsent: !cookieValue,
};

export const CookieConsentReducer = (state, action) => {
  switch (action.type) {
    case COOKIE_CONSENT_UPDATE:
      // Set the CookieConsent cookie to expire in 1 year
      set("CookieConsent", action.payload, { expires: 365 });
      return {
        cookieValue: action.payload,
        showCookieConsent: !action.payload,
      };
    case COOKIE_CONSENT_CLEAR:
      remove("CookieConsent");
      return {
        cookieValue: undefined,
        showCookieConsent: true,
      };
    default:
      return state;
  }
};
