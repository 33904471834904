import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import { isEqual, get, uniq } from "lodash";
import Button from "components/Button";
import FormField from "components/FormField";
import StateDropdown from "components/StateDropdown";
import Table from "components/Table";
import Pagination from "components/Pagination";
import { useAuthState, useAuthDispatch } from "context";
import {
  apiFetch,
  getTotalPages,
  formatEin,
  titleCase,
  getOrgDataStatus,
  useHandleRequestStates,
  useManageCoalitionMemberships,
  formatBusinessNumber,
} from "utils";
import ProvinceDropdown from "components/ProvinceDropdown";

const AddContributor = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const { coalitionId } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const { createContributorMembership } = useManageCoalitionMemberships();
  const { handleSuccess, handleError } = useHandleRequestStates();

  // We need to manage the isLoading state manually, instead of relying on
  // the useQuery isLoading property, so we can have more granular control
  // over when we display the table to prevent a flicker of the records from
  // the previous search
  const [isLoading, setIsLoading] = useState(false);

  const initialFilters = {
    search: "",
    state: "",
    provinceOrTerritory: "",
    orgType: "",
    country: "United States",
  };

  const [filters, setFilters] = useState(initialFilters);

  const getOrganizations = async ({
    page = 1,
    search = "",
    state = "",
    orgType = "",
    country = "",
  }) => {
    const response = await apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/organizations?page[number]=${page}&filter[status][eq]=confirmed${
        search ? `&filter[search]=${search}` : ""
      }${
        orgType ? `&filter[organization_type]=${orgType}` : ""
      }&filter[country][eq]=${country}&filter[coalition_membership_by_id_and_status][eq]=${coalitionId}&include=data_entry_statuses&extra_fields[organizations]=locations_us_states,locations_provinces&stats[total]=count&sort=name${
        state ? `&filter[locations_us_state]=${state}` : ""
      }`,
      includeMeta: true,
    });
    response.data.map((org) => {
      const incStatusRecords = response.included.filter((inc) => {
        return (
          inc.type === "organization_data_entry_statuses" &&
          String(inc.attributes.organizationId) === org.id
        );
      });

      if (!!incStatusRecords.length) {
        org.attributes.dataStatus = getOrgDataStatus(incStatusRecords);
      }

      return org;
    });
    return response;
  };

  // Initial query
  const { data: response = { data: [], included: [] }, error } = useQuery(
    ["searchOrganizations", { coalitionId, page, ...filters }],
    () => {
      // Manually set isLoading to true when the request kicks off
      setIsLoading(true);
      return getOrganizations({ page, ...filters });
    },
    {
      enabled: !isEqual(filters, initialFilters),
      keepPreviousData: true,
      // If the request errors out, set isLoading to false
      onError: () => setIsLoading(false),
    }
  );

  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["searchOrganizations", { coalitionId, page: page + 1, ...filters }],
        () => getOrganizations({ page: page + 1, ...filters })
      );
    }
  }, [response, page, filters, queryClient]);

  useEffect(() => {
    // We can assume that the response was successful if the response changes
    // and we set isLoading to false
    setIsLoading(false);
  }, [response]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    ...(filters.country === "United States"
      ? [
          {
            accessor: "attributes.locationsUsStates",
            Header: "States",
            Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
          },
        ]
      : []),
    ...(filters.country === "Canada"
      ? [
          {
            accessor: "attributes.locationsProvinces",
            Header: "Province/Territory",
            Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
          },
        ]
      : []),
    ...(filters.country === "United States"
      ? [
          {
            accessor: "attributes.ein",
            Header: "EIN",
            Cell: ({ cell }) => formatEin(cell.value),
          },
        ]
      : []),
    ...(filters.country === "Canada"
      ? [
          {
            accessor: "attributes.businessNumber",
            Header: "Business Number",
            Cell: ({ cell }) => formatBusinessNumber(cell.value),
          },
        ]
      : []),
    {
      accessor: "attributes.organizationType",
      Header: "Type",
    },
    {
      accessor: "attributes.dataStatus",
      Header: () => {
        return (
          <>
            <div className="flex items-center space-x-2">
              <span>Data Status</span>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-dashboard-medium-gray"
                data-tip
                data-for="dataStatus"
              />
            </div>
            <ReactTooltip place="right" className="w-48" id="dataStatus">
              Data status is calculated for the latest 12 months
            </ReactTooltip>
          </>
        );
      },
      Cell: ({ cell }) => (cell.value ? titleCase(cell.value) : null),
    },
    {
      accessor: "id",
      Header: "",
      Cell: ({ row: { original } }) => (
        <Button
          className="btn-sm"
          emphasis="transparent"
          onClick={() => inviteOrg(original)}
        >
          Invite Organization
        </Button>
      ),
    },
  ];

  /**
   * ACTIONS
   */
  // INVITE CONTRIBUTOR
  const { mutate: inviteOrg } = useMutation(
    (organization) =>
      apiFetch({
        token,
        dispatch,
        method: "POST",
        endpoint: "/api/v1/coalition_memberships",
        body: {
          data: {
            type: "coalition_memberships",
            attributes: {
              organizationId: organization.id,
              coalitionId,
              statusActor: "Coalition",
            },
          },
        },
      }),
    {
      onSuccess: (res, organization) => {
        setIsLoading(true);
        createContributorMembership(res.data);
        handleSuccess({
          message: `An invitation email has been sent to the members of ${organization.attributes.name}`,
          queries: [
            {
              key: ["coalitionContributors", coalitionId],
              config: {
                refetchActive: true,
              },
            },
            {
              key: ["searchOrganizations", { coalitionId }],
            },
            {
              key: "coalitions",
            },
            {
              key: "myCoalitions",
            },
          ],
        });

        history.push(`/coalitions/${coalitionId}/contributors`);
      },
      onError: (error) => {
        setIsLoading(false);
        handleError(error);
      },
    }
  );
  /**
   * FORM METHODS
   */
  const onSubmitFilters = (values) => {
    setShowTable(true);
    if (!isEqual(values, filters)) {
      setFilters(values);
    }
  };

  const onValidateForm = (values) => {
    const formValues = ["search", "state", "orgType"].reduce((vals, key) => {
      if (values[key]) {
        vals.push(values[key]);
      }
      return vals;
    }, []);
    return !!formValues.length ? {} : { error: "Select at least one filter." };
  };

  return (
    <>
      <h2>Invite Contributor</h2>

      <div className="sac-border mb-6">
        <Formik
          initialValues={{ ...initialFilters, error: "" }}
          validateOnMount
          validate={onValidateForm}
          onSubmit={onSubmitFilters}
        >
          {({
            resetForm,
            errors,
            touched,
            handleSubmit,
            values: { country },
          }) => (
            <Form>
              <div className="">
                <div className="mb-6 lg:flex justify-between">
                  <FormField
                    name="search"
                    label="Search"
                    placeholder="Name, EIN or BN"
                  />

                  <div className="w-80">
                    <FormField name="country" label="Country" as="select">
                      <option disabled value="" hidden>
                        -- Select a Country --
                      </option>
                      <option value="United States">United States</option>
                      <option value="Canada">Canada</option>
                    </FormField>
                  </div>

                  {country === "Canada" && (
                    <ProvinceDropdown name="state" label="Province/Territory" />
                  )}
                  {country === "United States" && (
                    <StateDropdown name="state" label="State" />
                  )}

                  <FormField
                    name="orgType"
                    label="Organization Type"
                    as="select"
                  >
                    <option value="">All Organization Types</option>
                    <option value="Government Animal Services">
                      Government Animal Services
                    </option>
                    <option value="Animal Shelter with a Government Contract">
                      Animal Shelter with a Government Contract
                    </option>
                    <option value="Animal Rescue with a Government Contract">
                      Animal Rescue with a Government Contract
                    </option>
                    <option value="Shelter without a Government Contract">
                      Shelter without a Government Contract
                    </option>
                    <option value="Rescue without a Government Contract">
                      Rescue without a Government Contract
                    </option>
                  </FormField>
                </div>

                <div className="flex justify-end">
                  <Button
                    onClick={() => {
                      resetForm();
                      setShowTable(false);
                      setFilters(initialFilters);
                    }}
                    emphasis="transparent"
                  >
                    Reset Filters
                  </Button>

                  <Button type="submit" onClick={handleSubmit}>
                    Search
                  </Button>
                </div>
              </div>

              {errors.error && touched.error && (
                <div className="text-sm text-red mt-1">{errors.error}</div>
              )}
            </Form>
          )}
        </Formik>
      </div>

      {showTable && (
        <>
          <Table
            data={response.data || []}
            columns={columns}
            noResultsText="No organizations found"
            isLoading={isLoading}
            error={error}
          />

          {!!(response.data || []).length && (
            <div className="flex justify-end mt-8">
              <Pagination
                totalPages={totalPages}
                currentPage={page}
                onPageChanged={(page) => setPage(page)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AddContributor;
