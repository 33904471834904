import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Button = ({
  emphasis,
  onClick,
  to,
  href,
  className,
  isLoading,
  disabled,
  loadingText,
  ref,
  children,
  download,
  type = "button",
  ...props
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  let ButtonType = "button";

  if (to) ButtonType = Link;
  if (href) ButtonType = "a";

  return (
    <ButtonType
      ref={ref}
      disabled={isLoading || disabled}
      download={download}
      className={`${
        isLoading ? "italic" : ""
      } btn btn-${emphasis} ${className}`}
      {...(onClick ? { onClick: handleClick } : {})}
      {...(ButtonType === "button" ? { type } : {})}
      {...(to ? { to } : {})}
      {...(href ? { href } : {})}
      {...(props["data-testid"] ? { "data-testid": props["data-testid"] } : {})}
    >
      {isLoading && loadingText ? loadingText : children}
    </ButtonType>
  );
};

Button.propTypes = {
  emphasis: PropTypes.oneOf([
    "primary",
    "secondary",
    "white",
    "transparent",
    "link",
    "gold",
    "teal",
  ]),
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.object,
  disabled: PropTypes.bool,
  download: PropTypes.bool,
  children: PropTypes.node,
  "data-testid": PropTypes.string,
};

Button.defaultProps = {
  className: "",
  type: "button",
  emphasis: "primary",
  isLoading: false,
  disabled: false,
  download: false,
};

export default Button;
