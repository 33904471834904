import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

const DataEntryOption = ({ id, icon, heading, content, selected }) => {
  return (
    <label
      data-testid="data-entry-option"
      htmlFor={id}
      className={`flex flex-col justify-between items-center flex-grow text-center p-8 border-2 rounded-lg ${
        selected
          ? "bg-dashboard-light-green bg-opacity-30  border-teal"
          : "bg-super-light-gray"
      }`}
    >
      <span className="block">
        <span
          className={`flex justify-center mb-4 ${
            selected ? "text-teal" : "text-dashboard-medium-gray"
          }`}
        >
          {icon}
        </span>
        <h5 className="mb-2">{heading}</h5>
        <p>{content}</p>
      </span>

      <Field
        type="radio"
        id={id}
        name="dataEntryOption"
        value={id}
        checked={selected}
        className="sr-only"
      />
      <span
        className={`flex items-center justify-center rounded-full border w-6 h-6 ${
          selected ? "border-teal" : "border-dashboard-medium-gray"
        }`}
      >
        {selected && (
          <span className="block rounded-full bg-teal w-3.5 h-3.5"></span>
        )}
      </span>
    </label>
  );
};

DataEntryOption.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default DataEntryOption;
