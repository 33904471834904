import { View, Text } from "@react-pdf/renderer";

const SideTextServices = (props) => {
  return (
    <View style={{ marginTop: 30 }}>
      <Text>
        This report is a snapshot of {"\n"} data for all species recorded {"\n"}{" "}
        by your organization, within {"\n"}the timeframe selected for
        {"\n"}the report. See additional {"\n"}pages for age breakouts for{" "}
        {"\n"}each species.{"\n"}
        {"\n"}
      </Text>
      <Text>
        For full definitions, visit {"\n"}
        https://www.shelteranimalscount.org/community-services-database-csd/
        {"\n"}
        {"\n"}
      </Text>
      <Text>
        Community Services are {"\n"}for owned/community {"\n"}supported pets,
        not {"\n"}animals counted as {"\n"}an intake
        {"\n"}
        {"\n"}
        {"\n"}
        {"\n"}
      </Text>
      {!props.hideOther && (
        <Text style={{ fontWeight: 400, fontSize: 11 }}>
          * Rabbits, equines, small
          {"\n"}mammals, farm animals, birds,{"\n"}
          and reptiles & amphibians. {"\n"}
          {"\n"}
        </Text>
      )}
    </View>
  );
};
export default SideTextServices;
