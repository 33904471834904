import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { MenuItem, FocusableItem } from "@szhsin/react-menu";
import { toast } from "react-toastify";
import { get } from "lodash";
import { apiFetch, getTotalPages } from "utils";
import { useAuthState, useAuthDispatch } from "context";
import { Formik, Form } from "formik";
import Table from "components/Table";
import Button from "components/Button";
import FormField from "components/FormField";
import Pagination from "components/Pagination";
import ActionsMenu from "components/ActionsMenu";
import AlertModal from "components/AlertModal";
import ConfirmationModal from "components/ConfirmationModal";

const Users = () => {
  const { token, userId } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState(null);

  const getUsers = async ({ page = 1, search = "", status = "" }) => {
    return await apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/users?page[number]=${page}&filter[id][not_eq]=${userId}&stats[total]=count&sort=full_name${
        search ? `&filter[search]=${search}` : ""
      }${status ? `&filter[status][eq]=${status}` : ""}`,
      includeMeta: true,
    });
  };

  // Initial query
  const {
    data: response = { data: [] },
    error,
    isLoading,
  } = useQuery(
    ["users", { page, search, status }],
    () => getUsers({ page, search, status }),
    {
      keepPreviousData: true,
    }
  );

  // Prefetch the next page of users
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["users", { page: page + 1, search, status }],
        () => getUsers({ page: page + 1, search, status })
      );
    }
  }, [response, page, search, queryClient, status]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    {
      accessor: "attributes.fullName",
      Header: "Name",
    },
    {
      accessor: "attributes.email",
      Header: "Email",
    },
    {
      accessor: "attributes.confirmedAt",
      Header: "Confirmation Status",
      Cell: ({ cell }) => (cell.value ? "Confirmed" : "Unconfirmed"),
    },
    {
      accessor: "id",
      Header: "",
      Cell: ({ row: { original }, cell }) => (
        <Menu
          id={cell.value}
          user={original}
          triggerDeleteUserModal={openModal}
        />
      ),
    },
  ];

  const openModal = (user) => {
    setUserToDelete(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalError(null);
    setUserToDelete(null);
  };

  const { mutate: deleteUser, isLoading: isLoadingDeleteUser } = useMutation(
    ({ id }) =>
      apiFetch({
        token,
        dispatch,
        method: "POST",
        endpoint: "/api/v1/resource_archivers",
        body: {
          data: {
            type: "resource_archivers",
            attributes: {
              resourceId: id,
              resourceType: "user",
            },
          },
        },
      }),
    {
      onSuccess: () => {
        toast.success(
          `Deleted ${userToDelete.attributes.fullName} successfully`,
          {
            autoClose: 3000,
          }
        );
        // Refresh the users queries
        queryClient.invalidateQueries("users", {
          refetchActive: true,
        });
        closeModal();
      },
      onError: setModalError,
    }
  );

  const handleSubmit = (values) => {
    if (values.search !== search) {
      setSearch(values.search);
    }
    if (values.status !== status) {
      setStatus(values.status);
    }
  };

  return (
    <>
      <h1>Users</h1>

      <div className="sac-border mb-6">
        <Formik initialValues={{ search, status }} onSubmit={handleSubmit}>
          {({ setFieldValue }) => (
            <Form className="flex items-end space-x-6">
              <div className="w-80">
                <FormField
                  name="search"
                  label="Search"
                  placeholder="Name or email"
                />
              </div>

              <div className="w-80">
                <FormField
                  as="select"
                  name="status"
                  label="Status"
                  placeholder="User Status"
                >
                  <option disabled value="" hidden>
                    -- User Status --
                  </option>
                  <option value="active">Active</option>
                  <option value="archived">Archived</option>
                </FormField>
              </div>

              <div className="flex items-center space-x-2">
                <Button type="submit">Search</Button>
                <Button
                  onClick={() => {
                    setFieldValue("search", "");
                    setFieldValue("status", "");
                    setSearch("");
                    setStatus("");
                  }}
                  emphasis="transparent"
                >
                  Clear Search
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <Table
        data={response.data || []}
        columns={columns}
        columnWidths={[null, null, null, "w-16"]}
        columnAlignments={[null, null, null, "text-right"]}
        noResultsText="No users found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}

      <AlertModal isModalOpen={isModalOpen} closeModal={closeModal}>
        <ConfirmationModal
          prompt={`
          Are you sure you want to delete ${get(
            userToDelete,
            "attributes.fullName"
          )}?`}
          isLoading={isLoadingDeleteUser}
          error={modalError}
          closeModal={closeModal}
          confirmModal={() => deleteUser(userToDelete)}
        />
      </AlertModal>
    </>
  );
};

export default Users;

const Menu = ({ id, user, triggerDeleteUserModal }) => {
  return (
    <ActionsMenu>
      {/* View */}
      <FocusableItem>
        {({ ref }) => (
          <Link to={`/users/${id}`} ref={ref}>
            View Details
          </Link>
        )}
      </FocusableItem>
      {/* Edit */}
      <FocusableItem>
        {({ ref }) => (
          <Link to={`/users/${id}/edit`} ref={ref}>
            Edit Details
          </Link>
        )}
      </FocusableItem>
      {/* Delete */}
      {!user.attributes.archivedAt && (
        <MenuItem onClick={() => triggerDeleteUserModal(user)}>
          Delete User
        </MenuItem>
      )}
    </ActionsMenu>
  );
};
