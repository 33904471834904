import React from "react";
import Upload from "../../components/DataEntryUpload";
import exampleCSV from "files/Shelter-Animals-Count_Sample_Services-Import.csv";

const ServicesUpload = () => (
  <Upload
    exampleCSV={exampleCSV}
    action="services"
    title="Community Services: Upload Your CSV"
  />
);

export default ServicesUpload;
