import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

const BackLink = ({ link, text }) => (
  <Link to={link} className="inline-flex items-center text-sm mb-2">
    <FontAwesomeIcon className="mr-1 text-xs" icon={faAngleDoubleLeft} />
    {text}
  </Link>
);

BackLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default BackLink;
