export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const UPDATE_USER_ID = "UPDATE_USER_ID";

export const USER_CREATE = "USER_CREATE";
export const USER_UPDATE = "USER_UPDATE";
export const USER_CONFIRM = "USER_CONFIRM";
export const USER_CLEAR = "USER_CLEAR";

export const COOKIE_CONSENT_UPDATE = "COOKIE_CONSENT_UPDATE";
export const COOKIE_CONSENT_CLEAR = "COOKIE_CONSENT_CLEAR";
