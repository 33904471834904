import React, { useEffect, useState } from "react";
import { API_URL, formatDateTime } from "utils";
import { useParams } from "react-router-dom";
import { useAuthDispatch, useAuthState, useUserState } from "context";
import LoadingIndicator from "components/LoadingIndicator";
import Table from "components/Table";

const EntryLogs = (props) => {
  const { token } = useAuthState();
  const { attributes, isAdmin } = useUserState();
  const [page] = useState(1);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { location } = useParams();

  let locationId = attributes.locationId;
  if (isAdmin && location) locationId = location;

  const fetchData = async () => {
    setIsLoading(true);
    const { accessToken, client, expiry, uid } = token;
    const response = await fetch(
      `${API_URL}/api/v1/entry_logs?page=${page}&locationId=${locationId}`,
      {
        method: "get",
        headers: {
          "access-token": accessToken,
          "token-type": "Bearer",
          "Content-Type": "application/json",
          client,
          expiry,
          uid,
        },
      }
    );

    const responseJson = await response.json();
    const { data, error } = responseJson;
    setResponse(data);
    setError(error);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingIndicator inline content={<>Loading logs&hellip;</>} />;
  }

  const columns = [
    {
      accessor: "created_at",
      Header: "Created At",
      Cell: ({ cell }) => {
        if (!cell.value) return "--";
        return formatDateTime(cell.value);
      },
    },
    {
      accessor: "month",
      Header: "Month of Record",
    },
    {
      accessor: "year",
      Header: "Year of Record",
    },
    {
      accessor: "updated_at",
      Header: "Updated At",
      Cell: ({ cell }) => {
        if (!cell.value) return "--";
        return formatDateTime(cell.value);
      },
    },
    {
      accessor: "channel",
      Header: "Data Entry Type",
      Cell: ({ cell }) => {
        if (!cell.value) return "--";
        return cell.value.toUpperCase();
      },
    },
    {
      accessor: "location_id",
      Header: "Location Id",
    },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <Table
        data={response || []}
        columns={columns}
        noResultsText="No Logs found"
        isLoading={isLoading}
        error={error}
      />

      {/* {!!(response.data || []).length && (
      <div className="flex justify-end mt-8">
        <Pagination
          totalPages={totalPages}
          currentPage={page}
          onPageChanged={(page) => setPage(page)}
        />
      </div>
    )} */}
    </div>
  );
};

export default EntryLogs;
