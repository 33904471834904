import React from "react";
import { speciesMap } from "utils";

const IntakeOverviewTableSpecies = (props) => {
  const { speciesKey } = props;
  const speciesData = speciesMap[speciesKey];

  if (speciesData.icon) {
    return (
      <div className="flex items-center">
        <div className="animal-icon mr-2">
          <img className="mx-auto" src={speciesData.icon} alt="" />
        </div>
        {speciesData.label}
      </div>
    );
  }
  return speciesData.label;
};

export default IntakeOverviewTableSpecies;
