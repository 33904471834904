import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import UserProfileForm from "components/UserProfileForm";
import Button from "components/Button";
import { useUserDispatch, useUserState, useAuthState } from "context";
import { FORM_MODES, API_URL, formatUiErrors } from "utils";
import { USER_UPDATE } from "actions";

const Profile = ({ formMode = FORM_MODES.VIEW }) => {
  const { token } = useAuthState();
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const history = useHistory();

  const { mutate: resetPassword, isLoading } = useMutation(
    async () => {
      const { accessToken, client, expiry, uid } = token;
      const requestOptions = {
        method: "POST",
        headers: {
          "access-token": accessToken,
          "token-type": "Bearer",
          "Content-Type": "application/json",
          client,
          expiry,
          uid,
        },
        body: JSON.stringify({
          email: user.attributes.email,
          redirect_url: `${process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL}/reset-password`,
        }),
      };
      const response = await fetch(`${API_URL}/auth/password`, requestOptions);
      const { message, errors, error } = await response.json();

      if (!response.ok) throw errors || error;

      return message;
    },
    {
      onSuccess: (message) => {
        toast.success(message, {
          autoClose: 3000,
        });
      },
      onError: (error) => {
        toast.error(() => formatUiErrors(error), {
          autoClose: 5000,
        });
      },
    }
  );

  const handleUpdateSuccess = (response) => {
    userDispatch({
      type: USER_UPDATE,
      payload: {
        attributes: response.data.attributes,
      },
    });
    history.push("/profile");

    toast.success(
      <div>
        {!!response.data.attributes.unconfirmedEmail ? (
          <div className="mt-2">
            Please check your email account for `
            {response.data.attributes.unconfirmedEmail}` to confirm your new
            email address. Your login information will not change until you
            confirm.
          </div>
        ) : (
          "Saved successfully"
        )}
      </div>,
      {
        autoClose: !!response.data.attributes.unconfirmedEmail ? 5000 : 3000,
      }
    );
  };

  const handleUpdateError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  return (
    <div>
      <h1>User Profile</h1>

      {formMode === FORM_MODES.VIEW && (
        <div className="flex space-x-2 mb-8">
          <Button to="/profile/edit">Edit Profile</Button>

          <Button
            onClick={resetPassword}
            emphasis="transparent"
            disabled={isLoading}
            isLoading={isLoading}
            loadingText={<>Loading&hellip;</>}
          >
            Reset Password
          </Button>
        </div>
      )}

      <UserProfileForm
        user={user}
        formMode={formMode}
        cancelRoute="/profile"
        onUpdateSuccess={handleUpdateSuccess}
        onUpdateError={handleUpdateError}
      />
    </div>
  );
};

export default Profile;
