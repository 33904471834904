import React from "react";
import { NavLink } from "react-router-dom";
import { isNil } from "lodash";

const Tabs = ({ tabs, className }) => {
  return (
    <div className={`flex border-b-2 border-pale-blue ${className}`}>
      {tabs.map((tab, index) => (
        <NavLink
          key={index}
          exact={!isNil(tab.exact) ? tab.exact : true}
          to={tab.to}
          className="tab bg-white px-3 py-1 flex flex-col justify-center text-center rounded-t-lg border-l-2 border-t-2 border-r-2 border-transparent"
          activeClassName="tab--active border-pale-blue"
          {...(tab.isActive ? { isActive: tab.isActive } : {})}
        >
          {tab.label}
        </NavLink>
      ))}
    </div>
  );
};

export default Tabs;
