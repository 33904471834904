import React, { useState } from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { format } from "date-fns";
import { useUserState, useAuthState, useAuthDispatch } from "context";
import { apiFetch, EXPORT_DATE_FORMATS } from "utils";
import Button from "components/Button";
import Error from "components/Error";
import FormField from "components/FormField";
import * as Yup from "yup";
import { endOfMonth } from "date-fns";
import {
  dataEntryHeaders,
  generateExport,
  transformCsvData,
  serviceHeaders,
  transformExportDates,
} from "./utils";
import PDFExport from "./PDFExport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import MultiDatePickerField from "components/MultiDatePickerField";
import PDFExportServices from "./PDFExportServices";

const ExportSchema = Yup.object().shape({
  location: Yup.string(),
  dateFormat: Yup.string().required("Select an export date format"),
  date: Yup.array().min(1, "too short").required("Select an export date"),
});

const DataEntryExport = ({
  headingTag = "h1",
  subheadingTag = "h2",
  title,
  action,
}) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { orgId, organizationId } = useParams();
  const { organization } = useUserState();
  const { isAdmin } = useUserState();
  var newOrgId = orgId;
  if (organization) newOrgId = organization.id;

  if (isAdmin && organizationId) newOrgId = organizationId;

  // The organization ID we want to use is the orgId in the route if defined
  // otherwise use the user's organization's id

  const [exportData, setExportData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [newOrg, setnewOrg] = useState({});
  const [dates, setDates] = useState([]);
  // Allow the user to change the heading level, since this component is used both as a top-level and nested page
  const HeadingTag = headingTag;
  const SubheadingTag = subheadingTag;

  // Get the locations for the organizationId
  const {
    data: locations = [],
    isLoading: isLoadingOrg,
    error: errorOrg,
  } = useQuery(["organizationLocations", newOrgId], async () => {
    const response = await apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/organizations/${newOrgId}?include=locations`,
    });
    if (!organization) setnewOrg(response.data);
    return response.included.filter((inc) => inc.type === "locations");
  });

  // API requests to get the CSV data
  const {
    mutate: getData,
    isSuccess,
    isLoading,
    error,
  } = useMutation(
    // Values from formik
    (values) =>
      generateExport({
        formValues: values,
        organizationId: newOrgId,
        token,
        dispatch,
        action,
      }),
    {
      onSuccess: (response) => {
        var res = response;
        setExportData(transformCsvData(response));
        if (action === "data-entry" || action === "services") {
          setPdfData(res);
        }
      },
    }
  );

  const getHeaders = () =>
    action === "data-entry" ? dataEntryHeaders : serviceHeaders;

  return (
    <>
      <HeadingTag>{title}</HeadingTag>

      <div className="sac-border bg-super-light-gray">
        <SubheadingTag>Choose a Month or Year to export.</SubheadingTag>

        <div className="bg-white rounded p-6 border">
          <Formik
            initialValues={{ location: " ", dateFormat: "", date: [] }}
            validationSchema={ExportSchema}
            onSubmit={getData}
          >
            {({ isValid, dirty, values: { dateFormat } }) => (
              <Form>
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    <FormField
                      as="select"
                      name="location"
                      label="Location"
                      disabled={isLoadingOrg}
                      required
                    >
                      <option value=" ">All Locations</option>
                      {locations.map((loc) => (
                        <option value={loc.id} key={loc.id}>
                          {loc.attributes.name}
                        </option>
                      ))}
                    </FormField>

                    {isLoadingOrg && (
                      <div className="text-sm italic mt-1">
                        Loading locations&hellip;
                      </div>
                    )}

                    {errorOrg && !isLoadingOrg && (
                      <div className="text-sm text-red mt-1">
                        Unable to load the locations
                      </div>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6 mb-6">
                  <FormField
                    as="select"
                    name="dateFormat"
                    className="form-select"
                    label="Export Date Format"
                    required
                  >
                    <option disabled value="" hidden>
                      -- Select Type of Export --
                    </option>
                    <option value={EXPORT_DATE_FORMATS.MONTH}>
                      Export a Month of Data
                    </option>
                    <option value={EXPORT_DATE_FORMATS.YEAR}>
                      Export a Year of Data
                    </option>
                  </FormField>

                  {dateFormat && (
                    <MultiDatePickerField
                      onlyMonthPicker={dateFormat === EXPORT_DATE_FORMATS.MONTH}
                      onlyYearPicker={dateFormat !== EXPORT_DATE_FORMATS.MONTH}
                      multiple
                      sort
                      plugins={[<DatePanel />]}
                      required
                      name="date"
                      label={
                        dateFormat === EXPORT_DATE_FORMATS.MONTH
                          ? "Export Month"
                          : "Export Year"
                      }
                      dateFormat={dateFormat}
                      maxDate={endOfMonth(new Date())}
                      setDates={setDates}
                    />
                  )}
                </div>

                <div className="flex justify-end">
                  <Button
                    type="submit"
                    disabled={isLoading || isLoadingOrg || !isValid || !dirty}
                    isLoading={isLoading}
                    loadingText={<>Generating Report&hellip;</>}
                  >
                    Generate Report
                  </Button>
                </div>
                {isSuccess && (
                  <>
                    <hr className="my-8" />
                    {action === "data-entry" && (
                      <PDFDownloadLink
                        document={
                          <PDFExport
                            data={pdfData}
                            action={action}
                            orgName={
                              organization
                                ? organization.attributes.name
                                : newOrg.attributes.name
                            }
                            timeframe={transformExportDates(dates)}
                          />
                        }
                        fileName={`sac-${action}_${format(
                          new Date(),
                          "yyyy-MM-dd"
                        )}.pdf`}
                        style={{ marginBottom: 20 }}
                        className="btn btn-primary btn-full"
                      >
                        Download Export PDF
                      </PDFDownloadLink>
                    )}
                    {action === "services" && (
                      <PDFDownloadLink
                        document={
                          <PDFExportServices
                            data={pdfData}
                            action={action}
                            orgName={
                              organization
                                ? organization.attributes.name
                                : newOrg.attributes.name
                            }
                            timeframe={transformExportDates(dates)}
                          />
                        }
                        fileName={`sac-${action}_${format(
                          new Date(),
                          "yyyy-MM-dd"
                        )}.pdf`}
                        style={{ marginBottom: 20 }}
                        className="btn btn-primary btn-full"
                      >
                        Download Export PDF
                      </PDFDownloadLink>
                    )}
                    {/* TODO: Add location/org to the file name? */}
                    <CSVLink
                      data={exportData}
                      headers={getHeaders()}
                      filename={`sac-${action}_${format(
                        new Date(),
                        "yyyy-MM-dd"
                      )}.csv`}
                      className="btn btn-primary btn-full"
                    >
                      Download Export CSV
                    </CSVLink>
                  </>
                )}

                {error && (
                  <Error className="mt-8">
                    Unable to generate the CSV and PDF
                  </Error>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

DataEntryExport.propTypes = {
  headingTag: PropTypes.string,
  subheadingTag: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.string.isRequired,
};

DataEntryExport.defaultProps = {
  headingTag: "h1",
  subheadingTag: "h2",
  title: "Data Entry Export",
};

export default DataEntryExport;
