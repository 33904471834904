import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useUserState } from "context";
import { ReactComponent as HomeIcon } from "images/home_icon.svg";
import { ReactComponent as DataIcon } from "images/data_icon.svg";
import { ReactComponent as ServicesIcon } from "images/services_icon.svg";
import { ReactComponent as CoalitionIcon } from "images/coalitions_icon.svg";
import { ReactComponent as UsersIcon } from "images/users_icon.svg";
import { ReactComponent as OrganizationsIcon } from "images/organizations_icon.svg";
import { ReactComponent as NationalDataIcon } from "images/national_data_icon.svg";
import { ReactComponent as ResourceCenterIcon } from "images/resource_center_icon.svg";
import { ReactComponent as DashboardsIcon } from "images/dashboards_icon.svg";

const Navigation = () => {
  const { isAdmin, organization, coalitions, isOrgAdmin } = useUserState();
  const [hasOrg, setHasOrg] = useState(false);

  useEffect(() => {
    setHasOrg(!!organization);
  }, [organization]);

  return (
    <nav>
      <ul className="primary-navigation">
        {/* Dashboard */}
        {(isAdmin || hasOrg) && (
          <li>
            <NavLink to="/" exact>
              <HomeIcon />
              Home
            </NavLink>
          </li>
        )}

        {/* Data Portal */}
        {hasOrg && (
          <li>
            <NavLink to="/data-entry">
              <DataIcon />
              Intake/Outcome Data
            </NavLink>
            <ul className={`sub-navigation`}>
              {isOrgAdmin ? (
                <li>
                  <NavLink to="/data-entry/upload">Upload CSV</NavLink>
                </li>
              ) : null}
              <li>
                <NavLink to="/data-entry/export">Export Data</NavLink>
              </li>
            </ul>
          </li>
        )}

        {hasOrg && (
          <li>
            <NavLink to="/services">
              <ServicesIcon />
              Services Data
            </NavLink>
            <ul className={`sub-navigation`}>
              {isOrgAdmin ? (
                <li>
                  <NavLink to="/services/upload">Upload CSV</NavLink>
                </li>
              ) : null}
              <li>
                <NavLink to="/services/export">Export Data</NavLink>
              </li>
            </ul>
          </li>
        )}

        {/* Organizations Management */}
        {!isAdmin && (
          <li>
            <NavLink to="/all-organizations">
              <OrganizationsIcon />
              All Organizations
            </NavLink>
          </li>
        )}

        {/* Organizations Management */}
        {isAdmin && (
          <li>
            <NavLink to="/organizations">
              <OrganizationsIcon />
              Organizations
            </NavLink>
          </li>
        )}

        {/* Coalition Builder */}
        <li>
          <NavLink
            to="/coalitions"
            data-tip
            data-for="coalitions"
            className="flex justify-between"
          >
            <div className="flex items-center">
              <CoalitionIcon />
              Coalitions
            </div>
          </NavLink>
          <ul className="sub-navigation">
            <li>
              <NavLink to="/coalitions" exact>
                View Coalitions
              </NavLink>
            </li>
            {!!Object.keys(coalitions).length && (
              <li>
                <NavLink to="/coalitions/mine">My Coalitions</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/coalitions/add">Add Coalition</NavLink>
            </li>
          </ul>
        </li>

        {/* Users Management */}
        {isAdmin && (
          <li>
            <NavLink to="/users">
              <UsersIcon />
              Users
            </NavLink>
          </li>
        )}

        {isAdmin && (
          <li>
            <NavLink to="/aggregation">
              <OrganizationsIcon />
              Aggregation
            </NavLink>
          </li>
        )}

        {/* Dashboards */}

        {hasOrg && (
          <li>
            <NavLink to="/dashboards" data-tip data-for="dashboards">
              <DashboardsIcon />
              Dashboards
            </NavLink>

            <ul className="sub-navigation">
              <li>
                <NavLink to="/dashboards/comparison">Comparison</NavLink>
              </li>
              <li>
                <NavLink to="/dashboards/analytics-and-forecast">
                  Analytics &amp; Forecast
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboards/rehome">Rehome</NavLink>
              </li>
              <li>
                <NavLink to="/dashboards/community-services-totals">
                  Community Services Totals
                </NavLink>
              </li>
              <li>
                <NavLink to="/dashboards/intake-outcome-totals">
                  Intake and Outcome Totals
                </NavLink>
              </li>
            </ul>
          </li>
        )}

        {/* National Data */}
        <li>
          <a
            href="https://www.shelteranimalscount.org/data-dashboards"
            target="_blank"
            rel="noreferrer"
          >
            <NationalDataIcon />
            National Data
          </a>
        </li>

        {/* Resource Center */}
        <li>
          <NavLink to="/resource-center" data-tip data-for="resource-center">
            <ResourceCenterIcon />
            Resource Center
          </NavLink>

          <ul className="sub-navigation">
            <li>
              <NavLink to="/resource-center/marketing-resources">
                Marketing Resources
              </NavLink>
            </li>
            <li>
              <NavLink to="/resource-center/video-tutorials">
                Video Tutorials
              </NavLink>
            </li>
            <li>
              <a
                href="https://www.shelteranimalscount.org/animal-welfare-glossary"
                target="_blank"
                rel="noreferrer"
              >
                Animal Welfare Glossary
              </a>
            </li>
            <li>
              <a
                href="https://www.shelteranimalscount.org/contributor-corner"
                target="_blank"
                rel="noreferrer"
              >
                FAQs
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
