import { cloneDeep } from "lodash";

const dataEntryTypes = {
  counts: {
    tempIdKey: "acID",
    removedSpeciesKey: "countId",
  },
  intakes: {
    tempIdKey: "aiID",
    removedSpeciesKey: "intakeId",
  },
  outcomes: {
    tempIdKey: "aoID",
    removedSpeciesKey: "outcomeId",
  },
};

const payloadInitValue = {
  relationships: {
    animal_counts: {
      data: [],
    },
    animal_intakes: {
      data: [],
    },
    animal_outcomes: {
      data: [],
    },
  },
  included: [],
};

export const generatePayload = ({
  addedSpecies,
  removedSpecies,
  locationId,
  year,
  month,
}) => {
  const changedSpecies = [];
  addedSpecies.forEach((speciesKey) =>
    changedSpecies.push({ speciesKey, method: "create" })
  );
  removedSpecies.forEach((species) =>
    changedSpecies.push({
      speciesKey: species.key,
      ...species,
      method: "destroy",
    })
  );

  // Loop over ADDED species and build relationships object & Included array
  return changedSpecies.reduce((payloadMap, species) => {
    const speciesKey = species.speciesKey;

    // Set up unique temp-id keys
    const ids = {
      [dataEntryTypes.counts.tempIdKey]: `ac_${speciesKey}`,
      [dataEntryTypes.intakes.tempIdKey]: `ai_${speciesKey}`,
      [dataEntryTypes.outcomes.tempIdKey]: `ao_${speciesKey}`,
    };

    const includedAttributes = {
      location_id: locationId,
      species: speciesKey,
      month_of_record: month,
      year_of_record: year,
    };

    Object.keys(dataEntryTypes).forEach((key) => {
      const tempIdKey = dataEntryTypes[key].tempIdKey;
      const removedSpeciesKey = dataEntryTypes[key].removedSpeciesKey;

      if (species.method === "create") {
        payloadMap.relationships[`animal_${key}`].data.push({
          type: `animal_${key}`,
          method: "create",
          "temp-id": ids[tempIdKey],
        });

        payloadMap.included.push({
          type: `animal_${key}`,
          "temp-id": ids[tempIdKey],
          attributes: includedAttributes,
        });
      } else {
        if (species[removedSpeciesKey]) {
          payloadMap.relationships[`animal_${key}`].data.push({
            type: `animal_${key}`,
            method: "destroy",
            id: species[removedSpeciesKey],
          });
        }
      }
    });

    return payloadMap;
  }, cloneDeep(payloadInitValue));
};

export const updateSpeciesCache = ({
  oldData = {},
  response,
  changedSpecies,
}) => {
  let newData = cloneDeep(oldData);

  // Add new species to the cached data
  Object.assign(newData, {
    ...(!!changedSpecies.added.length
      ? addSpeciesToCache({ oldData, response })
      : {}),
  });

  // Remove species from the cached data
  if (!!Object.keys(changedSpecies.removed).length) {
    newData = removeSpeciesFromCache({
      response: newData,
      removedSpecies: changedSpecies.removed,
    });
  }

  return newData;
};

export const addSpeciesToCache = ({ oldData = {}, response }) => {
  const newData = cloneDeep(oldData);

  const typeMap = {
    animal_counts: "counts",
    animal_intakes: "intakes",
    animal_outcomes: "outcomes",
  };

  response.included.forEach((incRecord) => {
    const type = incRecord.type;
    const cacheType = typeMap[type];
    newData[cacheType].data.push(incRecord);
  });

  return newData;
};

export const removeSpeciesFromCache = ({ response, removedSpecies }) => {
  const newData = cloneDeep(response);

  removedSpecies.forEach((removed) => {
    const countIndex = newData.counts.data.findIndex((countRecord) => {
      return countRecord.id === removed.countId;
    });
    if (countIndex > -1) newData.counts.data.splice(countIndex, 1);

    const intakeIndex = newData.intakes.data.findIndex((intakeRecord) => {
      return intakeRecord.id === removed.intakeId;
    });
    if (intakeIndex > -1) newData.intakes.data.splice(intakeIndex, 1);

    const outcomeIndex = newData.outcomes.data.findIndex((outcomeRecord) => {
      return outcomeRecord.id === removed.outcomeId;
    });
    if (outcomeIndex > -1) newData.outcomes.data.splice(outcomeIndex, 1);
  });

  return newData;
};
