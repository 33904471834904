import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL, formatDateTime } from "utils";
import { useAuthState } from "context";
import Table from "components/Table";
import LoadingIndicator from "components/LoadingIndicator";

const EntryLogs = () => {
  const { token } = useAuthState();
  const { orgId } = useParams();
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    const { accessToken, client, expiry, uid } = token;
    const response = await fetch(
      `${API_URL}/api/v1/entry_logs?page=1&orgId=${orgId}`,
      {
        method: "get",
        headers: {
          "access-token": accessToken,
          "token-type": "Bearer",
          "Content-Type": "application/json",
          client,
          expiry,
          uid,
        },
      }
    );

    const responseJson = await response.json();
    const { data, error } = responseJson;
    setResponse(data);
    setError(error);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <LoadingIndicator inline content={<>Loading logs&hellip;</>} />;
  }

  const columns = [
    {
      accessor: "created_at",
      Header: "Created At",
      Cell: ({ cell }) => {
        if (!cell.value) return "--";
        return formatDateTime(cell.value);
      },
    },
    {
      accessor: "month",
      Header: "Month of Record",
    },
    {
      accessor: "year",
      Header: "Year of Record",
    },
    {
      accessor: "updated_at",
      Header: "Updated At",
      Cell: ({ cell }) => {
        if (!cell.value) return "--";
        return formatDateTime(cell.value);
      },
    },
    {
      accessor: "channel",
      Header: "Data Entry Type",
      Cell: ({ cell }) => {
        if (!cell.value) return "--";
        return cell.value.toUpperCase();
      },
    },
    {
      accessor: "location_id",
      Header: "Location Id",
    },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <Table
        data={response || []}
        columns={columns}
        noResultsText="No Logs found"
        isLoading={isLoading}
        error={error}
      />
    </div>
  );
};

export default EntryLogs;
