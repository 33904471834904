// TODO: Uncomment when the software provider import is an option
// const dataSoftwareIcon = (
//   <svg
//     width="40"
//     height="40"
//     viewBox="0 0 40 40"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <g clipPath="url(#clip0)">
//       <path
//         d="M38.6274 15.4812H35.6327C35.3045 14.2518 34.7712 13.0634 34.1148 11.9159L36.248 9.82586C36.7813 9.29311 36.7813 8.47349 36.248 7.94074L32.1866 3.88363C31.6533 3.35088 30.8328 3.35088 30.2995 3.88363L28.1663 6.01464C27.0176 5.35894 25.8279 4.86717 24.5972 4.53933V1.54772C24.5972 0.851042 24.0228 0.236328 23.2844 0.236328H17.5821C16.8436 0.236328 16.2693 0.851042 16.2693 1.54772V4.58031C15.0386 4.90815 13.8489 5.39992 12.7002 6.05562L10.608 3.92461C10.0746 3.43284 9.25416 3.43284 8.72084 3.92461L4.65946 7.98172C4.12614 8.51447 4.12614 9.33409 4.65946 9.86685L6.79271 11.9979C6.13632 13.1453 5.64403 14.3338 5.31584 15.5632H2.28006C1.58265 15.5632 0.967285 16.1369 0.967285 16.8746V22.6119C0.967285 23.3496 1.58265 23.9233 2.28006 23.9233H5.31584C5.47994 24.497 5.64403 25.0298 5.84915 25.5625L11.8797 19.5793C11.1413 18.4728 10.9361 17.0795 11.3874 15.7681C11.5925 15.1534 11.9617 14.5387 12.413 14.0879C13.0694 13.4322 13.9719 12.9404 14.9155 12.8585L23.5716 11.711C23.7357 11.67 23.9408 11.67 24.1459 11.67C25.2946 11.67 26.4022 12.1208 27.2227 12.9404C28.1663 13.883 28.6175 15.2353 28.4534 16.5877L27.3048 25.2347C27.0586 26.8739 25.951 28.2263 24.3921 28.7591C23.9408 28.923 23.4485 28.964 22.9972 28.964C22.1357 28.964 21.3152 28.7181 20.6178 28.2673L14.5873 34.2915C15.1206 34.4964 15.6949 34.7013 16.2693 34.8652V37.8568C16.2693 38.5945 16.8436 39.1682 17.5821 39.1682H23.3254C24.0639 39.1682 24.6382 38.5945 24.6382 37.8568V34.8652C25.8689 34.5374 27.0586 34.0046 28.2073 33.3489L30.2995 35.4799C30.8328 36.0127 31.6533 36.0127 32.1866 35.4799L36.248 31.4228C36.7813 30.8901 36.7813 30.0704 36.248 29.5377L34.1148 27.4067C34.7712 26.2592 35.2634 25.0708 35.5916 23.8413H38.5864C39.3248 23.8413 39.8992 23.2676 39.8992 22.53V16.8336C39.9402 16.0959 39.3659 15.4812 38.6274 15.4812Z"
//         className="fill-current"
//       />
//       <path
//         d="M22.2179 25.4403C22.5051 25.7271 22.9563 25.8501 23.3256 25.6861C23.7358 25.5632 23.9819 25.1944 24.064 24.7846L25.2127 16.1376C25.2537 15.8097 25.1306 15.4819 24.8845 15.236C24.6383 14.9901 24.3101 14.8672 23.9819 14.9082L15.3259 16.0556C15.0797 16.0966 14.8746 16.1786 14.7105 16.3835C14.5874 16.5064 14.5054 16.6294 14.4644 16.7933C14.3413 17.2031 14.4233 17.6129 14.7105 17.8998L16.3925 19.58L5.27495 30.7678C4.94676 31.1366 4.94676 31.6693 5.27495 32.0382L8.10562 34.8659C8.43381 35.1937 9.00815 35.1937 9.37736 34.8659L20.5359 23.7191L22.2179 25.4403Z"
//         className="fill-current"
//       />
//     </g>
//     <defs>
//       <clipPath id="clip0">
//         <rect
//           width="38.9319"
//           height="38.9319"
//           fill="white"
//           transform="translate(0.967285 0.236328)"
//         />
//       </clipPath>
//     </defs>
//   </svg>
// );

const csvIcon = (
  <svg
    width="33"
    height="40"
    viewBox="0 0 33 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8721 0.582031V8.47696H31.7874L23.8721 0.582031Z"
      className="fill-current"
    />
    <path
      d="M32.0844 29.6449V11.6346H23.8723C22.1408 11.6346 20.7062 10.2037 20.7062 8.47666V0.236328H0.324219V29.6449H32.0844ZM21.844 16.0262C21.844 16.0262 23.526 21.2566 23.6249 21.6513C23.7734 21.2566 25.5543 16.0262 25.5543 16.0262H27.9289L24.5649 25.4014H22.5861L19.5189 16.0262C19.4694 16.0262 21.844 16.0262 21.844 16.0262ZM15.6107 21.602C14.9676 21.3553 13.0382 20.7138 13.0382 18.7401C13.0382 17.1117 14.4234 15.9275 16.3033 15.9275C17.1937 15.9275 18.0842 16.1249 18.8263 16.6183L18.1832 18.592C17.639 18.1973 16.9464 17.9506 16.3033 17.9506C15.5612 17.9506 15.3139 18.296 15.3139 18.592C15.3139 18.9374 15.4128 19.1842 16.6001 19.6283C17.7379 20.0723 19.0736 20.8125 19.0736 22.5889C19.0736 24.0692 17.9853 25.5495 15.6107 25.5495C14.6213 25.5495 13.6318 25.2534 12.8403 24.7106L13.434 22.6875C14.0771 23.1316 14.8686 23.5264 15.6602 23.5264C16.0064 23.5264 16.798 23.4277 16.798 22.6875C16.798 22.2928 16.699 21.9967 15.6107 21.602C14.9676 21.3553 16.699 21.9967 15.6107 21.602C14.9676 21.3553 16.699 21.9967 15.6107 21.602C14.9676 21.3553 16.699 21.9967 15.6107 21.602C14.9676 21.3553 16.699 21.9967 15.6107 21.602ZM9.42684 15.9275C10.3668 15.9275 11.2573 16.0755 12.0488 16.569L11.4551 18.5427C10.812 18.1973 10.1689 17.9506 9.42684 17.9506C7.74483 17.9506 6.80489 18.9868 6.80489 20.7631C6.80489 22.4902 7.74483 23.477 9.37737 23.477C10.1194 23.477 10.8615 23.2303 11.5046 22.8849L11.9993 24.8586C11.1088 25.3521 10.1194 25.5001 9.13001 25.5001C6.31018 25.5001 4.47976 23.6251 4.47976 20.7631C4.52923 17.9012 6.50806 15.9275 9.42684 15.9275Z"
      className="fill-current"
    />
    <path
      d="M0.324219 32.8535V39.1695H32.0844V32.8535H0.324219Z"
      className="fill-current"
    />
  </svg>
);

const manuallyIcon = (
  <svg
    width="35"
    height="41"
    viewBox="0 0 35 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.3434 23.0451C34.3434 32.5205 26.7927 40.1693 17.4388 40.1693C8.08492 40.1693 0.53418 32.5205 0.53418 23.0451V10.202C0.53418 8.77498 1.66116 7.63337 3.06987 7.63337C4.47859 7.63337 5.60557 8.77498 5.60557 10.202V17.3941H7.29603V5.63555C7.29603 4.20853 8.42301 3.06692 9.83173 3.06692C11.2404 3.06692 12.3674 4.20853 12.3674 5.63555V17.3941H14.0579V2.78152C14.0579 1.3545 15.1849 0.212891 16.5936 0.212891C18.0023 0.212891 19.1293 1.3545 19.1293 2.78152V17.3941H20.8197V5.63555C20.8197 4.20853 21.9467 3.06692 23.3554 3.06692C24.7642 3.06692 25.8911 4.20853 25.8911 5.63555C25.8911 30.9222 25.8911 16.7092 25.8911 23.0451H28.6522V17.5083C28.6522 14.3689 31.1879 11.8002 34.2871 11.8002L34.3434 23.0451Z"
      className="fill-current"
    />
  </svg>
);

export const dataEntryOptions = {
  DATA_SOFTWARE: "data_software",
  CSV: "csv",
  MANUAL: "manually",
};

export const dataEntryOptionsMap = {
  // [dataEntryOptions.DATA_SOFTWARE]: {
  //   icon: dataSoftwareIcon,
  //   heading: "Data Software",
  //   content:
  //     "My shelter/rescue uses data tracking software and I would like to upload data from there.",
  // },
  [dataEntryOptions.CSV]: {
    icon: csvIcon,
    heading: "Upload CSV",
    content:
      "My organization uses data tracking software and/or a spreadsheet and I would like to upload a CSV.",
  },
  [dataEntryOptions.MANUAL]: {
    icon: manuallyIcon,
    heading: "Manually",
    content:
      "My organization does not use data tracking software and I will upload data manually.",
  },
};
