import React from "react";

export const data = [
  {
    org: "Shelter Animals Count",
    month: "September",
    year: "2020",
    status: "Incomplete",
    lastModified: new Date(2020, 8, 17).toLocaleDateString(),
    actions: 1,
  },
  {
    org: "Software for Good",
    month: "February",
    year: "2020",
    status: "Complete",
    lastModified: new Date(2020, 1, 28).toLocaleDateString(),
    actions: 2,
  },
  {
    org: "Humane Society of Golden Valley",
    month: "December",
    year: "2019",
    status: "Complete",
    lastModified: new Date(2019, 11, 10).toLocaleDateString(),
    actions: 3,
  },
];

export const columns = [
  {
    Header: "Organization",
    accessor: "org", // accessor is the "key" in the data
  },
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Year",
    accessor: "year",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Last Modified",
    accessor: "lastModified",
  },
  {
    accessor: "actions",
    Cell: ({ value: id }) => (
      <>
        <a
          className="px-4 underline text-blue-400 hover:text-blue-800"
          href={`/edit?id=${id}`}
        >
          Edit
        </a>
        <a
          className="px-4 underline text-blue-400 hover:text-blue-800"
          href={`/delete?id=${id}`}
        >
          Delete
        </a>
      </>
    ),
  },
];
