import { cloneDeep } from "lodash";
import { titleCase, getOrgDataStatus } from "utils";

export const COALITION_MEMBERSHIP_STATUSES = {
  ACTIVE: "Active",
  ORG_INVITE_PENDING: "Organization Invite Pending",
  COALITION_APPROVAL_PENDING: "Coalition Approval Pending",
  ORG_DECLINED: "Organization Declined",
  COALITION_DECLINED: "Coalition Declined",
  COALITION_REINVITED: "Coalition Invite Resent",
  COALITION_PENDING: "All Pending",
  COALITION_INVITE_PENDING: "Coalition Invite Accept Pending",
};

/**
 * Transforms API response by adding relevant included records to each data record
 * @param {Object} params
 * @param {Object} params.response
 * @param {string} params.coalitionId
 * @return Object - clone of the response object supplemented with the included records
 */
export const mergeIncludesIntoData = ({ response, coalitionId }) => {
  const resClone = cloneDeep(response);
  resClone.data.forEach((org) => {
    const incStatusRecords = (resClone.included || []).filter((inc) => {
      return (
        inc.type === "organization_data_entry_statuses" &&
        String(inc.attributes.organizationId) === org.id
      );
    });

    const membership = (resClone.included || []).find((inc) => {
      return (
        inc.type === "coalition_memberships" &&
        String(inc.attributes.organizationId) === org.id &&
        String(inc.attributes.coalitionId) === coalitionId
      );
    });

    if (!!incStatusRecords.length) {
      org.attributes.dataStatus = getOrgDataStatus(incStatusRecords);
    }

    if (!!membership) {
      org.membership = {
        id: membership.id,
        status: membership.attributes.status,
        statusActor: membership.attributes.statusActor,
        updatedAt: new Date(membership.attributes.updatedAt),
        statusUpdatedAt: new Date(membership.attributes.statusUpdatedAt),
      };
    }
  });
  return resClone;
};

/**
 * Converts object with status and statusActor properties to a single line of text
 * @param {Object} membership
 * @return status - String
 */
export const getOrgMembershipStatus = (membership) => {
  if (!membership || !membership.status) return "";
  if (
    membership.status === "requested" &&
    membership.statusActor === "Organization"
  )
    return COALITION_MEMBERSHIP_STATUSES.COALITION_APPROVAL_PENDING;
  if (
    membership.status === "requested" &&
    membership.statusActor === "Coalition"
  )
    return COALITION_MEMBERSHIP_STATUSES.ORG_INVITE_PENDING;
  if (
    membership.status === "rejected" &&
    membership.statusActor === "Organization"
  )
    return COALITION_MEMBERSHIP_STATUSES.ORG_DECLINED;
  if (
    membership.status === "rejected" &&
    membership.statusActor === "Coalition"
  )
    return COALITION_MEMBERSHIP_STATUSES.COALITION_DECLINED;
  if (
    membership.status === "reminded" &&
    membership.statusActor === "Coalition"
  )
    return COALITION_MEMBERSHIP_STATUSES.COALITION_INVITE_PENDING;
  return titleCase(membership.status);
};

/**
 * Converts status filter to object with status and statusActor properties for API consumption
 * @param {string} status
 * @return filter object - { status: "", statusActor: "" }
 */
export const getMembershipFilter = (status) => {
  switch (status) {
    case COALITION_MEMBERSHIP_STATUSES.ORG_INVITE_PENDING:
      return {
        status: "requested",
        statusActor: "Coalition",
      };
    case COALITION_MEMBERSHIP_STATUSES.COALITION_APPROVAL_PENDING:
      return {
        status: "requested",
        statusActor: "Organization",
      };
    case COALITION_MEMBERSHIP_STATUSES.ORG_DECLINED:
      return {
        status: "rejected",
        statusActor: "Organization",
      };
    case COALITION_MEMBERSHIP_STATUSES.COALITION_DECLINED:
      return {
        status: "rejected",
        statusActor: "Coalition",
      };
    case COALITION_MEMBERSHIP_STATUSES.ACTIVE:
      return {
        status: "active",
      };
    case COALITION_MEMBERSHIP_STATUSES.COALITION_REINVITED:
      return {
        status: "reminded",
        statusActor: "Coalition",
      };
    case COALITION_MEMBERSHIP_STATUSES.COALITION_PENDING:
      return {
        status: "requested,invited",
      };
    default:
      return {};
  }
};

/**
 * Constructs API endpoint for getting list of a coalition's contributors
 * @param {Object} params
 * @param {string} params.coalitionId
 * @param {string} params.page
 * @param {string} params.search
 * @param {string} params.status
 * @return endpoint - String
 */
export const constructEndpoint = ({
  coalitionId,
  page = 1,
  search,
  status,
  organizationType,
  state,
}) => {
  const membershipFilter = getMembershipFilter(status);
  let noStatusActorFilter = true;
  // if a status has been picked from the dropdown, filter by it
  if (membershipFilter.status && membershipFilter.statusActor) {
    noStatusActorFilter = false;
  } else if (status && !membershipFilter.status) {
    // if a status hasn't been picked from the dropdown, show that status from both organizations and coalitions
    membershipFilter.status = status;
  }

  return `/api/v1/organizations?page[number]=${page}&filter[status][not_eq]=closed&filter[coalition_id][eq]=${coalitionId}&filter[coalition_memberships.coalition_id][eq]=${coalitionId}&include=data_entry_statuses,coalition_memberships&extra_fields[organizations]=locations_us_states&stats[total]=count&sort=name${
    search ? `&filter[search]=${search}` : ""
  }${
    organizationType ? `&filter[organization_type][eq]=${organizationType}` : ""
  }${state ? `&filter[locations_us_state]=${state}` : ""}${
    membershipFilter.status
      ? `&filter[coalition_membership_status][eq]=${membershipFilter.status}`
      : ""
  }${
    membershipFilter.statusActor && !noStatusActorFilter
      ? `&filter[coalition_membership_status_actor][eq]=${membershipFilter.statusActor}`
      : ""
  }`;
};
