import React, { useEffect, useState, useRef } from "react";
import { async, hash } from "rsvp";
import {
  AnimalCounts,
  AnimalIntakes,
  AnimalOutcomes,
  AnimalEntryDetails,
} from "./components";
import LoadingIndicator from "components/LoadingIndicator";
import Error from "components/Error";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import {
  apiFetch,
  API_URL,
  formatUiErrors,
  useHandleRequestStates,
} from "utils";
import {
  generatePayload,
  updateSpeciesCache,
} from "./components/AnimalEntryDetails/utils";
import Button from "components/Button";

const DataEntry = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { year, month, location, organizationId } = useParams();
  const queryClient = useQueryClient();
  const [animalSpecies, setAnimalSpecies] = useState(["canine", "feline"]);
  const [selectedSpecies, setSelectedSpecies] = useState("");
  const [recalculate, setRecalculate] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [submittedAll, setSubmittedAll] = useState(false);
  const [speciesMonthEndingCount, setSpeciesMonthEndingCount] = useState({});
  const [newOrganization, setOrganization] = useState({});
  const [newlocation, setNewLocation] = useState({});

  const animalIntakeForm = useRef(null);
  const animalOutcomeForm = useRef([]);
  const animalCountForm = useRef(null);

  const { attributes, isAdmin, organization, isOrgAdmin } = useUserState();
  var locationId = attributes.locationId;
  if (isAdmin) locationId = location;

  const getOrg = async () => {
    if (organizationId) {
      const response = await apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/organizations/${organizationId}`,
      });
      setOrganization(response.data);
    }
    const response2 = await apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/locations/${locationId}`,
    });
    setNewLocation(response2.data);
  };

  useEffect(() => {
    getOrg();
  }, []);

  const { handleSuccess } = useHandleRequestStates();

  const generateDataEntries = ({
    locationId,
    year,
    month,
    token,
    dispatch,
  }) => {
    const dateFilters = `filter[year_of_record][eq]=${year}&filter[month_of_record][eq]=${month}`;
    const statusDateFilters = `filter[year][eq]=${year}&filter[month][eq]=${month}`;
    const locationFilter = `&filter[location_id][eq]=${locationId}`;

    return hash({
      status: apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/data_entry_status?${statusDateFilters}${locationFilter}`,
      }),
      counts: apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_counts?${dateFilters}${locationFilter}`,
      }),
      intakes: apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_intakes?${dateFilters}${locationFilter}`,
      }),
      outcomes: apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_outcomes?${dateFilters}${locationFilter}`,
      }),
    });
  };

  const {
    mutate: setMatrixSpecies,
    isLoading: isLoadingTables,
    error: isTableError,
  } = useMutation(
    () => {
      const payload = generatePayload({
        addedSpecies: animalSpecies,
        removedSpecies: [],
        locationId,
        year,
        month,
      });
      return apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/locations/${locationId}`,
        method: "PATCH",
        body: {
          data: {
            id: locationId,
            type: "locations",
            attributes: {},
            relationships: payload.relationships,
          },
          included: payload.included,
        },
      });
    },
    {
      onSuccess: (response) => {
        return queryClient.setQueryData(
          ["dataEntry", `${locationId}_${month}/${year}`],
          (oldData) =>
            updateSpeciesCache({
              oldData,
              response,
              changedSpecies: { added: animalSpecies, removed: [] },
            })
        );
      },
    }
  );

  // Calculate number of species in care at the end of the month
  const calculateEndingCount = () => {
    const { data: intakes } = response.intakes;
    const { data: outcomes } = response.outcomes;
    const { data: counts } = response.counts;
    let intakeHash = {};
    let outcomeHash = {};
    intakes.forEach((intake) => {
      intakeHash[intake.attributes.species] =
        intake.attributes.totalLiveIntakeCount;
    });
    outcomes.forEach((outcome) => {
      outcomeHash[outcome.attributes.species] =
        outcome.attributes.totalOutcomesCount;
    });
    let countsHash = {};
    animalSpecies.forEach((species) => {
      counts.forEach((count) => {
        const endCount = count.attributes.endingAnimalCount;
        if (count.attributes.species === species) {
          const total =
            count.attributes.beginningAnimalCount +
            (intakeHash[species] - outcomeHash[species]);
          Object.assign(
            countsHash,
            endCount === 0 || !endCount
              ? {
                  [species]: total < 0 ? 0 : total,
                }
              : {
                  [species]: count.attributes.endingAnimalCount,
                }
          );
        }
      });
    });
    setSpeciesMonthEndingCount(countsHash);
  };
  // General Request
  const {
    isLoading,
    error,
    data: response,
    refetch,
  } = useQuery(
    ["dataEntry", `${locationId}_${month}/${year}`],
    () => generateDataEntries({ locationId, year, month, token, dispatch }),
    {
      onSuccess: (response) => {
        // IF Counts & Intakes & Outcomes have no data we need to set up our species
        if (
          !response.counts.data.length &&
          !response.intakes.data.length &&
          !response.outcomes.data.length
        ) {
          if (
            organizationId &&
            newOrganization &&
            newOrganization.attributes &&
            newOrganization.attributes.animalSpecies.length !== 0
          ) {
            setAnimalSpecies(newOrganization.attributes.animalSpecies);
          } else if (
            organization &&
            organization.attributes &&
            organization.attributes.animalSpecies.length !== 0
          ) {
            setAnimalSpecies(organization.attributes.animalSpecies);
          }
        } else {
          const dataToSpeciesArray = ["counts", "intakes", "outcomes"].reduce(
            (speciesArray, key) => {
              const subArray = response[key].data;

              subArray.forEach((d) => {
                const { attributes } = d;
                const { species } = attributes;

                if (speciesArray.indexOf(species) === -1) {
                  speciesArray.push(species);
                }
              });

              return speciesArray;
            },
            []
          );

          setAnimalSpecies(dataToSpeciesArray);
        }
      },
    }
  );

  useEffect(() => {
    if (!response) {
      return;
    }

    if (
      !response.counts.data.length &&
      !response.intakes.data.length &&
      !response.outcomes.data.length &&
      isOrgAdmin
    ) {
      setMatrixSpecies();
    } else {
      calculateEndingCount();
    }
  }, [response, setMatrixSpecies]);

  useEffect(refetch, [locationId, submittedAll]);
  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 1000);
  }, [isEditing]);

  const handleSubmit = async () => {
    await animalIntakeForm.current?.handleSubmit();
    await animalOutcomeForm.current?.liveOutcome.handleSubmit();
    await animalOutcomeForm.current?.otherOutcome.handleSubmit();
    await animalCountForm.current?.handleSubmit();
    setIsEditing(false);
  };

  const setSpecies = (species) => {
    if (species === selectedSpecies) {
      setSelectedSpecies("");
    } else {
      setSelectedSpecies(species);
    }
  };
  const autoRecalculate = (key, val) => {
    debugger;
    if (key == "endingAnimalCount") {
      const { values: intakes } = animalIntakeForm.current;
      var newIntakes = Object.keys(intakes)
        .filter(
          (a) =>
            ![
              "locationId",
              "species",
              "monthOfRecord",
              "yearOfRecord",
            ].includes(a)
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: intakes[key] });
        }, {});
      var newIntakesSum = Object.values(newIntakes)
        .filter((a) => a >= 0)
        .reduce((a, b) => a + b, 0);

      const { values: liveOutcomes } = animalOutcomeForm.current.liveOutcome;
      const { values: otherOutcomes } = animalOutcomeForm.current.otherOutcome;

      var newLiveOutCome = Object.keys(liveOutcomes)
        .filter(
          (a) =>
            ![
              "locationId",
              "species",
              "monthOfRecord",
              "yearOfRecord",
            ].includes(a)
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: liveOutcomes[key] });
        }, {});
      var newliveOutComeSum = Object.values(newLiveOutCome)
        .filter((a) => a >= 0)
        .reduce((a, b) => a + b, 0);

      var newotherOutcomes = Object.keys(otherOutcomes)
        .filter(
          (a) =>
            ![
              "locationId",
              "species",
              "monthOfRecord",
              "yearOfRecord",
            ].includes(a)
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: otherOutcomes[key] });
        }, {});
      var newotherOutcomesSum = Object.values(newotherOutcomes)
        .filter((a) => a >= 0)
        .reduce((a, b) => a + b, 0);

      var total = newliveOutComeSum + newotherOutcomesSum;

      val =
        animalCountForm.current.values.beginningAnimalCount +
        newIntakesSum -
        total;
    }

    animalCountForm.current.setFieldValue(key, val || 0);
  };
  const handleSubmitAll = () => {
    setSubmittedAll(!submittedAll);
    handleSuccess({
      message: "All data submitted.",
    });
  };

  const Content = () => {
    if (isLoading || isLoadingTables)
      return (
        <LoadingIndicator inline content={<>Loading data matrix&hellip;</>} />
      );

    if (error || isTableError)
      return <Error>{formatUiErrors(error || isTableError)}</Error>;

    if (newlocation.attributes?.status == "closed") {
      return (
        <div>
          <b>{newlocation.attributes?.name}</b> - location is closed by system
          admin. If you want to enter data for this location please contact
          Shelter Animals Count helpdesk, <a>info@shelteranimalscount.org</a>
        </div>
      );
    }

    return (
      <>
        {/* Entry Status at this level will only ever return 1 item so we just grab the 0 index */}
        <AnimalEntryDetails
          defaultSpecies={animalSpecies}
          entryStatus={response.status.data[0].attributes.status}
          selectedSpecies={selectedSpecies}
          setSpecies={setSpecies}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          handleSubmit={handleSubmit}
          location={newlocation}
          handleSubmitAll={handleSubmitAll}
        />
        <AnimalIntakes
          animalData={response.intakes.data}
          selectedSpecies={selectedSpecies}
          isEditing={isEditing}
          ref={animalIntakeForm}
        />
        <AnimalOutcomes
          animalData={response.outcomes.data}
          selectedSpecies={selectedSpecies}
          isEditing={isEditing}
          ref={animalOutcomeForm}
        />
        <AnimalCounts
          animalData={response.counts.data}
          selectedSpecies={selectedSpecies}
          isEditing={isEditing}
          ref={animalCountForm}
          recalculate={recalculate}
          setRecalculate={autoRecalculate}
          endingCounts={speciesMonthEndingCount}
        />
        {selectedSpecies && (
          <div className="sac-border flex gap-1 justify-end">
            <Button
              key="edit"
              disabled={isEditing}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
            <Button key="submit" disabled={!isEditing} onClick={handleSubmit}>
              Save
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <h1 className="mb-0">Enter Shelter Intake and Outcome Data</h1>
      <p className="mb-10">
        Please keep in mind that all data fields must be filled in, even if the
        entry is “0.” Records will be considered incomplete until all fields are
        populated with numeric values.
      </p>

      <Content />
    </div>
  );
};

export default DataEntry;
