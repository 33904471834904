import React from "react";
import { FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import { endOfMonth, setMonth, subYears } from "date-fns";
import { speciesMap, addNamespace } from "utils";
import FormField from "components/FormField";
import DatePickerField from "components/DatePickerField";
import SpeciesCheckbox from "components/SpeciesCheckbox";
import CountyDropdown from "components/CountyDropdown";
import StateDropdown from "components/StateDropdown";
import {
  OrganizationTypeTooltip,
  OrganizationEinTooltip,
  OrganizationAnnualIntakeTooltip,
  OrganizationAnnualBudgetTooltip,
  OrganizationBusinessNumberTooltip,
} from "components/Tooltips";

const urlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const defaultValues = {
  name: "",
  country: "United States",
  addressLine1: "",
  addressLine2: "",
  city: "",
  usState: "",
  provinceOrTerritory: "",
  zipCode: "",
  postalCode: "",
  usCounty: "",
  organizationType: "",
  websiteUrl: "",
  incorporatedOn: "",
  ein: "",
  businessNumber: "",
  referred: false,
  referringOrganizationName: "",
  animalSpecies: Object.keys(speciesMap).reduce((species, key) => {
    species[key] = false;
    return species;
  }, {}),
  annualIntake: "",
  annualOperatingBudget: "",
  shelterSoftware: "",
};

export const validationSchema = () => ({
  name: Yup.string().required("Name is required"),
  country: Yup.string().required("Country is required"),
  addressLine1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  usState: Yup.string().when("country", {
    is: "United States",
    then: Yup.string().required("State is required"),
  }),
  provinceOrTerritory: Yup.string().when("country", {
    is: "Canada",
    then: Yup.string().required("Province or territory is required"),
  }),
  zipCode: Yup.string().when("country", {
    is: "United States",
    then: Yup.string()
      .matches(/^(\d{5})(?:[-. ]?(\d{4}))?$/, "Invalid zip code")
      .required("Zip code is required"),
  }),
  postalCode: Yup.string().when("country", {
    is: "Canada",
    then: Yup.string()
      .matches(/^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/, "Invalid postal code")
      .required("Postal code is required"),
  }),
  usCounty: Yup.string().when("country", {
    is: "United States",
    then: Yup.string().required("County is required"),
  }),
  contactPhoneNumber: Yup.string().matches(
    /^\(?([2-9]\d\d)\)?[-. ]?(\d{3})[-. ]?(\d{4})$/,
    "Invalid phone number"
  ),
  organizationType: Yup.string().required("Select an organization type"),
  websiteUrl: Yup.string().matches(urlRegex, "Invalid URL"),
  incorporatedOn: Yup.string().required("Date is required"),
  ein: Yup.string().when("country", {
    is: "United States",
    then: Yup.string()
      .matches(/^\d{9}$/, "Invalid EIN")
      .required("EIN is required"),
  }),
  businessNumber: Yup.string().when("country", {
    is: "Canada",
    then: Yup.string()
      .matches(/^\d{9}$/, "Invalid business number")
      .required("Business number is required"),
  }),
  referral: Yup.boolean(),
  referringOrganizationName: Yup.string().when("referral", {
    is: true,
    then: Yup.string().required("Referring organization is required"),
  }),
  annualIntake: Yup.string().required("Animal intake is required"),
  annualOperatingBudget: Yup.string().required(
    "Annual operating budget is required"
  ),
  shelterSoftware: Yup.string().required("Shelter software is required"),
});

const OrganizationSubForm = ({ namespace }) => {
  const withNamespace = addNamespace(namespace);

  const {
    values: {
      organization: { usState, referral, animalSpecies, country },
    },
  } = useFormikContext();
  const lastYear = subYears(new Date(), 1);

  return (
    <>
      <h2>Organization Information</h2>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Organization Name */}
        <FormField name={withNamespace("name")} label="Name" required />

        {/* Country */}
        <FormField
          name={withNamespace("country")}
          label="Country"
          as="select"
          data-testid="country"
          required
        >
          <option disabled value="" hidden>
            -- Select a Country --
          </option>
          <option value="United States">United States</option>
          <option value="Canada">Canada</option>
        </FormField>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Address Line 1 */}
        <FormField
          name={withNamespace("addressLine1")}
          label="Street Address"
          required
        />

        {/* Address Line 2 */}
        <FormField
          name={withNamespace("addressLine2")}
          label="Street Address 2"
        />
      </div>

      <div className="grid grid-cols-3 gap-6 mb-6">
        {/* City */}
        <FormField name={withNamespace("city")} label="City" required />

        {/* State | Province */}
        {country === "United States" ? (
          <StateDropdown
            name={withNamespace("usState")}
            label="State"
            data-testid="usState"
            required
          />
        ) : (
          <FormField
            name={withNamespace("provinceOrTerritory")}
            label="Province/Territory"
            as="select"
            required
          >
            <option disabled value="" hidden>
              -- Select a Province --
            </option>
            <option value="AB">AB</option>
            <option value="BC">BC</option>
            <option value="MB">MB</option>
            <option value="NB">NB</option>
            <option value="NL">NL</option>
            <option value="NT">NT</option>
            <option value="NS">NS</option>
            <option value="NU">NU</option>
            <option value="ON">ON</option>
            <option value="PE">PE</option>
            <option value="QC">QC</option>
            <option value="SK">SK</option>
            <option value="YT">YT</option>
          </FormField>
        )}

        {/* Zip Code | Postac code */}
        {country === "United States" ? (
          <FormField
            name={withNamespace("zipCode")}
            label="Zip Code"
            required
          />
        ) : (
          <FormField
            name={withNamespace("postalCode")}
            label="Postal Code"
            required
          />
        )}
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* County */}
        {country === "United States" && (
          <CountyDropdown
            fieldName={withNamespace("usCounty")}
            usState={usState}
            required
          />
        )}
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Contact Phone Number */}
        <FormField
          name={withNamespace("contactPhoneNumber")}
          label="Contact Phone Number"
        />

        {/* Extension */}
        <FormField name={withNamespace("extension")} label="Extension" />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Org Type */}
        <FormField
          name={withNamespace("organizationType")}
          label="Organization Type"
          as="select"
          tooltip={<OrganizationTypeTooltip />}
          required
        >
          <option disabled value="" hidden>
            -- Select an Organization Type --
          </option>
          <option value="Government Animal Services">
            Government Animal Services
          </option>
          <option value="Animal Shelter with a Government Contract">
            Animal Shelter with a Government Contract
          </option>
          <option value="Animal Rescue with a Government Contract">
            Animal Rescue with a Government Contract
          </option>
          <option value="Shelter without a Government Contract">
            Shelter without a Government Contract
          </option>
          <option value="Rescue without a Government Contract">
            Rescue without a Government Contract
          </option>
          <option value="Non-sheltering Service Provider">
            Non-sheltering Service Provider
          </option>
        </FormField>

        {/* Website */}
        <FormField name={withNamespace("websiteUrl")} label="Website URL" />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Date Started */}
        <DatePickerField
          label="Date of Organization Incorporation"
          name={withNamespace("incorporatedOn")}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          className="form-input w-full"
          maxDate={endOfMonth(setMonth(new Date(), 11))}
          required
        />

        {/* EIN | Business Number */}
        {country === "United States" ? (
          <FormField
            name={withNamespace("ein")}
            label="Employer Identification Number (EIN)"
            tooltip={<OrganizationEinTooltip />}
            required
          />
        ) : (
          <FormField
            name={withNamespace("businessNumber")}
            label="Business Number"
            tooltip={<OrganizationBusinessNumberTooltip />}
            required
          />
        )}
      </div>

      {/* Referral */}
      <div className="mb-6">
        <FormField
          name={withNamespace("referral")}
          data-testid="referral"
          label="Were you referred to us by another organization?"
          type="checkbox"
        />
      </div>

      {/* Referring Org */}
      {referral && (
        <div className="grid grid-cols-2 gap-6 mb-6">
          <FormField
            name={withNamespace("referringOrganizationName")}
            label="Referring Organization Name, City, State"
          />
        </div>
      )}

      <div className="mb-6">
        {/* Animal Species */}
        <div id="animalSpecies" className="mb-1">
          What animal species do you work with? (Select all that apply)
        </div>
        <div
          role="group"
          aria-labelledby="animalSpecies"
          className="grid grid-cols-4 gap-4"
        >
          <FieldArray
            name={withNamespace("animalSpecies")}
            render={() =>
              Object.keys(animalSpecies).map((key) => (
                <SpeciesCheckbox
                  key={key}
                  species={key}
                  fieldName={`${withNamespace("animalSpecies")}.${key}`}
                  label={speciesMap[key].label}
                  selected={animalSpecies[key]}
                />
              ))
            }
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Annual Intake */}
        <FormField
          name={withNamespace("annualIntake")}
          label={`Annual Intake of animals for ${lastYear.getFullYear()} (cats and dogs only)`}
          tooltip={<OrganizationAnnualIntakeTooltip />}
          tooltipWidthClass="w-80"
          required
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Annual Budget */}
        <FormField
          name={withNamespace("annualOperatingBudget")}
          label={`Annual Operating Budget for ${lastYear.getFullYear()}`}
          tooltip={<OrganizationAnnualBudgetTooltip />}
          tooltipWidthClass="w-80"
          required
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* Software Provider */}
        <FormField
          name={withNamespace("shelterSoftware")}
          label="Do you use any of the following software?"
          as="select"
          required
        >
          <option disabled value="" hidden>
            -- Select Software Provider --
          </option>
          <option value="Adopt-A-Friend">Adopt-A-Friend</option>
          <option value="Animal Shelter Net">Animal Shelter Net</option>
          <option value="AnimalsFirst">AnimalsFirst</option>
          <option value="Ark Software">Ark Software</option>
          <option value="Barrk">Barrk</option>
          <option value="Chameleon">Chameleon</option>
          <option value="Companion Connect">Companion Connect</option>
          <option value="iShelters">iShelters</option>
          <option value="Multiple Options">Multiple Options</option>
          <option value="Pawlytics">Pawlytics</option>
          <option value="PawTrax">PawTrax</option>
          <option value="PetPoint">PetPoint</option>
          <option value="Petlogix">Petlogix</option>
          <option value="Petstablished">Petstablished</option>
          <option value="RescueConnection">RescueConnection</option>
          <option value="RescueGroups">RescueGroups</option>
          <option value="Salesforce">Salesforce</option>
          <option value="Shelter Boss">Shelter Boss</option>
          <option value="Shelter Manager">Shelter Manager</option>
          <option value="Shelter Pro">Shelter Pro</option>
          <option value="ShelterBuddy">ShelterBuddy</option>
          <option value="ShelterLuv">ShelterLuv</option>
          <option value="ShelterTrack">ShelterTrack</option>
          <option value="Smart Pet Health">Smart Pet Health</option>
          <option value="Sparkie">Sparkie</option>
          <option value="Trackabeast">Trackabeast</option>
          <option value="Vestafy">Vestafy</option>
          <option value="Visibility">Visibility</option>
          <option value="Custom Database">Custom Database</option>
          <option value="Spreadsheet">Spreadsheet</option>
          <option value="Paper">Paper</option>
          <option value="Other">Other</option>
        </FormField>
      </div>
    </>
  );
};

export default OrganizationSubForm;
