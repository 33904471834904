import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik, Form, useFormikContext } from "formik";
import FormField from "components/FormField";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import * as Yup from "yup";
import { FORM_MODES } from "utils";
import { useMutation } from "react-query";
import { apiFetch } from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Button from "components/Button";

const UserProfileForm = ({
  user,
  formMode,
  cancelRoute,
  onUpdateSuccess,
  onUpdateError,
}) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { isAdmin } = useUserState();
  const { orgId } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [showConfirmEmail, setShowConfirmEmail] = useState(
    formMode === FORM_MODES.ADD
  );
  const [status, setStatus] = useState(!!user.attributes.confirmedAt);
  const [role, setRole] = useState(user.attributes.systemAdmin);

  const initialValues = {
    fullName: user.attributes.fullName || "",
    title: user.attributes.title || "",
    email: user.attributes.email || "",
    confirmEmail: "",
    confirmedAt: !user.attributes.confirmedAt || false,
    systemAdmin: user.attributes.systemAdmin || false,
    isActive: !user.attributes.archivedAt || false,
  };

  const UserProfileSchema = Yup.object().shape({
    fullName: Yup.string().required("Name is required"),
    title: Yup.string().required("Title is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    confirmEmail: Yup.lazy(() =>
      showConfirmEmail
        ? Yup.string()
            .email("Invalid email")
            .oneOf([Yup.ref("email")], "Emails do not match")
            .required("Confirm the email")
        : Yup.string()
    ),
  });

  const { mutate: addMember, isLoading: isLoadingAdd } = useMutation(
    ({ fullName, title, email }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/user_membership_invites`,
        method: "POST",
        body: {
          data: {
            type: "user_membership_invites",
            attributes: {
              resourceId: orgId,
              resourceType: "organization",
              name: fullName,
              title,
              email,
            },
          },
        },
      }),
    {
      onSuccess: (data) => {
        setIsSuccess(true);
        onUpdateSuccess(data);
      },
      onError: (e) => {
        setIsSuccess(false);
        onUpdateError(e);
      },
    }
  );

  const { mutate: updateUserInformation, isLoading } = useMutation(
    ({ fullName, title, email, isActive }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/users/${user.id}`,
        method: "PATCH",
        body: {
          data: {
            id: user.id,
            type: "users",
            attributes: {
              fullName,
              title,
              confirmedAt: status === "true" ? new Date() : null,
              systemAdmin: role,
              archivedAt: !isActive ? new Date() : null,
              ...(showConfirmEmail ? { unconfirmedEmail: email } : {}),
            },
          },
        },
      }),
    {
      onSuccess: (data) => {
        setIsSuccess(true);
        onUpdateSuccess(data);
      },
      onError: (e) => {
        setIsSuccess(false);
        onUpdateError(e);
      },
    }
  );

  const { mutate: resetUserPassword } = useMutation(() =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/users/${user.id}/reset/`,
      method: "POST",
      body: {
        data: {
          id: user.id,
        },
      },
    })
  );

  const SelectOptions = ({ props, children }) => {
    const propsClone = { ...props };
    delete propsClone?.className;
    delete propsClone?.value;
    return (
      <select
        className="w-52 form-input"
        {...propsClone}
        defaultValue={props.value}
      >
        {children}
      </select>
    );
  };

  const StatusSelect = (props) => (
    <SelectOptions props={props}>
      <option value={true}>Confirmed</option>
      <option value={false}>Unconfirmed</option>
    </SelectOptions>
  );

  const RoleSelect = (props) => (
    <SelectOptions props={props}>
      <option value={true}>Admin</option>
      <option value={false}>User</option>
    </SelectOptions>
  );

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={UserProfileSchema}
      onSubmit={formMode === FORM_MODES.ADD ? addMember : updateUserInformation}
    >
      {({ handleSubmit }) => (
        <Form>
          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Full Name */}
            <FormField
              name="fullName"
              label="Full Name"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />

            {/* Title */}
            <FormField
              name="title"
              label="Title"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Email */}
            <FormField
              name="email"
              label="Email"
              type="email"
              readOnly={formMode === FORM_MODES.VIEW}
              disabled={formMode === FORM_MODES.VIEW}
              required
            />

            {/* Confirm Email */}
            {showConfirmEmail && (
              <FormField
                name="confirmEmail"
                label="Confirm Email"
                type="email"
                required
              />
            )}
          </div>

          {formMode === FORM_MODES.EDIT && formMode !== FORM_MODES.VIEW && (
            <div className="grid grid-cols-6 gap-60 mb-6">
              {/** User Confirmation Status */}
              <FormField
                name="confirmedAt"
                label="Status"
                as={StatusSelect}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              />

              {/** User role */}
              {isAdmin && (
                <FormField
                  name="systemAdmin"
                  label="Role"
                  as={RoleSelect}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                />
              )}

              {/** Active/Deactivate User */}
              <div className="flex mt-9">
                <FormField name="isActive" label="Active" type="checkbox" />
              </div>
            </div>
          )}

          {formMode !== FORM_MODES.VIEW && (
            <div className="flex justify-end space-x-4">
              <Button to={cancelRoute} emphasis="transparent">
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={isLoading || isLoadingAdd}
                isLoading={isLoading || isLoadingAdd}
                loadingText={<>Saving&hellip;</>}
                onClick={handleSubmit}
              >
                {formMode === FORM_MODES.ADD ? "Invite" : "Update"}
              </Button>

              {formMode === FORM_MODES.EDIT && (
                <Button
                  type="reset"
                  emphasis="secondary"
                  disabled={isLoading || isLoadingAdd}
                  isLoading={isLoading || isLoadingAdd}
                  loadingText={<>Saving&hellip;</>}
                  onClick={resetUserPassword}
                >
                  Reset
                </Button>
              )}
            </div>
          )}

          <FormContext
            formMode={formMode}
            user={user}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            setShowConfirmEmail={setShowConfirmEmail}
          />
        </Form>
      )}
    </Formik>
  );
};

UserProfileForm.propTypes = {
  user: PropTypes.object.isRequired,
  formMode: PropTypes.string,
  cancelRoute: PropTypes.string.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
  onUpdateError: PropTypes.func.isRequired,
  onResetSuccess: PropTypes.string,
};

UserProfileForm.defaultProps = {
  formMode: FORM_MODES.VIEW,
};

export default UserProfileForm;
const FormContext = ({
  formMode,
  user,
  isSuccess,
  setIsSuccess,
  setShowConfirmEmail,
}) => {
  const {
    resetForm,
    dirty,
    values: { email },
  } = useFormikContext();
  const [emailClone, setEmailClone] = useState(user.attributes.email);

  useEffect(() => {
    setEmailClone(user.attributes.email);
  }, [user]);

  useEffect(() => {
    resetForm({
      values: {
        fullName: user.attributes.fullName || "",
        title: user.attributes.title || "",
        email: user.attributes.email || "",
        confirmEmail: "",
        confirmedAt: !user.attributes.confirmedAt || false,
        systemAdmin: user.attributes.systemAdmin || false,
        isActive: !user.attributes.archivedAt || false,
      },
    });
  }, [formMode, user, resetForm]);

  useEffect(() => {
    if (dirty) {
      setIsSuccess(false);
    }
  }, [dirty, setIsSuccess]);

  useEffect(() => {
    setShowConfirmEmail(email !== emailClone || formMode === FORM_MODES.ADD);
  }, [emailClone, email, setShowConfirmEmail]);

  return (
    <RouteLeavingGuard
      when={!isSuccess && dirty}
      shouldBlockNavigation={() => !isSuccess && dirty}
    />
  );
};
