import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { FORM_MODES, ROUTE_GUARDS } from "utils";
import {
  LoginRoute,
  UnauthenticatedRoute,
  PrivateRoute,
} from "components/Routes";
import LegacyOrganization from "screens/LegacyOrganization";
import Dashboard from "screens/Dashboard";
import DataEntryOptions from "screens/DataEntryOptions";
import DataEntry from "screens/DataEntry";
import DataEntryUpload from "screens/DataEntryUpload";
import DataEntryExport from "screens/DataEntryExport";
import Login from "screens/Login";
import PageNotFound from "screens/PageNotFound";
import Register from "screens/Register";
import ForgotPassword from "screens/ForgotPassword";
import ResetPassword from "screens/ResetPassword";
import Profile from "screens/Profile";
import Organizations from "screens/OrganizationManagement/Organizations";
import Organization from "screens/OrganizationManagement/Organization";
import Users from "screens/Users";
import UserDetails from "screens/Users/UserDetails";
import Coalitions from "screens/CoalitionBuilder/Coalitions";
import MyCoalitions from "screens/CoalitionBuilder/MyCoalitions";
import AddCoalition from "screens/CoalitionBuilder/AddCoalition";
import Coalition from "screens/CoalitionBuilder/Coalition";
import ResourceCenter from "screens/ResourceCenter";
import Dashboards from "screens/Dashboards";
import DataMigration from "screens/DataMigration";
import ServicesMatrixOptions from "screens/ServicesMatrixOptions";
import ServicesMatrix from "screens/ServicesMatrix";
import ServicesUpload from "screens/ServicesUpload";
import ServicesExport from "screens/ServicesExport";
import DataUseAgreement from "screens/DataUseAgreement";
import AllOrganizations from "screens/AllOrganizations";
import Aggregation from "screens/Aggregation";

const Routes = () => {
  return process.env.REACT_APP_DATA_IMPORT_SPLASH_SCREEN ? (
    <Switch>
      <Route path="/" component={DataMigration} />
      <Redirect to="/" />
    </Switch>
  ) : (
    <Switch>
      <LoginRoute path="/login" exact component={Login} />
      <UnauthenticatedRoute path="/register" exact component={Register} />

      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/data-use-agreement" component={DataUseAgreement} />

      {/* Dashboard */}
      <PrivateRoute path="/" exact component={Dashboard} />

      <PrivateRoute
        path="/legacy"
        exact
        component={LegacyOrganization}
        meta={{
          guards: [ROUTE_GUARDS.HAS_LEGACY_ORG],
          isLegacyRoute: true,
        }}
        isSubRoute
      />

      {/* Profile */}
      <PrivateRoute path="/profile" exact component={Profile} />
      <PrivateRoute
        path="/profile/edit"
        exact
        component={Profile}
        meta={{ formMode: FORM_MODES.EDIT }}
      />

      {/* Admin */}
      <PrivateRoute
        path="/users"
        exact
        component={Users}
        meta={{ guards: [ROUTE_GUARDS.ADMIN] }}
      />
      <PrivateRoute
        path="/users/:userId"
        exact
        component={UserDetails}
        meta={{ guards: [ROUTE_GUARDS.ADMIN] }}
      />
      <PrivateRoute
        path="/users/:userId/edit"
        exact
        component={UserDetails}
        meta={{ guards: [ROUTE_GUARDS.ADMIN], formMode: FORM_MODES.EDIT }}
      />

      {/* Data Portal */}
      <PrivateRoute
        path="/data-entry"
        exact
        component={DataEntryOptions}
        meta={{ guards: [ROUTE_GUARDS.ORG_MEMBER] }}
      />
      <PrivateRoute
        path="/data-entry-admin/:location/:organizationId"
        exact
        component={DataEntryOptions}
        meta={{ guards: [ROUTE_GUARDS.ADMIN] }}
      />
      <PrivateRoute
        path="/data-entry/:year/:month"
        exact
        component={DataEntry}
        meta={{
          guards: [ROUTE_GUARDS.DATA_MATRIX, ROUTE_GUARDS.ORG_MEMBER],
        }}
      />
      <PrivateRoute
        path="/data-entry/:year/:month/:location/:organizationId"
        exact
        component={DataEntry}
        meta={{
          guards: [ROUTE_GUARDS.DATA_MATRIX, ROUTE_GUARDS.ADMIN],
        }}
      />
      <PrivateRoute
        path="/data-entry/upload"
        exact
        component={DataEntryUpload}
      />
      <PrivateRoute
        path="/data-entry/upload/:location/:organizationId"
        exact
        component={DataEntryUpload}
      />
      <PrivateRoute
        path="/data-entry/export"
        exact
        component={DataEntryExport}
        meta={{ guards: [ROUTE_GUARDS.ORG_MEMBER] }}
      />
      <PrivateRoute
        path="/data-entry/export/:location/:organizationId"
        exact
        component={DataEntryExport}
        meta={{ guards: [ROUTE_GUARDS.ADMIN] }}
      />
      {/* Services Matrix */}
      <PrivateRoute
        path="/services"
        exact
        component={ServicesMatrixOptions}
        meta={{
          guards: [ROUTE_GUARDS.ORG_MEMBER],
        }}
      />
      <PrivateRoute
        path="/admin-services/:location/:organizationId"
        exact
        component={ServicesMatrixOptions}
        meta={{
          guards: [ROUTE_GUARDS.ADMIN],
        }}
      />
      <PrivateRoute
        path="/services/:year/:month"
        exact
        component={ServicesMatrix}
        meta={{
          guards: [ROUTE_GUARDS.SERVICES_MATRIX, ROUTE_GUARDS.ORG_MEMBER],
        }}
      />
      <PrivateRoute
        path="/services/:year/:month/:location/:organizationId"
        exact
        component={ServicesMatrix}
        meta={{
          guards: [ROUTE_GUARDS.ADMIN],
        }}
      />
      <PrivateRoute path="/services/upload" exact component={ServicesUpload} />
      <PrivateRoute
        path="/services/upload/:location/:organizationId"
        exact
        component={ServicesUpload}
      />
      <PrivateRoute
        path="/services/export"
        exact
        component={ServicesExport}
        meta={{ guards: [ROUTE_GUARDS.ORG_MEMBER] }}
      />
      <PrivateRoute
        path="/services/export/:location/:organizationId"
        exact
        component={ServicesExport}
        meta={{ guards: [ROUTE_GUARDS.ADMIN] }}
      />

      {/* Organizations */}
      <PrivateRoute
        path="/all-organizations"
        exact
        component={AllOrganizations}
      />

      <PrivateRoute
        path="/organizations"
        exact
        component={Organizations}
        meta={{ guards: [ROUTE_GUARDS.ADMIN, ROUTE_GUARDS.ORGANIZATIONS] }}
      />
      <PrivateRoute path="/organizations/:orgId" component={Organization} />

      {/* Coalition Builder */}
      <PrivateRoute path="/coalitions" exact component={Coalitions} />
      <PrivateRoute
        path="/coalitions/mine"
        exact
        component={MyCoalitions}
        meta={{ guards: [ROUTE_GUARDS.HAS_COALITIONS] }}
      />
      <PrivateRoute path="/coalitions/add" exact component={AddCoalition} />
      <PrivateRoute path="/coalitions/:coalitionId" component={Coalition} />
      <Redirect path="/coalitions" to="/coalitions" />

      {/* Resource Center Builder */}
      <PrivateRoute
        path="/resource-center/marketing-resources"
        exact
        component={ResourceCenter}
      />
      <PrivateRoute
        path="/resource-center/video-tutorials"
        exact
        component={ResourceCenter}
      />
      <Redirect
        path="/resource-center"
        to="/resource-center/marketing-resources"
      />

      {/* Dashboards */}
      <PrivateRoute
        path="/dashboards/comparison"
        exact
        component={Dashboards}
      />
      <PrivateRoute
        path="/dashboards/analytics-and-forecast"
        exact
        component={Dashboards}
      />
      <PrivateRoute path="/dashboards/rehome" exact component={Dashboards} />
      <PrivateRoute
        path="/dashboards/community-services-totals"
        exact
        component={Dashboards}
      />
      <PrivateRoute
        path="/dashboards/intake-outcome-totals"
        exact
        component={Dashboards}
      />
      <Redirect path="/dashboards" to="/dashboards/comparison" />

      <PrivateRoute path="/aggregation" exact component={Aggregation} />

      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
