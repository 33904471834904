import React, { useState } from "react";
import AlertModal from "components/AlertModal";
import ConfirmationModal from "components/ConfirmationModal";
import { useHandleRequestStates, apiFetch } from "utils";
import { useMutation, useQuery } from "react-query";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import Button from "components/Button";

const Settings = () => {
  const { handleSuccess } = useHandleRequestStates();
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { coalitionId } = useParams();
  const { isAdmin } = useUserState();

  const [coalitionToArchive, setCoalitionToArchive] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState(null);

  const { isLoading, data: response } = useQuery(
    ["coalition", coalitionId],
    () =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/coalitions/${coalitionId}`,
      })
  );

  /**
   * MODAL METHODS
   */
  const openModal = (coalition) => {
    setCoalitionToArchive(coalition);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalError(null);
    setCoalitionToArchive(null);
  };

  // ARCHIVE
  const { mutate: archiveCoalition, isLoading: isLoadingArchiveCoalition } =
    useMutation(
      ({ id }) =>
        apiFetch({
          token,
          dispatch,
          method: "POST",
          endpoint: "/api/v1/resource_archivers",
          body: {
            data: {
              type: "resource_archivers",
              attributes: {
                resourceId: id,
                resourceType: "coalition",
              },
            },
          },
        }),
      {
        onSuccess: () => {
          handleSuccess({
            message: `Updated ${coalitionToArchive.attributes.name} successfully`,
            queries: [
              {
                key: "coalition",
                config: {
                  refetchActive: true,
                },
              },
              {
                key: "coalitions",
              },
              {
                key: "myCoalitions",
              },
            ],
          });
          closeModal();
        },
        onError: setModalError,
      }
    );

  // Active from draft
  const { mutate: activeCoalition, isLoading: isLoadingActiveCoalition } =
    useMutation(
      ({ id }) =>
        apiFetch({
          token,
          dispatch,
          method: "PUT",
          endpoint: "/api/v1/coalitions/" + id,
          body: {
            data: {
              type: "coalitions",
              id: id,
              attributes: {
                status: "active",
              },
            },
          },
        }),
      {
        onSuccess: () => {
          handleSuccess({
            message: `Updated ${coalitionToArchive.attributes.name} successfully`,
            queries: [
              {
                key: "coalition",
                config: {
                  refetchActive: true,
                },
              },
              {
                key: "coalitions",
              },
              {
                key: "myCoalitions",
              },
            ],
          });
          closeModal();
        },
        onError: setModalError,
      }
    );

  const archiveRecord = response.data.attributes.status === "active";
  const draftRecord = response.data.attributes.status === "draft";
  return (
    <div className="mb-8">
      <h2>{response.data.attributes.name} Settings</h2>
      {/* Archive coalition (admin or manager only) */}
      {draftRecord && isAdmin && (
        <Button
          type="submit"
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => openModal(response.data)}
        >
          Activate Coalition
        </Button>
      )}
      {/* Only sys admin can delete records */}
      {!draftRecord && isAdmin && (
        <Button
          type="submit"
          disabled={isLoading}
          isLoading={isLoading}
          onClick={() => openModal(response.data)}
        >
          {archiveRecord ? "Delete Coalition" : "Activate Coalition"}
        </Button>
      )}
      <AlertModal isModalOpen={isModalOpen} closeModal={closeModal}>
        <ConfirmationModal
          prompt={`Are you sure you want to update ${get(
            coalitionToArchive,
            "attributes.name"
          )}?`}
          isLoading={isLoadingArchiveCoalition}
          error={modalError}
          closeModal={closeModal}
          confirmModal={() => {
            draftRecord
              ? activeCoalition(coalitionToArchive)
              : archiveCoalition(coalitionToArchive);
          }}
        />
      </AlertModal>
    </div>
  );
};

export default Settings;
