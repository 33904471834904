import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import RegisterTypeSubForm from "./components/RegisterTypeSubForm";
import AccountSubForm, * as AccountSubFormMeta from "./components/AccountSubForm";
import OrganizationSubForm, * as OrganizationSubFormMeta from "./components/OrganizationSubForm";
import AuthorizationSubForm, * as AuthorizationSubFormMeta from "./components/AuthorizationSubForm";
import DataSharingSubForm, * as DataSharingSubFormMeta from "./components/DataSharingSubForm";
import Button from "components/Button";
import PageWrapper from "components/PageWrapper";
import { apiFetch, formatUiErrors } from "utils";
import { useAuthState, useAuthDispatch } from "context";
import { constructPayload } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const Register = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const [formStep, setFormStep] = useState(1);

  const validationSchema = Yup.lazy(() =>
    Yup.object({
      account: Yup.object(AccountSubFormMeta.validationSchema()),
      organization: Yup.object(OrganizationSubFormMeta.validationSchema()),
      authorization: Yup.object(AuthorizationSubFormMeta.validationSchema()),
      dataSharing: Yup.object(DataSharingSubFormMeta.validationSchema()),
    })
  );

  const nextStep = () => setFormStep(2);

  const {
    mutate: submit,
    isLoading,
    isSuccess,
  } = useMutation(
    (values) => {
      const payload = constructPayload(values);
      return apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/registrations`,
        method: "POST",
        body: payload,
      });
    },
    {
      onError: (error) => {
        toast.error(() => formatUiErrors(error), {
          autoClose: 5000,
          className: "toast-center",
        });
      },
    }
  );

  return (
    <PageWrapper>
      {isSuccess ? (
        <Success />
      ) : (
        <Formik
          initialValues={{
            account: AccountSubFormMeta.defaultValues,
            organization: OrganizationSubFormMeta.defaultValues,
            authorization: AuthorizationSubFormMeta.defaultValues,
            dataSharing: DataSharingSubFormMeta.defaultValues,
          }}
          validationSchema={validationSchema}
          validateOnMount
          onSubmit={submit}
        >
          {({ handleSubmit, values }) => (
            <Form>
              <h1>Create an Account</h1>
              {formStep === 1 ? (
                <SelectRegistrationType nextStep={nextStep} />
              ) : (
                <>
                  <div className="mb-12">
                    <p>
                      Complete this form to create an account and register your
                      organization. Registration is open to animal control
                      agencies, private shelters, and rescues with adoption
                      programs and cat and/or dog intake or outcome data to
                      contribute. Your username and password will be used to
                      securely access your organization&rsquo;s account, view
                      data, and input monthly data.
                    </p>
                    <Link to="/login">Already a data contributor? Log in.</Link>
                  </div>

                  <p aria-hidden>
                    Fields marked with an asterisk (*) are required.
                  </p>

                  <div className="mb-12">
                    <AccountSubForm namespace="account" />
                  </div>

                  <div className="mb-12">
                    <OrganizationSubForm namespace="organization" />
                  </div>

                  <div className="mb-12">
                    <AuthorizationSubForm namespace="authorization" />
                  </div>

                  {values.authorization.isAuthorizer && (
                    <div className="mb-12">
                      <DataSharingSubForm namespace="dataSharing" />
                    </div>
                  )}

                  <div className=" mb-20">
                    <TermsOfService />
                  </div>

                  <div className="flex justify-end">
                    <Button
                      disabled={isLoading}
                      isLoading={isLoading}
                      loadingText={<>Creating Account&hellip;</>}
                      onClick={handleSubmit}
                      type="submit"
                    >
                      Save and Create Account
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </PageWrapper>
  );
};

export default Register;

const SelectRegistrationType = ({ nextStep }) => {
  return (
    <>
      <div>
        <div className="mb-12">
          <Link to="/login">Already have an account? Log in.</Link>
        </div>

        <div className="mb-12">
          <RegisterTypeSubForm />
        </div>
      </div>

      <div className="flex justify-end">
        <Button onClick={nextStep}>Continue</Button>
      </div>
    </>
  );
};

const TermsOfService = () => (
  <div className="sac-border overflow-auto" style={{ height: "350px" }}>
    <h3 className="mb-4">Shelter Animals Count - Agreement For Shelter Data</h3>
    <p className="text-sm">
      Shelter Animals Count (&ldquo;SAC&rdquo;) maintains a database of animal
      shelter data (the “Database”), in an effort to create a national database
      for the animal welfare field (the “Project”). When a shelter (“Shelter”)
      provides information (the &ldquo;Information&rdquo;) for the Database, it
      will be provided and used on the following terms:
    </p>
    <ol className="ml-4 text-sm list-outside list-decimal mb-6">
      <li className="mb-4">
        Shelter agrees to submit complete data into the SAC data matrix portal
        each month.
      </li>
      <li className="mb-4">
        Permission to use Information. SAC has permission to use any Information
        submitted by Shelter to SAC in the Database at no cost to SAC. This
        permission will be on a non-exclusive, worldwide, royalty-free,
        permanent basis, meaning that Shelter may give others permission to use
        the Information in addition to SAC; that the Database may be used
        anywhere and without time limit; SAC does not have to pay any royalties
        for using the Information in the Database.
      </li>
      <li className="mb-4">
        Permitted uses of Information. SAC may use the Information by compiling
        it with the data of other participating organizations and otherwise
        supporting its development of a national database for the animal welfare
        field. SAC may make the data in the database available to members of the
        public, both by making it available for review on SAC’s website and also
        by releasing the data in CSV or comparable format. SAC may include the
        Information in data analysis projects, and may disclose, publish, or
        present the results of those projects for any lawful purpose relating to
        the Project, including demonstrating the Project to potential funders.
        SAC may not, however, disclose, distribute, present, or publish any of
        the Information in a way that discloses the identity of any animal or
        any person associated with an animal.
      </li>
      <li className="mb-4">
        Shelter owns the Information. Shelter does not transfer ownership of the
        Information to SAC, and these Terms of Use do not limit or restrict in
        any way Shelter’s ownership of the Information. Shelter reserves all
        rights in and to the Information not specifically granted to the SAC in
        these Terms of Use.
      </li>
      <li>
        Shelter&rsquo;s and SAC&rsquo;s representations to each other. Shelter
        represents to SAC that the Information is accurate and that it is not
        limited in any way from sharing the Information. Shelter agrees to
        SAC&rsquo;s{" "}
        <a
          href="https://www.shelteranimalscount.org/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          TERMS OF USE
        </a>{" "}
        AND{" "}
        <a
          href="https://www.shelteranimalscount.org/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          PRIVACY POLICY
        </a>
        .
      </li>
    </ol>
  </div>
);

const Success = () => {
  return (
    <div className="text-center">
      <div className="mb-12">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-4xl text-teal mb-2"
        />
        <h2 className="text-h1 leading-tight">
          Thanks! Your account has been registered.
        </h2>
        <p>You must confirm your account before you log in.</p>
      </div>

      <Button to="/login" emphasis="transparent">
        Login
      </Button>
    </div>
  );
};
