import React, { useState, useEffect } from "react";
import { useAuthState, useAuthDispatch } from "context";
import { useQuery, useQueryClient } from "react-query";
import Table from "components/Table";
import Pagination from "components/Pagination";
import { getTotalPages, camelToTitle, getCoalitions } from "utils";
import { uniq, get } from "lodash";
import Button from "components/Button";

const UserCoalitions = ({ userId }) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();

  // Pagination
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  // Initial query
  const {
    data: response = { data: [], included: [] },
    isLoading,
    error,
  } = useQuery(
    ["userCoalitions", { page, userId }],
    () => getCoalitions({ token, dispatch, page, userId }),
    {
      keepPreviousData: true,
    }
  );

  // Prefetch the next page of coalitions
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["userCoalitions", { page: page + 1, userId }],
        () => getCoalitions({ token, dispatch, page: page + 1, userId })
      );
    }
  }, [response, page, userId, queryClient]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  // Columns - Map up with accessor from Data - attributes.name etc.
  const columns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    {
      accessor: "attributes.operatingRegion",
      Header: "States",
      Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
    },
    {
      accessor: "attributes.operatingRegionType",
      Header: "Region Type",
    },
    {
      accessor: "attributes.organizationsCount",
      Header: "Shelters",
      Cell: ({ cell }) => <div className="text-center">{cell.value}</div>,
    },
    {
      accessor: "attributes.description",
      Header: "Description",
    },
    ...(userId
      ? [
          {
            accessor: "attributes.userRole",
            Header: "My Role",
            Cell: ({ cell, row: { original } }) =>
              `${camelToTitle(cell.value)}${
                original.attributes.userStatus === "requested"
                  ? ` - (${original.attributes.userStatus})`
                  : ""
              }`,
          },
        ]
      : []),
    {
      accessor: "id",
      Header: "",
      Cell: ({ cell }) => (
        <Button
          to={`/coalitions/${cell.value}`}
          className="btn-sm"
          emphasis="transparent"
        >
          View Coalition
        </Button>
      ),
    },
  ];

  return (
    <>
      <h2>Coalitions</h2>

      <Table
        data={response.data || []}
        columns={columns}
        noResultsText="No coalitions found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default UserCoalitions;
