import { USER_CREATE, USER_UPDATE, USER_CLEAR } from "actions";
import { getIncludedDataByKey } from "utils";
import { get } from "lodash";

export const initialUserState = {
  attributes: {},
  coalitions: {},
  locations: [],
  pendingCoalitionContributorRequests: [],
};

export const UserReducer = (state, action) => {
  switch (action.type) {
    case USER_CREATE:
      const {
        data: user,
        included,
        pendingCoalitionContributorRequests,
      } = action.payload;
      const userState = getUpdatedUserState({ user, included, state });

      return {
        ...state,
        ...userState,
        pendingCoalitionContributorRequests,
      };

    case USER_UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case USER_CLEAR: {
      return {
        ...initialUserState,
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

/**
 * Constructs the user state from the /users/:userId API reponse
 * @param {Object} params
 * @param {string} params.user
 * @param {string} params.included
 * @param {string} params.state
 * @returns {Object} userState
 * @returns {string} userState.id
 * @returns {Object} userState.attributes
 * @returns {boolean} userState.isAdmin
 * @returns {boolean} userState.isOrgAdmin
 * @returns {boolean} userState.isDataPortalUser
 * @returns {boolean} userState.isCoalitionOnly
 * @returns {array} userState.coalitions
 * @returns {Object} userState.organization
 * @returns {Array} userState.locations
 */
export const getUpdatedUserState = ({ user, included, state }) => {
  const isAdmin = !!user.attributes.systemAdmin;

  // Construct the state from the includes
  const includedData = constructIncludedData({
    included,
    state,
  });
  const includedDataState = constructIncludedState(includedData);

  // Get the user's primary location (location record for the user's locationId attribute)
  const location = (get(includedData, "locations") || []).find(
    (loc) => loc.id === String(user.attributes.locationId)
  );
  // const currentOrganization = get(includedData, "organizations");
  const organization = (get(includedData, "organizations") || []).find(
    (org) => org.id === String(location?.attributes?.organizationId)
  );

  const membership = (get(includedData, "memberships") || []).find(
    (membership) => membership.attributes.resourceType === "Organization"
  );

  const isOrgAdmin =
    membership?.attributes.role === "organization_manager" || isAdmin;

  const primaryLocation = (get(includedData, "locations") || []).find(
    (loc) => loc.attributes.primary === true
  );

  return {
    id: user.id,
    attributes: Object.assign(
      user.attributes,
      location ? { locationName: location.attributes.name } : {}
    ),
    isAdmin,
    isOrgAdmin,
    isDataPortalUser: !!organization,
    isCoalitionOnly: !isAdmin && !organization,
    ...includedDataState,
    organization,
    primaryLocation,
  };
};

/**
 * Separates out records in the included array API response by the records `type` property
 * and returns the coalitions, locations, and organization associated with the logged in user
 * @param {Object} params
 * @param {string} params.included
 * @param {string} params.state
 * @returns {Object} includedData
 * @returns {Array} includedData.coalitions
 * @returns {Object} includedData.organization
 * @returns {Array} includedData.locations
 */
export const constructIncludedData = ({ included, state }) => {
  const locations = getLocations(included) || state.locations;
  const organizations = getOrganizations(included) || [];
  // const organization = getOrganization(organizations) || state.organization;
  const coalitions = getCoalitions(included) || state.coalitions;
  const memberships = getUserMemberships(included) || [];

  return {
    coalitions,
    organizations,
    locations,
    memberships,
  };
};

/**
 * Takes the includedData object and omits each property if there is no value
 * @param {Object} params
 * @param {string} [null] - params.coalitions If not null, object will have a `coalitions` property
 * @param {string} [null] - params.organization If not null, object will have a `organization` property
 * @param {string} [null] - params.locations If not null, object will have a `locations` property
 * @returns {Object} includedState
 * @returns {Array} includedState.coalitions
 * @returns {Object} includedState.organization
 * @returns {Array} includedState.locations
 */
export const constructIncludedState = (includedData) => {
  return {
    ...(includedData.coalitions ? { coalitions: includedData.coalitions } : {}),
    ...(includedData.locations ? { locations: includedData.locations } : {}),
  };
};

/**
 * Filters the included array to the location records
 * @param {Array} included
 * @returns {(Array|null)} location records
 */
export const getLocations = (included) => {
  return getIncludedDataByKey({ included, type: "locations" });
};

/**
 * Filters the included array to the organiztion records
 * @param {Array} included
 * @returns {(Array|null)} organization records
 */
export const getOrganizations = (included) => {
  return getIncludedDataByKey({ included, type: "organizations" });
};

/**
 * Filters the included array to the coalition records
 * @param {Array} included
 * @returns {(Array|null)} coalition records
 */
export const getCoalitions = (included) => {
  const coalitionMemberships = getCoalitionMemberships(included) || [];
  const userMemberships = (getUserMemberships(included) || []).filter(
    (membership) => membership.attributes.resourceType === "Coalition"
  );

  const memberships = [...coalitionMemberships, ...userMemberships];

  return memberships.reduce((coalitionMap, membership) => {
    let coalitionId;
    const isCoalitionUser = membership.type === "user_memberships";

    // The coalition id is the `resourceId` on user membership records and `coalitionId` on coalition membership records
    coalitionId = isCoalitionUser
      ? membership.attributes.resourceId
      : membership.attributes.coalitionId;

    const membershipKey = isCoalitionUser ? "userMembership" : "orgMembership";

    if (coalitionMap[coalitionId]) {
      coalitionMap[coalitionId][membershipKey] = membership;
    } else {
      coalitionMap[coalitionId] = {
        [membershipKey]: membership,
      };
    }

    return coalitionMap;
  }, {});
};

/**
 * Filters the included array to the coalition membership records
 * @param {Array} included
 * @returns {(Array|null)} coalition membership records
 */
export const getCoalitionMemberships = (included) => {
  return getIncludedDataByKey({
    included,
    type: "coalition_memberships",
  });
};

/**
 * Filters the included array to the user membership records
 * @param {Array} included
 * @returns {(Array|null)} user membership records
 */
export const getUserMemberships = (included) => {
  return getIncludedDataByKey({
    included,
    type: "user_memberships",
  });
};

/**
 * Returns the first organization record in an array or org records; returns null if the array is nil
 * @param {Array} organizations
 * @returns {(Object|null)} organization record
 */
export const getOrganization = (organizations) => {
  // Organization - If the user has organizations we're assuming it's an array
  // with a length of one, since a user can only have one organization association
  if (!organizations) return null;
  return organizations.length ? organizations[0] : null;
};
