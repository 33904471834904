import React from "react";
import { Menu, MenuButton } from "@szhsin/react-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

const ActionsMenu = ({ children }) => {
  return (
    <Menu
      menuButton={
        <MenuButton
          className="actions-menu__trigger"
          data-testid="actions-menu-button"
        >
          <span className="sr-only">Open menu</span>
          <FontAwesomeIcon icon={faEllipsisV} />
        </MenuButton>
      }
      className="actions-menu"
      align="end"
    >
      {children}
    </Menu>
  );
};

export default ActionsMenu;
