import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as UploadIcon } from "images/upload_icon.svg";
import { ReactComponent as CsvIcon } from "images/csv_icon.svg";
import { ReactComponent as TrashIcon } from "images/trash_icon.svg";
import Error from "components/Error";
import Button from "components/Button";
import { apiFetch, formatCsvValidationErrors } from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const DataEntryUpload = ({ exampleCSV, action, title }) => {
  const { attributes, isAdmin } = useUserState();
  const { location, organizationId } = useParams();
  var locationId = attributes.location;
  if (isAdmin && location) locationId = location;

  if (!locationId) locationId = attributes.locationId;
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const [file, setFile] = useState(null);
  const [uiError, setUiError] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        setFile({
          name: file.name,
          binaryStr,
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const {
    mutate: handleSubmit,
    isLoading,
    error,
    isSuccess,
  } = useMutation(() =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/file_imports`,
      method: "POST",
      body: {
        data: {
          type: "file_imports",
          attributes: {
            locationId,
            file: file.binaryStr,
            file_type: action,
          },
        },
      },
    })
  );

  useEffect(() => {
    setUiError(error);
  }, [error]);

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      accept: ".csv",
      disabled: isLoading,
    });

  const fileRejectionItems = fileRejections.map(({ errors }) => (
    <>{errors.map((e) => e.message)}</>
  ));

  if (isSuccess) return <Success />;

  return (
    <div>
      <div className="mb-8">
        <h1>{title}</h1>
        <p>
          All fields in the data file must contain values. Data upload will
          overwrite data previously entered for same month and year.{" "}
          <a
            href="https://www.shelteranimalscount.org/resources"
            target="_blank"
            rel="noreferrer"
          >
            View detailed instructions.
          </a>
        </p>
      </div>

      <div className="sac-border bg-super-light-gray p-12 mb-10">
        <h2>Upload formatted CSV</h2>
        {file ? (
          <div className="flex items-center justify-between bg-white rounded p-6 border mb-10">
            <div className="flex items-center text-dashboard-blue text-lg">
              <CsvIcon className="mr-4" />
              {file.name}
            </div>
            <button
              onClick={() => {
                setFile(null);
                setUiError(null);
              }}
              className="p-2 border rounded"
              type="button"
              disabled={isLoading}
            >
              <TrashIcon />
              <span className="sr-only">Remove file</span>
            </button>
          </div>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <div className="flex flex-col items-center justify-center bg-dashboard-blue rounded p-6 border-dashed border mb-10">
                <UploadIcon />
                <h3 className="mt-6 text-white">Drop File</h3>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center bg-white rounded p-6 border-dashed border mb-10">
                <UploadIcon />
                <h3 className="mt-6">
                  <span className="text-dashboard-blue cursor-pointer">
                    Choose a file
                  </span>{" "}
                  or drag it here.
                </h3>
                <h4 className="text-red">{fileRejectionItems}</h4>
              </div>
            )}
          </div>
        )}
        <hr className="my-8" />
        <div className="flex items-start justify-between space-x-4 mb-6">
          <p className="w-1/2">
            If you would prefer to format the data yourself, please download the
            CSV template.
          </p>
          <a
            className="btn btn-outline text-center w-1/3"
            type="button"
            download
            href={exampleCSV}
          >
            Download Example CSV
          </a>
        </div>

        {uiError && (
          <Error>{formatCsvValidationErrors(uiError, exampleCSV)}</Error>
        )}
      </div>

      <div className="flex items-center justify-between mb-4">
        <Link to={`/${action}`}>Enter data a different way</Link>

        <Button
          disabled={!file || isLoading}
          isLoading={isLoading}
          loadingText={<>Loading&hellip;</>}
          onClick={handleSubmit}
          type="button"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

DataEntryUpload.propTypes = {
  exampleCSV: PropTypes.instanceOf(File).isRequired,
  action: PropTypes.string.isRequired,
  title: PropTypes.string,
};

DataEntryUpload.defaultProps = {
  title: "Upload Your CSV",
};
export default DataEntryUpload;

const Success = () => {
  return (
    <div className="text-center">
      <div className="mb-12">
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-4xl text-teal mb-2"
        />
        <h2 className="text-h1 leading-tight">
          Thanks! Your entry is complete.
        </h2>
        <p>No further action is required.</p>
      </div>

      <Button to="/">View My Data</Button>
    </div>
  );
};
