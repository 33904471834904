import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { camelCase } from "lodash";

const EntryStatus = ({ status }) => {
  const statuses = {
    unbegun: "unbegun",
    inProgress: "inProgress",
    completeServicesProvided: "completeServicesProvided",
    completeNoServicesProvided: "completeNoServicesProvided",
  };
  const statusContent = {
    [statuses.completeServicesProvided]: {
      content: "Entry is Complete",
      icon: faCheckCircle,
      color: "text-teal",
    },
    [statuses.completeNoServicesProvided]: {
      content: "Entry is Complete, No Services Provided",
      icon: faCheckCircle,
      color: "text-teal",
    },
    [statuses.inProgress]: {
      content: "Entry is Incomplete",
      icon: faExclamationTriangle,
      color: "text-gold",
    },
    [statuses.unbegun]: {
      content: "Entry is Unbegun",
      icon: faQuestionCircle,
      color: "text-dashboard-blue",
    },
  };

  return (
    <div>
      <p className="mb-0 flex items-center">
        <FontAwesomeIcon
          icon={statusContent[camelCase(status)].icon}
          className={`text-xl ${statusContent[camelCase(status)].color} mr-2`}
        />
        {statusContent[camelCase(status)].content}
      </p>
    </div>
  );
};

export default EntryStatus;
