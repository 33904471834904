import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

const ButtonIncrement = ({ onClick }) => {
  return (
    <button
      className="px-4 py-2 border rounded-lg bg-super-light-gray text-sm text-center mb-3 mr-1"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretLeft} />
    </button>
  );
};

export default ButtonIncrement;
