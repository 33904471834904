import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Table, {
  getRowData,
  columns as defaultColumns,
  getServiceTypeAttributes,
} from "components/Table";
import { speciesMap, useHandleRequestStates } from "utils";
import { getTableColumns, getChangedAttributes } from "../../utils";

const BehavioralServicesTable = forwardRef(
  ({ id, attributes, species, isEditing, submit }, ref) => {
    const { handleSuccess } = useHandleRequestStates();

    const columns = getTableColumns({ defaultColumns, isEditing, species });

    const setRow = ({ rowLabel, keySubString, tooltip }) =>
      getRowData({
        data: attributes,
        accessKeys: ["adult", "youth", "ageUnknown", "total"],
        rowLabel,
        keySubString,
        tooltip,
      });

    const data = [
      setRow({
        rowLabel: "Behavior Consultation",
        keySubString: "BehaviorConsultationCount",
        tooltip:
          "Owned animals receiving behavior consultation in-person, by phone, or online. Limited to this defintion: consult is provided as a free or low-cost community service.",
      }),
      setRow({
        rowLabel: "Group Training",
        keySubString: "GroupTrainingCount",
        tooltip:
          "Owned animals receiving training in a group setting. Limited to this definition: training provided as a free or low-cost community service, not all training classes provided by an organization.",
      }),
      setRow({
        rowLabel: "Private Training",
        keySubString: "PrivateTrainingCount",
        tooltip:
          "Owned animals receiving training in a private setting. Limited to this definitioN: training provided as a free or low-cost community service, not all training classes provided by an organization.",
      }),
      setRow({
        rowLabel: "Behavior Modification Program",
        keySubString: "BehaviorModificationProgramCount",
        tooltip:
          "Owned animals participating in a behavior modification program. Limited to this definition: training provided as a free or low-cost community service, not all training classes provided by an organization.",
      }),
    ];
    const handleSubmit = async (values) => {
      handleSuccess({
        message: `Updated the ${speciesMap[
          attributes.species
        ].label.toLocaleLowerCase()} behavioral services`,
      });

      await submit(id, getChangedAttributes("behavior", attributes, values));
    };
    return (
      <div className="mt-12">
        <div className="flex items-center justify-between space-x-4 mb-4">
          <h3 className="text-2xl flex items-center">
            <img src={speciesMap[species].icon} alt="" className="mr-4" />
            {speciesMap[species].label}
          </h3>
        </div>

        <Formik
          enableReinitialize
          initialValues={getServiceTypeAttributes(data, attributes)}
          onSubmit={(values) => handleSubmit(values)}
          innerRef={ref}
        >
          {() => (
            <Form>
              {/* Form Table */}
              <Table
                data={data}
                columns={columns}
                columnAlignments={[null, null, null, null, "text-right"]}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
);

BehavioralServicesTable.propTypes = {
  attributes: PropTypes.object.isRequired,
  species: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};

export default BehavioralServicesTable;
