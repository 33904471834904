import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { get } from "lodash";
import { useAuthState, useAuthDispatch } from "context";
import {
  usePermissions,
  ROUTE_GUARDS,
  apiFetch,
  formatUiErrors,
  getTotalPages,
  camelToTitle,
} from "utils";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import Button from "components/Button";
import Table from "components/Table";
import Pagination from "components/Pagination";
import AlertModal from "components/AlertModal";
import ConfirmationModal from "components/ConfirmationModal";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";

const Users = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const { coalitionId } = useParams();
  const permissions = usePermissions();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [userToRemove, setUserToRemove] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState(null);

  const getUsers = ({ page = 1 }) =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/users?&filter[coalition_id][eq]=${coalitionId}&page[number]=${page}&stats[total]=count&sort=full_name&include=user_memberships&filter[user_memberships.resource_id]=${coalitionId}`,
      includeMeta: true,
    });

  // Initial query
  const {
    data: response = { data: [], included: [] },
    error,
    isLoading,
  } = useQuery(
    ["coalitionUsers", { coalitionId, page }],
    async () => {
      const response = await getUsers({ page });
      response.data.map((user) => {
        const membership = response.included.find(
          (inc) =>
            String(inc.attributes.userId) === user.id &&
            String(inc.attributes.resourceId) === coalitionId
        );
        return Object.assign(user, {
          membership,
        });
      });
      return response;
    },
    {
      keepPreviousData: true,
    }
  );

  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["coalitionUsers", { coalitionId, page: page + 1 }],
        () => getUsers({ page: page + 1 })
      );
    }
  }, [response, page, queryClient]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    {
      accessor: "attributes.fullName",
      Header: "Name",
    },
    {
      accessor: "attributes.email",
      Header: "Email",
    },
    {
      accessor: "membership.attributes.role",
      Header: "Role",
      Cell: ({ cell }) => {
        const [isEdited, setEdited] = useState(false);
        const [role, setRole] = useState(cell.value);

        const handleRoleChange = async () => {
          const user = cell.row.original;
          user.membership.attributes.role = role;
          updateUser(user);
        };

        const editOrSave = (e) => {
          if (isEdited === true) {
            e.stopPropagation();
            setEdited(false);
            handleRoleChange();
          }
        };

        const changeRole = (newRole) => {
          if (newRole === role) {
            return;
          } else {
            setRole(newRole);
            setEdited(true);
          }
        };

        return (
          <div className="flex justify-between">
            <span className="self-center">{camelToTitle(role) || ""}</span>

            {permissions[ROUTE_GUARDS.COALITION_MANAGER]() && (
              <div className="w-20">
                <Menu
                  menuButton={
                    <MenuButton
                      className="actions-menu__trigger btn-sm"
                      data-testid="actions-menu-button"
                      index={cell.row.id}
                    >
                      <span onClick={(e) => editOrSave(e)}>
                        {!isEdited ? "Edit" : "Save"}
                      </span>
                    </MenuButton>
                  }
                  className="actions-menu"
                  align="end"
                >
                  <MenuItem onClick={() => changeRole("coalition_viewer")}>
                    Coalition Viewer
                  </MenuItem>
                  <MenuItem onClick={() => changeRole("coalition_manager")}>
                    Coalition Manager
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        );
      },
    },
    {
      accessor: "id",
      Header: "",
      Cell: ({ row: { original: user } }) => (
        <Button
          className="btn-sm"
          emphasis="transparent"
          onClick={() => openModal(user)}
        >
          Remove from Coalition
        </Button>
      ),
    },
  ];

  const openModal = (user) => {
    setUserToRemove(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalError(null);
    setUserToRemove(null);
  };

  const getResourceId = (userId) => {
    if (!response || !response.data) return null;

    const user = response.data.find((user) => {
      return user.id === userId;
    });

    return get(user, "membership.id") || null;
  };

  const { mutate: removeUser, isLoading: isLoadingRemoveUser } = useMutation(
    async ({ id }) => {
      const membershipId = getResourceId(id);
      return await apiFetch({
        token,
        dispatch,
        method: "DELETE",
        endpoint: `/api/v1/user_memberships/${membershipId}`,
        body: {
          data: {
            id: membershipId,
            type: "user_memberships",
          },
        },
      });
    },
    {
      onSuccess: () => {
        toast.success(
          `Removed ${userToRemove.attributes.fullName} successfully`,
          {
            autoClose: 3000,
          }
        );
        // Refresh the org members queries
        queryClient.invalidateQueries(["coalitionUsers", { coalitionId }], {
          refetchActive: true,
        });
        closeModal();
      },
      onError: setModalError,
    }
  );

  const { mutate: updateUser } = useMutation(
    async (user) => {
      const membershipId = getResourceId(user.id);
      return await apiFetch({
        token,
        dispatch,
        method: "PATCH",
        endpoint: `/api/v1/user_memberships/${membershipId}`,
        body: {
          data: {
            id: membershipId,
            type: "user_memberships",
            attributes: {
              role: user.membership.attributes.role,
            },
          },
        },
      });
    },
    {
      onSuccess: (res) => {
        toast.success(
          `Updated user ${res.data.attributes.userId} to ${res.data.attributes.role} successfully`,
          {
            autoClose: 3000,
          }
        );
        // Refresh the org members queries
        queryClient.invalidateQueries(["coalitionUsers", { coalitionId }], {
          refetchActive: true,
        });
      },
      onError: (err) => {
        console.log(err);
        toast.error(`Failed to update user to new role!`, {
          autoClose: 3000,
        });
      },
    }
  );

  return (
    <>
      <div className="mb-8">
        <h2>Users</h2>

        {permissions[ROUTE_GUARDS.COALITION_MANAGER]() && (
          <div className="flex space-x-2">
            <Button to={`/coalitions/${coalitionId}/users/add`}>
              Invite User
            </Button>
          </div>
        )}
      </div>

      <Table
        data={response.data || []}
        columns={columns}
        noResultsText="No users found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}

      <AlertModal isModalOpen={isModalOpen} closeModal={closeModal}>
        <ConfirmationModal
          prompt={`Are you sure you want to remove ${get(
            userToRemove,
            "attributes.fullName"
          )} from the coalition?`}
          isLoading={isLoadingRemoveUser}
          error={modalError}
          closeModal={closeModal}
          confirmModal={() => removeUser(userToRemove)}
        />
      </AlertModal>
    </>
  );
};

export default Users;
