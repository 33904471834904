import React from "react";

const IntakeOverviewFilter = (props) => {
  const { className, year } = props;
  return (
    <div
      className={`px-4 w-full py-2 border rounded-lg bg-super-light-gray text-sm text-center mb-3 ${className}`}
    >
      {year}
    </div>
  );
};

export default IntakeOverviewFilter;
