export const LIVE_INTAKE_KEYS = [
  { name: "Stray/At Large", key: "totalStrayAtLargeCount" },
  {
    name: "Relinquished by Owner",
    key: "totalRelinquishedByOwnerCount",
  },
  {
    name: "Transferred in from agency (in state) ",
    key: "totalTransferredInstateInFromAgencyCount",
  },
  {
    name: "Transferred in from agency (out of state)",
    key: "totalTransferredOutOfStateInFromAgencyCount",
  },
  {
    name: "Transferred in from agency (international)",
    key: "totalTransferredInternationallyInFromAgencyCount",
  },
  {
    name: "Transferred in from agency (undesignated)",
    key: "totalLegacyTransferredInFromAgencyCount",
  },
  {
    name: "Owner Intended Euthanasia",
    key: "totalOwnerIntendedEuthanasiaCount",
  },
  { name: "Seizure", key: "totalSeizedOrImpoundedCount" },
  { name: "Other Intakes", key: "totalOtherIntakesCount" },
  { name: "TOTAL LIVE INTAKES", key: "total" },
];

export const LIVE_OUTCOMES_KEYS = [
  { name: "Adoption", key: "totalAdoptionCount" },
  {
    name: "Returned to Owner (RTO)",
    key: "totalReturnedToOwnerCount",
  },
  {
    name: "Transferred out to agency (InState) ",
    key: "totalTransferredInstateToAnotherAgencyCount",
  },
  {
    name: "Transferred out to agency (out of state)",
    key: "totalTransferredOutOfStateToAnotherAgencyCount",
  },
  {
    name: "Transferred out to agency (international)",
    key: "totalTransferredInternationallyToAnotherAgencyCount",
  },
  {
    name: "Transferred out to agency (undesignated)",
    key: "totalLegacyTransferredToAnotherAgencyCount",
  },
  {
    name: "Returned to Field (RTF)",
    key: "totalReturnedToFieldCount",
  },
  {
    name: "Other Live Outcomes",
    key: "totalOtherLiveOutcomeCount",
  },
  { name: "TOTAL OUTCOMES", key: "total" },
];

export const OTHER_OUTCOMES = [
  { name: "Died in Care", key: "totalDiedInCareCount" },
  { name: "Lost in Care ", key: "totalLostInCareCount" },
  {
    name: "Shelter Euthanasia",
    key: "totalShelterEuthanasiaCount",
  },
  {
    name: "Owner Intended Euthanasia",
    key: "totalOwnerIntendedEuthanasiaCount",
  },
  { name: "TOTAL OTHER OUTCOMES ", key: "total" },
];

export const SP_LIVE_INTAKE_KEYS = [
  {
    name: "Stray/At Large",
    key1: "adultStrayAtLargeCount",
    key2: "youthStrayAtLargeCount",
    key3: "ageUnknownStrayAtLargeCount",
  },
  {
    name: "Relinquished by Owner",
    key1: "adultRelinquishedByOwnerCount",
    key2: "youthRelinquishedByOwnerCount",
    key3: "ageUnknownRelinquishedByOwnerCount",
  },
  {
    name: "Transferred in from agency (in state) ",
    key1: "adultTransferredInstateInFromAgencyCount",
    key2: "youthTransferredInstateInFromAgencyCount",
    key3: "ageUnknownTransferredInstateInFromAgencyCount",
  },
  {
    name: "Transferred in from agency (out of state)",
    key1: "adultTransferredOutOfStateInFromAgencyCount",
    key2: "youthTransferredOutOfStateInFromAgencyCount",
    key3: "ageUnknownTransferredOutOfStateInFromAgencyCount",
  },
  {
    name: "Transferred in from agency (international)",
    key1: "adultTransferredInternationallyInFromAgencyCount",
    key2: "youthTransferredInternationallyInFromAgencyCount",
    key3: "ageUnknownTransferredInternationallyInFromAgencyCount",
  },
  {
    subname: "undesignated",
    name: "Transferred in from agency (undesignated)",
    key1: "AdultLegacyTransferredInFromAgencyCount",
    key2: "YouthLegacyTransferredInFromAgencyCount",
    key3: "AgeUnknownLegacyTransferredInFromAgencyCount",
  },
  {
    name: "Owner Intended Euthanasia",
    key1: "adultOwnerIntendedEuthanasiaCount",
    key2: "youthOwnerIntendedEuthanasiaCount",
    key3: "ageUnknownOwnerIntendedEuthanasiaCount",
  },
  {
    name: "Seizure",
    key1: "adultSeizedOrImpoundedCount",
    key2: "youthSeizedOrImpoundedCount",
    key3: "ageUnknownSeizedOrImpoundedCount",
  },
  {
    name: "Other Intakes",
    key1: "adultOtherIntakesCount",
    key2: "youthOtherIntakesCount",
    key3: "ageUnknownOtherIntakesCount",
  },
  { name: "TOTAL LIVE INTAKES", key: "total" },
];

export const SP_LIVE_OUTCOMES_KEYS = [
  {
    name: "Adoption",
    key1: "adultAdoptionCount",
    key2: "youthAdoptionCount",
    key3: "ageUnknownAdoptionCount",
  },
  {
    name: "Returned to Owner (RTO)",
    key1: "adultReturnedToOwnerCount",
    key2: "youthReturnedToOwnerCount",
    key3: "ageUnknownReturnedToOwnerCount",
  },
  {
    name: "Transferred out to agency (InState) ",
    key1: "adultTransferredInstateToAnotherAgencyCount",
    key2: "youthTransferredInstateToAnotherAgencyCount",
    key3: "ageUnknownTransferredInstateToAnotherAgencyCount",
  },
  {
    name: "Transferred out to agency (out of state)",
    key1: "adultTransferredOutOfStateToAnotherAgencyCount",
    key2: "youthTransferredOutOfStateToAnotherAgencyCount",
    key3: "ageUnknownTransferredOutOfStateToAnotherAgencyCount",
  },
  {
    name: "Transferred out to agency (international)",
    key1: "adultTransferredInternationallyToAnotherAgencyCount",
    key2: "youthTransferredInternationallyToAnotherAgencyCount",
    key3: "ageUnknownTransferredInternationallyToAnotherAgencyCount",
  },
  {
    subname: "undesignated",
    name: "Transferred out to agency (undesignated)",
    key1: "AdultLegacyTransferredToAnotherAgencyCount",
    key2: "YouthLegacyTransferredToAnotherAgencyCount",
    key3: "AgeUnknownLegacyTransferredToAnotherAgencyCount",
  },
  {
    name: "Returned to Field (RTF)",
    key1: "adultReturnedToFieldCount",
    key2: "youthReturnedToFieldCount",
    key3: "ageUnknownReturnedToFieldCount",
  },
  {
    name: "Other Live Outcomes",
    key1: "adultOtherLiveOutcomeCount",
    key2: "youthOtherLiveOutcomeCount",
    key3: "ageUnknownOtherLiveOutcomeCount",
  },
  { name: "TOTAL OUTCOMES", key: "total" },
];

export const SP_OTHER_OUTCOMES = [
  {
    name: "Died in Care",
    key1: "adultDiedInCareCount",
    key2: "youthDiedInCareCount",
    key3: "ageUnknownDiedInCareCount",
  },
  {
    name: "Lost in Care ",
    key1: "adultLostInCareCount",
    key2: "youthLostInCareCount",
    key3: "ageUnknownLostInCareCount",
  },
  {
    name: "Shelter Euthanasia",
    key1: "adultShelterEuthanasiaCount",
    key2: "youthShelterEuthanasiaCount",
    key3: "ageUnknownShelterEuthanasiaCount",
  },
  {
    name: "Owner Intended Euthanasia",
    key1: "adultOwnerIntendedEuthanasiaCountOutCome",
    key2: "youthOwnerIntendedEuthanasiaCountOutCome",
    key3: "ageUnknownOwnerIntendedEuthanasiaCountOutCome",
  },
  { name: "TOTAL OTHER OUTCOMES ", key: "total" },
];

const totalTransferDataSumIsNotZero = (data) => {
  const keys = [
    "totalTransferredInstateInFromAgencyCount",
    "totalTransferredOutOfStateInFromAgencyCount",
    "totalTransferredInternationallyInFromAgencyCount",
    "totalTransferredInstateToAnotherAgencyCount",
    "totalTransferredOutOfStateToAnotherAgencyCount",
    "totalTransferredInternationallyToAnotherAgencyCount",
  ];
  const sum = keys.reduce(
    (total, key) => total + (data.attributes[key] || 0),
    0
  );
  const year = data.attributes.yearOfRecord;
  const month = data.attributes.monthOfRecord;

  const priorDate = new Date(2021, 2);
  const currentDate = new Date(year, month - 1);

  const isPriorDate = currentDate < priorDate;
  return sum > 0 && isPriorDate;
};

export const generateLiveIntakeData = (intakes, keys) => {
  var intakesData = [];

  var feline = intakes.filter((a) => a.attributes.species === "feline");
  var canine = intakes.filter((a) => a.attributes.species === "canine");
  var orther = intakes.filter(
    (a) =>
      a.attributes.species !== "canine" && a.attributes.species !== "feline"
  );

  for (let i = 0; i < keys.length - 1; i++) {
    let key = keys[i];
    var c = 0,
      f = 0,
      o = 0;

    f = feline
      .map((data) => {
        if (totalTransferDataSumIsNotZero(data)) {
          if (
            key.key !== "totalLegacyTransferredInFromAgencyCount" &&
            key.key !== "totalLegacyTransferredToAnotherAgencyCount"
          ) {
            return data.attributes[key.key];
          }
        } else {
          return data.attributes[key.key];
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    c = canine
      .map((data) => {
        if (totalTransferDataSumIsNotZero(data)) {
          if (
            key.key !== "totalLegacyTransferredInFromAgencyCount" &&
            key.key !== "totalLegacyTransferredToAnotherAgencyCount"
          ) {
            return data.attributes[key.key];
          }
        } else {
          return data.attributes[key.key];
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    o = orther
      .map((data) => {
        if (totalTransferDataSumIsNotZero(data)) {
          if (
            key.key !== "totalLegacyTransferredInFromAgencyCount" &&
            key.key !== "totalLegacyTransferredToAnotherAgencyCount"
          ) {
            return data.attributes[key.key];
          }
        } else {
          return data.attributes[key.key];
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    intakesData.push({
      name: key.name,
      canine: c,
      feline: f,
      other: o,
    });
  }

  intakesData.push({
    name: keys[keys.length - 1].name,
    canine: intakesData
      .map((a) => a.canine)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
    feline: intakesData
      .map((a) => a.feline)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
    other: intakesData
      .map((a) => a.other)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
  });
  return intakesData;
};

export const generateCountsData = (data, month) => {
  var countsData = [];
  var feline = data
    .filter((a) => a.attributes.species === "feline")
    .sort((a, b) => {
      return a.attributes.monthOfRecord - b.attributes.monthOfRecord;
    });
  var canine = data
    .filter((a) => a.attributes.species === "canine")
    .sort((a, b) => {
      return a.attributes.monthOfRecord - b.attributes.monthOfRecord;
    });
  var orther = data
    .filter(
      (a) =>
        a.attributes.species !== "canine" && a.attributes.species !== "feline"
    )
    .sort((a, b) => {
      return a.attributes.monthOfRecord - b.attributes.monthOfRecord;
    });
  var s_c = canine;
  var s_f = feline;
  var s_o = orther;

  var l_c = canine;
  var l_f = feline;
  var l_o = orther;

  if (month !== "") {
    var years = canine
      .map((a) => a.attributes.yearOfRecord)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(function (a, b) {
        return a - b;
      });
    var months_of_first_year = canine
      .filter((a) => a.attributes.yearOfRecord == years[0])
      .map((a) => a.attributes.monthOfRecord)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(function (a, b) {
        return a - b;
      });

    s_c = canine.filter(
      (a) =>
        a.attributes.monthOfRecord == months_of_first_year[0] &&
        a.attributes.yearOfRecord == years[0]
    );
    s_f = feline.filter(
      (a) =>
        a.attributes.monthOfRecord == months_of_first_year[0] &&
        a.attributes.yearOfRecord == years[0]
    );
    s_o = orther.filter(
      (a) =>
        a.attributes.monthOfRecord == months_of_first_year[0] &&
        a.attributes.yearOfRecord == years[0]
    );

    // get last month record
    var last_day = years[years.length - 1];
    var months_of_last_year = canine
      .filter((a) => a.attributes.yearOfRecord == last_day)
      .map((a) => a.attributes.monthOfRecord)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(function (a, b) {
        return a - b;
      });
    months_of_last_year = months_of_last_year[months_of_last_year.length - 1];
    l_c = canine.filter(
      (a) =>
        a.attributes.monthOfRecord == months_of_last_year &&
        a.attributes.yearOfRecord == last_day
    );
    l_f = feline.filter(
      (a) =>
        a.attributes.monthOfRecord == months_of_last_year &&
        a.attributes.yearOfRecord == last_day
    );
    l_o = orther.filter(
      (a) =>
        a.attributes.monthOfRecord == months_of_last_year &&
        a.attributes.yearOfRecord == last_day
    );
  } else {
    var months = canine
      .map((a) => a.attributes.monthOfRecord)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(function (a, b) {
        return a - b;
      });

    s_c = canine.filter((a) => a.attributes.monthOfRecord == months[0]);
    s_f = feline.filter((a) => a.attributes.monthOfRecord == months[0]);
    s_o = orther.filter((a) => a.attributes.monthOfRecord == months[0]);

    // get last month record
    var months_of_last_year = months[months.length - 1];
    l_c = canine.filter(
      (a) => a.attributes.monthOfRecord == months_of_last_year
    );
    l_f = feline.filter(
      (a) => a.attributes.monthOfRecord == months_of_last_year
    );
    l_o = orther.filter(
      (a) => a.attributes.monthOfRecord == months_of_last_year
    );
  }
  var otherTotalStart = s_o
    .map((a) => a.attributes.beginningAnimalCount)
    .reduce((a, b) => a + b, 0);
  var otherTotalFosterStart = s_o
    .map((a) => a.attributes.beginningFosterAnimalCount)
    .reduce((a, b) => a + b, 0);

  var otherTotalEnd = l_o
    .map((a) => a.attributes.endingAnimalCount)
    .reduce((a, b) => a + b, 0);
  var otherTotalFosterEnd = l_o
    .map((a) => a.attributes.endingFosterAnimalCount)
    .reduce((a, b) => a + b, 0);

  countsData.push({
    name: "Beginning Count",
    canine_c: s_c.length > 0 ? s_c[0].attributes.beginningAnimalCount : 0,
    canine_f: s_c.length > 0 ? s_c[0].attributes.beginningFosterAnimalCount : 0,
    feleine_c: s_f.length > 0 ? s_f[0].attributes.beginningAnimalCount : 0,
    feleine_f:
      s_f.length > 0 ? s_f[0].attributes.beginningFosterAnimalCount : 0,
    other_c: otherTotalStart,
    other_f: otherTotalFosterStart,
  });
  countsData.push({
    name: "Ending Count",
    canine_c: l_c.length > 0 ? l_c[0].attributes.endingAnimalCount : 0,
    canine_f: l_c.length > 0 ? l_c[0].attributes.endingFosterAnimalCount : 0,
    feleine_c: l_f.length > 0 ? l_f[0].attributes.endingAnimalCount : 0,
    feleine_f: l_f.length > 0 ? l_f[0].attributes.endingFosterAnimalCount : 0,
    other_c: otherTotalEnd,
    other_f: otherTotalFosterEnd,
  });
  return countsData;
};

export const generateYOYData = (
  liveIntakes,
  liveOutComes,
  otherOutcomes,
  year
) => {
  var YOYdata = [];
  YOYdata.push({
    name: year,
    live_intake_c: liveIntakes[liveIntakes.length - 1].canine,
    live_outcome_c: liveOutComes[liveOutComes.length - 1].canine,
    other_outcome_c: otherOutcomes[otherOutcomes.length - 1].canine,
    total_outcome_c:
      otherOutcomes[otherOutcomes.length - 1].canine +
      liveOutComes[liveOutComes.length - 1].canine,
    live_intake_f: liveIntakes[liveIntakes.length - 1].feleine,
    live_outcome_f: liveOutComes[liveOutComes.length - 1].feleine,
    other_outcome_f: otherOutcomes[otherOutcomes.length - 1].feleine,
    total_outcome_f:
      liveOutComes[liveOutComes.length - 1].feleine +
      otherOutcomes[otherOutcomes.length - 1].feleine,
    live_intake_o: liveIntakes[liveIntakes.length - 1].other,
    live_outcome_o: liveOutComes[liveOutComes.length - 1].other,
    other_outcome_o: otherOutcomes[otherOutcomes.length - 1].other,
    total_outcome_o:
      otherOutcomes[otherOutcomes.length - 1].other +
      liveOutComes[liveOutComes.length - 1].other,
  });
  return YOYdata;
};

export const getSPServices = (data, sp, keys) => {
  var current = data.filter((a) => a.attributes.species === sp);
  var specs = [];
  if (current.length !== 0) {
    for (let i = 0; i < keys.length - 1; i++) {
      if (keys[i].name !== "undesignated") {
        specs.push({
          name: keys[i].name,
          key1: current
            .map((a) => a.attributes[keys[i].key1])
            .reduce((a, b) => a + b, 0),
          key2: current
            .map((a) => a.attributes[keys[i].key2])
            .reduce((a, b) => a + b, 0),
          key3: current
            .map((a) => a.attributes[keys[i].key3])
            .reduce((a, b) => a + b, 0),
        });
      } else {
        specs.push({
          name: keys[i].rename,
          key1: 0,
          key2: 0,
          key3: 0,
        });
      }
    }
    specs.push({
      name: keys[keys.length - 1].name,
      key1: specs.map((a) => a.key1).reduce((a, b) => a + b, 0),
      key2: specs.map((a) => a.key2).reduce((a, b) => a + b, 0),
      key3: specs.map((a) => a.key3).reduce((a, b) => a + b, 0),
    });
  }
  return specs;
};

const isSumGreaterThanZero = (obj) => {
  const keys = [
    "adultTransferredInstateInFromAgencyCount",
    "youthTransferredInstateInFromAgencyCount",
    "ageUnknownTransferredInstateInFromAgencyCount",
    "adultTransferredOutOfStateInFromAgencyCount",
    "youthTransferredOutOfStateInFromAgencyCount",
    "ageUnknownTransferredOutOfStateInFromAgencyCount",
    "adultTransferredInternationallyInFromAgencyCount",
    "youthTransferredInternationallyInFromAgencyCount",
    "ageUnknownTransferredInternationallyInFromAgencyCount",

    "adultTransferredInstateToAnotherAgencyCount",
    "youthTransferredInstateToAnotherAgencyCount",
    "ageUnknownTransferredInstateToAnotherAgencyCount",
    "adultTransferredOutOfStateToAnotherAgencyCount",
    "youthTransferredOutOfStateToAnotherAgencyCount",
    "ageUnknownTransferredOutOfStateToAnotherAgencyCount",
    "adultTransferredInternationallyToAnotherAgencyCount",
    "youthTransferredInternationallyToAnotherAgencyCount",
    "ageUnknownTransferredInternationallyToAnotherAgencyCount",
  ];
  const sum = keys.reduce((total, key) => total + (obj[key] || 0), 0);
  return sum > 0;
};

export const getSPIntakes = (data, sp, keys) => {
  const current = data.filter((a) => a.attributes.species === sp);
  let specieData = [];
  if (current.length !== 0) {
    for (let i = 0; i < keys.length - 1; i++) {
      specieData.push({
        name: keys[i].name,
        key1: current
          .map((a) => {
            if (isSumGreaterThanZero(a.attributes)) {
              if (keys[i].subname !== "undesignated") {
                return a.attributes[keys[i].key1];
              }
            } else {
              return a.attributes[keys[i].key1];
            }
            return 0;
          })
          .reduce((a, b) => (a || 0) + (b || 0), 0),
        key2: current
          .map((a) => {
            if (isSumGreaterThanZero(a.attributes)) {
              if (keys[i].subname !== "undesignated") {
                return a.attributes[keys[i].key2];
              }
            } else {
              return a.attributes[keys[i].key2];
            }
            return 0;
          })
          .reduce((a, b) => (a || 0) + (b || 0), 0),
        key3: current
          .map((a) => {
            if (isSumGreaterThanZero(a.attributes)) {
              if (keys[i].subname !== "undesignated") {
                return a.attributes[keys[i].key3];
              }
            } else {
              return a.attributes[keys[i].key3];
            }
            return 0;
          })
          .reduce((a, b) => (a || 0) + (b || 0), 0),
      });
    }
  }
  specieData.push({
    name: keys[keys.length - 1].name,
    key1: specieData
      .map((a) => a.key1)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
    key2: specieData
      .map((a) => a.key2)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
    key3: specieData
      .map((a) => a.key3)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
  });

  return specieData;
};

export const generateCountsDataSP = (data, SP, month) => {
  var current = data.filter((a) => a.attributes.species === SP);
  var countsData = [];

  var years = current
    .map((a) => a.attributes.yearOfRecord)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort(function (a, b) {
      return a - b;
    });
  var months_of_first_year = current
    .filter((a) => a.attributes.yearOfRecord == years[0])
    .map((a) => a.attributes.monthOfRecord)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort(function (a, b) {
      return a - b;
    });
  var months = current
    .map((a) => a.attributes.monthOfRecord)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort(function (a, b) {
      return a - b;
    });

  var last_day = years[years.length - 1];
  var months_of_last_year = current
    .filter((a) => a.attributes.yearOfRecord == last_day)
    .map((a) => a.attributes.monthOfRecord)
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort(function (a, b) {
      return a - b;
    });
  months_of_last_year = months_of_last_year[months_of_last_year.length - 1];

  if (current.length > 0) {
    var s_c = current;
    var l_c = current;
    if (month !== "") {
      s_c = current.filter(
        (a) =>
          a.attributes.monthOfRecord == months_of_first_year[0] &&
          a.attributes.yearOfRecord == years[0]
      );
      l_c = current.filter(
        (a) =>
          a.attributes.monthOfRecord == months_of_last_year &&
          a.attributes.yearOfRecord == last_day
      );
    } else {
      var months_of_last_year = months[months.length - 1];
      s_c = current.filter((a) => a.attributes.monthOfRecord == months[0]);
      l_c = current.filter(
        (a) => a.attributes.monthOfRecord == months_of_last_year
      );
    }
    countsData.push({
      name: "Beginning Count",
      total_incare:
        s_c.length > 0 ? s_c[0].attributes.beginningAnimalCount : "",
      total_in_foster:
        s_c.length > 0 ? s_c[0].attributes.beginningFosterAnimalCount : "",
    });
    countsData.push({
      name: "Ending Count",
      total_incare: l_c.length > 0 ? l_c[0].attributes.endingAnimalCount : "",
      total_in_foster:
        l_c.length > 0 ? l_c[0].attributes.endingFosterAnimalCount : "",
    });
  }
  return countsData;
};

export const generateFieldDataServices = (intakes, keys) => {
  var intakesData = [];
  var feline = intakes.filter((a) => a.attributes.species === "feline");
  var canine = intakes.filter((a) => a.attributes.species === "canine");
  var other = intakes.filter(
    (a) =>
      a.attributes.species !== "canine" && a.attributes.species !== "feline"
  );
  for (let i = 0; i < keys.length - 1; i++) {
    let key = keys[i];
    var c = 0,
      f = 0,
      o = 0;

    c = canine
      .map((data) => {
        if (key.key !== "alltotal") {
          return data.attributes[key.key] || 0;
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    f = feline
      .map((data) => {
        if (key.key !== "alltotal") {
          return data.attributes[key.key] || 0;
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    o = other
      .map((data) => {
        if (key.key !== "alltotal") {
          return data.attributes[key.key] || 0;
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    intakesData.push({
      name: key.name,
      canine: c,
      feline: f,
      other: o,
    });
  }
  intakesData.push({
    name: keys[keys.length - 1].name,
    canine: intakesData
      .map((a) => a.canine)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
    feline: intakesData
      .map((a) => a.feline)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
    other: intakesData
      .map((a) => a.other)
      .reduce((a, b) => (a || 0) + (b || 0), 0),
  });
  return intakesData;
};

export const FIELD_DATA = [
  { name: "Trap-Neuter-Return (TNR)", key: "totalTrapNeuterReturnCount" },
  {
    name: "Return-to-Owner in the Field",
    key: "totalReturnToOwnerCount",
  },
  { name: "TOTAL FIELD SERVICES", key: "alltotal" },
];

export const VETERINAY_DATA = [
  {
    name: "Microchipping and/or Collar with ID Tag",
    key: "totalMicrochippingCollarIdTagsCount",
  },
  {
    name: "Spay/Neuter for OwnedAnimals",
    key: "totalSpayNeuterOwnedAnimalCount",
  },
  {
    name: "Wellness/Preventative Medical Care",
    key: "totalWellnessPreventiveMedicalCareCount",
  },
  {
    name: "Sick Animal Care/Basic Veterinary Care",
    key: "totalBasicAnimalCareCount",
  },
  {
    name: "Sick Animal Care/Advanced Veterinary Care",
    key: "totalAdvancedAnimalCareCount",
  },
  {
    name: "Owner Intended Euthanasia(OIE)",
    key: "totalOwnerIntendedEuthanasiaCount",
  },
  { name: "TOTAL VETERINARY SERVICES", key: "alltotal" },
];

export const BEHAVIORAL_DATA = [
  { name: "Behavior Consultation", key: "totalBehaviorConsultationCount" },
  { name: "Group Training", key: "totalGroupTrainingCount" },
  { name: "Private Training", key: "totalPrivateTrainingCount" },
  {
    name: "Behavior Modification Program",
    key: "totalBehaviorModificationProgramCount",
  },
  { name: "TOTAL BEHAVIORAL SERVICES", key: "alltotal" },
];

export const SUPPORT_DATA = [
  { name: "Pet Food", key: "totalPetFoodCount" },
  { name: "Pet Supplies", key: "totalPetSuppliesCount" },
  { name: "Grooming", key: "totalGroomingCount" },
  {
    name: "Temporary Housing for Owned Animals",
    key: "totalTemporaryHousingOwnedAnimalsCount",
  },
  {
    name: "Temporary Housing for People and Animals",
    key: "totalTemporaryHousingPeopleAndAnimalsCount",
  },
  {
    name: "Other Pet RetentionServices",
    key: "totalOtherPetRetentionServicesCount",
  },
  { name: "Rehoming Support", key: "totalRehomingSupportCount" },
  { name: "TOTAL SUPPORT SERVICES", key: "alltotal" },
];

export const SP_FIELD_DATA_KEYS = [
  {
    name: "Trap-Neuter-Return (TNR)",
    key1: "adultTrapNeuterReturnCount",
    key2: "youthTrapNeuterReturnCount",
    key3: "ageUnknownTrapNeuterReturnCount",
  },
  {
    name: "Return-to-Owner in the Field",
    key1: "adultReturnToOwnerCount",
    key2: "youthReturnToOwnerCount",
    key3: "ageUnknownReturnToOwnerCount",
  },
  { name: "TOTAL FIELD SERVICES", key: "total" },
];

export const SP_VETERINAY_DATA_KEYS = [
  {
    name: "Microchipping and/or Collar with ID Tag",
    key1: "adultMicrochippingCollarIdTagsCount",
    key2: "youthMicrochippingCollarIdTagsCount",
    key3: "ageUnknownMicrochippingCollarIdTagsCount",
  },
  {
    name: "Spay/Neuter for OwnedAnimals",
    key1: "adultSpayNeuterOwnedAnimalCount",
    key2: "youthSpayNeuterOwnedAnimalCount",
    key3: "ageUnknownSpayNeuterOwnedAnimalCount",
  },
  {
    name: "Wellness/Preventative Medical Care",
    key1: "adultWellnessPreventiveMedicalCareCount",
    key2: "youthWellnessPreventiveMedicalCareCount",
    key3: "ageUnknownWellnessPreventiveMedicalCareCount",
  },
  {
    name: "Sick Animal Care/Basic Veterinary Care",
    key1: "adultBasicAnimalCareCount",
    key2: "youthBasicAnimalCareCount",
    key3: "ageUnknownBasicAnimalCareCount",
  },
  {
    name: "Sick Animal Care/Advanced Veterinary Care",
    key1: "adultAdvancedAnimalCareCount",
    key2: "youthAdvancedAnimalCareCount",
    key3: "ageUnknownAdvancedAnimalCareCount",
  },
  {
    name: "Owner Intended Euthanasia(OIE)",
    key1: "adultOwnerIntendedEuthanasiaCount",
    key2: "youthOwnerIntendedEuthanasiaCount",
    key3: "ageUnknownOwnerIntendedEuthanasiaCount",
  },
  { name: "TOTAL VETERINARY SERVICES", key: "total" },
];

export const SP_BEHAVIORAL_DATA_KEYS = [
  {
    name: "Behavior Consultation",
    key1: "adultBehaviorConsultationCount",
    key2: "youthBehaviorConsultationCount",
    key3: "ageUnknownBehaviorConsultationCount",
  },
  {
    name: "Group Training",
    key1: "adultGroupTrainingCount",
    key2: "youthGroupTrainingCount",
    key3: "ageUnknownGroupTrainingCount",
  },
  {
    name: "Private Training",
    key1: "adultPrivateTrainingCount",
    key2: "youthPrivateTrainingCount",
    key3: "ageUnknownPrivateTrainingCount",
  },
  {
    name: "Behavior Modification Program",
    key1: "adultBehaviorModificationProgramCount",
    key2: "youthBehaviorModificationProgramCount",
    key3: "ageUnknownBehaviorModificationProgramCount",
  },
  { name: "TOTAL BEHAVIORAL SERVICES", key: "total" },
];

export const SP_SUPPORT_DATA_KEYS = [
  {
    name: "Pet Food",
    key1: "adultPetFoodCount",
    key2: "youthPetFoodCount",
    key3: "ageUnknownPetFoodCount",
  },
  {
    name: "Pet Supplies",
    key1: "adultPetSuppliesCount",
    key2: "youthPetSuppliesCount",
    key3: "ageUnknownPetSuppliesCount",
  },
  {
    name: "Grooming",
    key1: "adultGroomingCount",
    key2: "youthGroomingCount",
    key3: "ageUnknownGroomingCount",
  },
  {
    name: "Temporary Housing for Owned Animals",
    key1: "adultTemporaryHousingOwnedAnimalsCount",
    key2: "youthTemporaryHousingOwnedAnimalsCount",
    key3: "ageUnknownTemporaryHousingOwnedAnimalsCount",
  },
  {
    name: "Temporary Housing for People and Animals",
    key1: "adultTemporaryHousingPeopleAndAnimalsCount",
    key2: "youthTemporaryHousingPeopleAndAnimalsCount",
    key3: "ageUnknownTemporaryHousingPeopleAndAnimalsCount",
  },
  {
    name: "Other Pet Retention Services",
    key1: "adultOtherPetRetentionServicesCount",
    key2: "youthOtherPetRetentionServicesCount",
    key3: "ageUnknownOtherPetRetentionServicesCount",
  },
  {
    name: "Rehoming Support",
    key1: "adultRehomingSupportCount",
    key2: "youthRehomingSupportCount",
    key3: "ageUnknownRehomingSupportCount",
  },
  { name: "TOTAL SUPPORT SERVICES", key: "total" },
];
