import React, { useState, useEffect } from "react";
import { Field, useField, useFormikContext } from "formik";
import { get } from "lodash";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

const FormField = ({ ...props }) => {
  const propsClone = { ...props };
  const {
    label,
    required,
    readOnly,
    type,
    tooltip,
    tooltipWidthClass,
    className,
  } = props;
  delete propsClone.tooltipWidthClass;

  const [field] = useField(props);
  const { name } = field;
  const { errors, touched } = useFormikContext();
  const [fieldErrors, setFieldErrors] = useState(null);
  const [isCheckbox, setIsCheckbox] = useState(false);

  useEffect(() => {
    const fieldErrors = get(errors, name);
    setFieldErrors(fieldErrors && get(touched, name) ? fieldErrors : null);
  }, [name, errors, touched]);

  useEffect(() => {
    setIsCheckbox(type === "checkbox");
  }, [type]);

  return (
    <div>
      <label
        htmlFor={name}
        className={
          isCheckbox ? "flex flex-row-reverse items-center justify-end" : ""
        }
      >
        <LabelContent
          label={label}
          required={required}
          readOnly={readOnly}
          name={name}
          tooltip={tooltip}
          tooltipWidthClass={tooltipWidthClass}
          isCheckbox={isCheckbox}
        />
        <Field
          {...field}
          {...propsClone}
          id={name}
          className={`${
            !isCheckbox ? "form-input w-full" : "mr-2"
          } ${className}`}
        />
      </label>
      <FieldErrors fieldErrors={fieldErrors} />
    </div>
  );
};

export default FormField;

const LabelContent = ({
  label,
  required,
  readOnly,
  tooltip,
  tooltipWidthClass,
  name,
  isCheckbox,
}) => {
  return (
    <span
      className={`flex items-center space-x-2 ${
        !isCheckbox ? "mb-1" : "text-base"
      } ${readOnly ? "text-medium-gray font-body-bold" : ""}`}
    >
      <span>
        {label}
        {required && !readOnly && <span className="text-sm required">*</span>}
      </span>

      {tooltip && (
        <Tooltip name={name} tooltip={tooltip} widthClass={tooltipWidthClass} />
      )}
    </span>
  );
};

const Tooltip = ({ name, tooltip, widthClass }) => (
  <>
    <FontAwesomeIcon
      icon={faQuestionCircle}
      className="text-dashboard-medium-gray"
      data-tip
      data-for={name}
    />
    <ReactTooltip place="right" className={widthClass} id={name}>
      {tooltip}
    </ReactTooltip>
  </>
);

const FieldErrors = ({ fieldErrors }) => {
  return fieldErrors ? (
    <div className="text-sm text-red mt-1">{fieldErrors}</div>
  ) : null;
};
