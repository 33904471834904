import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#ccc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
  },
  description: {
    width: "44%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    paddingLeft: 10,
    borderColor: borderColor,
    borderWidth: 1,
  },
  rowWidth: {
    width: "18%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    textAlign: "center",
    borderColor: borderColor,
    borderWidth: 1,
  },
  rowWidth2: {
    width: "10%",
    height: 20,
    paddingTop: 5,
    fontSize: 9,
    textAlign: "center",
    borderColor: borderColor,
    borderWidth: 1,
  },
});

const SpecieTableRow = ({ items }) => {
  const rows = items.map((item, i) => {
    return i + 1 === items.length ? (
      <View style={styles.row}>
        <Text
          style={[styles.description, { border: 1, borderColor: "#1f6165" }]}
        >
          {item.name}
        </Text>
        <Text style={[styles.rowWidth2, { border: 1, borderColor: "#1f6165" }]}>
          {item.key1}
        </Text>
        <Text style={[styles.rowWidth, { border: 1, borderColor: "#1f6165" }]}>
          {item.key2}
        </Text>
        <Text style={[styles.rowWidth, { border: 1, borderColor: "#1f6165" }]}>
          {item.key3}
        </Text>
        <Text style={[styles.rowWidth2, { border: 1, borderColor: "#1f6165" }]}>
          {item.key1 + item.key2 + item.key3}
        </Text>
      </View>
    ) : (
      <View style={styles.row}>
        <Text style={styles.description}>{item.name}</Text>
        <Text style={styles.rowWidth2}>{item.key1}</Text>
        <Text style={styles.rowWidth}>{item.key2}</Text>
        <Text style={styles.rowWidth}>{item.key3}</Text>
        <Text style={styles.rowWidth2}>
          {item.key1 + item.key2 + item.key3}
        </Text>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default SpecieTableRow;
