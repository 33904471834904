import React from "react";
import CoalitionsNav from "components/CoalitionsNav";
import CoalitionsTable from "components/CoalitionsTable";
import PendingCoalitionContributors from "components/PendingCoalitionContributors";
import { useUserState } from "context";

const Coalitions = () => {
  const { organization, pendingCoalitionContributorRequests } = useUserState();

  return (
    <>
      <h1>All Coalitions</h1>

      <PendingCoalitionContributors
        data={pendingCoalitionContributorRequests}
      />

      <CoalitionsNav />

      <div className="tab__content">
        {/* TODO: Do we need to pass the entire organization object or can we just pass ID? */}
        <CoalitionsTable organization={organization} />
      </div>
    </>
  );
};

export default Coalitions;
