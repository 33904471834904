import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  apiFetch,
  formatUiErrors,
  FORM_MODES,
  usePermissions,
  ROUTE_GUARDS,
} from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import Button from "components/Button";
import OrganizationForm from "components/OrganizationForm";
import OrganizationManagers from "./components/OrganizationManagers";
import BackLink from "components/BackLink";

const OrganizationDetails = ({ formMode = FORM_MODES.VIEW }) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { isAdmin, isOrgAdmin } = useUserState();
  const { orgId } = useParams();
  const queryClient = useQueryClient();
  const history = useHistory();
  const permissions = usePermissions();

  const {
    isLoading,
    error,
    data: response,
  } = useQuery(["organization", orgId], () =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/organizations/${orgId}`,
    })
  );

  const isOrgMember = permissions[ROUTE_GUARDS.CAN_VIEW_ORG]();

  const communicationOptIn = response?.data.attributes.communicationsOptIn;

  const { mutate: resendAuthorizationEmail, isLoading: isResending } =
    useMutation(
      () =>
        apiFetch({
          token,
          dispatch,
          method: "POST",
          endpoint: "/api/v1/resend_authorization_emails",
          body: {
            data: {
              type: "resend_authorization_emails",
              attributes: {
                organizationId: orgId,
              },
            },
          },
        }),
      {
        onSuccess: () => {
          toast.success("Resent authorization email successfully", {
            autoClose: 3000,
          });
        },
        onError: (error) => {
          handleUpdateError(error);
        },
      }
    );

  const handleUpdateSuccess = (data) => {
    history.push(`/organizations/${orgId}`);
    window.location.reload();
    toast.success("Saved successfully", {
      autoClose: 3000,
    });
    return queryClient.setQueryData(["organization", orgId], data);
  };

  const handleUpdateError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  if (isLoading) {
    return (
      <LoadingIndicator inline content={<>Loading organization&hellip;</>} />
    );
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  return (
    <>
      <div className="mb-8">
        {isAdmin && (
          <BackLink
            link="/organizations"
            text="Back to organizations listing"
          />
        )}
        <h2>{formMode === FORM_MODES.EDIT && "Editing "}Details</h2>

        {isOrgAdmin && formMode === FORM_MODES.VIEW && isOrgMember && (
          <div className="flex space-x-2">
            <Button to={`/organizations/${orgId}/edit`}>
              Edit Organization
            </Button>

            {response.data.attributes.status === "registered" && (
              <Button
                onClick={resendAuthorizationEmail}
                emphasis="transparent"
                disabled={isResending}
                isLoading={isResending}
                loadingText={<>Resending authorization email&hellip;</>}
              >
                Resend Authorization Email
              </Button>
            )}
          </div>
        )}
      </div>

      {!isOrgMember && communicationOptIn && (
        <div className="w-1/2 float-right">
          <OrganizationManagers />
        </div>
      )}

      <OrganizationForm
        organization={response.data}
        formMode={formMode}
        onUpdateSuccess={handleUpdateSuccess}
        onUpdateError={handleUpdateError}
        orgId={orgId}
      />
    </>
  );
};

export default OrganizationDetails;
