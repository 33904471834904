import React from "react";
import { Switch, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Tabs from "components/Tabs";
import MarketingResources from "./components/MarketingResources/MarketingResources";
import VideoTutorials from "./components/VideoTutorials/VideoTutorials";

const ResourceCenter = () => {
  const { pathname } = useLocation();
  const tabs = [
    {
      to: "/resource-center/marketing-resources",
      label: "Marketing Resources",
      exact: false,
      isActive: () => {
        return pathname === "/resource-center/marketing-resources";
      },
    },
    {
      to: "/resource-center/video-tutorials",
      label: "Video Tutorials",
      exact: false,
      isActive: () => {
        return pathname === "/resource-center/video-tutorials";
      },
    },
  ];
  return (
    <>
      <h1>Resource Center</h1>

      <Tabs tabs={tabs} />

      <div className="tab__content">
        <Switch>
          <Route
            path="/resource-center/marketing-resources"
            exact
            component={MarketingResources}
          />
          <Route
            path="/resource-center/video-tutorials"
            exact
            component={VideoTutorials}
          />
        </Switch>
      </div>
    </>
  );
};

export default ResourceCenter;
