/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useUserState } from "context";

const IntakeOutcomeTotals = () => {
  const { organization } = useUserState();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://public.tableau.com/javascripts/api/viz_v1.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      class="tableauPlaceholder"
      id="viz1719588643863"
      style={{ position: "relative" }}
    >
      <noscript>
        <a href="#">
          <img
            alt="Shelter Summary"
            src="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;In&#47;Intake_Outcome_OrgID&#47;ShelterSummary&#47;1_rss.png"
            style={{ border: "none" }}
          />
        </a>
      </noscript>
      <object
        class="tableauViz"
        style={{ display: "none", width: "100%", height: 1100 }}
      >
        <param name="filter" value={`OrgID=${organization?.id || ""}`} />
        <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
        <param name="embed_code_version" value="3" />
        <param name="site_root" value="" />
        <param name="name" value="Intake_Outcome_OrgID&#47;ShelterSummary" />
        <param name="tabs" value="no" />
        <param name="toolbar" value="yes" />
        <param
          name="static_image"
          value="https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;In&#47;Intake_Outcome_OrgID&#47;ShelterSummary&#47;1.png"
        />
        <param name="animate_transition" value="yes" />
        <param name="display_static_image" value="yes" />
        <param name="display_spinner" value="yes" />
        <param name="display_overlay" value="yes" />
        <param name="display_count" value="yes" />
        <param name="language" value="en-US" />
      </object>
    </div>
  );
};

export default IntakeOutcomeTotals;
