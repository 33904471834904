import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import TableFormInput from "../common/TableFormInput";
import { useParams } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";
import { Formik, Form } from "formik";
import { cloneDeep } from "lodash";
import Table, {
  getRowData,
  rowLabelCell,
  getFormAttributes,
  transformAttributesForPayload,
} from "components/Table";
import { animalOutcomes } from "components/Table/__mocks__";
import { useAuthDispatch, useAuthState, useUserState } from "context";
import { apiFetch, speciesMap, useHandleRequestStates } from "utils";

const AnimalOutcomeForm = forwardRef(({ attributes, type, dataId }, ref) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const { year, month } = useParams();
  const { handleSuccess, handleError } = useHandleRequestStates();

  const {
    attributes: { locationId },
  } = useUserState();

  // Set our Table Columns
  const formColumns = [
    {
      accessor: "rowLabel",
      Header: "",
      Cell: rowLabelCell,
    },
    {
      accessor: "adult",
      Header: "Adult",
      Cell: TableFormInput,
    },
    {
      accessor: "youth",
      Header: "Up to 5 mos.",
      Cell: TableFormInput,
    },
    {
      accessor: "ageUnknown",
      Header: "Age Unknown",
      Cell: TableFormInput,
    },
    {
      accessor: "total",
      Header: "Subtotal",
    },
  ];

  let data = [
    {
      rowLabel: "Adoption",
      keySubString: "AdoptionCount",
      tooltip: `Final adoptions only, having permanently left the agency's possession. For example, it does NOT include animals placed in foster care or on overnight "trial" stays.`,
    },
    {
      rowLabel: "Returned to owner",
      keySubString: "ReturnedToOwnerCount",
      tooltip: "Stray or Owner Relinquished animals returned to their owner",
    },
    {
      rowLabel: "Transferred in state",
      keySubString: "TransferredInstateToAnotherAgencyCount",
      tooltip:
        "Transferred out of the agency's possession to another entity within the same state or territory.",
    },
    {
      rowLabel: "Transferred out of state",
      keySubString: "TransferredOutOfStateToAnotherAgencyCount",
      tooltip:
        "Transferred out of the agency's possession to another entity in a different state or territory.",
    },
    {
      rowLabel: "Transferred internationally",
      keySubString: "TransferredInternationallyToAnotherAgencyCount",
      tooltip:
        "Transferred out of the agency's possession to another entity outside the US.",
    },
    {
      rowLabel: "Transfers Out (undesignated)",
      keySubString: "LegacyTransferredToAnotherAgencyCount",
      tooltip:
        "Transfer in to agency (undesignated) represents Transfer In data prior to when agency location break outs.",
    },
    {
      rowLabel: "Returned to field",
      keySubString: "ReturnedToFieldCount",
      tooltip:
        "Animals included in intake, already altered or altered after intake, and returned to stray capture location to be released (often referred to as SNR). This is not TNR.",
    },
    {
      rowLabel: "Other live outcome",
      keySubString: "OtherLiveOutcomeCount",
    },
  ].map(({ rowLabel, keySubString, tooltip }) =>
    getRowData({
      data: attributes,
      accessKeys: ["adult", "youth", "ageUnknown", "total"],
      rowLabel,
      keySubString,
      tooltip,
    })
  );

  if (type === "other") {
    data = [
      {
        rowLabel: "Died in care",
        keySubString: "DiedInCareCount",
      },
      {
        rowLabel: "Lost in care",
        keySubString: "LostInCareCount",
      },
      {
        rowLabel: "Shelter euthanasia",
        keySubString: "ShelterEuthanasiaCount",
      },
      {
        rowLabel: "Owner intended euthanasia",
        keySubString: "OwnerIntendedEuthanasiaCount",
        tooltip:
          "Limited to this definition: Euthanasia of pets whose owner brought the pet to the shelter with the INTENT of utilizing euthanasia services.",
      },
    ].map(({ rowLabel, keySubString, tooltip }) =>
      getRowData({
        data: attributes,
        accessKeys: ["adult", "youth", "ageUnknown", "total"],
        rowLabel,
        keySubString,
        tooltip,
      })
    );
  }

  const { mutate: updateAnimalOutcomes } = useMutation(
    (attributes) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_outcomes/${dataId}`,
        method: "PATCH",
        body: {
          data: {
            id: dataId,
            type: "animal_outcomes",
            attributes: transformAttributesForPayload(attributes),
          },
        },
      }),
    {
      onSuccess: async (response) => {
        handleSuccess({
          message: `Updated the ${speciesMap[
            attributes.species
          ].label.toLocaleLowerCase()} ${
            type === "live" ? "live" : "other"
          } outcomes`,
        });

        return await queryClient.setQueryData(
          ["dataEntry", `${locationId}_${month}/${year}`],
          (oldData = {}) => {
            const newData = cloneDeep(oldData);
            if (newData.outcomes) {
              const speciesIndex = newData.outcomes.data.findIndex(
                (outcomesRecord) => {
                  return outcomesRecord.id === dataId;
                }
              );
              newData.outcomes.data[speciesIndex] = response.data;
            }
            return newData;
          }
        );
      },
      onError: handleError,
    }
  );

  return (
    <div>
      <Formik
        initialValues={getFormAttributes(attributes)}
        onSubmit={(values) => updateAnimalOutcomes(values)}
        innerRef={ref}
      >
        <Form>
          <Table
            data={data}
            columns={formColumns}
            columnAlignments={[null, null, null, null, "text-right"]}
          />
        </Form>
      </Formik>
    </div>
  );
});

AnimalOutcomeForm.defaultProps = {
  attributes: animalOutcomes,
};

AnimalOutcomeForm.propTypes = {
  type: PropTypes.oneOf(["live", "other"]),
  dataId: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    species: PropTypes.string.isRequired,
    adultAdoptionCount: PropTypes.number,
    youthAdoptionCount: PropTypes.number,
    ageUnknownAdoptionCount: PropTypes.number,
    adultReturnedToOwnerCount: PropTypes.number,
    youthReturnedToOwnerCount: PropTypes.number,
    ageUnknownReturnedToOwnerCount: PropTypes.number,
    adultReturnedToFieldCount: PropTypes.number,
    youthReturnedToFieldCount: PropTypes.number,
    ageUnknownReturnedToFieldCount: PropTypes.number,
    adultOtherLiveOutcomeCount: PropTypes.number,
    youthOtherLiveOutcomeCount: PropTypes.number,
    ageUnknownOtherLiveOutcomeCount: PropTypes.number,
    adultDiedInCareCount: PropTypes.number,
    youthDiedInCareCount: PropTypes.number,
    ageUnknownDiedInCareCount: PropTypes.number,
    adultLostInCareCount: PropTypes.number,
    youthLostInCareCount: PropTypes.number,
    ageUnknownLostInCareCount: PropTypes.number,
    adultShelterEuthanasiaCount: PropTypes.number,
    youthShelterEuthanasiaCount: PropTypes.number,
    ageUnknownShelterEuthanasiaCount: PropTypes.number,
    adultOwnerIntendedEuthanasiaCount: PropTypes.number,
    youthOwnerIntendedEuthanasiaCount: PropTypes.number,
    ageUnknownOwnerIntendedEuthanasiaCount: PropTypes.number,
    adultTransferredInstateToAnotherAgencyCount: PropTypes.number,
    youthTransferredInstateToAnotherAgencyCount: PropTypes.number,
    ageUnknownTransferredInstateToAnotherAgencyCount: PropTypes.number,
    adultTransferredOutOfStateToAnotherAgencyCount: PropTypes.number,
    youthTransferredOutOfStateToAnotherAgencyCount: PropTypes.number,
    ageUnknownTransferredOutOfStateToAnotherAgencyCount: PropTypes.number,
    adultTransferredInternationallyToAnotherAgencyCount: PropTypes.number,
    youthTransferredInternationallyToAnotherAgencyCount: PropTypes.number,
    ageUnknownTransferredInternationallyToAnotherAgencyCount: PropTypes.number,
    adultLegacyTransferredToAnotherAgencyCount: PropTypes.number,
    youthLegacyTransferredToAnotherAgencyCount: PropTypes.number,
    ageUnknownLegacyTransferredToAnotherAgencyCount: PropTypes.number,
    totalAdoptionCount: PropTypes.number,
    totalReturnedToOwnerCount: PropTypes.number,
    totalTransferredInstateToAnotherAgencyCount: PropTypes.number,
    totalTransferredOutOfStateToAnotherAgencyCount: PropTypes.number,
    totalTransferredInternationallyToAnotherAgencyCount: PropTypes.number,
    totalTransferredToAnotherAgencyCount: PropTypes.number,
    totalReturnedToFieldCount: PropTypes.number,
    totalOtherLiveOutcomeCount: PropTypes.number,
    totalDiedInCareCount: PropTypes.number,
    totalLostInCareCount: PropTypes.number,
    totalShelterEuthanasiaCount: PropTypes.number,
    totalOwnerIntendedEuthanasiaCount: PropTypes.number,
    totalAdultOutcomesCount: PropTypes.number,
    totalYouthOutcomesCount: PropTypes.number,
    totalAgeUnknownOutcomesCount: PropTypes.number,
    totalOutcomesCount: PropTypes.number,
  }),
};

export default AnimalOutcomeForm;
