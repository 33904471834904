import React from "react";
import FormField from "components/FormField";
import { ORGANIZATION_TYPES } from "./utils";

const OrganizationTypeDropDown = ({ ...props }) => {
  return (
    <FormField as="select" {...props}>
      <option value="">{ORGANIZATION_TYPES.ALL}</option>
      <option value={ORGANIZATION_TYPES.GOVERNMENT_ANIMAL_SERVICES}>
        {ORGANIZATION_TYPES.GOVERNMENT_ANIMAL_SERVICES}
      </option>
      <option
        value={ORGANIZATION_TYPES.ANIMAL_SHELTER_WITH_A_GOVERNMENT_CONTRACT}
      >
        {ORGANIZATION_TYPES.ANIMAL_SHELTER_WITH_A_GOVERNMENT_CONTRACT}
      </option>
      <option
        value={ORGANIZATION_TYPES.ANIMAL_RESCUE_WITH_A_GOVERNMENT_CONTRACT}
      >
        {ORGANIZATION_TYPES.ANIMAL_RESCUE_WITH_A_GOVERNMENT_CONTRACT}
      </option>
      <option value={ORGANIZATION_TYPES.SHELTER_WITHOUT_A_GOVERNMENT_CONTRACT}>
        {ORGANIZATION_TYPES.SHELTER_WITHOUT_A_GOVERNMENT_CONTRACT}
      </option>
      <option value={ORGANIZATION_TYPES.RESCUE_WITHOUT_A_GOVERNMENT_CONTRACT}>
        {ORGANIZATION_TYPES.RESCUE_WITHOUT_A_GOVERNMENT_CONTRACT}
      </option>
    </FormField>
  );
};

export default OrganizationTypeDropDown;
