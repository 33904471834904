import React from "react";
import { COOKIE_CONSENT_CLEAR } from "actions";
import { useCookieConsentDispatch } from "context";
import classnames from "classnames";

const Footer = ({ showAttribution }) => {
  const currentYear = new Date().getFullYear();
  const dispatch = useCookieConsentDispatch();

  return (
    <div className="footer">
      <ul className="flex mb-4">
        <li>
          <a
            href="https://www.shelteranimalscount.org/privacy-policy"
            target="_blank"
          >
            Privacy policy
          </a>
        </li>
        <li>
          <a
            href="https://www.shelteranimalscount.org/terms-of-use"
            target="_blank"
          >
            Terms of use
          </a>
        </li>
        {showAttribution && (
          <li>
            <button
              onClick={() => dispatch({ type: COOKIE_CONSENT_CLEAR })}
              className="inline text-dashboard-blue transition-colors duration-300 hover:text-dashboard-blue-hover active:text-dashboard-blue-hover"
            >
              Manage Consent
            </button>
          </li>
        )}
      </ul>
      <div
        className={classnames({
          "flex space-x-4 justify-between": !showAttribution,
        })}
      >
        <p>&copy;{currentYear} Shelter Animals Count&trade;</p>
        <p>
          Built by{" "}
          <a href="https://softwareforgood.com/" target="_blank">
            Software for Good
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
