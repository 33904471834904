export function getUniqueTypeSubtypePairs(data) {
  const uniquePairs = new Set();

  data.forEach((obj) => {
    const pair = `${obj.eventType}=${obj.subtype}=${extractSacCategory(
      obj.intakeOutcomeType
    )}`;
    uniquePairs.add(pair);
  });

  const uniquePairsArray = Array.from(uniquePairs);

  const result = uniquePairsArray.map((pair) => {
    const [type, subtype, sacCategory] = pair.split("=");
    const key = `${type}=${subtype}`;
    return { type, subtype, key, sacCategory };
  });

  return result; // Return the array of unique type and subtype pairs
}

export const intakeCategories = [
  {
    name: "Stray at Large",
    value: "stray_at_large_count",
  },
  {
    name: "Relinquished by Owner",
    value: "relinquished_by_owner_count",
  },
  {
    name: "Owner Intended Euthanasia",
    value: "owner_intended_euthanasia_count",
  },
  {
    name: "Transferred In State",
    value: "transferred_instate_in_from_agency_count",
  },
  {
    name: "Transferred Out of State",
    value: "transferred_out_of_state_in_from_agency_count",
  },
  {
    name: "Transferred Internationally",
    value: "transferred_internationally_in_from_agency_count",
  },
  {
    name: "Transferred Undesignated",
    value: "legacy_transferred_in_from_agency_count",
  },
  {
    name: "Seized or Impounded",
    value: "seized_or_impounded_count",
  },
  {
    name: "Other Intakes",
    value: "other_intakes_count",
  },
  {
    name: "Do not include",
    value: "do_not_include",
  },
];

export const outcomeCategories = [
  {
    name: "Adoption",
    value: "adoption_count",
  },
  {
    name: "Return to Owner",
    value: "returned_to_owner_count",
  },
  {
    name: "Return to field",
    value: "returned_to_field_count",
  },
  {
    name: "Transferred In State",
    value: "transferred_instate_to_another_agency_count",
  },
  {
    name: "Transferred Out of State",
    value: "transferred_out_of_state_to_another_agency_count",
  },
  {
    name: "Transferred Internationally",
    value: "transferred_internationally_to_another_agency_count",
  },
  {
    name: "Transferred Undesignated",
    value: "legacy_transferred_to_another_agency_count",
  },
  {
    name: "Shelter Euthanasia",
    value: "shelter_euthanasia_count",
  },
  {
    name: "Died in Care",
    value: "died_in_care_count",
  },
  {
    name: "Lost in Care",
    value: "lost_in_care_count",
  },
  {
    name: "Owner Intended Euthanasia",
    value: "owner_intended_euthanasia_count",
  },
  {
    name: "Do not include",
    value: "do_not_include",
  },
];

function extractSacCategory(inputString) {
  if (!inputString || inputString === "null") {
    return null;
  }

  return inputString;
}
