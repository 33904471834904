import React from "react";
import Modal from "react-modal";

const AlertModal = ({
  children,
  isModalOpen,
  closeModal,
  contentLabel,
  shouldCloseOnOverlayClick,
}) => {
  return (
    // https://github.com/reactjs/react-modal
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className="modal"
      overlayClassName="modal-overlay"
      appElement={document.querySelector("#root")}
    >
      {children}
    </Modal>
  );
};

export default AlertModal;
