import React from "react";
import { Route, Redirect } from "react-router-dom";
import AppPage from "components/AppPage";
import { useAuthState, useUserState } from "context";
import { useRouteGuard } from "utils";
import { get } from "lodash";

function PrivateRoute({
  component: Component,
  meta,
  isSubRoute = false,
  ...rest
}) {
  const { token, userId } = useAuthState();
  const { organization } = useUserState();
  const routeGuards = useRouteGuard(rest.computedMatch);

  let redirect = null;
  if (meta?.guards) {
    for (let i = 0; i < meta.guards.length; i++) {
      const metaKey = meta.guards[i];
      const guard = routeGuards[metaKey]();
      if (guard) {
        redirect = guard;
        break;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // User is unauthenticated if the accessToken or userId are not defined
        if (!Boolean(token?.accessToken) || !Boolean(userId)) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: rest.location },
              }}
            />
          );
        }

        // Legacy org redirect
        if (
          get(organization, "attributes.status") === "imported" &&
          !meta?.isLegacyRoute
        )
          return (
            <Redirect
              to={{
                pathname: "/legacy",
              }}
            />
          );

        if (redirect) {
          return (
            <Redirect
              to={{
                pathname: redirect,
              }}
            />
          );
        }

        if (isSubRoute) {
          return <Component {...props} {...(meta || {})} />;
        }

        return (
          <AppPage>
            <Component {...props} {...(meta || {})} />
          </AppPage>
        );
      }}
    />
  );
}

export default PrivateRoute;
