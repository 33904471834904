import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { ROUTE_GUARDS, apiFetch, formatUiErrors, usePermissions } from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Error from "components/Error";
import CoalitionForm from "components/CoalitionForm";
import LoadingIndicator from "components/LoadingIndicator";
import BackLink from "components/BackLink";
import CoalitionManagers from "./components/CoalitionManagers";
import Button from "components/Button";
import { toast } from "react-toastify";

const CoalitionDetails = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const permissions = usePermissions();
  const { coalitionId } = useParams();
  const [formMode, seformMode] = useState("VIEW");
  const { isAdmin } = useUserState();
  const isCoalitionManager =
    permissions[ROUTE_GUARDS.COALITION_MANAGER](coalitionId);
  const history = useHistory();
  const queryClient = useQueryClient();

  const {
    isLoading,
    error,
    data: response,
    refetch,
  } = useQuery(["coalition", coalitionId], () =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/coalitions/${coalitionId}`,
    })
  );

  if (isLoading) {
    return <LoadingIndicator inline content={<>Loading coalition&hellip;</>} />;
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  const handleUpdateSuccess = () => {
    history.push(`/coalitions/${coalitionId}/detail`);
    refetch();
    seformMode("VIEW");
    toast.success("Saved successfully", {
      autoClose: 3000,
    });

    queryClient.invalidateQueries(["coalitions"], {
      refetchInActive: true,
    });
    queryClient.invalidateQueries(["myCoalitions"], {
      refetchInActive: true,
    });
    queryClient.invalidateQueries(["orgCoalitions"], {
      refetchInActive: true,
    });
  };

  const handleUpdateError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  return (
    <div className="grid grid-cols-2 gap-8">
      <div>
        <div className="mb-8">
          <BackLink link="/coalitions" text="Back to coalitions listing" />

          <h2>Details</h2>
        </div>

        <CoalitionForm
          coalition={response.data}
          onUpdateSuccess={handleUpdateSuccess}
          onUpdateError={handleUpdateError}
          formMode={formMode}
          cancelRoute={`/coalitions`}
        />
      </div>

      <div>
        {(isCoalitionManager || isAdmin) && (
          <div style={{ float: "right", marginBottom: 15 }}>
            <Button
              onClick={() => {
                seformMode("EDIT");
              }}
            >
              Edit
            </Button>
          </div>
        )}
        <CoalitionManagers />
      </div>
    </div>
  );
};

export default CoalitionDetails;
