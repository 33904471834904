import React from "react";
import { CookieConsentReducer, initialCookieConsentState } from "../reducers";

export const CookieConsentStateContext = React.createContext();
export const CookieConsentDispatchContext = React.createContext();

export function useCookieConsentState() {
  const context = React.useContext(CookieConsentStateContext);
  if (context === undefined) {
    throw new Error(
      "useCookieConsentState must be used within a CookieConsentProvider"
    );
  }

  return context;
}

export function useCookieConsentDispatch() {
  const context = React.useContext(CookieConsentDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useCookieConsentDispatch must be used within a CookieConsentProvider"
    );
  }

  return context;
}

export const CookieConsentProvider = ({ children }) => {
  const [showCookieConsent, dispatch] = React.useReducer(
    CookieConsentReducer,
    initialCookieConsentState
  );

  return (
    <CookieConsentStateContext.Provider value={showCookieConsent}>
      <CookieConsentDispatchContext.Provider value={dispatch}>
        {children}
      </CookieConsentDispatchContext.Provider>
    </CookieConsentStateContext.Provider>
  );
};
