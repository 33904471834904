import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import { apiFetch, formatUiErrors } from "utils";
import RecordStatusTableItem from "../RecordStatusTableItem";
import { isUnavailable } from "../../utils";
import { dataEntryStatuses } from "utils";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";

// Animal Data Records
const RecordStatusTable = ({ action, adminLocation, adminOrganization }) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { attributes, isAdmin } = useUserState();

  var locationId = attributes.locationId;
  if (!!adminLocation) locationId = adminLocation;
  const [year, setYear] = useState(new Date().getFullYear());
  const currentYear = new Date().getFullYear();
  const endpoint =
    action === "services"
      ? `/api/v1/data_entry_status?filter[location_id][eq]=${locationId}&filter[year][eq]=${year}&filter[action][eq]=services`
      : `/api/v1/data_entry_status?filter[location_id][eq]=${locationId}&filter[year][eq]=${year}`;

  const {
    isLoading,
    error,
    data: response,
    refetch,
  } = useQuery([`dataStatus${action}`, locationId], () =>
    apiFetch({
      token,
      dispatch,
      endpoint,
    })
  );

  useEffect(refetch, [locationId, year, refetch]);

  if (isLoading) {
    return (
      <LoadingIndicator
        inline
        content={<>Loading record status table&hellip;</>}
      />
    );
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  return (
    <div data-testid="record-status-table">
      <div className="flex">
        <button
          className="px-4 py-2 border rounded-lg bg-super-light-gray text-sm text-center mb-3 mr-1"
          onClick={() => setYear(year - 1)}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>

        <div className="px-4 w-full py-2 border rounded-lg bg-super-light-gray text-sm text-center mb-3">
          {year}
        </div>

        <button
          className="px-4 py-2 border rounded-lg bg-super-light-gray text-sm text-center mb-3 ml-1"
          disabled={currentYear === year}
          onClick={() => setYear(year + 1)}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </button>
      </div>

      <div className="flex flex-wrap -mx-1">
        {response.data.map((record, i) => {
          const { attributes } = record;
          const { month, year, status } = attributes;

          return (
            <RecordStatusTableItem
              key={i}
              month={month}
              year={year}
              status={
                isUnavailable(month, year)
                  ? dataEntryStatuses.unavailable
                  : status
              }
              action={action}
              location={isAdmin ? locationId : ""}
              adminOrganization={isAdmin ? adminOrganization : ""}
            />
          );
        })}
      </div>
    </div>
  );
};

RecordStatusTable.propTypes = {
  action: PropTypes.string,
};

RecordStatusTable.defaultProps = {
  action: "data-entry",
};

export default RecordStatusTable;
