import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy } from "react-table";
import { getTableHeaderClasses, getTableCellClasses } from "./utils";
import { formatUiErrors } from "utils";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";

// Docs: https://react-table.tanstack.com/docs/api/overview

const Table = ({
  data,
  columns,
  dashboard,
  columnWidths = [],
  columnAlignments = [],
  noResultsText = "No data found",
  isLoading,
  error,
}) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  var rowIndex = 0;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({ data: memoizedData, columns: memoizedColumns }, useSortBy);

  const tableBody = () => {
    if (isLoading)
      return (
        <tr>
          <td
            colSpan={columns.length}
            className="px-4 pt-4 pb-2 text-base text-center"
          >
            <LoadingIndicator inline />
          </td>
        </tr>
      );

    if (error)
      return (
        <tr>
          <td
            colSpan={columns.length}
            className="px-4 pt-4 pb-2 text-base text-center"
          >
            <Error>{formatUiErrors(error)}</Error>
          </td>
        </tr>
      );

    if (!rows.length)
      return (
        <tr>
          <td
            colSpan={columns.length}
            className="px-4 pt-4 pb-2 text-base text-center"
          >
            {noResultsText}
          </td>
        </tr>
      );

    return rows.map((row, i) => {
      if (row.original.total < 0) {
        return "";
      }
      rowIndex += 1;
      prepareRow(row);
      return (
        <tr
          {...row.getRowProps()}
          className={`${rowIndex % 2 && !dashboard ? "bg-gray-100" : ""}`}
        >
          {row.cells.map((cell, cellIndex) => {
            return (
              <td
                {...cell.getCellProps()}
                className={getTableCellClasses({
                  index: cellIndex,
                  dashboard,
                  columnAlignments,
                })}
              >
                {cell.render("Cell")}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const tableFooter = () => {
    return (
      <>
        {footerGroups.map((group) => (
          <tr
            {...group.getFooterGroupProps()}
            className={`${rowIndex % 2 && !dashboard ? "" : "bg-gray-100"}`}
          >
            {group.headers.map((column, index) => (
              <td
                {...column.getFooterProps()}
                className={getTableCellClasses({
                  index: index,
                  dashboard,
                  columnAlignments,
                })}
              >
                {column.render("Footer")}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  return (
    <table {...getTableProps()} className="table-fixed w-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={getTableHeaderClasses({
                  index: i,
                  columns,
                  columnWidths,
                  dashboard,
                  columnAlignments,
                })}
              >
                {column.render("Header")}
                {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>{tableBody()}</tbody>
      {!dashboard && <tfoot>{tableFooter()}</tfoot>}
    </table>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pluginHooks: PropTypes.array,
  columnWidths: PropTypes.array,
  noResultsText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
};

Table.defaultProps = {
  pluginHooks: [],
  columnWidths: [],
  columnAlignments: [],
  noResultsText: "No data found",
  isLoading: false,
};

export default Table;
