import React, { useState } from "react";
import IntakeOverviewGraph from "components/IntakeOverviewGraph";
import IntakeOverviewTable from "components/IntakeOverviewTable";
import CoalitionFilter from "components/IntakeOverviewGraphFilters/CoalitionFilter";
import { useParams } from "react-router-dom";

const Home = () => {
  const [action, setAction] = useState("intakes");
  const defaultFilters = JSON.parse(localStorage.getItem("filters"));
  const [filters, setFilters] = useState(defaultFilters);
  const [searchClick, setSearchClick] = useState(true);
  const { coalitionId } = useParams();

  return (
    <div className="w-full sac-border mb-4">
      <CoalitionFilter
        coalitionId={coalitionId}
        filters={filters}
        searchClick={setSearchClick}
        setFilters={setFilters}
      />

      <div className="sac-border">
        <div className="w-max flex space-y-2 flex-col rmsc">
          <label htmlFor="intake-community-graph-toggle" className="text-base">
            Toggle Intake/Community Graph
          </label>
          <select
            name="intake-community-graph-toggle"
            className="form-select text-sm dropdown-container"
            onChange={(e) => {
              setAction(e.target.value);
            }}
            value={action}
          >
            <option value={"intakes"}>Intake/Outcome Graph</option>
            <option value={"services"}>Community Service Graph</option>
          </select>
        </div>
      </div>
      <h5 className="mb-4 mt-10">
        {action === "intakes"
          ? "Intake Overview"
          : "Community Services Overview"}
      </h5>
      <IntakeOverviewGraph
        coalitionId={coalitionId}
        coalitionPage
        setSearchClick={setSearchClick}
        searchClick={searchClick}
        coalitionFilters={filters}
        action={action}
      />
      <h5 className="mt-12">
        {action === "intakes"
          ? "Intake Overview"
          : "Community Services Overview"}
      </h5>
      <IntakeOverviewTable
        coalitionId={coalitionId}
        setSearchClick={setSearchClick}
        searchClick={searchClick}
        coalitionPage
        coalitionFilters={filters}
        action={action}
      />
    </div>
  );
};
export default Home;
