import { View } from "@react-pdf/renderer";
import TotalTableHeader from "./TotalTable/TotalTableHeader";
import TotalTableRow from "./TotalTable/TotalTableRow";

const MainTable = (props) => {
  return (
    <View>
      <TotalTableHeader styles={props.styles} name={props.name} />
      <TotalTableRow items={props.data} />
    </View>
  );
};
export default MainTable;
