import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useMutation } from "react-query";
import Error from "components/Error";
import FormField from "components/FormField";
import FormWrapper from "components/FormWrapper";
import Button from "components/Button";
import { API_URL } from "utils";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const EmailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
});

const ForgotPassword = () => {
  const [uiError, setUiError] = useState(null);

  const { mutate: handleSubmit, isLoading, data: response } = useMutation(
    async ({ email }) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          redirect_url: `${process.env.REACT_APP_RESET_PASSWORD_REDIRECT_URL}/reset-password`,
        }),
      };
      const response = await fetch(`${API_URL}/auth/password`, requestOptions);
      return await response.json();
    }
  );

  useEffect(() => {
    const errors = response?.errors || response?.error;
    setUiError(Array.isArray(errors) ? errors.join(", ") : errors);
  }, [response]);

  return (
    <FormWrapper>
      <h1 className="h5 text-center">Forgot Password</h1>
      {response?.message ? (
        <>
          <div className="mt-8 text-center">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-4xl text-teal mb-4"
            />
            <p className="mb-6">{response?.message}</p>

            <Button to="/login" emphasis="transparent">
              Back to Login
            </Button>
          </div>
        </>
      ) : (
        <>
          {uiError && <Error className="mb-4">{uiError}</Error>}
          <Formik
            initialValues={{ email: "" }}
            validateOnMount
            validationSchema={EmailSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                <div className="mb-6">
                  <FormField label="Email" type="email" name="email" required />
                </div>

                <Button
                  disabled={isLoading || !isValid || !dirty}
                  isLoading={isLoading}
                  loadingText={<>Loading&hellip;</>}
                  className="w-full mb-4"
                  type="submit"
                >
                  Send Reset Password Email
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </FormWrapper>
  );
};

export default ForgotPassword;
