import React from "react";
import { useQuery } from "react-query";
import {
  useParams,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import {
  apiFetch,
  formatUiErrors,
  FORM_MODES,
  ROUTE_GUARDS,
  usePermissions,
} from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import Tabs from "components/Tabs";
import OrganizationDetails from "./components/OrganizationDetails";
import Locations from "./components/Locations";
import LocationDetails from "./components/LocationDetails";
import AddLocation from "./components/AddLocation";
import Members from "./components/Members";
import AddMember from "./components/AddMember";
import MemberDetails from "./components/MemberDetails";
import DataEntryExport from "screens/DataEntryExport";
import { PrivateRoute } from "components/Routes";
import CoalitionsTable from "components/CoalitionsTable";
import AddCoalition from "screens/CoalitionBuilder/AddCoalition";
import ComparisonDashboard from "./components/ComparisonDashboard";
import EntryLogs from "./components/EntryLogs";
import AnalyticsAndForecast from "./components/AnalyticsAndForecast";
import SharingPermissions from "./components/SharingPermissions";
import ApiMapping from "./components/ApiMapping";
import RehomeDashboard from "./components/RehomeDashboard";
import CommunityServicesTotals from "./components/CommunityServicesTotals";
import IntakeOutcomeTotals from "./components/IntakeOutcomeTotals";

const Organization = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { orgId } = useParams();
  const { url, path } = useRouteMatch();
  const cleanUrl = url.replace(/\/$/, "");
  const { isOrgAdmin } = useUserState();
  const permissions = usePermissions();

  const isAdmin = permissions[ROUTE_GUARDS.ADMIN]();

  const isOrgMember = permissions[ROUTE_GUARDS.CAN_VIEW_ORG]();

  const {
    isLoading,
    error,
    data: response,
  } = useQuery(["organization", orgId], () =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/organizations/${orgId}`,
    })
  );

  const orgApiProvider = response?.data?.attributes?.shelterSoftware;

  const tabs = [
    {
      to: cleanUrl,
      label: "Details",
      isActive: (match, location) => {
        const editRegex = /\/organizations\/[\d]+\/edit[/]?/;
        return match || editRegex.test(location.pathname);
      },
    },
    {
      to: `${cleanUrl}/locations`,
      label: "Locations",
      exact: false,
    },
    ...(isOrgMember
      ? [
          {
            to: `${cleanUrl}/members`,
            label: "Members",
            exact: false,
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            to: `${cleanUrl}/coalitions`,
            label: "Coalitions",
          },
          {
            to: `${cleanUrl}/export`,
            label: "Export Data",
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            to: `${cleanUrl}/entry-logs`,
            label: "Data Entry Log",
          },
          {
            to: `${cleanUrl}/comparison_dashboard`,
            label: "Comparison Tableau",
            exact: false,
          },
          {
            to: `${cleanUrl}/analytics_forecast`,
            label: "Analytics & Forecast",
            exact: false,
          },
          {
            to: `${cleanUrl}/rehome_dashboard`,
            label: "Rehome Dashboard",
            exact: false,
          },
          {
            to: `${cleanUrl}/community-services-totals`,
            label: "Community Services Totals",
            exact: false,
          },
          {
            to: `${cleanUrl}/intake-outcome-totals`,
            label: "Intake Outcome Totals",
            exact: false,
          },
        ]
      : []),
    ...(isOrgAdmin && isOrgMember
      ? [
          {
            to: `${cleanUrl}/sharing-permissions`,
            label: "Sharing Permissions",
            exact: false,
          },
        ]
      : []),
    ...(isOrgAdmin &&
    isOrgMember &&
    (orgApiProvider === "ShelterLuv" || orgApiProvider === "ShelterBuddy")
      ? [
          {
            to: `${cleanUrl}/api-mapping`,
            label: "API Mapping",
            exact: false,
          },
        ]
      : []),
  ];

  if (isLoading) {
    return (
      <LoadingIndicator inline content={<>Loading organization&hellip;</>} />
    );
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  const {
    data: organization,
    data: {
      attributes: { name },
    },
  } = response;

  return (
    <>
      <h1>{name}</h1>

      <Tabs tabs={tabs} />

      <div className="tab__content">
        <Switch>
          <Route path={path} exact component={OrganizationDetails} />
          <Route
            path="/organizations/:orgId/edit"
            exact
            render={() => <OrganizationDetails formMode={FORM_MODES.EDIT} />}
          />
          <Route path={`${path}/locations`} exact component={Locations} />
          <Route path={`${path}/locations/add`} exact component={AddLocation} />
          <Route
            path={`${path}/locations/:locId`}
            exact
            component={LocationDetails}
          />
          <PrivateRoute
            path={`${path}/locations/:locId/edit`}
            exact
            component={LocationDetails}
            isSubRoute
            meta={{ formMode: FORM_MODES.EDIT }}
          />
          <Route path={`${path}/members`} exact component={Members} />
          <Route path={`${path}/members/add`} exact component={AddMember} />
          <Route path={`${path}/entry-logs`} exact component={EntryLogs} />
          <Route
            path={`${path}/members/:userId`}
            exact
            component={MemberDetails}
          />
          <PrivateRoute
            path={`${path}/members/:userId/edit`}
            exact
            component={MemberDetails}
            isSubRoute
            meta={{ formMode: FORM_MODES.EDIT }}
          />
          <Route
            path={`${path}/sharing-permissions`}
            exact
            component={SharingPermissions}
          />
          {isOrgAdmin && isOrgMember && (
            <Route path={`${path}/api-mapping`} exact component={ApiMapping} />
          )}
          <PrivateRoute
            path={`${path}/comparison_dashboard`}
            exact
            isSubRoute
            component={ComparisonDashboard}
          />
          <PrivateRoute
            path={`${path}/analytics_forecast`}
            exact
            isSubRoute
            component={AnalyticsAndForecast}
          />
          <PrivateRoute
            path={`${path}/rehome_dashboard`}
            exact
            isSubRoute
            component={RehomeDashboard}
          />
          <PrivateRoute
            path={`${path}/community-services-totals`}
            exact
            isSubRoute
            component={CommunityServicesTotals}
          />
          <PrivateRoute
            path={`${path}/intake-outcome-totals`}
            exact
            isSubRoute
            component={IntakeOutcomeTotals}
          />
          {isAdmin && (
            <>
              <Route
                path={`${path}/coalitions`}
                exact
                render={() => (
                  <>
                    <h2>Coalitions</h2>
                    <CoalitionsTable
                      organization={organization}
                      path={cleanUrl}
                    />
                  </>
                )}
              />
              <Route
                path={`${path}/coalitions/add`}
                exact
                render={() => <AddCoalition path={cleanUrl} />}
              />
              <Route
                path={`${path}/export`}
                exact
                render={() => (
                  <DataEntryExport headingTag="h2" subheadingTag="h3" />
                )}
              />
            </>
          )}
          <Redirect from={path} to={path} />
        </Switch>
      </div>
    </>
  );
};

export default Organization;
