import React from "react";
import { useLocation } from "react-router-dom";
import Tabs from "components/Tabs";
import { usePermissions, ROUTE_GUARDS } from "utils";

const CoalitionsNav = () => {
  const { pathname } = useLocation();
  const permissions = usePermissions();

  const tabs = [
    {
      to: "/coalitions",
      label: "All Coalitions",
      isActive: (match) => {
        return match?.url === "/coalitions" || pathname === "/coalitions/add";
      },
    },
    ...(permissions[ROUTE_GUARDS.HAS_COALITIONS]()
      ? [
          {
            to: `/coalitions/mine`,
            label: "My Coalitions",
          },
        ]
      : []),
  ];

  return <Tabs tabs={tabs} />;
};

export default CoalitionsNav;
