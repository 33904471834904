import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { FocusableItem, MenuItem } from "@szhsin/react-menu";
import { Formik, Form, Field } from "formik";
import { isEqual, get } from "lodash";
import {
  apiFetch,
  getTotalPages,
  usePermissions,
  useHandleRequestStates,
  ROUTE_GUARDS,
  getCoalitions,
  COALITION_MEMBERSHIP_ACTIONS,
} from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Table from "components/Table";
import Pagination from "components/Pagination";
import ActionsMenu from "components/ActionsMenu";
import FormField from "components/FormField";
import Button from "components/Button";
import StateDropdown from "components/StateDropdown";
import ProvinceDropdown from "components/ProvinceDropdown";
import RegionDropDown from "components/RegionDropDown";
import {
  coalitionRoleColumn,
  useHandleRespondToInviteSuccess,
  useHandleRequestAccessSuccess,
  useHandleLeaveCoalitionSuccess,
  getDataSharingType,
  baseColumnsForSharing,
  getBaseColumns,
} from "./utils";
import AlertModal from "components/AlertModal";

const CoalitionsTable = ({ userId, organization, path = "" }) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { coalitions, isAdmin } = useUserState();
  const queryClient = useQueryClient();
  const { handleError } = useHandleRequestStates();
  const handleRespondToInviteSuccess = useHandleRespondToInviteSuccess();
  const handleRequestAccessSuccess = useHandleRequestAccessSuccess();
  const handleLeaveCoalitionSuccess = useHandleLeaveCoalitionSuccess();
  const orgId = get(organization, "id");
  const { pathname } = useLocation();
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [openUserId, setOpenUserId] = useState(0);
  const [state, setOpenState] = useState(false);
  const [openType, setOpenType] = useState("");

  // Pagination
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  // const openModel = (data) => {
  //   setIsModelOpen(true);
  //   setOpenUserId(data.id);
  //   setOpenType(data.type);
  //   setOpenState(data.state);
  // };
  // Form Filters
  const initialFilters = {
    search: "",
    operatingRegions: "",
    region: "",
    country: "United States",
  };
  const [filters, setFilters] = useState(initialFilters);

  // Initial query
  const {
    data: response = { data: [], included: [] },
    isLoading,
    error,
  } = useQuery(
    [
      userId
        ? "myCoalitions"
        : path
        ? ["orgCoalitions", `${orgId}`]
        : "coalitions",
      { page, ...filters },
    ],
    () =>
      getCoalitions({
        token,
        dispatch,
        page,
        ...filters,
        userId,
        orgId: pathname === "/coalitions" ? "" : orgId,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  // Prefetch the next page of coalitions
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        [
          userId
            ? "myCoalitions"
            : path
            ? ["orgCoalitions", `${orgId}`]
            : "coalitions",
          { page: page + 1, ...filters },
        ],
        () =>
          getCoalitions({
            token,
            dispatch,
            page: page + 1,
            ...filters,
            userId,
            orgId: pathname === "/coalitions" ? "" : orgId,
          })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, totalPages, filters, queryClient]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    ...(userId
      ? baseColumnsForSharing
      : getBaseColumns(organization?.attributes.country)),
    ...(userId
      ? [
          {
            accessor: "id",
            Header: "My Role",
            Cell: ({ cell }) =>
              coalitionRoleColumn({ coalitionId: cell.value, coalitions }),
          },
          {
            accessor: "id1",
            Header: "Community Service Data Sharing",
            Cell: ({ cell }) =>
              getDataSharingType({
                coalitionId: cell.row.original.id,
                coalitions,
                type: "community",
              }),
          },

          {
            accessor: "id2",
            Header: "Intake/outcome Data Sharing",
            Cell: ({ cell }) =>
              getDataSharingType({
                coalitionId: cell.row.original.id,
                coalitions,
                type: "intake_outcome",
              }),
          },
        ]
      : []),
    {
      accessor: "actions",
      Header: "",
      Cell: ({ row: { original } }) => {
        const membership = response?.included?.find(
          (data) =>
            data.type === "coalition_memberships" &&
            data.attributes.coalitionId === Number(original.id)
        );

        return (
          <Menu
            memberships={
              isAdmin
                ? { orgMembership: { ...membership } }
                : coalitions[original.id]
            }
            coalition={original}
            userId={userId}
            organization={organization}
            requestAccess={requestAccess}
            leaveCoalition={leaveCoalition}
            respondToInvitation={respondToInvitation}
            path={path}
            isAdmin={isAdmin}
          />
        );
      },
    },
  ];

  const getColumns = (columns) => {
    if (userId) {
      columns.splice(-1, 0, columns.splice(-3, 1)[0]);
    }
    return columns;
  };

  /**
   * ACTIONS
   */

  // ACCEPT/DECLINE INVITE
  const { mutate: respondToInvitation } = useMutation(
    async ({ coalition, action }) => {
      const adminMembership = response.included.find(
        (data) =>
          data.type === "coalition_memberships" &&
          data.attributes.coalitionId === Number(coalition.id)
      );
      const membership = isAdmin
        ? adminMembership
        : coalitions[coalition.id].orgMembership;
      return await apiFetch({
        token,
        dispatch,
        method: "PATCH",
        endpoint: `/api/v1/coalition_memberships/${membership.id}`,
        body: {
          data: {
            type: "coalition_memberships",
            id: membership.id,
            attributes: {
              statusChangeAction: action,
              statusActor: "Organization",
            },
          },
        },
      });
    },
    {
      onSuccess: (res, { coalition, action }) => {
        handleRespondToInviteSuccess({
          response: res,
          organization,
          coalition,
          action,
        });
      },
      onError: handleError,
    }
  );

  // LEAVE (AS CONTRIBUTOR)
  const { mutate: leaveCoalition } = useMutation(
    async (coalition) => {
      const adminMembership = response.included.find(
        (data) =>
          data.type === "coalition_memberships" &&
          data.attributes.coalitionId === Number(coalition.id)
      );
      const membership = isAdmin
        ? adminMembership
        : get(coalitions[coalition.id], "orgMembership");
      return await apiFetch({
        token,
        dispatch,
        method: "DELETE",
        endpoint: `/api/v1/coalition_memberships/${membership.id}`,
        body: {
          data: {
            type: "coalition_memberships",
            id: membership.id,
          },
        },
      });
    },
    {
      onSuccess: (res, coalition) => {
        handleLeaveCoalitionSuccess(coalition);
      },
      onError: handleError,
    }
  );

  // REQUEST ACCESS
  const { mutate: requestAccess } = useMutation(
    ({ coalition, orgId }) =>
      apiFetch({
        token,
        dispatch,
        method: "POST",
        endpoint: "/api/v1/coalition_memberships",
        body: {
          data: {
            type: "coalition_memberships",
            attributes: {
              organizationId: orgId,
              coalitionId: coalition.id,
              statusActor: "Organization",
            },
          },
        },
      }),
    {
      onSuccess: (res, { coalition }) => {
        handleRequestAccessSuccess({
          response: res,
          coalition,
        });
      },
      onError: handleError,
    }
  );

  /**
   * FORM METHODS
   */
  const onSubmitFilters = (values) => {
    if (!isEqual(values, filters)) {
      setFilters(values);
    }
  };

  const onValidateForm = (values) => {
    const formValues = [
      "search",
      "operatingRegions",
      "region",
      "country",
    ].reduce((vals, key) => {
      if (values[key]) {
        vals.push(values[key]);
      }
      return vals;
    }, []);
    return !!formValues.length ? {} : { error: "Select at least one filter." };
  };

  return (
    <>
      {!userId && (
        <div className="mb-8">
          <Button to={`${path}/coalitions/add`}>Create Coalition</Button>
        </div>
      )}

      <AlertModal
        isModalOpen={isModalOpen}
        closeModal={() => {
          setIsModelOpen(false);
          setOpenUserId(0);
          setOpenType("");
          setOpenState(false);
        }}
      >
        <Formik
          initialValues={{ state: state, error: "" }}
          onSubmit={() => {
            apiFetch({
              token,
              dispatch,
              method: "POST",
              endpoint: "/api/v1/coalition_memberships/update_sharing",
              body: {
                data: {
                  type: "coalition_memberships",
                  attributes: {
                    orgId: organization.id,
                    userId: userId,
                    coalitionId: openUserId,
                    state: state,
                    type: openType,
                  },
                },
              },
            });
            setIsModelOpen(false);
            setOpenUserId(0);
            setOpenType("");
            setOpenState(false);
            window.location.reload();
          }}
        >
          {({ resetForm, errors, touched, handleSubmit }) => (
            <Form className="flex items-end space-x-6">
              <div className="w-80">
                <input type={"hidden"} name="orgId" value={organization.id} />
                <input type={"hidden"} name="userId" value={userId} />
                <input type={"hidden"} name="coalitionId" value={openUserId} />
                {openType === "intake_outcome"
                  ? "Intake/Outcome Data Sharing"
                  : "Community Service Data Sharing"}
                <Field
                  name="state"
                  render={({ field }) => (
                    <>
                      <div className="radio-item">
                        <input
                          {...field}
                          id="yes"
                          value="true"
                          checked={state === true}
                          name="state"
                          onChange={() => {
                            setOpenState(true);
                          }}
                          type="radio"
                        />
                        <label htmlFor="yes">Yes</label>
                      </div>

                      <div className="radio-item">
                        <input
                          {...field}
                          id="no"
                          value="false"
                          name="state"
                          checked={state === false}
                          type="radio"
                          onChange={() => {
                            setOpenState(false);
                          }}
                        />
                        <label htmlFor="no">No</label>
                      </div>
                    </>
                  )}
                />
              </div>

              <div className="flex items-center space-x-2">
                <Button type="submit" onClick={handleSubmit}>
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </AlertModal>

      <div className="sac-border mb-6">
        <Formik
          initialValues={{ ...initialFilters, error: "" }}
          onSubmit={onSubmitFilters}
          validateOnMount
          validate={onValidateForm}
        >
          {({
            resetForm,
            errors,
            touched,
            handleSubmit,
            values: { country },
          }) => (
            <Form>
              <div className="flex items-end justify-between mb-6">
                <div className="w-80">
                  <FormField
                    name="search"
                    label="Search"
                    placeholder="Coalition Name"
                  />
                </div>

                <div className="w-80">
                  <FormField name="country" label="Country" as="select">
                    <option disabled value="" hidden>
                      -- Select a Country --
                    </option>
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                  </FormField>
                </div>

                {country === "Canada" && (
                  <ProvinceDropdown
                    name="operatingRegions"
                    label="Province/Territory"
                  />
                )}
                {country === "United States" && (
                  <StateDropdown name="operatingRegions" label="State" />
                )}
                <RegionDropDown
                  name="region"
                  label="Region"
                  country={country}
                />
              </div>

              <div className="flex items-center justify-end space-x-2 ml-auto">
                <Button
                  onClick={() => {
                    resetForm();
                    setFilters(initialFilters);
                  }}
                  emphasis="transparent"
                >
                  Clear Search
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                  Search
                </Button>
              </div>
              {errors.error && touched.error && (
                <div className="text-sm text-red mt-1">{errors.error}</div>
              )}
            </Form>
          )}
        </Formik>
      </div>

      <Table
        data={response.data || []}
        columns={getColumns(columns)}
        columnWidths={[
          null,
          null,
          null,
          "w-24",
          null,
          ...(userId ? [null, null, null, null] : ["w-24"]),
          "w-16",
        ]}
        columnAlignments={[
          null,
          null,
          null,
          null,
          null,
          ...(userId ? [null, null, null, null] : [null]),
          "text-right",
        ]}
        noResultsText="No coalitions found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default CoalitionsTable;

const Menu = ({
  memberships,
  coalition,
  organization,
  userId,
  requestAccess,
  leaveCoalition,
  respondToInvitation,
  path,
  isAdmin,
}) => {
  const permissions = usePermissions();
  const hasPendingCoalitionInvite =
    (userId || isAdmin) &&
    permissions[ROUTE_GUARDS.COALITION_CONTRIBUTOR_PENDING](coalition.id) &&
    get(memberships, "orgMembership.attributes.statusActor") === "Coalition";
  const orgHasPendingCoalitionInvite =
    path &&
    permissions[ROUTE_GUARDS.COALITION_CONTRIBUTOR_PENDING](coalition.id) &&
    get(memberships, "orgMembership.attributes.statusActor") === "Organization";
  const pendingInvites =
    hasPendingCoalitionInvite || orgHasPendingCoalitionInvite;
  const canRequestAccess =
    !isAdmin &&
    !userId &&
    permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() &&
    !permissions[ROUTE_GUARDS.COALITION_CONTRIBUTOR](coalition.id);
  const canLeaveCoalition =
    (userId || path || isAdmin) &&
    permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() &&
    permissions[ROUTE_GUARDS.COALITION_CONTRIBUTOR](coalition.id);
  const isOrgManagerAndCoalitionContributor =
    permissions[ROUTE_GUARDS.COALITION_CONTRIBUTOR](coalition.id) &&
    permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() &&
    coalition.attributes.status === "active";

  return (
    <ActionsMenu>
      {/* View Coalition (Any User) Details */}
      <FocusableItem>
        {({ ref }) => (
          <Link to={`/coalitions/${coalition.id}`} ref={ref}>
            View Details
          </Link>
        )}
      </FocusableItem>
      {/* Request Access (Org Managers) */}
      {canRequestAccess && (
        <MenuItem
          onClick={() =>
            requestAccess({
              coalition,
              orgId: organization.id,
            })
          }
        >
          Request to Join as Contributor
        </MenuItem>
      )}
      {/* Accept Invite to join Coalition */}
      {pendingInvites && (
        <MenuItem
          onClick={() =>
            respondToInvitation({
              coalition,
              action: COALITION_MEMBERSHIP_ACTIONS.ACCEPT,
            })
          }
        >
          Accept Invitation to Join as Contributor
        </MenuItem>
      )}
      {/* Decline Invite to join Coalition */}
      {pendingInvites && (
        <MenuItem
          onClick={() =>
            respondToInvitation({
              coalition,
              action: COALITION_MEMBERSHIP_ACTIONS.REJECT,
            })
          }
        >
          Decline Invitation to Join as Contributor
        </MenuItem>
      )}
      {/* Leave Coalition (Coalition Contributor) */}
      {canLeaveCoalition && (
        <MenuItem onClick={() => leaveCoalition(coalition)}>
          Leave Coalition as Contributor
        </MenuItem>
      )}

      {/* Sharing Permissions */}
      {isOrgManagerAndCoalitionContributor && (
        <FocusableItem>
          {({ ref }) => (
            <Link
              to={`/coalitions/${coalition.id}/sharing-permissions`}
              ref={ref}
            >
              Sharing Permissions
            </Link>
          )}
        </FocusableItem>
      )}
    </ActionsMenu>
  );
};
