import { View } from "@react-pdf/renderer";
import SpecieTableHeader from "../TotalTable/SpecieTableHeader";
import SpecieTableRow from "../TotalTable/SpecieTableRow";

const SpecieTable = (props) => {
  return (
    <View>
      {props.data.length > 0 && (
        <View>
          <SpecieTableHeader styles={props.styles} name={props.name} />
          <SpecieTableRow items={props.data} />
        </View>
      )}
    </View>
  );
};
export default SpecieTable;
