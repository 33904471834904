import React from "react";
import CoalitionsNav from "components/CoalitionsNav";
import CoalitionsTable from "components/CoalitionsTable";
import PendingCoalitionContributors from "components/PendingCoalitionContributors";
import { useUserState, useAuthState } from "context";

const MyCoalitions = () => {
  const { userId } = useAuthState();
  const { organization, pendingCoalitionContributorRequests } = useUserState();

  return (
    <>
      <h1>My Coalitions</h1>

      <PendingCoalitionContributors
        data={pendingCoalitionContributorRequests}
      />

      <CoalitionsNav />

      <div className="tab__content">
        <CoalitionsTable userId={userId} organization={organization} />
      </div>
    </>
  );
};

export default MyCoalitions;
