import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { API_URL, useQueryParams } from "utils";
import { useMutation } from "react-query";
import Error from "components/Error";
import FormField from "components/FormField";
import FormWrapper from "components/FormWrapper";
import Button from "components/Button";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { decamelizeKeys } from "humps";

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Invalid password")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .min(8, "Invalid password")
    .oneOf([Yup.ref("password")], "Passwords do not match")
    .required("Confirm your password"),
});

const ResetPassword = () => {
  const { accessToken, client, expiry, uid } = useQueryParams();
  const [uiError, setUiError] = useState(null);

  const { mutate: handleSubmit, isLoading, data } = useMutation(
    async (values) => {
      const requestOptions = {
        method: "PUT",
        headers: {
          "access-token": accessToken,
          "token-type": "Bearer",
          "Content-Type": "application/json",
          client,
          expiry,
          uid,
        },
        body: JSON.stringify(
          decamelizeKeys({
            ...values,
            email: uid,
          })
        ),
      };
      const response = await fetch(`${API_URL}/auth/password`, requestOptions);
      return await response.json();
    }
  );

  useEffect(() => {
    const errors = data?.errors || data?.error;
    setUiError(Array.isArray(errors) ? errors.join(", ") : errors);
  }, [data]);

  return (
    <FormWrapper>
      <h1 className="h5 text-center">Reset Password</h1>
      {data?.message ? (
        <>
          <div className="mt-8 text-center">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-4xl text-teal mb-4"
            />
            <p className="mb-6">{data?.message}</p>
            <Button to="/login" emphasis="transparent">
              Back to Login
            </Button>
          </div>
        </>
      ) : (
        <>
          {uiError && <Error className="mb-4">{uiError}</Error>}
          <Formik
            initialValues={{
              email: "",
              password: "",
              passwordConfirmation: "",
            }}
            validateOnMount
            validationSchema={PasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid }) => (
              <Form>
                <div className="mb-6">
                  <FormField
                    type="password"
                    name="password"
                    label="New Password"
                    tooltip={<PasswordTooltip />}
                    tooltipWidthClass="w-80"
                    required
                  />
                </div>
                <div className="mb-6">
                  <FormField
                    type="password"
                    name="passwordConfirmation"
                    label="Confirm Password"
                    required
                  />
                </div>

                <Button
                  disabled={isLoading || !isValid || !dirty}
                  isLoading={isLoading}
                  loadingText={<>Loading&hellip;</>}
                  className="w-full mb-4"
                  type="submit"
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </FormWrapper>
  );
};

export default ResetPassword;

const PasswordTooltip = () => (
  <>
    <p className="text-white">Passwords must be between 8 and 18 characters.</p>
    <p className="mb-0 text-white">
      We recommend using a combination of uppercase letters, lowercase letters,
      numbers, and special characters.
    </p>
  </>
);
