import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { dataEntryStatuses } from "utils";

const DataStatus = ({ status }) => {
  const statusContent = {
    [dataEntryStatuses.complete]: {
      content: "Entry is Complete",
      icon: faCheckCircle,
      color: "text-teal",
    },
    [dataEntryStatuses.incomplete]: {
      content: "Entry is Incomplete",
      icon: faExclamationTriangle,
      color: "text-gold",
    },
    [dataEntryStatuses.unbegun]: {
      content: "Entry is Unbegun",
      icon: faQuestionCircle,
      color: "text-dashboard-blue",
    },
    [dataEntryStatuses.unavailable]: {
      content: "Entry status unavailable",
      icon: faExclamationTriangle,
      color: "text-red",
    },
  };

  return (
    <div>
      <p className="mb-0 flex items-center">
        <FontAwesomeIcon
          icon={statusContent[status].icon}
          className={`text-xl ${statusContent[status].color} mr-2`}
        />
        {statusContent[status].content}
      </p>
    </div>
  );
};

export default DataStatus;
