import { camelizeKeys } from "humps";

export const getQueryParams = (search) => {
  if (!search.match(/[^&?]*?=[^&?]*/g)) return {};
  return search.match(/[^&?]*?=[^&?]*/g).reduce((params, value) => {
    const split = value.split("=");
    params[split[0]] = decodeURIComponent(split[1]);
    return camelizeKeys(params);
  }, {});
};
