import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAllDataStatus } from "./utils";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import ReactTooltip from "react-tooltip";
import { Formik, Form } from "formik";
import { isEqual, get } from "lodash";
import { endOfMonth, startOfMonth } from "date-fns";
import { useAuthState, useAuthDispatch } from "context";
import {
  apiFetch,
  camelToTitle,
  getMonthAbbr,
  getTotalPages,
  EXPORT_DATE_FORMATS,
  formatUiErrors,
} from "utils";
import Button from "components/Button";
import FormField from "components/FormField";
import Table from "components/Table";
import Pagination from "components/Pagination";
import DatePickerField from "components/DatePickerField";
import { format } from "date-fns";
import { CSVLink } from "react-csv";

const DataStatusTable = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { coalitionId } = useParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const currentYear = new Date().getFullYear();
  const [csvFileData, setCsvFileData] = useState([]);
  const [ready, setReady] = useState(false);
  const initialFilters = {
    search: "",
    year: String(currentYear),
  };
  const [filters, setFilters] = useState(initialFilters);
  const [loadingData, setLoadingData] = useState(false);

  const getOrganizations = async ({
    page = 1,
    search = "",
    year = currentYear,
  }) => {
    setLoadingData(true);
    // TODO: Validate the data_entry_status filter works as expected
    const response = await apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/organizations?page[number]=${page}&filter[active_coalition][eq]=${coalitionId}&filter[coalition_membership_status][eq]=active&include=data_entry_statuses,community_data_entry_statuses&stats[total]=count&sort=name&filter[data_entry_statuses.year][eq]=${year}&filter[community_data_entry_statuses.year][eq]=${year}${
        search ? `&filter[search]=${search}` : ""
      }`,
      includeMeta: true,
    });

    await Promise.all(
      response.data.map(async (org) => {
        const intakes = await apiFetch({
          token,
          dispatch,
          endpoint: `/api/v1/animal_intakes?filter[organization_id][eq]=${org.id}&sort=-updated_at&page[size]=1&page[number]=0`,
        });

        const services = await apiFetch({
          token,
          dispatch,
          endpoint: `/api/v1/animal_services?filter[organization_id][eq]=${org.id}&sort=-updated_at&page[size]=1&page[number]=0`,
        });

        const incStatusRecords = response.included.filter((inc) => {
          return (
            inc.type === "organization_data_entry_statuses" &&
            String(inc.attributes.organizationId) === org.id
          );
        });

        const servicesStatusRecords = response.included.filter((inc) => {
          return (
            inc.type === "organization_community_data_entry_statuses" &&
            String(inc.attributes.organizationId) === org.id
          );
        });

        org.servicesStatuses = {};
        org.dataStatuses = {};
        org.serviceLatestIntake = new Date(1970);
        org.latestIntake = new Date(1970);
        if (intakes.data[0]?.attributes.updatedAt) {
          org.latestIntake = new Date(intakes?.data[0].attributes.updatedAt);
        }
        if (services.data[0]?.attributes.updatedAt) {
          org.serviceLatestIntake = new Date(
            services?.data[0].attributes.updatedAt
          );
        }

        for (let i = 0; i < 12; i++) {
          const month = i + 1;
          const monthRecord = (incStatusRecords || []).find(
            (rec) => rec.attributes.month === month
          );

          const servicesMonthRecord = (servicesStatusRecords || []).find(
            (rec) => rec.attributes.month === month
          );
          org.dataStatuses[month] = monthRecord
            ? monthRecord.attributes.status
            : "unbegun";

          org.servicesStatuses[month] = servicesMonthRecord
            ? servicesMonthRecord.attributes.status
            : "unbegun";
        }

        return org;
      })
    );

    setLoadingData(false);
    return response;
  };

  // Initial query
  const {
    data: response = { data: [], included: [] },
    error,
    isLoading,
  } = useQuery(
    ["constributorsDataStatus", { coalitionId, page, ...filters }],
    () => getOrganizations({ page, ...filters }),
    {
      keepPreviousData: false,
    }
  );

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  useEffect(() => {
    getOrganizations({ page, ...filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSubmitFilters = (values) => {
    if (!isEqual(values, filters)) {
      if (typeof values.year != "string") {
        const yearAsFourDigits = String(values.year.getFullYear());
        values.year = yearAsFourDigits;
      }
      setFilters(values);
    }
  };

  const { mutate: exportDataStatus, isLoading: dataStatusLoading } =
    useMutation(
      async () => {
        const allStatuses = await getAllDataStatus({
          token,
          dispatch,
          coalitionId,
          year: filters.year,
          search: filters.search,
        });
        setCsvFileData(allStatuses);

        return;
      },
      {
        onSuccess: () => {
          setReady(true);
        },
      }
    );

  const columns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    ...getStatusColumns(),
    {
      accessor: `latestIntake`,
      Header: "Last Update",
      sortType: "datetime",
      sortDescFirst: true,
      Cell: ({ cell }) => {
        const formattedDate = format(new Date(cell.value), "MM-dd-yyyy");
        return (
          <>
            {cell.value &&
            cell.value.toDateString() !== new Date(1970).toDateString() ? (
              <span>{formattedDate}</span>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    },
  ];

  const serviceStatusColumns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    ...getServiceStatusColumns(),
    {
      accessor: `serviceLatestIntake`,
      Header: "Last Update",
      sortType: "datetime",
      sortDescFirst: true,
      Cell: ({ cell }) => {
        const formattedDate = format(new Date(cell.value), "MM-dd-yyyy");
        return (
          <>
            {cell.value &&
            cell.value.toDateString() !== new Date(1970).toDateString() ? (
              <span>{formattedDate}</span>
            ) : (
              <span>N/A</span>
            )}
          </>
        );
      },
    },
  ];

  let columnWidths = new Array(13).fill(null, 1, 13);
  columnWidths[0] = "w-40";
  columnWidths[13] = "w-36";

  return (
    <>
      <div>
        <h2>Intake/Outcome Data Entry Status</h2>

        <div className="sac-border mb-6">
          <Formik initialValues={initialFilters} onSubmit={onSubmitFilters}>
            {({ resetForm, handleSubmit }) => (
              <Form>
                <div className="items-end xl:flex xl:space-x-6">
                  <div className="mb-4 space-y-2 lg:flex lg:space-x-2 lg:space-y-0 xl:w-2/3 xl:mb-0">
                    <FormField
                      name="search"
                      label="Search"
                      placeholder="Name or EIN"
                    />

                    <DatePickerField
                      label={"Export Year"}
                      name="year"
                      dateFormat={EXPORT_DATE_FORMATS.YEAR}
                      showMonthYearPicker={false}
                      showYearPicker={true}
                      maxDate={endOfMonth(new Date())}
                      minDate={startOfMonth(
                        new Date(2010, 1, 1, 0, 0)
                      )} /* Must be set to 2010 for 2011 to be the min year in UI */
                      required
                    />
                  </div>

                  <div className="flex items-center space-x-2">
                    <Button type="submit" onClick={handleSubmit}>
                      Search
                    </Button>
                    <Button
                      onClick={() => {
                        resetForm();
                        setFilters(initialFilters);
                      }}
                      emphasis="transparent"
                    >
                      Reset Filters
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <Table
          data={response.data.length > 0 ? response.data : []}
          columns={columns}
          columnWidths={columnWidths}
          noResultsText="No contributors found"
          isLoading={isLoading || loadingData}
          error={error}
        />

        {ready ? (
          <CSVLink
            data={getCsvData(csvFileData.data, filters.year, "dataStatuses")}
            filename={"intake-outcome-data-status-table.csv"}
            className="text-dashboard-blue m-4 mb-0 inline-block"
          >
            Download Intake Data Status Table as CSV
          </CSVLink>
        ) : (
          <Button
            onClick={() => {
              exportDataStatus("dataStatuses");
            }}
            isLoading={dataStatusLoading}
            loadingText={<>Generating your export&hellip;</>}
            emphasis="secondary"
            className="w-max mt-4"
          >
            Generate Intakes Data Status CSV
          </Button>
        )}

        {!!(response.data || []).length && (
          <div className="flex justify-end mt-8">
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChanged={(page) => {
                setPage(page);
              }}
            />
          </div>
        )}
      </div>

      <div className="mt-12">
        <h2>Community Services Data Entry Status</h2>

        <Table
          data={response.data.length > 0 ? response.data : []}
          columns={serviceStatusColumns}
          columnWidths={columnWidths}
          noResultsText="No contributors found"
          isLoading={isLoading || loadingData}
          error={error}
        />

        {ready ? (
          <CSVLink
            data={getCsvData(
              csvFileData.data,
              filters.year,
              "servicesStatuses"
            )}
            filename={"community-services-data-status-table.csv"}
            className="text-dashboard-blue m-4 mb-0 inline-block"
          >
            Download Community Services Data Status Table as CSV
          </CSVLink>
        ) : (
          <Button
            onClick={() => {
              exportDataStatus("servicesStatuses");
            }}
            isLoading={dataStatusLoading}
            loadingText={<>Generating your export&hellip;</>}
            emphasis="secondary"
            className="w-max mt-4"
          >
            Generate Community Services Data Status CSV
          </Button>
        )}

        {!!(response.data || []).length && (
          <div className="flex justify-end mt-8">
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChanged={(page) => {
                setPage(page);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DataStatusTable;

const getStatusColumns = () => {
  let columns = [];
  for (let i = 0; i < 12; i++) {
    columns.push({
      accessor: `dataStatuses.${i + 1}`,
      Header: getMonthAbbr(i + 1),
      Cell: ({ cell, row: { original } }) =>
        dataStatusIndicator({
          status: cell.value,
          month: i + 1,
          id: original.id,
        }),
    });
  }
  return columns;
};

const getServiceStatusColumns = () => {
  let columns = [];
  for (let i = 0; i < 12; i++) {
    columns.push({
      accessor: `servicesStatuses.${i + 1}`,
      Header: getMonthAbbr(i + 1),
      Cell: ({ cell, row: { original } }) =>
        dataStatusIndicator({
          status: cell.value,
          month: i + 1,
          id: original.id,
          data: "services",
        }),
    });
  }
  return columns;
};

const indicatorIcon = ({ icon, colorClass }) => {
  return <FontAwesomeIcon icon={icon} className={`${colorClass} text-2xl`} />;
};

const dataStatusIndicator = ({ status, month, id, data = "intakes" }) => {
  let icon = null;
  switch (status) {
    case "unbegun":
      icon = indicatorIcon({
        icon: faCircle,
        colorClass: "text-dashboard-medium-gray",
      });
      break;
    case "incomplete":
      icon = indicatorIcon({
        icon: faExclamationCircle,
        colorClass: "text-gold",
      });
      break;
    case "complete":
      icon = indicatorIcon({ icon: faCheckCircle, colorClass: "text-teal" });
      break;
    default:
      icon = "--";
      break;
  }

  const uuid = `${data}_${month}_${id}`;

  return (
    <>
      <span data-tip data-for={uuid}>
        {icon}
      </span>
      <ReactTooltip place="right" className="w-48" id={uuid}>
        {camelToTitle(status) || "Unavailable"}
      </ReactTooltip>
    </>
  );
};

function getCsvData(data, year, statusType) {
  return data.map((item) => {
    const organization_name = item.attributes.name;
    let dataEntryStatuses = {};
    Object.entries(item[statusType]).map(([key, value]) => {
      const month = getMonthAbbr(Number(key));
      dataEntryStatuses[month] = value;
      return null; // Add a return statement here
    });
    const last_updated =
      statusType === "dataStatuses"
        ? item.latestIntake
        : item.serviceLatestIntake;
    const year_completion_status = calculateYearCompletionStatus(
      item[statusType],
      year
    );

    return {
      organization_name,
      ...dataEntryStatuses,
      year,
      last_updated,
      year_completion_status,
    };
  });
}

function calculateYearCompletionStatus(status_object, year) {
  const statuses = Object.values(status_object);

  const past_year = year < new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Adding 1 because months are 0-indexed in JavaScript
  if (
    statuses.every((s) => s === "unbegun") ||
    !statuses.includes("complete")
  ) {
    return "Unbegun";
  }

  if (past_year) {
    if (statuses.every((s) => s === "complete")) return "Full-Annual";

    return "Partial - Annual";
  } else {
    if (
      statuses.slice(0, currentMonth).every((s) => s === "complete") &&
      statuses.slice(currentMonth).every((s) => s === "unbegun")
    )
      return "Full - Year to Date";

    return "Partial - Year to Date";
  }
}
