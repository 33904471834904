import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { speciesMap } from "utils";
import DataStatus from "../common/DataStatus";

const AnimalEntryDetailsSelected = ({
  formattedDate,
  locationName,
  defaultSpecies,
  entryStatus,
}) => {
  return (
    <div data-testid="selected-details">
      <div className="mb-6 flex space-x-4">
        <h5 className="w-1/5 font-body text-base">Entering Data For:</h5>

        <p className="font-body text-base mb-0">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="text-deepest-teal mr-1"
          />
          {locationName}
        </p>
      </div>

      <div className="mb-6 flex space-x-4">
        <h5 className="w-1/5 font-body text-base">Timeframe:</h5>

        <p className="mb-0">{formattedDate}</p>
      </div>

      <div className="mb-6 flex space-x-4">
        <h5 className="w-1/5 font-body text-base">Data Entry Status:</h5>

        <DataStatus status={entryStatus} />
      </div>

      <div className="flex space-x-4 mb-6">
        <h5 className="w-1/5 font-body text-base flex-shrink-0">
          Animal Types:
        </h5>
        <ul className="flex flex-wrap">
          {defaultSpecies.map((species) => (
            <li
              key={species}
              className="rounded-full py-1 px-3 bg-pale-blue mr-2 mb-2"
            >
              {speciesMap[species].label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AnimalEntryDetailsSelected;
