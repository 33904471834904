import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { cloneDeep } from "lodash";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { USER_UPDATE } from "actions";
import AlertModal from "components/AlertModal";
import Button from "components/Button";
import {
  useAuthDispatch,
  useAuthState,
  useUserDispatch,
  useUserState,
} from "context";
import { apiFetch } from "utils";

const LocationSelector = ({ locations, locationId }) => {
  const { token, userId } = useAuthState();
  const dispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();
  const { attributes, locations: stateLocations } = useUserState();
  const [location, setLocation] = useState(locationId || "");
  const [tempLocation, setTempLocation] = useState(null);
  const [isModalOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setLocation(locationId || "");
  }, [locationId]);

  const openModal = (event) => {
    setTempLocation(event.target.value);
    setIsOpen(true);
  };

  const closeModal = () => {
    setTempLocation(null);
    setIsOpen(false);
    window.location.reload();
  };

  const {
    mutate: updateUserLocationId,
    isLoading,
    error,
  } = useMutation(
    (locationId) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/users/${userId}`,
        method: "PATCH",
        body: {
          data: {
            id: userId,
            type: "users",
            attributes: {
              locationId,
            },
          },
        },
      }),
    {
      onSuccess: (response) => {
        const locationId = response.data.attributes.locationId;
        const location = stateLocations.find(
          (loc) => loc.id === String(locationId)
        );
        userDispatch({
          type: USER_UPDATE,
          payload: {
            attributes: Object.assign(cloneDeep(attributes), {
              locationId,
              ...(location ? { locationName: location.attributes.name } : {}),
            }),
          },
        });
        closeModal();
      },
    }
  );

  return (
    locations.length > 1 && (
      <div className="flex items-center space-x-2">
        <label htmlFor="location" className="text-xs text-dashboard-pale-gray">
          Location:
        </label>
        <select
          name="location"
          className="form-select text-sm"
          disabled={isLoading}
          onChange={openModal}
          value={location}
        >
          {locations.map((item) => (
            <option key={item.id} value={item.id}>
              {item.attributes.name}
            </option>
          ))}
        </select>

        <AlertModal isModalOpen={isModalOpen} closeModal={closeModal}>
          <AlertContent
            isLoading={isLoading}
            error={error}
            closeModal={closeModal}
            confirmModal={() => updateUserLocationId(tempLocation)}
          />
        </AlertModal>
      </div>
    )
  );
};

export default LocationSelector;

const AlertContent = ({ isLoading, error, closeModal, confirmModal }) => {
  return (
    <div className="text-center">
      {isLoading ? (
        <div className="italic">Loading&hellip;</div>
      ) : (
        <>
          <h3 className={`text-2xl mb-4 ${error ? "text-red" : "text-gold"}`}>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            {error ? "Error" : "Warning!"}
          </h3>

          <p className="mb-6">
            {error
              ? "There was an error with the request."
              : `If you have any changes or edits in progress and switch locations you will lose un-saved data.`}
          </p>

          <div className="flex items-center justify-center space-x-4">
            <Button onClick={closeModal} emphasis="secondary">
              Cancel
            </Button>
            <Button onClick={confirmModal}>
              {error ? "Try Again" : "Confirm"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
