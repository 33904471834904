import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import fontBold from "../../fonts/Cera-Pro-Bold.woff";
import PDFLogo from "./ExportPDF/PDFLogo";
import TopHeader from "./ExportPDF/TopHeader";
import MainTable from "./ExportPDF/MainTable";
import CountsTable from "./ExportPDF/CountsTable";
import CustomSpeciesPage from "./ExportPDF/CustomSpeciesPage";
import {
  generateCountsData,
  generateLiveIntakeData,
  LIVE_INTAKE_KEYS,
  LIVE_OUTCOMES_KEYS,
  OTHER_OUTCOMES,
} from "./ExportPDF/helpers/PDFTableKeys";
import SideText from "./ExportPDF/Shared/SideText";

Font.register({
  src: fontBold,
  family: "Cera Pro Bold",
});

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  container: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  rightCol: {
    flexDirection: "column",
    flexGrow: 9,
    paddingLeft: 10,
    paddingRight: 20,
    fontSize: 15,
    textTransform: "uppercase",
  },
  container2: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    height: 30,
    paddingTop: 7,
    textAlign: "center",
    flexGrow: 1,
  },
  container3: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    paddingTop: 7,
    textAlign: "center",
  },
  container4: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    marginTop: "-5px",
    textAlign: "center",
  },
  leftColumn: {
    width: 400,
    paddingRight: 10,
  },
  leftColumn2: {
    width: 350,
    paddingRight: 0,
    paddingLeft: 20,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 900,
  },
  header: {
    color: "#0F6165",
    fontSize: "24px",
    paddingLeft: 10,
    fontFamily: "Cera Pro Bold",
    fontWeight: 900,
  },
});

// Create Document Component
const PDFExport = ({ data, orgName, action, timeframe }) => {
  var totalSpecies = [
    ...data.intakes.data
      .map((a) => a.attributes.species)
      .filter((x, i, a) => a.indexOf(x) === i),
    ...data.outcomes.data
      .map((a) => a.attributes.species)
      .filter((x, i, a) => a.indexOf(x) === i),
    ...data.counts.data
      .map((a) => a.attributes.species)
      .filter((x, i, a) => a.indexOf(x) === i),
  ].filter((x, i, a) => a.indexOf(x) === i);

  var liveIntakes = [];
  liveIntakes = generateLiveIntakeData(data.intakes.data, LIVE_INTAKE_KEYS);
  var liveOutComes = [];
  liveOutComes = generateLiveIntakeData(data.outcomes.data, LIVE_OUTCOMES_KEYS);
  var otherOutcomes = [];
  otherOutcomes = generateLiveIntakeData(data.outcomes.data, OTHER_OUTCOMES);
  var countsData = [];
  countsData = generateCountsData(data.counts.data, data.month);
  // Need to comment for now but we need this in future
  // var YOYData = [];
  // YOYData = generateYOYData(
  //   liveIntakes,
  //   liveOutComes,
  //   otherOutcomes,
  //   data.year
  // );
  return (
    <Document>
      <Page size="A3">
        <View style={styles.section}></View>
        <View style={styles.container}>
          <View style={styles.rightCol}>
            <PDFLogo />
          </View>
          <View style={styles.leftColumn}>
            <TopHeader
              orgName={orgName}
              timeframe={timeframe}
              action={action}
              headerName={"TOTAL"}
            />
          </View>
        </View>
        <Text style={styles.header}>ORGANIZATION TOTALS</Text>
        <View style={styles.container}>
          <View style={styles.rightCol}>
            <MainTable
              styles={styles}
              data={liveIntakes}
              name={"LIVE INTAKES"}
            />
            <Text>{"\n"}</Text>
            <MainTable
              styles={styles}
              name={"LIVE OUTCOMES"}
              data={liveOutComes}
            />
            <Text>{"\n"}</Text>
            <MainTable
              styles={styles}
              name={"OTHER OUTCOMES"}
              data={otherOutcomes}
            />
          </View>
          <View style={styles.leftColumn2}>
            <SideText />
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.rightCol}>
            <CountsTable styles={styles} data={countsData} />
            {/* <YOYTable styles={styles} data={YOYData} /> */}
          </View>
        </View>
      </Page>
      {totalSpecies.map((sp) => (
        <CustomSpeciesPage
          key={sp}
          title={sp.toLocaleUpperCase()}
          sp={sp}
          data={data}
          action={action}
          orgName={orgName}
          styles={styles}
          timeframe={timeframe}
        />
      ))}
    </Document>
  );
};
export default PDFExport;
