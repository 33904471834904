import React from "react";

import { Link } from "react-router-dom";

const PendingCoalitionContributors = ({ data }) => {
  if (!data || !data.length) return null;

  return (
    <div className="mb-8 sac-border bg-super-light-gray rounded-lg px-6 py-4">
      <h3 className="mb-2">Pending Coalition Requests</h3>

      {data.map((coalition) => (
        <div key={coalition.id}>
          <div>
            {coalition.attributes.name}:{" "}
            <Link to={`/coalitions/${coalition.id}/contributors`}>
              View Contributors
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PendingCoalitionContributors;
