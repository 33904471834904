import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { get } from "lodash";
import { MenuItem, FocusableItem } from "@szhsin/react-menu";
import { ROUTE_GUARDS, apiFetch, getTotalPages, usePermissions } from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import ActionsMenu from "components/ActionsMenu";
import ConfirmationModal from "components/ConfirmationModal";
import AlertModal from "components/AlertModal";
import Button from "components/Button";
import Table from "components/Table";
import Pagination from "components/Pagination";

const Locations = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const permissions = usePermissions();
  const { organization, isAdmin } = useUserState();
  const { orgId } = useParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState(null);
  const isOrgMember = permissions[ROUTE_GUARDS.CAN_VIEW_ORG]();
  const getOrgLocations = ({ page = 1 }) =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/locations?filter[organization_id][eq]=${orgId}&extra_fields[locations]=us_county_name&page[number]=${page}&stats[total]=count&sort=name`,
      includeMeta: true,
    });

  // Initial query
  const {
    data: response = { data: [] },
    error,
    isLoading,
  } = useQuery(
    ["organizationLocations", { orgId, page }],
    () => getOrgLocations({ page }),
    {
      keepPreviousData: true,
    }
  );

  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["organizationLocations", { orgId, page: page + 1 }],
        () => getOrgLocations({ page: page + 1 })
      );
    }
  }, [response, page, queryClient]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    {
      accessor: "id",
      Header: "Location Id",
    },
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    {
      accessor: "address",
      Header: "Address",
      Cell: ({ row: { original } }) => (
        <>
          {isOrgMember && `${original.attributes.addressLine1}`}
          {isOrgMember && original.attributes.addressLine2 && (
            <>
              <br />
              {original.attributes.addressLine2}
              <br />
            </>
          )}
          {original.attributes.city},{" "}
          {`${
            original.attributes.country === "United States"
              ? original.attributes.usState
              : original.attributes.provinceOrTerritory
          }, `}
          {original.attributes.country === "United States"
            ? original.attributes.zipCode
            : original.attributes.postalCode}
        </>
      ),
    },
    ...[
      organization?.attributes.country === "United States"
        ? {
            accessor: "attributes.usCountyName",
            Header: "County",
          }
        : {
            accessor: "attributes.provinceOrTerritory",
            Header: "Province/Territory",
          },
    ],
    {
      accessor: "attributes.status",
      Header: "Status",
    },
  ];

  if (
    permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() &&
    permissions[ROUTE_GUARDS.CAN_VIEW_ORG]()
  ) {
    columns.push({
      accessor: "orgId",
      Header: "",
      Cell: ({ row: { original } }) => (
        <Menu
          location={original}
          orgId={orgId}
          triggerDeleteLocationModal={openModal}
        />
      ),
    });
  }

  const openModal = (location) => {
    setLocationToDelete(location);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalError(null);
    setLocationToDelete(null);
  };

  const { mutate: deleteLocation, isLoading: isLoadingDeleteLocation } =
    useMutation(
      ({ id }) =>
        apiFetch({
          token,
          dispatch,
          method: "POST",
          endpoint: "/api/v1/resource_archivers",
          body: {
            data: {
              type: "resource_archivers",
              attributes: {
                resourceId: id,
                resourceType: "location",
              },
            },
          },
        }),
      {
        onSuccess: () => {
          toast.success(
            isAdmin
              ? `Deleted ${locationToDelete.attributes.name} successfully`
              : "SAC administrators have been notified that you would like this location deleted.",
            {
              autoClose: 3000,
            }
          );
          // Refresh the org members queries
          queryClient.invalidateQueries(["organizationLocations", { orgId }], {
            refetchActive: true,
          });
          closeModal();
        },
        onError: setModalError,
      }
    );

  return (
    <>
      <div className="mb-8">
        <h2>Locations</h2>

        {/* TODO: https://app.asana.com/0/1179331329125530/1199708861796442/f */}
        {permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() &&
          permissions[ROUTE_GUARDS.CAN_VIEW_ORG]() && (
            <div className="flex space-x-2">
              <Button
                to={`/organizations/${orgId}/locations/add`}
                data-testid="add-location-btn"
              >
                Add Location
              </Button>
            </div>
          )}
      </div>

      <Table
        data={response.data || []}
        columns={columns}
        columnWidths={[null, null, null, null, null, null, "w-16"]}
        columnAlignments={[null, null, null, null, null, null, "text-right"]}
        noResultsText="No locations found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}

      <AlertModal isModalOpen={isModalOpen} closeModal={closeModal}>
        <ConfirmationModal
          prompt={`Are you sure you want to delete ${get(
            locationToDelete,
            "attributes.name"
          )}?`}
          isLoading={isLoadingDeleteLocation}
          error={modalError}
          closeModal={closeModal}
          confirmModal={() => deleteLocation(locationToDelete)}
        />
      </AlertModal>
    </>
  );
};

export default Locations;

const Menu = ({ location, orgId, triggerDeleteLocationModal }) => (
  <ActionsMenu>
    {/* View */}
    <FocusableItem>
      {({ ref }) => (
        <Link to={`/organizations/${orgId}/locations/${location.id}`} ref={ref}>
          View Details
        </Link>
      )}
    </FocusableItem>
    {/* Edit */}
    <FocusableItem>
      {({ ref }) => (
        <Link
          to={`/organizations/${orgId}/locations/${location.id}/edit`}
          ref={ref}
        >
          Edit Details
        </Link>
      )}
    </FocusableItem>
    {/* Remove */}
    {!location.attributes.primary && (
      <MenuItem onClick={() => triggerDeleteLocationModal(location)}>
        Delete Location
      </MenuItem>
    )}
  </ActionsMenu>
);
