import React, { useEffect } from "react";
import FormWrapper from "components/FormWrapper";
import Button from "components/Button";

const PageNotFound = () => {
  useEffect(() => {
    document.body.className = "four-oh-four";
    return () => {
      document.body.className = "";
    };
  });

  return (
    <FormWrapper className="bg-white">
      <h1 className="h2 text-center">404 - Page Not Found</h1>
      <p className="mb-6">
        The page you requested could not be found. Check that you typed the
        address correctly, go back to your previous page or try navigating to
        your location from the homepage. Still can't find what you're looking
        for?{" "}
        <a
          href="https://www.shelteranimalscount.org/contact"
          target="_blank"
          rel="noreferrer"
        >
          Contact us
        </a>
        .
      </p>
      <div className="flex items-center w-100">
        <Button to="/" className="mx-auto">
          Return to Home
        </Button>
      </div>
    </FormWrapper>
  );
};

export default PageNotFound;
