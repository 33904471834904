import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthState, useUserState } from "context";

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  const { token, userId } = useAuthState();
  const { id } = useUserState();

  return (
    <Route
      {...rest}
      render={(props) => {
        // We can consider a user logged in if both the access token and userId are defined
        // In that case, redirect to the dashboard route
        if (Boolean(token?.accessToken) && Boolean(userId) && id)
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );

        return <Component {...props} />;
      }}
    />
  );
};

export default UnauthenticatedRoute;
