import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { logout } from "actions";
import { useAuthDispatch, useUserDispatch } from "context";
import logo from "images/sac-logo.7776b610_NEW.svg";
import Button from "components/Button";

const PageWrapper = ({ showLogout, children }) => {
  const dispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();
  const history = useHistory();

  const { mutate: logoutRequest } = useMutation(logout);

  const queryClient = useQueryClient();

  const handleLogout = () => {
    logoutRequest({
      dispatch,
      userDispatch,
      queryClient,
    });
    history.push("/login");
  };

  return (
    <>
      <div className="bg-light-gray border-b-2 border-pale-blue">
        <div className="flex justify-between items-center mx-auto max-w-4xl py-4 px-6">
          <Link to="/">
            <img
              src={logo}
              alt="Shelter Animals Count - The National Database"
              style={{ width: "150px" }}
            />
          </Link>

          <div className="flex items-center space-x-4">
            {/* TODO: Is this the right link? */}
            <a href="https://www.shelteranimalscount.org/">
              shelteranimalscount.org
            </a>

            {showLogout && <Button onClick={handleLogout}>Logout</Button>}
          </div>
        </div>
      </div>

      <div className="w-full mx-auto pt-16 pb-20 max-w-2xl">{children}</div>
    </>
  );
};

export default PageWrapper;
