import React from "react";
import { getMonthAbbr } from "utils";
import { dataEntryStatuses } from "utils";
import RecordStatusIndicator from "../RecordStatusIndicator";
import classnames from "classnames";

const RecordStatusTableItem = (props) => {
  const { month, year, status, action, location, adminOrganization } = props;
  const getStatus = () => {
    switch (status) {
      case "complete":
      case "complete_services_provided":
      case "complete_no_services_provided":
        return "complete";
      case "incomplete":
      case "in_progress":
        return "incomplete";
      default:
        return status;
    }
  };

  const statusClasses = {
    [dataEntryStatuses.complete]: {
      background: "bg-dashboard-light-green",
      border: "border-dashboard-light-green",
    },
    [dataEntryStatuses.incomplete]: {
      background: "bg-gold bg-opacity-25",
      border: "border-gold border-opacity-25",
    },
    [dataEntryStatuses.unbegun]: {
      background: "bg-transparent",
      border: "border-dashboard-pale-blue",
    },
    [dataEntryStatuses.unavailable]: {
      background: "bg-light-gray",
      border: "border-light-gray",
    },
  };
  const serviceStatusClasses = {
    [dataEntryStatuses.complete]: {
      background: "bg-teal",
    },
    [dataEntryStatuses.incomplete]: {
      background: "bg-gold",
    },
    [dataEntryStatuses.unbegun]: {
      background: "bg-dashboard-blue",
    },
    [dataEntryStatuses.unavailable]: {
      background: "bg-dashboard-pale-gray",
    },
  };

  const isService = action === "services";
  const defaultStyles = `${statusClasses[getStatus()].background} ${
    statusClasses[getStatus()].border
  } border`;
  const serviceStyles = `${serviceStatusClasses[getStatus()].background}`;
  return (
    <div className="w-1/6 px-1 flex flex-col">
      <div
        className={classnames(
          "mb-2 flex flex-col flex-grow items-center content-center py-3 px-2 text-deepest-teal rounded-lg",
          { [defaultStyles]: !isService, [serviceStyles]: isService }
        )}
      >
        <span
          className={classnames("mb-2 text-sm", {
            "text-white":
              isService &&
              (getStatus() === "unbegun" || getStatus() === "unavailable"),
          })}
        >
          {getMonthAbbr(month)}
        </span>

        <div
          className="w-full flex flex-col flex-grow items-center justify-center"
          style={{ height: 40 }}
        >
          <RecordStatusIndicator
            month={month}
            year={year}
            status={getStatus()}
            action={action}
            adminOrganization={adminOrganization}
            location={location}
          />
        </div>
      </div>
    </div>
  );
};

export default RecordStatusTableItem;
