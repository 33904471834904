import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  REFRESH_TOKEN,
  UPDATE_TOKEN,
  UPDATE_USER_ID,
} from "actions";
import { saveToken, saveUserId, TOKEN, USER_ID } from "utils";

export const initialAuthState = {
  token: TOKEN,
  userId: USER_ID,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return initialState;

    case LOGIN_SUCCESS: {
      const { token, userId } = action.payload;

      saveToken(token);
      saveUserId(userId);

      return {
        ...initialState,
        token,
        userId,
      };
    }

    case UPDATE_USER_ID: {
      const { userId } = action.payload;

      return {
        ...initialState,
        userId,
      };
    }

    case UPDATE_TOKEN: {
      const { token } = action.payload;

      return {
        ...initialState,
        token,
      };
    }

    case REFRESH_TOKEN: {
      const { token } = action.payload;

      saveToken(token);

      return {
        ...initialState,
        token,
      };
    }

    case LOGIN_FAILURE:
      return {
        ...initialState,
        errorMessage: action.error,
      };

    case LOGOUT_REQUEST:
      return {
        token: {},
        userId: null,
        errorMessage: action.error || null,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
