import { View } from "@react-pdf/renderer";
import CountsTableHeader from "./TotalTable/CountsTableHeader";
import CountsTableRow from "./TotalTable/CountsTableRow";

const CountsTable = (props) => {
  return (
    <View style={{ marginTop: 30 }}>
      <CountsTableHeader styles={props.styles} />
      <CountsTableRow items={props.data} />
    </View>
  );
};
export default CountsTable;
