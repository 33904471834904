import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { endOfMonth } from "date-fns";
import {
  EXPORT_DATE_FORMATS,
  useInterval,
  useHandleRequestStates,
  apiFetch,
} from "utils";
import { useAuthState, useAuthDispatch } from "context";
import Table from "components/Table";
import DatePickerField from "components/DatePickerField";
import Button from "components/Button";
import FormField from "components/FormField";
import { columns, EXPORT_STATUS, ALLSTATES } from "./utils";
import { MultiSelect } from "react-multi-select-component";
import { API_URL } from "utils";
import { decamelizeKeys } from "humps";

const options = ALLSTATES;

const ExportData = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { coalitionId } = useParams();
  const queryClient = useQueryClient();
  const { handleError } = useHandleRequestStates();
  const [shouldPoll, setShouldPoll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedProvinces, setSelectedProvinces] = useState([]); // for Canada
  const provinces = [
    { label: "AB", value: "AB" },
    { label: "BC", value: "BC" },
    { label: "MB", value: "MB" },
    { label: "NB", value: "NB" },
    { label: "NL", value: "NL" },
    { label: "NT", value: "NT" },
    { label: "NS", value: "NS" },
    { label: "NU", value: "NU" },
    { label: "ON", value: "ON" },
    { label: "PE", value: "PE" },
    { label: "QC", value: "QC" },
    { label: "SK", value: "SK" },
    { label: "YT", value: "YT" },
  ];
  const [selectedOrgType, setSelectedOrgType] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [allOrgs, setallOrgs] = useState([]);
  const [allOrgsTypes, setallOrgsTypes] = useState([]);
  const [selectedExport, setSelectedExpot] = useState("intake_outcome");

  const ExportSchema = Yup.object().shape({
    dateFormat: Yup.string().when([], {
      is: () => selectedExport !== "contributors",
      then: Yup.string().required("Select a date format"),
    }),
    date: Yup.string().when([], {
      is: () => selectedExport !== "contributors",
      then: Yup.string().required("Select an export date"),
    }),
  });

  // Get CSV exports
  const {
    data: response = { data: [] },
    refetch,
    isLoading,
    error,
  } = useQuery(
    ["coalitionExports", coalitionId],
    () =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/coalition_data_exports?filter[coalition_id]=${coalitionId}&sort=-created_at`,
      }),
    {
      onSuccess: (res) => {
        // Start polling when there are records with a status of `started`
        const startedRecords = res.data.filter(
          (rec) => rec.attributes.status === EXPORT_STATUS.STARTED
        );
        setShouldPoll(!!startedRecords.length);
      },
    }
  );

  // API requests to trigger the CSV generation
  const { mutate: generateCsv, isLoading: isGeneratingCsv } = useMutation(
    ({ dateFormat, date, state, usCountyId }) => {
      const year = new Date(date).getFullYear();
      const month = new Date(date).getMonth() + 1;

      return apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/coalition_data_exports?filter[coalition_id]=${coalitionId}`,
        method: "POST",
        body: {
          data: {
            type: "coalition_data_exports",
            attributes: {
              state,
              usCountyId,
              states: selected.map((s) => s.value),
              us_county_ids: selectedCountry.map((s) => s.value),
              org_types: selectedOrgType.map((s) => s.value),
              export_for: selectedExport,
              organization_ids: selectedOrg.map((s) => s.value),
              coalitionId,
              yearOfRecord:
                selectedExport === "contributors"
                  ? new Date().getFullYear()
                  : year,
              ...(dateFormat === EXPORT_DATE_FORMATS.MONTH
                ? { monthOfRecord: month }
                : {}),
            },
          },
        },
      });
    },
    {
      onSuccess: (res) => {
        // When we have successfully generating a report, set shouldPoll to true
        // and add the newly created record to the top of the exports cache,
        // which will also add the new record to the top of the table
        setShouldPoll(true);
        queryClient.setQueryData(
          ["coalitionExports", coalitionId],
          (oldRes = { data: [] }) => {
            return Object.assign(oldRes, {
              data: [res.data, ...oldRes.data],
            });
          }
        );
      },
      onError: handleError,
    }
  );

  // When shouldPoll is true, call the refetch function to re-fetch
  // the available exports every 10 seconds
  useInterval(refetch, shouldPoll ? 10000 : null);

  const fetchCountries = async (usState) => {
    var states = usState.map((a) => a.value);
    if (states.length == 0) return "";
    var cont = [];
    for (let index = 1; index < 5; index++) {
      const response = await fetch(
        // Page size is 260 because the max number of counties for a given state is 254
        `${API_URL}/api/v1/us_counties?filter[us_state][eq]=${states}&page[size]=1000&page[number]=${index}`,
        {
          method: "GET",
        }
      );
      const {
        data,
        error: apiError,
        errors: apiErrors,
      } = await response.json();

      if (!response.ok) {
        const resErrors = apiErrors || apiError;
        throw resErrors;
      }
      var newStates = data.map((a) => {
        return { label: a.attributes.name, value: a.id };
      });
      cont = cont.concat(newStates);
    }
    setCountries(cont);
  };

  const fetchOrgs = async (orgTypes) => {
    const { accessToken, client, expiry, uid } = token;
    var orgType = orgTypes.map((a) => a.value);
    try {
      const response = await fetch(
        `${API_URL}/api/v1/organizations/coalition_organization`,
        {
          method: "POST",
          headers: {
            "access-token": accessToken,
            "token-type": "Bearer",
            "Content-Type": "application/json",
            client,
            expiry,
            uid,
          },
          body: JSON.stringify(
            decamelizeKeys({
              coalitionId: coalitionId,
              counties: selectedCountry,
              provinces: selectedProvinces,
              orgType: orgType,
              dataRequested: selectedExport,
            })
          ),
        }
      );
      const res = await response.json();
      var orgs = res.map((a) => {
        return { label: a.name, value: a.id };
      });
      setallOrgs(orgs.sort((a, b) => a.label.localeCompare(b.label)));
    } catch (error) {}
  };
  useEffect(() => {
    fetchCountries(selected);
  }, [selected]);

  useEffect(() => {
    fetchOrgs(selectedOrgType);
  }, [selectedOrgType, selectedExport, selectedProvinces]);

  const fetchOrgTypes = async (selectedCountry, selectedProvinces) => {
    const { accessToken, client, expiry, uid } = token;
    if (selectedCountry.length === 0 && selectedProvinces.length === 0) return;

    try {
      const response = await fetch(
        `${API_URL}/api/v1/organizations/coalition_organization_types`,
        {
          method: "POST",
          headers: {
            "access-token": accessToken,
            "token-type": "Bearer",
            "Content-Type": "application/json",
            client,
            expiry,
            uid,
          },
          body: JSON.stringify(
            decamelizeKeys({
              coalitionId: coalitionId,
              counties: selectedCountry,
              provinces: selectedProvinces,
              communityService: selectedExport !== "intake_outcome",
            })
          ),
        }
      );
      const res = await response.json();
      var orgs = res.map((a) => {
        return { label: a, value: a };
      });
      setallOrgsTypes(orgs);
    } catch (error) {}
  };

  useEffect(() => {
    fetchOrgTypes(selectedCountry, selectedProvinces);
  }, [selectedCountry, selectedProvinces]);

  const validAttrs = () => {
    if (selectedExport === "contributors" && selectedOrg.length > 0) {
      return true;
    } else if (selectedOrg.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <h2>Export Coalition Data</h2>
      <div
        className="flex gap-2"
        style={{
          margin: 15,
          float: "right",
        }}
      >
        <Button
          className="btn-sm xl:btn-full"
          emphasis={selectedExport === "contributors" ? "gold" : "primary"}
          onClick={() => {
            setSelectedExpot("contributors");
          }}
        >
          Contributor Contacts
        </Button>
        <Button
          className="btn-sm xl:btn-full"
          emphasis={selectedExport === "intake_outcome" ? "gold" : "primary"}
          onClick={() => {
            setSelectedExpot("intake_outcome");
          }}
        >
          Intake/Outcome Export
        </Button>
        <Button
          className="btn-sm xl:btn-full"
          emphasis={selectedExport === "community" ? "gold" : "primary"}
          onClick={() => {
            setSelectedExpot("community");
          }}
        >
          Community Services Data
        </Button>
      </div>
      <div className="sac-border bg-super-light-gray mb-8">
        <h3>Choose a Month or Year to export.</h3>

        <div className="bg-white rounded p-6 border">
          <div className="grid grid-cols-4 gap-6 mb-6">
            {selectedExport !== "contributors" && (
              <>
                <div className="">
                  State
                  <MultiSelect
                    options={options}
                    value={selected}
                    className="grid gap-6 mb-6"
                    onChange={setSelected}
                    labelledBy="-- Select a State --"
                  />
                </div>
                <div className="">
                  Counties
                  <MultiSelect
                    options={countries}
                    value={selectedCountry}
                    className="grid gap-6 mb-6"
                    onChange={setSelectedCountry}
                    labelledBy="-- Select a Country --"
                  />
                </div>
                <div className="">
                  Provinces
                  <MultiSelect
                    options={provinces}
                    value={selectedProvinces}
                    className="grid gap-6 mb-6"
                    onChange={setSelectedProvinces}
                    labelledBy="-- Select a Province --"
                  />
                </div>

                <div className="">
                  Organization Type
                  <MultiSelect
                    options={
                      selectedCountry.length > 0 || selectedProvinces.length > 0
                        ? allOrgsTypes
                        : []
                    }
                    value={selectedOrgType}
                    className="grid gap-6 mb-6"
                    onChange={setSelectedOrgType}
                  />
                </div>
              </>
            )}

            <div className="">
              Organization<span className="text-sm required">*</span>
              <MultiSelect
                options={allOrgs}
                value={selectedOrg}
                className="grid gap-6 mb-6"
                onChange={setSelectedOrg}
              />
            </div>
          </div>
          <Formik
            initialValues={{
              dateFormat: "",
              date: "",
              state: "",
              usCountyId: "",
            }}
            validationSchema={ExportSchema}
            onSubmit={generateCsv}
          >
            {({
              isValid,
              dirty,
              values: { dateFormat, state, usCountyId },
            }) => {
              return (
                <Form>
                  <div className="grid grid-cols-2 gap-6 mb-6">
                    {selectedExport !== "contributors" && (
                      <FormField
                        as="select"
                        name="dateFormat"
                        className="form-select"
                        label="Export Date Format"
                        required={selectedExport !== "contributors"}
                      >
                        <option disabled value="" hidden>
                          -- Select Type of Export --
                        </option>
                        <option value={EXPORT_DATE_FORMATS.MONTH}>
                          Export a Month of Data
                        </option>
                        <option value={EXPORT_DATE_FORMATS.YEAR}>
                          Export a Year of Data
                        </option>
                      </FormField>
                    )}

                    {dateFormat && (
                      <DatePickerField
                        label={
                          dateFormat === EXPORT_DATE_FORMATS.MONTH
                            ? "Export Month"
                            : "Export Year"
                        }
                        name="date"
                        dateFormat={dateFormat}
                        showMonthYearPicker={
                          dateFormat === EXPORT_DATE_FORMATS.MONTH
                        }
                        showYearPicker={dateFormat === EXPORT_DATE_FORMATS.YEAR}
                        maxDate={endOfMonth(new Date())}
                        required
                      />
                    )}
                  </div>

                  <div className="flex justify-end">
                    <Button
                      type="submit"
                      disabled={!validAttrs() || isGeneratingCsv || !isValid}
                      isLoading={isGeneratingCsv}
                      loadingText={<>Generating CSV&hellip;</>}
                    >
                      Generate CSV
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      <h3>Available Exports</h3>
      <Table
        data={response.data || []}
        columns={columns}
        noResultsText="No exports found"
        isLoading={isLoading}
        error={error}
      />
    </>
  );
};

export default ExportData;
