import React from "react";
import { Field } from "formik";
import { ReactComponent as DataIcon } from "images/data_icon.svg";

const RegisterTypeSubForm = () => (
  <RegisterTypeOption
    id="organization"
    icon={DataIcon}
    heading="Organization"
    content="I want to register with a new organization"
  />
);

export default RegisterTypeSubForm;

const RegisterTypeOption = ({ id, icon: Component, heading, content }) => {
  return (
    <label
      data-testid="register-type-option"
      htmlFor={id}
      className="flex flex-col justify-between items-center flex-grow text-center p-8 border-2 rounded-lg bg-dashboard-light-green bg-opacity-30  border-teal"
    >
      <span className="block">
        <span className="flex justify-center mb-4 text-teal">
          <Component />
        </span>
        <h5 className="mb-2">{heading}</h5>
        <p>{content}</p>
      </span>

      <Field
        type="radio"
        id={id}
        name="registerType"
        value={id}
        checked
        className="sr-only"
      />
      <span className="flex items-center justify-center rounded-full border w-6 h-6 border-teal">
        <span className="block rounded-full bg-teal w-3.5 h-3.5"></span>
      </span>
    </label>
  );
};
