import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

const borderColor = "#ccc";
const backColor = "#e6e7e8";

const styles = StyleSheet.create({
  live_intake: {
    width: "22%",
    height: 30,
    paddingTop: 7,
    backgroundColor: "#1f6165",
    borderColor: "#1f6165",
    borderWidth: 1,
    color: "white",
  },
  live_intake1: {
    width: "22%",
    height: 30,
    paddingTop: 7,
    backgroundColor: "#1f6165",
    borderColor: "#1f6165",
    borderWidth: 1,
    color: "white",
  },
  canine: {
    width: "26%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  feline: {
    width: "26%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  other: {
    width: "26%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  care_foster: {
    width: "13%",
    height: 30,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
});

const TableHeader = (props) => (
  <View>
    <View style={props.styles.container3}>
      <Text style={styles.live_intake}>ANIMAL</Text>
      <Text style={styles.canine}>CANINE</Text>
      <Text style={styles.feline}>FELINE</Text>
      <Text style={styles.other}>OTHER*</Text>
    </View>
    <View style={props.styles.container4}>
      <Text style={styles.live_intake1}>COUNTS</Text>
      <Text style={styles.care_foster}>TOTAL IN CARE</Text>
      <Text style={styles.care_foster}>TOTAL IN FOSTER</Text>
      <Text style={styles.care_foster}>TOTAL IN CARE</Text>
      <Text style={styles.care_foster}>TOTAL IN FOSTER</Text>
      <Text style={styles.care_foster}>TOTAL IN CARE</Text>
      <Text style={styles.care_foster}>TOTAL IN FOSTER</Text>
    </View>
  </View>
);

export default TableHeader;
