import React, { useState } from "react";
import { Formik, Form } from "formik";
import FormField from "components/FormField";
import Button from "components/Button";
import {
  intakeCategories,
  outcomeCategories,
  getUniqueTypeSubtypePairs,
} from "./utils";
import { apiFetch, formatUiErrors } from "utils";
import { toast } from "react-toastify";
import { useAuthState, useAuthDispatch } from "context";
import { useMutation, useQuery } from "react-query";
import Error from "components/Error";
import { FORM_MODES } from "utils";
import { useParams } from "react-router-dom";

export default function ApiMappingTable({ formMode, setFormMode }) {
  const { token } = useAuthState();
  const { orgId } = useParams();
  const dispatch = useAuthDispatch();
  const [sacCategoryGroups, setSacCategoryGroups] = useState([]);
  const [sacCategoryData, setSacCategoryData] = useState([]);

  const { isFetching, error } = useQuery(
    ["organizationAnimalEvents", { orgId }],
    () =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_events?filter[org_id][eq]=${orgId}`,
        includeMeta: true,
      }),
    {
      onSuccess: (data) => {
        setSacCategoryGroups(data.data);
        setSacCategoryData(getUniqueTypeSubtypePairs(data.data));
      },
    }
  );

  const outcomeData = sacCategoryData.filter((obj) =>
    obj?.type?.includes("Outcome")
  );
  const intakeData = sacCategoryData.filter((obj) =>
    obj?.type?.includes("Intake")
  );

  const handleSave = (group) => {
    const oldGroupIndex = sacCategoryData.findIndex(
      (obj) => obj.key === group.key
    );
    const newGroup = sacCategoryData.filter((obj) => obj.key !== group.key);
    newGroup.splice(oldGroupIndex, 0, group);
    setSacCategoryData([...newGroup]);
  };

  const { mutate: mapCategories, isLoading } = useMutation(
    () =>
      apiFetch({
        token,
        dispatch,
        method: "POST",
        endpoint: "/api/v1/animal_events/map_sac_categories",
        body: {
          orgId,
          sacCategoryData,
        },
      }),
    {
      onSuccess: () => {
        toast.success(`SAC Categories mapped successfully`, {
          autoClose: 3000,
        });
        setFormMode("VIEW");
      },
      onError: (error) => {
        toast.error(() => formatUiErrors(error), {
          autoClose: 5000,
          className: "toast-center",
        });
      },
    }
  );

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  return (
    <>
      <div className="flex justify-end gap-4 my-4">
        {formMode !== FORM_MODES.VIEW && (
          <div className="flex justify-end space-x-4">
            <Button
              emphasis="transparent"
              onClick={() => {
                setFormMode("VIEW");
                setSacCategoryData(
                  getUniqueTypeSubtypePairs(sacCategoryGroups)
                );
              }}
            >
              Cancel
            </Button>
          </div>
        )}

        {formMode === FORM_MODES.VIEW && (
          <div className="flex justify-end space-x-4">
            <Button
              onClick={() => {
                setFormMode("EDIT");
                setSacCategoryData(
                  getUniqueTypeSubtypePairs(sacCategoryGroups)
                );
              }}
            >
              Edit
            </Button>
          </div>
        )}
      </div>
      <div className="mb-6">
        <div className="grid lg:grid-cols-3 w-full items-center">
          <span className="px-4 py-2 text-left font-normal text-table bg-table-header-gray">
            Intakes
          </span>
          <span className="px-4 py-2 text-left font-normal text-table bg-table-header-gray">
            Subtypes
          </span>
          <span className="px-4 py-2 text-left font-normal text-table bg-table-header-gray">
            SAC Category
          </span>
        </div>
        <div>
          {isFetching && intakeData.length === 0 ? (
            <div className="flex w-full justify-center">
              <span className="px-4 py-4 italic text-table">
                Loading data&hellip;
              </span>
            </div>
          ) : (
            intakeData.length === 0 && (
              <div className="flex w-full justify-center">
                <span className="px-4 py-4 italic text-table">
                  No data found
                </span>
              </div>
            )
          )}
          {intakeData.map((obj, idx) => (
            <CategoryForm
              formMode={formMode}
              key={`${idx}_key`}
              data={obj}
              categoryData={intakeCategories}
              idx={idx}
              handleSave={handleSave}
            />
          ))}
        </div>
      </div>
      <div>
        <div className="grid lg:grid-cols-3 w-full items-center">
          <span className="px-4 py-2 text-left font-normal text-table bg-table-header-gray">
            Outcomes
          </span>
          <span className="px-4 py-2 text-left font-normal text-table bg-table-header-gray">
            Subtypes
          </span>
          <span className="px-4 py-2 text-left font-normal text-table bg-table-header-gray">
            SAC Category
          </span>
        </div>
        <div>
          {isFetching ? (
            <div className="flex w-full justify-center">
              <span className="px-4 py-4 italic text-table">
                Loading data&hellip;
              </span>
            </div>
          ) : (
            outcomeData.length === 0 && (
              <div className="flex w-full justify-center">
                <span className="px-4 py-4 italic text-table">
                  No data found
                </span>
              </div>
            )
          )}
          {outcomeData.map((obj, idx) => (
            <CategoryForm
              formMode={formMode}
              key={`${idx}_key`}
              data={obj}
              categoryData={outcomeCategories}
              idx={idx}
              handleSave={handleSave}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-end gap-4 my-4">
        {formMode !== FORM_MODES.VIEW && (
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            loadingText={<>Mapping&hellip;</>}
            onClick={mapCategories}
            type="button"
          >
            Save
          </Button>
        )}
        {formMode !== FORM_MODES.VIEW && (
          <Button
            emphasis="transparent"
            onClick={() => {
              setFormMode("VIEW");
              setSacCategoryData(getUniqueTypeSubtypePairs(sacCategoryGroups));
            }}
          >
            Cancel
          </Button>
        )}

        {formMode === FORM_MODES.VIEW && (
          <Button
            onClick={() => {
              setFormMode("EDIT");
              setSacCategoryData(getUniqueTypeSubtypePairs(sacCategoryGroups));
            }}
          >
            Edit
          </Button>
        )}
      </div>
    </>
  );
}

const CategoryForm = ({ data, categoryData, idx, handleSave, formMode }) => (
  <Formik initialValues={data} enableReinitialize>
    {({ values }) => (
      <Form
        className={`${
          idx % 2 === 0 ? "" : "bg-gray-100"
        } pr-4 py-2 border-b grid lg:grid-cols-3 w-full items-center`}
      >
        <FormField
          name="type"
          label=""
          readOnly={true}
          disabled={true}
          className="mx-4 py-2 bg-transparent"
        />
        <FormField
          name="subtype"
          label=""
          readOnly={true}
          disabled={true}
          className="mx-4 py-2 bg-transparent"
        />
        <FormField
          className={`${
            formMode === FORM_MODES.VIEW ? "mx-4 py-2 bg-transparent" : ""
          }`}
          readOnly={formMode === FORM_MODES.VIEW}
          disabled={formMode === FORM_MODES.VIEW}
          name="sacCategory"
          label=""
          as="select"
          onChange={(e) => {
            handleSave({ ...values, sacCategory: e.target.value });
          }}
        >
          <option value={"null"} disabled>
            {formMode === FORM_MODES.VIEW ? "--" : "-- Choose --"}
          </option>
          {categoryData.map((category) => (
            <option value={category.value} key={category.value}>
              {category.name}
            </option>
          ))}
        </FormField>
      </Form>
    )}
  </Formik>
);
