import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

const borderColor = "#ccc";
const backColor = "#e6e7e8";
const styles = StyleSheet.create({
  live_intake_n: {
    width: "20%",
    height: 50,
    paddingTop: 7,
    backgroundColor: "#1f6165",
    borderColor: borderColor,
    borderWidth: 1,
    color: "white",
  },
  canine: {
    width: "40%",
    height: 50,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
  feline: {
    width: "40%",
    height: 50,
    paddingTop: 8,
    fontSize: 11,
    backgroundColor: backColor,
    borderColor: borderColor,
    borderWidth: 1,
  },
});

const SpecieCountTableHeader = (props) => (
  <View style={props.styles.container2}>
    <Text style={styles.live_intake_n}>ANIMAL{"\n"}COUNT</Text>
    <Text style={styles.canine}>TOTAL{"\n"}IN CARE</Text>
    <Text style={styles.feline}>TOTAL{"\n"}IN FOSTER</Text>
  </View>
);

export default SpecieCountTableHeader;
