import React from "react";
import * as Yup from "yup";
import { apiFetch, formatUiErrors } from "utils";
import FormField from "components/FormField";
import {
  OrganizationSearchableOptInTooltip,
  OrganizationCommunicationsOptInTooltip,
  OrganizationMediaOptInTooltip,
} from "components/Tooltips";
import { Form, Formik } from "formik";
import { useAuthDispatch, useAuthState, useUserState } from "context";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import Error from "components/Error";

const SharingPermissions = () => {
  const { orgId } = useParams();
  const { token } = useAuthState();
  const { isOrgAdmin } = useUserState();
  const dispatch = useAuthDispatch();

  const sharingPermissionsSchema = Yup.object().shape({
    communicationsOptIn: Yup.boolean(),
    searchableOptIn: Yup.boolean(),
    mediaOptIn: Yup.boolean(),
  });

  const {
    isOrgLoading,
    error,
    data: response,
  } = useQuery(["organization", orgId], () =>
    apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/organizations/${orgId}`,
    })
  );

  const initialValues = getInitialValues(response?.data);

  const { mutate: updateSharingPermissions, isLoading } = useMutation(
    ({ communicationsOptIn, searchableOptIn, mediaOptIn }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/organizations/${orgId}`,
        method: "PATCH",
        body: {
          data: {
            id: orgId,
            type: "organizations",
            attributes: {
              ...(isOrgAdmin
                ? {
                    communicationsOptIn,
                    searchableOptIn,
                    mediaOptIn,
                  }
                : {}),
            },
          },
        },
      }),
    {
      onSuccess: () => {
        toast.success("Sharing permissions updated successfully", {
          autoClose: 3000,
        });
      },
      onError: (e) => {
        toast.error(() => formatUiErrors(e), {
          autoClose: 5000,
        });
      },
    }
  );

  if (isOrgLoading) {
    return (
      <LoadingIndicator inline content={<>Loading organization&hellip;</>} />
    );
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  return (
    <>
      <h2>Sharing Permissions</h2>

      <Formik
        initialValues={initialValues}
        validateOnMount
        validationSchema={sharingPermissionsSchema}
        onSubmit={updateSharingPermissions}
      >
        {({ handleSubmit }) => (
          <Form>
            <div className="flex flex-col gap-6 mb-6 w-1/2">
              <FormField
                name="communicationsOptIn"
                label="Do you give SAC permission to share your contact information with other SAC contributing organizations?"
                as="select"
                required
                tooltip={<OrganizationCommunicationsOptInTooltip />}
                tooltipWidthClass="w-80"
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </FormField>

              <FormField
                name="searchableOptIn"
                label="Do you give permission for your organization to be searchable by other contributing organizations within the SAC portal?"
                as="select"
                required
                tooltip={<OrganizationSearchableOptInTooltip />}
                tooltipWidthClass="w-80"
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </FormField>

              <FormField
                name="mediaOptIn"
                label="SAC receives inquiries from media who are interested in the work and experiences of animal shelters. Do you give SAC permission to forward contact information to media contacts?"
                as="select"
                required
                tooltip={<OrganizationMediaOptInTooltip />}
                tooltipWidthClass="w-80"
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </FormField>

              <div className="w-20">
                <Button
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                  loadingText={<>Saving&hellip;</>}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SharingPermissions;

const getInitialValues = (organization) => {
  return {
    communicationsOptIn: organization?.attributes.communicationsOptIn,
    searchableOptIn: organization?.attributes.searchableOptIn,
    mediaOptIn: organization?.attributes.mediaOptIn,
  };
};
