import { uniq } from "lodash";

export * from "./api";
export * as SPECIES from "./species";
export { speciesMap } from "./species";
export * from "./routeGuards";
export * from "./dates";
export * from "./queryParams";
export * from "./formModes";
export * from "./addNamespace";
export * from "./formatUiErrors";
export * from "./formatCsvValidationErrors";
export * from "./exports";
export * from "./hooks";
export * from "./dataStatus";
export * from "./requests";
export * from "./entryOptions";

/**
 * Calculates the total number number of pages in a paginated data set
 * @param {Object} params
 * @param {number} [0] params.totalCount
 * @param {number} [20] params.perPage
 * @return {number} Total number of pages
 */
export const getTotalPages = ({ totalCount = 0, perPage = 20 }) => {
  return Math.ceil(totalCount / perPage);
};

/**
 * Takes camelCaseString and converts it Camel Case String
 * @param {string} camelCase
 * @return {string} Title Case
 */
export const camelToTitle = (camelCase) => {
  if (!camelCase) return camelCase;
  return camelCase
    .replace(/(-|_)([a-z])/g, (g) => g[1].toUpperCase())
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
};

/**
 * Takes string and converts it Title Case String
 * @param {string} str
 * @return {string} Title Case
 */
export const titleCase = (str) => {
  if (!str || typeof str !== "string") return str;
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
};

/**
 * Filters array to records where record.type === type
 * @param {Object} params
 * @param {array} [[]] params.included
 * @param {array} params.type
 * @return {(Array|null)} Records of the specified type
 */
export const getIncludedDataByKey = ({ included = [], type }) => {
  if (!included || !Array.isArray(included)) return null;
  return included.reduce((arr, inc) => {
    if (inc.type === type) {
      arr.push(inc);
    }
    return arr;
  }, []);
};

/**
 * Returns a coalition's operating region as an array
 * specifically turns a region that's a string into an array with a length of 1
 * i.e. constructCoalitionOperatingRegion("MN") returns ["MN"]
 * @param {(Array|string)} region
 * @return {Array} Region as an array
 */
export const constructCoalitionOperatingRegion = (region) => {
  if (!region) {
    return [];
  }
  if (!Array.isArray(region)) {
    return [region];
  }
  return region;
};

export const COALITION_TYPES = {
  INTERNATIONAL: "International",
  NATIONAL: "National",
  MULTISTATE: "Multi-State (2 or more)",
  STATEWIDE: "Statewide",
  COMMUNITY: "Community",
  MULTIPROVINCE: "Multi-province (2 or more)",
  PROVINCEWIDE: "Provincewide",
};

/**
 * Calculates the data status of a give set of records
 * Status is considered unbegun or complete if ALL records
 * have a status of unbegun or complete, respectively
 * Otherwise default to incomplete
 * @param {Array} dataStatusRecords
 * @return {string} Data status type of the organization
 */
export const getOrgDataStatus = (dataStatusRecords) => {
  // Get a list of all the unique statues
  const statuses = uniq(dataStatusRecords.map((rec) => rec.attributes.status));

  // If there's only 1 unique status and it's `unbegun` or `complete`
  // that's what all the statuses are
  if (
    statuses.length === 1 &&
    (statuses.includes("unbegun") || statuses.includes("complete"))
  )
    return statuses[0];

  // Otherwise we consider the status `incomplete`
  return "incomplete";
};

export const COALITION_MEMBERSHIP_ACTIONS = {
  ACCEPT: "accept",
  REJECT: "reject",
  REMIND: "remind",
};

export const getMonthAbbr = (monthNum) => {
  if (
    isNaN(monthNum) ||
    typeof monthNum !== "number" ||
    monthNum > 12 ||
    monthNum < 1
  )
    return "";
  const year = new Date().getFullYear();
  // Need -1 because the Date function is expecting a 0-indexed month
  const date = new Date(year, monthNum - 1, 1);
  return date.toLocaleString("default", { month: "short" });
};

export const isUnavailable = (month, year) => {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  if (year === currentYear) {
    return month > currentMonth + 1;
  }
};
