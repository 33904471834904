import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useAuthState, useAuthDispatch } from "context";
import { apiFetch, formatUiErrors } from "utils";
import FormField from "components/FormField";
import Button from "components/Button";
import { OrganizationMemberRoleTooltip } from "components/Tooltips";
import RouteLeavingGuard from "components/RouteLeavingGuard";
import BackLink from "components/BackLink/BackLink";

export default function AddMember() {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  // const { isAdmin } = useUserState();
  const { orgId } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState(false);

  const UserSchema = Yup.object().shape({
    fullName: Yup.string().required("Name is required"),
    role: Yup.string().required("Role is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    confirmEmail: Yup.string()
      .email("Invalid email")
      .oneOf([Yup.ref("email")], "Emails do not match")
      .required("Confirm the email"),
    title: Yup.string().required("Title is required"),
  });

  const handleAddSuccess = () => {
    history.push(`/organizations/${orgId}/members`);

    toast.success("Saved successfully", {
      autoClose: 3000,
    });

    queryClient.invalidateQueries(["organizationMembers", { orgId }], {
      refetchActive: true,
    });
  };

  const handleAddError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  const { mutate: addMember, isLoading } = useMutation(
    ({ fullName, role, email, title }) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/user_membership_invites`,
        method: "POST",
        body: {
          data: {
            type: "user_membership_invites",
            attributes: {
              resourceId: orgId,
              resourceType: "organization",
              name: fullName,
              role,
              email,
              title,
            },
          },
        },
      }),
    {
      onSuccess: () => {
        setIsSuccess(true);
        handleAddSuccess();
      },
      onError: () => {
        setIsSuccess(false);
        handleAddError();
      },
    }
  );
  return (
    <>
      <div className="mb-8">
        <BackLink
          link={`/organizations/${orgId}/members`}
          text="Back to members listing"
        />
        <h2>Invite Member</h2>
      </div>

      <Formik
        initialValues={{
          fullName: "",
          email: "",
          confirmEmail: "",
          role: "",
          title: "",
        }}
        validationSchema={UserSchema}
        validateOnMount
        onSubmit={addMember}
      >
        {({ handleSubmit }) => (
          <Form>
            <div className="grid grid-cols-2 gap-6 mb-6">
              {/* Full Name */}
              <FormField name="fullName" label="Full Name" required />

              {/* Title */}
              <FormField name="title" label="Title" required />

              {/* Email */}
              <FormField name="email" label="Email" type="email" required />

              {/* Confirm Email */}
              <FormField
                name="confirmEmail"
                label="Confirm Email"
                type="email"
                required
              />

              {/* Role */}
              <FormField
                name="role"
                label="Role"
                required
                as="select"
                tooltip={<OrganizationMemberRoleTooltip />}
              >
                <option disabled value="" hidden>
                  -- Select a Role --
                </option>
                <option value="organization_manager">
                  Organization Manager
                </option>
                <option value="organization_viewer">Organization Viewer</option>
              </FormField>
            </div>

            <div className="flex justify-end space-x-4">
              <Button
                to={`/organizations/${orgId}/members`}
                emphasis="transparent"
              >
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                loadingText={<>Saving&hellip;</>}
                onClick={handleSubmit}
              >
                Invite
              </Button>
            </div>

            <FormContext isSuccess={isSuccess} setIsSuccess={setIsSuccess} />
          </Form>
        )}
      </Formik>
    </>
  );
}

const FormContext = ({ isSuccess, setIsSuccess }) => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    if (dirty) {
      setIsSuccess(false);
    }
  }, [dirty, setIsSuccess]);

  return (
    <RouteLeavingGuard
      when={!isSuccess && dirty}
      shouldBlockNavigation={() => !isSuccess && dirty}
    />
  );
};
