import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Table, {
  getRowData,
  columns as defaultColumns,
  getServiceTypeAttributes,
} from "components/Table";
import { speciesMap, useHandleRequestStates } from "utils";
import { getTableColumns, getChangedAttributes } from "../../utils";

const FieldServicesTable = forwardRef(
  ({ id, attributes, species, isEditing, submit }, ref) => {
    const columns = getTableColumns({ defaultColumns, isEditing, species });

    const { handleSuccess } = useHandleRequestStates();

    const setRow = ({ rowLabel, keySubString, tooltip }) =>
      getRowData({
        data: attributes,
        accessKeys: ["adult", "youth", "ageUnknown", "total"],
        rowLabel,
        keySubString,
        tooltip,
      });

    const data = [
      setRow({
        rowLabel: "Trap-Neuter-Return (TNR)",
        keySubString: "TrapNeuterReturnCount",
        tooltip:
          "Animals not included in intake, already altered or altered after trapping, and returned to capture location.",
      }),
      setRow({
        rowLabel: "Return-to-Owner in the Field",
        keySubString: "ReturnToOwnerCount",
        tooltip: "Stray animals returned to their owner prior to intake.",
      }),
    ];

    const handleSubmit = async (values) => {
      handleSuccess({
        message: `Updated the ${speciesMap[
          attributes.species
        ].label.toLocaleLowerCase()} field services`,
      });

      await submit(id, getChangedAttributes("field", attributes, values));
    };

    return (
      <div className="mt-12">
        <div className="flex items-center justify-between space-x-4 mb-4">
          <h3 className="text-2xl flex items-center">
            <img src={speciesMap[species].icon} alt="" className="mr-4" />
            {speciesMap[species].label}
          </h3>
        </div>

        <Formik
          enableReinitialize
          initialValues={getServiceTypeAttributes(data, attributes)}
          onSubmit={(values) => handleSubmit(values)}
          innerRef={ref}
        >
          {() => (
            <Form>
              {/* Form Table */}
              <Table
                data={data}
                columns={columns}
                columnAlignments={[null, null, null, null, "text-right"]}
              />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
);

FieldServicesTable.propTypes = {
  id: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
  species: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};

export default FieldServicesTable;
