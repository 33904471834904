import React from "react";
import { QueryClientProvider, QueryClient, QueryCache } from "react-query";
import { ToastContainer, Slide, toast } from "react-toastify";
import Zendesk from "react-zendesk";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { AuthProvider, CookieConsentProvider, UserProvider } from "context";
import CookieConsent from "components/CookieConsent";
import DataMigrationBanner from "components/DataMigrationBanner";

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
const showBanner = process.env.REACT_APP_DATA_IMPORT_BANNER === "true";

if (showBanner) {
  document.body.classList.add("data-migration-banner");
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      retry: false,
    },
  },
});
const queryCache = new QueryCache();

function App() {
  return (
    <QueryClientProvider client={queryClient} queryCache={queryCache}>
      <Router>
        <AuthProvider>
          <UserProvider>
            <CookieConsentProvider>
              {showBanner && <DataMigrationBanner />}

              <Routes />
              <ToastContainer
                position={toast.POSITION.TOP_CENTER}
                transition={Slide}
                hideProgressBar
              />
              <Zendesk zendeskKey={ZENDESK_KEY} />
              <CookieConsent />
            </CookieConsentProvider>
          </UserProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
