import { View } from "@react-pdf/renderer";
import SpecieCountTableHeader from "../TotalTable/SpecieCountTableHeader";
import SpecieCountTableRow from "../TotalTable/SpecieCountTableRow";

const SpecieCountTable = (props) => {
  return (
    <View>
      {props.data.length > 0 && (
        <View>
          <SpecieCountTableHeader styles={props.styles} name={props.name} />
          <View style={{ marginTop: 10 }}>
            <SpecieCountTableRow items={props.data} />
          </View>
        </View>
      )}
    </View>
  );
};
export default SpecieCountTable;
