import React from "react";
import { useFormikContext } from "formik";
import * as Yup from "yup";
import { addNamespace } from "utils";
import FormField from "components/FormField";

export const defaultValues = {
  isAuthorizer: false,
  authorizerName: "",
  authorizerTitle: "",
  authorizerEmail: "",
  authorizerConfirmEmail: "",
  authorizerPhoneNumber: "",
  authorizerExtension: "",
};

export const validationSchema = () => ({
  isAuthorizer: Yup.boolean(),
  authorizerName: Yup.string().when("isAuthorizer", {
    is: false,
    then: Yup.string().required("Name is required"),
  }),
  authorizerTitle: Yup.string().when("isAuthorizer", {
    is: false,
    then: Yup.string().required("Title is required"),
  }),
  authorizerEmail: Yup.string().when("isAuthorizer", {
    is: false,
    then: Yup.string().email("Invalid email").required("Email is required"),
  }),
  authorizerConfirmEmail: Yup.string().when("isAuthorizer", {
    is: false,
    then: Yup.string()
      .email("Invalid email")
      .oneOf([Yup.ref("authorizerEmail")], "Emails do not match")
      .required("Confirm the email"),
  }),
  authorizerPhoneNumber: Yup.string().when("isAuthorizer", {
    is: false,
    then: Yup.string().matches(
      /^\(?([2-9]\d\d)\)?[-. ]?(\d{3})[-. ]?(\d{4})$/,
      "Invalid phone number"
    ),
  }),
});

const AuthorizationSubForm = ({ namespace }) => {
  const withNamespace = addNamespace(namespace);

  const {
    values: { authorization },
  } = useFormikContext();

  return (
    <>
      <h2>Authorization</h2>
      <p className="mb-8">
        A leader (e.g. CEO, executive director, board leadership, etc.) within
        your organization must agree to partipation in our national database.
        Participation will be confirmed via an email sent to your
        organization&rsquo;s authorized person.
      </p>

      <div className="grid grid-cols-2 gap-6 mb-6">
        {/* User is Authorizer */}
        <FormField
          name={withNamespace("isAuthorizer")}
          label="I am the authorized person"
          type="checkbox"
          data-testid="is-authorizer"
        />
      </div>

      {!authorization.isAuthorizer && (
        <>
          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Authorizer Name */}
            <FormField
              name={withNamespace("authorizerName")}
              label="Name"
              required
            />

            {/* Authorizer Title */}
            <FormField
              name={withNamespace("authorizerTitle")}
              label="Title"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Email */}
            <FormField
              name={withNamespace("authorizerEmail")}
              label="Email"
              type="email"
              required
            />

            {/* Confirm Email */}
            <FormField
              name={withNamespace("authorizerConfirmEmail")}
              label="Confirm Email"
              type="email"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Authorizer Phone Number */}
            <FormField
              name={withNamespace("authorizerPhoneNumber")}
              label="Primary Phone Number"
              required
            />

            {/* Extension */}
            <FormField
              name={withNamespace("authorizerExtension")}
              label="Extension"
            />
          </div>
        </>
      )}
    </>
  );
};

export default AuthorizationSubForm;
