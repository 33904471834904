import React from "react";
import { Link } from "react-router-dom";
import Footer from "components/Footer";
import logo from "images/sac-logo.7776b610_NEW.svg";

const FormWrapper = ({ children, className }) => {
  return (
    <div className={`w-full mx-auto mt-10 mb-20 max-w-xl ${className}`}>
      <div className="sac-border">
        <Link to="/">
          <img
            className="mx-auto login-logo"
            src={logo}
            alt="Shelter Animals Count - The National Database"
          />
        </Link>

        {children}

        <Footer />
      </div>
    </div>
  );
};

export default FormWrapper;
