import React from "react";
import { remove } from "js-cookie";
import {
  useAuthState,
  useCookieConsentState,
  useCookieConsentDispatch,
} from "context";
import { COOKIE_CONSENT_UPDATE } from "actions";
import Button from "components/Button";

const CookieConsent = () => {
  const { userId, token } = useAuthState();
  const { showCookieConsent } = useCookieConsentState();
  const dispatch = useCookieConsentDispatch();
  const isLoggedIn = Boolean(token?.accessToken) && Boolean(userId);

  const acceptCookies = () => {
    dispatch({ type: COOKIE_CONSENT_UPDATE, payload: String(true) });

    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }
  };

  const declineCookies = () => {
    dispatch({ type: COOKIE_CONSENT_UPDATE, payload: String(false) });

    remove("_ga");
    remove("_gid");
    remove(`_gad_${process.env.REACT_APP_GA_MEASUREMENT_ID}`);

    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "denied",
        analytics_storage: "denied",
      });
    }
  };

  if (!isLoggedIn || !showCookieConsent) return null;

  return (
    <div
      className="min-w-app fixed w-full left-0 bottom-0 bg-dashboard-light-green border-t-2 border-teal p-4 text-sm flex align-center space-x-12"
      style={{ zIndex: 999999 }}
    >
      <div>
        We use cookies to improve user experience, and analyze website traffic.
        For these reasons, we may share your site usage data with our analytics
        partners. By clicking &ldquo;Accept Cookies,&rdquo; you consent to store
        on your device all the technologies described in our{" "}
        <a
          href="https://www.shelteranimalscount.org/privacy-policy"
          target="_blank"
          className="underline text-black font-body-bold"
        >
          Privacy Policy
        </a>
        .
      </div>
      <div className="flex space-x-4 items-center flex-shrink-0">
        <Button emphasis="white" onClick={declineCookies}>
          Decline
        </Button>
        <Button onClick={acceptCookies}>Accept Cookies</Button>
      </div>
    </div>
  );
};

export default CookieConsent;
