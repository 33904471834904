import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import Table from "components/Table";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import { apiFetch, formatUiErrors } from "utils";
import {
  transformTableData,
  transformCoalitionTableData,
  constructEndpoint,
} from "./utils";
import Error from "components/Error";
import LoadingIndicator from "components/LoadingIndicator";
import DateFilter from "components/IntakeOverviewGraphFilters/DateFilter";

const IntakeOverviewTable = ({
  coalitionId,
  coalitionPage,
  searchClick,
  setSearchClick,
  coalitionFilters,
  action,
  adminLocation,
}) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { attributes } = useUserState();
  var locationId = attributes.locationId;
  if (!!adminLocation) locationId = adminLocation;

  const [year, setYear] = useState(new Date().getFullYear());
  const dashboardEndpoint = `/api/v1/locations/${locationId}?include=ytd_species_totals&filter[ytd_species_totals.year][eq]=${year}&filter[ytd_species_totals.action][eq]=${action}`;

  const { orgName: organizationId, year: coalitionYear } = coalitionFilters;

  const coalitionEndpoint = constructEndpoint({
    coalitionId,
    organizationId,
    coalitionYear,
    coalitionFilters,
    action,
  });
  const { isLoading, error, data, refetch } = useQuery(
    "overviewAnimalCounts",
    async () => {
      const res = await apiFetch({
        token,
        dispatch,
        endpoint: coalitionPage ? coalitionEndpoint : dashboardEndpoint,
      });
      if (setSearchClick) setSearchClick(false);
      return coalitionPage
        ? transformCoalitionTableData(res)
        : transformTableData(res);
    }
  );

  useEffect(refetch, [
    locationId,
    year,
    organizationId,
    isLoading,
    coalitionFilters,
    searchClick,
    refetch,
    action,
  ]);

  if (isLoading) {
    return (
      <LoadingIndicator
        inline
        content={<>Loading intake overview table&hellip;</>}
      />
    );
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  const columns =
    action === "services"
      ? [
          {
            accessor: "rowHeader",
            Header: () =>
              coalitionPage ? "" : <DateFilter setYear={setYear} year={year} />,
          },
          {
            accessor: "totalFieldServices",
            Header: "Field Services",
            Cell: ({ value }) => transformCellStyle(value),
          },
          {
            accessor: "totalSupportServices",
            Header: "Support Services",
            Cell: ({ value }) => transformCellStyle(value),
          },
          {
            accessor: "totalBehaviorServices",
            Header: "Behavior Services",
            Cell: ({ value }) => transformCellStyle(value),
          },
          {
            accessor: "totalVeterinaryServices",
            Header: "Veterinary Services",
            Cell: ({ value }) => transformCellStyle(value),
          },
        ]
      : [
          {
            accessor: "rowHeader",
            Header: () =>
              coalitionPage ? "" : <DateFilter setYear={setYear} year={year} />,
          },
          {
            accessor: "totalIntake",
            Header: "Total Intake",
            Cell: ({ value }) => transformCellStyle(value),
          },
          {
            accessor: "totalLiveOutcomes",
            Header: "Total Live Outcomes",
            Cell: ({ value }) => transformCellStyle(value),
          },
          {
            accessor: "totalOtherOutcomes",
            Header: "Other Outcomes",
            Cell: ({ value }) => transformCellStyle(value),
          },
        ];
  const columnWidths =
    action === "services"
      ? ["w-4/12", "sm:px-0", "sm:px-0", "sm:px-0", "sm:px-0"]
      : ["w-4/12"];

  const transformCellStyle = (value) => (
    <span className="font-body text-2xl">{value || 0}</span>
  );

  // Setup what items we want for CSV download
  const csvData = data.reduce((acc, item) => {
    const { locationId, rowHeader, ...rest } = item;

    return [...acc, rest];
  }, []);

  return (
    <>
      <Table
        data={data}
        columns={columns}
        columnWidths={columnWidths}
        dashboard
      />
      <CSVLink
        data={csvData}
        filename={
          action === "services" ? "ytd-services.csv" : "ytd-intake-outcomes.csv"
        }
        className="text-dashboard-blue m-4 mb-0 inline-block"
      >
        Export Combined Monthly Totals CSV
      </CSVLink>
    </>
  );
};

IntakeOverviewTable.propTypes = {
  coalitionId: PropTypes.string,
  coalitionPage: PropTypes.bool,
  coalitionFilters: PropTypes.shape({
    orgName: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }),
  action: PropTypes.string,
};

IntakeOverviewTable.defaultProps = {
  coalitionId: "",
  coalitionPage: false,
  coalitionFilters: {
    orgName: "",
    year: String(new Date().getFullYear()),
  },
  action: "data-entry",
};

export default IntakeOverviewTable;
