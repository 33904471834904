import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Table, { getRowData, columns } from "components/Table";
import { animalIntakes } from "components/Table/__mocks__";
import AnimalIntakeForm from "./AnimalIntakeForm";
import { speciesMap } from "utils";

const AnimalIntake = forwardRef(({ attributes, dataId, isEditing }, ref) => {
  const { species, organizationId } = attributes;

  const setRow = ({ rowLabel, keySubString, tooltip }) =>
    getRowData({
      data: attributes,
      accessKeys: ["adult", "youth", "ageUnknown", "total"],
      rowLabel,
      keySubString,
      tooltip,
    });

  var data = [
    setRow({
      rowLabel: "Stray at large",
      keySubString: "StrayAtLargeCount",
      tooltip: "Stated to be unowned or free-roaming",
    }),
    setRow({
      rowLabel: "Relinquished by owner",
      keySubString: "RelinquishedByOwnerCount",
      tooltip:
        "Admitted by owner, including adoption returns. All returns should be tracked as owner relinquishment.",
    }),
    setRow({
      rowLabel: "Owner-intended euthanasia",
      keySubString: "OwnerIntendedEuthanasiaCount",
      tooltip:
        "Limited to this definition: Euthanasia of pets whose owner brought the pet to the shelter with the INTENT of utilizing euthanasia services.",
    }),
    setRow({
      rowLabel: "Transferred in state",
      keySubString: "TransferredInstateInFromAgencyCount",
      tooltip:
        "Admission from another agency within the same state, for adoption, large scale seizure support, etc.",
    }),
    setRow({
      rowLabel: "Transferred out of state",
      keySubString: "TransferredOutOfStateInFromAgencyCount",
      tooltip:
        "Admission from another agency in a different state, for adoption, large scale seizure support, etc.",
    }),
    setRow({
      rowLabel: "Transferred internationally",
      keySubString: "TransferredInternationallyInFromAgencyCount",
      tooltip:
        "Admission from another agency outside the US, for adoption, large scale seizure support, etc.",
    }),
    setRow({
      rowLabel: "Transfers In (undesignated)",
      keySubString: "LegacyTransferredInFromAgencyCount",
      tooltip:
        "Transfer in to agency (undesignated) represents Transfer In data prior to when agency location break outs.",
    }),
    setRow({
      rowLabel: "Seized or impounded",
      keySubString: "SeizedOrImpoundedCount",
      tooltip:
        "Impounds for pets seized during cruelty cases or for protective custody. This does not include pets relinquished by their owners.",
    }),
    setRow({
      rowLabel: "Other intakes",
      keySubString: "OtherIntakesCount",
      tooltip:
        "Pets born while in care, and other types of admission not captured above.",
    }),
  ];

  return (
    <div className="mt-12">
      <div className="flex items-center justify-between space-x-4 mb-4">
        <h3 className="text-2xl flex items-center">
          <img src={speciesMap[species].icon} alt="" className="mr-4" />
          {speciesMap[species].label}
        </h3>
      </div>
      {!isEditing ? (
        <Table
          data={data}
          columns={columns}
          columnAlignments={[null, null, null, null, "text-right"]}
        />
      ) : (
        <AnimalIntakeForm
          dataId={dataId}
          key={`${species}-intake-${organizationId}`}
          attributes={attributes}
          ref={ref}
        />
      )}
    </div>
  );
});

AnimalIntake.propTypes = {
  attributes: PropTypes.array,
  dataId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

AnimalIntake.defaultProps = {
  attributes: animalIntakes,
};

AnimalIntake.propTypes = {
  attributes: PropTypes.shape({
    species: PropTypes.string.isRequired,
    adultStrayAtLargeCount: PropTypes.number,
    youthStrayAtLargeCount: PropTypes.number,
    ageUnknownStrayAtLargeCount: PropTypes.number,
    adultRelinquishedByOwnerCount: PropTypes.number,
    youthRelinquishedByOwnerCount: PropTypes.number,
    ageUnknownRelinquishedByOwnerCount: PropTypes.number,
    adultOwnerIntendedEuthanasiaCount: PropTypes.number,
    youthOwnerIntendedEuthanasiaCount: PropTypes.number,
    ageUnknownOwnerIntendedEuthanasiaCount: PropTypes.number,
    adultOtherIntakesCount: PropTypes.number,
    youthOtherIntakesCount: PropTypes.number,
    ageUnknownOtherIntakesCount: PropTypes.number,
    adultTransferredInstateInFromAgencyCount: PropTypes.number,
    youthTransferredInstateInFromAgencyCount: PropTypes.number,
    ageUnknownTransferredInstateInFromAgencyCount: PropTypes.number,
    adultTransferredOutOfStateInFromAgencyCount: PropTypes.number,
    youthTransferredOutOfStateInFromAgencyCount: PropTypes.number,
    ageUnknownTransferredOutOfStateInFromAgencyCount: PropTypes.number,
    adultTransferredInternationallyInFromAgencyCount: PropTypes.number,
    youthTransferredInternationallyInFromAgencyCount: PropTypes.number,
    ageUnknownTransferredInternationallyInFromAgencyCount: PropTypes.number,
    adultSeizedOrImpoundedCount: PropTypes.number,
    youthSeizedOrImpoundedCount: PropTypes.number,
    ageUnknownSeizedOrImpoundedCount: PropTypes.number,
    adultLegacyTransferredInFromAgencyCount: PropTypes.number,
    youthLegacyTransferredInFromAgencyCount: PropTypes.number,
    ageUnknownLegacyTransferredInFromAgencyCount: PropTypes.number,
    totalStrayAtLargeCount: PropTypes.number,
    totalRelinquishedByOwnerCount: PropTypes.number,
    totalOwnerIntendedEuthanasiaCount: PropTypes.number,
    totalTransferredInstateInFromAgencyCount: PropTypes.number,
    totalTransferredOutOfStateInFromAgencyCount: PropTypes.number,
    totalTransferredInternationallyInFromAgencyCount: PropTypes.number,
    totalOtherIntakesCount: PropTypes.number,
    totalAdultLiveIntakeCount: PropTypes.number,
    totalYouthLiveIntakeCount: PropTypes.number,
    totalAgeUnknownLiveIntakeCount: PropTypes.number,
    totalSeizedOrImpoundedCount: PropTypes.number,
    totalLegacyTransferredInFromAgencyCount: PropTypes.number,
    totalLiveIntakeCount: PropTypes.number,
  }),
};

export default AnimalIntake;
