import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "components/Button";
import { useQueryClient, useMutation } from "react-query";
import { Formik, Form, FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import SpeciesCheckbox from "components/SpeciesCheckbox/SpeciesCheckbox";
import { speciesMap, apiFetch } from "utils";
import DataStatus from "../common/DataStatus";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import { isEqual } from "lodash";
import MatrixSettingsModal from "components/MatrixSettingsModal";
import { generatePayload, updateSpeciesCache } from "./utils";

const AnimalEntryDetailsForm = ({
  setIsEditable,
  formattedDate,
  locationName,
  defaultSpecies,
  entryStatus,
}) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { year, month } = useParams();
  const queryClient = useQueryClient();
  const {
    attributes: { locationId },
  } = useUserState();

  const [isModalOpen, setIsOpen] = useState(false);

  const [changedSpecies, setChangedSpecies] = useState(null);

  const matrixData = queryClient.getQueryData([
    "dataEntry",
    `${locationId}_${month}/${year}`,
  ]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const initialSpeciesValues = Object.keys(speciesMap).reduce(
    (map, speciesKey) => {
      map[speciesKey] = defaultSpecies.includes(speciesKey);
      return map;
    },
    {}
  );

  // Fires on Form Submit
  const handleSubmitDetailsForm = (values) => {
    if (!isEqual(values, initialSpeciesValues)) {
      const removedSpecies = getRemovedSpecies(values);
      const addedSpecies = getAddedSpecies(values);

      // Update state
      setChangedSpecies({
        removed: removedSpecies, // array of objects
        added: addedSpecies, // array of strings
      });
    } else {
      setIsEditable(false);
    }
  };

  useEffect(() => {
    // If changedSpecies is null, we know the form is being shown for
    // the first time and we don't want to do anything
    if (!changedSpecies) {
      return;
    }
    if (!!changedSpecies.removed.length) {
      // If species have been removed, open the modal
      setIsOpen(true);
    } else if (!!changedSpecies.added.length) {
      // If species have only been added (and none have been removed),
      // make the API request
      setMatrixSpecies();
    } else {
      // If the species have not changed we can set the form back to it's view mode
      setIsEditable(false);
    }
  }, [changedSpecies]);

  const getRemovedSpecies = (animalSpecies) => {
    const removedSpecies = Object.keys(initialSpeciesValues).filter(
      (species) => !!initialSpeciesValues[species] && !animalSpecies[species]
    );
    return removedSpecies.map((speciesKey) => {
      const intakeRecord = matrixData.intakes.data.find((intake) => {
        return intake.attributes.species === speciesKey;
      });
      const outcomeRecord = matrixData.outcomes.data.find((outcome) => {
        return outcome.attributes.species === speciesKey;
      });
      const countRecord = matrixData.counts.data.find((count) => {
        return count.attributes.species === speciesKey;
      });

      return {
        key: speciesKey,
        intakeId: intakeRecord?.id,
        outcomeId: outcomeRecord?.id,
        countId: countRecord?.id,
      };
    });
  };

  const getAddedSpecies = (animalSpecies) => {
    return Object.keys(initialSpeciesValues).filter(
      (species) => !initialSpeciesValues[species] && !!animalSpecies[species]
    );
  };

  // Fire off our API request to add new species to DB
  const { mutate: setMatrixSpecies } = useMutation(
    () => {
      const payload = generatePayload({
        addedSpecies: changedSpecies.added,
        removedSpecies: changedSpecies.removed,
        locationId,
        year,
        month,
      });

      return apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/locations/${locationId}`,
        method: "PATCH",
        body: {
          data: {
            id: locationId,
            type: "locations",
            attributes: {},
            relationships: payload.relationships,
          },
          included: payload.included,
        },
      });
    },
    {
      onSettled: () => setIsEditable(false),
      onSuccess: (response) => {
        return queryClient.setQueryData(
          ["dataEntry", `${locationId}_${month}/${year}`],
          (oldData) => updateSpeciesCache({ oldData, response, changedSpecies })
        );
      },
    }
  );

  return (
    <div data-testid="form-selections">
      <Formik
        initialValues={initialSpeciesValues}
        onSubmit={handleSubmitDetailsForm}
        validate={(values) => {
          const isValid = Object.keys(values).some((key) => {
            return values[key];
          });

          return isValid
            ? {}
            : { invalid: "You must select at least one species" };
        }}
      >
        {({ values, handleSubmit, errors }) => (
          <>
            <Form>
              <div className="mb-8 flex items-center">
                <h5 className="w-1/5 font-body-bold">Entering Data For:</h5>

                <p className="mb-0">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="text-deepest-teal mr-1"
                  />
                  {locationName}
                </p>
              </div>
              <div className="mb-8 flex items-center">
                <h5 className="font-body-bold w-1/5">Timeframe:</h5>
                <p className="mb-0">{formattedDate}</p>
              </div>

              <div className="mb-8 flex items-center">
                <h5 className="w-1/5 font-body-bold">Data Entry Status:</h5>
                <DataStatus status={entryStatus} />
              </div>

              <h5 className="font-body-bold">
                What types of animals are you entering for?
              </h5>
              <div className="mt-6 mb-2 grid grid-cols-4 gap-4">
                <FieldArray
                  name="species"
                  render={() => {
                    return Object.keys(values).map((key) => (
                      <SpeciesCheckbox
                        key={key}
                        species={key}
                        fieldName={key}
                        label={speciesMap[key].label}
                        selected={values[key]}
                      />
                    ));
                  }}
                />
              </div>
              {errors.invalid && (
                <div className="text-sm text-red">{errors.invalid}</div>
              )}
              <div className="flex justify-end mt-4">
                <Button
                  className="btn ml-4"
                  emphasis="transparent"
                  onClick={() => setIsEditable(false)}
                >
                  Cancel
                </Button>

                <Button
                  className="btn ml-4"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>

              <MatrixSettingsModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                confirmModal={setMatrixSpecies}
              />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AnimalEntryDetailsForm;
