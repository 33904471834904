import React, { forwardRef } from "react";
import AnimalOutcome from "./AnimalOutcome";
import PropTypes from "prop-types";

const AnimalOutcomes = forwardRef(
  ({ animalData, selectedSpecies, isEditing }, ref) => {
    return (
      <div>
        <div className="sac-border mb-4">
          <div className="flex">
            <div>
              <span className="circle bg-dashboard-blue mr-4">3</span>
            </div>

            <div>
              <div className="flex justify-between">
                <h2 className="text-2xl mt-1">Live Outcomes</h2>
              </div>

              {animalData.map(
                ({ id, attributes, attributes: { species, locationId } }) => {
                  if (species === selectedSpecies || !selectedSpecies) {
                    return (
                      <AnimalOutcome
                        key={`${species}-live-outcome-${locationId}`}
                        type="live"
                        attributes={attributes}
                        dataId={id}
                        isEditing={isEditing}
                        ref={(el) => (ref.current["liveOutcome"] = el)}
                      />
                    );
                  }
                }
              )}
            </div>
          </div>
        </div>
        <div className="sac-border mb-4">
          <div className="flex">
            <div>
              <span className="circle bg-dashboard-blue mr-4">4</span>
            </div>

            <div>
              <div className="flex justify-between">
                <h2 className="text-2xl mt-1">Non-Live (Other) Outcomes</h2>
              </div>
              {animalData.map(
                ({
                  id,
                  attributes,
                  attributes: { species, organizationId },
                }) => {
                  if (species === selectedSpecies || !selectedSpecies) {
                    return (
                      <AnimalOutcome
                        key={`${species}-other-outcome-${organizationId}`}
                        type="other"
                        attributes={attributes}
                        dataId={id}
                        isEditing={isEditing}
                        ref={(el) => (ref.current["otherOutcome"] = el)}
                      />
                    );
                  }
                }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

AnimalOutcomes.propTypes = {
  animalData: PropTypes.array.isRequired,
  selectedSpecies: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
};

export default AnimalOutcomes;
