import React, { useState } from "react";
import YouTube from "@u-wave/react-youtube";
import { videoThumbnails } from "images";

const VideoTutorials = () => {
  const videos = [
    {
      id: "l3aa1PrPScE",
      name: "How to Register an Organization",
      icon: "register_an_org",
    },
    {
      id: "b_OtS423urY",
      name: "How to Add a User to an Organization",
      icon: "add_user_to_org",
    },
    {
      id: "cCvi5ePMLt4",
      name: "How to Enter Data with a CSV File Upload",
      icon: "enter_data_with_csv",
    },
    {
      id: "N8LEzcW98mc",
      name: "How to Enter Data Manually",
      icon: "enter_data_manually",
    },
    {
      id: "oDGkZbSjncM",
      name: "How to Create a Coalition",
      icon: "create_a_coalition",
    },
    {
      id: "-i9uf-WVOno",
      name: "How to Request to Join a Coalition",
      icon: "request_to_join_coalition",
    },
    {
      id: "LP1HvVTpbeA",
      name: "How to Invite Organizations to a Coalition",
      icon: "invite_org_to_coalition",
    },
    {
      id: "-JhiwvcgJOY",
      name: "How to Opt into Sharing Data with a Coalition",
      icon: "share_data_with_coalition",
    },
  ];

  const hashVideoRx = /^#!\/video\/(\d)$/;
  const hash =
    typeof window.location !== "undefined" ? window.location.hash : ""; // eslint-disable-line no-undef
  const defaultVideo = hashVideoRx.test(hash)
    ? parseInt(hash.replace(hashVideoRx, "$1"), 10)
    : 0;

  const [videoIndex, setVideoIndex] = useState(defaultVideo);
  const [suggestedQuality] = useState("auto");

  const video = videos[videoIndex];

  const selectVideo = (index) => {
    setVideoIndex(index);
  };

  return (
    <>
      <div className="flex gap-8">
        <YouTube
          video={video.id}
          style={{ maxWidth: "100%", flexGrow: 1 }}
          width={900}
          height={480}
          autoplay
          controls={true}
          allowFullscreen={true}
          suggestedQuality={suggestedQuality}
          showRelatedVideos={false}
        />

        <div className="col s4">
          <h5>Shelter Animals Count "How To" Videos</h5>
          <div className="flex flex-col gap-6 mt-4">
            {videos.map((choice, index) => (
              <div
                key={choice.id}
                className={`flex gap-2 items-center p-2 ${
                  video === choice ? "bg-dashboard-light-green" : ""
                }`}
              >
                <img
                  src={videoThumbnails[choice.icon]}
                  alt={choice.icon}
                  className="h-auto w-20"
                />
                <a
                  key={choice.id}
                  href={`#!/video/${index}`}
                  className={`collection-item ${
                    video === choice ? "active" : ""
                  }`}
                  onClick={() => selectVideo(index)}
                >
                  {choice.name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoTutorials;
