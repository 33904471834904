import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { range } from "lodash";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const Pagination = ({
  totalPages,
  currentPage,
  pageNeighbors,
  onPageChanged,
}) => {
  const [pages, setPages] = useState([]);

  /**
   * pageNeighbors: is the number of additional page numbers to show on each side of the current page.
   *                The value can be 0, 1, or 2 and will default to 0 (set in the defaultValues) if not specified.
   * currentPage: the currentPage
   * totalPages: the total number of pages based on the API response
   */
  const getPages = ({ pageNeighbors, currentPage, totalPages }) => {
    /**
     * totalNumbers: the total page numbers to show, i.e. if pageNeighbors = 2, totalNumbers is 7
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbors * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);
      // We need the + 1 on the second range parameter (`end`) because the lodash range method
      // returns a range *UP TO, BUT NOT INCLUDING* the `end` value
      let pages = range(startPage, endPage + 1);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      if (hasLeftSpill && !hasRightSpill) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        // extra pages is the range starting from the max number of pages
        // we want to show BEFORE the startPage, through start page - 1
        // i.e. startPage = 8, spillOffset = 4, extraPages = [4, 5, 6, 7]
        const extraPages = range(startPage - spillOffset, startPage);
        pages = [LEFT_PAGE, ...extraPages, ...pages];
      } else if (!hasLeftSpill && hasRightSpill) {
        // handle: (1) {2 3} [4] {5 6} > (10)
        // extra pages is the range starting from the number AFTER the end range, through the spill offset
        // i.e. endPage = 3, spillOffset = 4, extraPages = [4, 5, 6, 7]
        const extraPages = range(endPage + 1, endPage + spillOffset + 1);
        pages = [...pages, ...extraPages, RIGHT_PAGE];
      } else if (hasLeftSpill && hasRightSpill) {
        // handle: (1) < {4 5} [6] {7 8} > (10)
        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages + 1);
  };

  useEffect(() => {
    setPages(getPages({ pageNeighbors, currentPage, totalPages }));
  }, [currentPage, pageNeighbors, totalPages]);

  const handlePageClick = (page) => {
    switch (page) {
      case RIGHT_PAGE:
        onPageChanged(currentPage + 1);
        break;
      case LEFT_PAGE:
        onPageChanged(currentPage - 1);
        break;
      default:
        onPageChanged(page);
    }
  };

  return !!pages.length ? (
    <nav aria-label="Navigation">
      <ul className="flex space-x-3">
        {pages.map((page, index) => (
          <li key={index}>
            <button
              disabled={page === currentPage}
              onClick={() => handlePageClick(page)}
              className={`rounded-lg py-1 px-3 text-sm transition-colors ${
                page === currentPage
                  ? "bg-dashboard-blue text-white"
                  : "bg-pale-blue text-deepest-teal hover:bg-dashboard-blue hover:text-white focus:bg-dashboard-blue focus:text-white"
              }`}
              {...(page === currentPage
                ? { "data-testid": "current-page" }
                : {})}
            >
              {page === currentPage && (
                <span className="sr-only">You&rsquo;re on page </span>
              )}
              {page === RIGHT_PAGE && (
                <>
                  <span className="sr-only">Next page</span>
                  <span aria-hidden>&raquo;</span>
                </>
              )}
              {page === LEFT_PAGE && (
                <>
                  <span className="sr-only">Previous page</span>
                  <span aria-hidden>&laquo;</span>
                </>
              )}
              {page !== LEFT_PAGE && page !== RIGHT_PAGE && page}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  ) : null;
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageNeighbors: PropTypes.oneOf([0, 1, 2]),
  onPageChanged: PropTypes.func,
};
Pagination.defaultProps = {
  pageNeighbors: 2,
};

export default Pagination;
