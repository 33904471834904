import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";

const Input = ({ name, label, ...props }) => {
  const [field] = useField(name);
  return (
    <div>
      <label htmlFor={name} className="sr-only">
        {label}
      </label>
      <input className="form-input w-full" {...field} {...props} />
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Input;
