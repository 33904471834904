import { format } from "date-fns";
import { formatPhoneNumberForApi } from "utils";

const getAuthorizer = ({ account, authorization }) => {
  return authorization.isAuthorizer
    ? {
        organizationAuthorizerName: account.fullName,
        organizationAuthorizerTitle: account.title,
        organizationAuthorizerEmail: account.email,
        organizationAuthorizerPhoneNumber: formatPhoneNumberForApi({
          phoneNumber: account.phoneNumber,
          extension: account.extension,
        }),
      }
    : {
        organizationAuthorizerName: authorization.authorizerName,
        organizationAuthorizerTitle: authorization.authorizerTitle,
        organizationAuthorizerEmail: authorization.authorizerEmail,
        organizationAuthorizerPhoneNumber: formatPhoneNumberForApi({
          phoneNumber: authorization.authorizerPhoneNumber,
          extension: authorization.extension,
        }),
      };
};

export const constructPayload = (values) => {
  if (
    values.organization.websiteUrl &&
    !values.organization.websiteUrl.startsWith("http://" || "https://")
  ) {
    values.organization.websiteUrl = `http://${values.organization.websiteUrl}`;
  }

  return {
    data: {
      type: "registrations",
      attributes: {
        registrationType: values.registerType,
        userFullName: values.account.fullName,
        userTitle: values.account.title,
        userEmail: values.account.email,
        userPassword: values.account.password,
        organizationName: values.organization.name,
        organizationCountry: values.organization.country,
        organizationType: values.organization.organizationType,
        organizationWebsiteUrl: values.organization.websiteUrl,
        organizationBusinessNumber: values.organization.businessNumber.replace(
          /\D/g,
          ""
        ),
        organizationIncorporatedOn: format(
          values.organization.incorporatedOn,
          "dd/MM/yyyy"
        ),
        organizationEin: values.organization.ein.replace(/\D/g, ""),
        ...(values.organization.referral
          ? {
              organizationReferringOrganizationName:
                values.organization.referringOrganizationName,
            }
          : {}),
        organizationAnimalSpecies: Object.keys(
          values.organization.animalSpecies
        ).reduce((array, key) => {
          if (!!values.organization.animalSpecies[key]) {
            array.push(key);
          }
          return array;
        }, []),
        organizationAnnualIntake: values.organization.annualIntake,
        organizationAnnualOperatingBudget:
          values.organization.annualOperatingBudget,
        organizationShelterSoftware: values.organization.shelterSoftware,
        ...getAuthorizer({
          account: values.account,
          authorization: values.authorization,
        }),
        organizationCommunicationsOptIn: values.dataSharing.communicationsOptIn,
        organizationSearchableOptIn: values.dataSharing.searchableOptIn,
        organizationMediaOptIn: values.dataSharing.mediaOptIn,
        locationAddressLine_1: values.organization.addressLine1,
        locationAddressLine_2: values.organization.addressLine2,
        locationCity: values.organization.city,
        locationUsState:
          values.organization.country === "United States"
            ? values.organization.usState
            : null,
        locationZipCode:
          values.organization.country === "United States"
            ? values.organization.zipCode
            : null,
        locationUsCountyId:
          values.organization.country === "United States"
            ? values.organization.usCounty
            : null,
        locationPostalCode: values.organization.postalCode.replace(/\s/g, ""),
        locationProvinceOrTerritory: values.organization.provinceOrTerritory,
        locationContactPhoneNumber: formatPhoneNumberForApi({
          phoneNumber: values.organization.contactPhoneNumber,
          extension: values.organization.extension,
        }),
      },
    },
  };
};
