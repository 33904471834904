import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import TableFormInput from "../common/TableFormInput";
import { useQueryClient, useMutation } from "react-query";
import { Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import Table, {
  getRowData,
  rowLabelCell,
  getFormAttributes,
  transformAttributesForPayload,
} from "components/Table";
import { animalIntakes } from "components/Table/__mocks__";
import { useAuthDispatch, useAuthState, useUserState } from "context";
import { apiFetch, speciesMap, useHandleRequestStates } from "utils";

const AnimalIntakeForm = forwardRef(({ attributes, dataId }, ref) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const { year, month } = useParams();
  const { handleSuccess, handleError } = useHandleRequestStates();

  const {
    attributes: { locationId },
  } = useUserState();

  const setRow = ({ rowLabel, keySubString, tooltip }) =>
    getRowData({
      data: attributes,
      accessKeys: ["adult", "youth", "ageUnknown", "total"],
      rowLabel,
      keySubString,
      tooltip,
    });

  // Set our Table Columns
  const formColumns = [
    {
      accessor: "rowLabel",
      Header: "",
      Cell: rowLabelCell,
    },
    {
      accessor: "adult",
      Header: "Adult",
      Cell: TableFormInput,
    },
    {
      accessor: "youth",
      Header: "Up to 5 mos.",
      Cell: TableFormInput,
    },
    {
      accessor: "ageUnknown",
      Header: "Age Unknown",
      Cell: TableFormInput,
    },
    {
      accessor: "total",
      Header: "Subtotal",
    },
  ];

  const data = [
    setRow({
      rowLabel: "Stray at large",
      keySubString: "StrayAtLargeCount",
      tooltip: "Stated to be unowned or free-roaming",
    }),
    setRow({
      rowLabel: "Relinquished by owner",
      keySubString: "RelinquishedByOwnerCount",
      tooltip:
        "Admitted by owner, including adoption returns. All returns should be tracked as owner relinquishment.",
    }),
    setRow({
      rowLabel: "Owner-intended euthanasia",
      keySubString: "OwnerIntendedEuthanasiaCount",
      tooltip:
        "Limited to this definition: Euthanasia of pets whose owner brought the pet to the shelter with the INTENT of utilizing euthanasia services.",
    }),
    setRow({
      rowLabel: "Transferred in state",
      keySubString: "TransferredInstateInFromAgencyCount",
      tooltip:
        "Admission from another agency within the same state, for adoption, large scale seizure support, etc.",
    }),
    setRow({
      rowLabel: "Transferred out of state",
      keySubString: "TransferredOutOfStateInFromAgencyCount",
      tooltip:
        "Admission from another agency in a different state, for adoption, large scale seizure support, etc.",
    }),
    setRow({
      rowLabel: "Transferred internationally",
      keySubString: "TransferredInternationallyInFromAgencyCount",
      tooltip:
        "Admission from another agency outside the US, for adoption, large scale seizure support, etc.",
    }),
    setRow({
      rowLabel: "Transfers In (undesignated)",
      keySubString: "LegacyTransferredInFromAgencyCount",
      tooltip:
        "Transfer in to agency (undesignated) represents Transfer In data prior to when agency location break outs.",
    }),
    setRow({
      rowLabel: "Seized or impounded",
      keySubString: "SeizedOrImpoundedCount",
      tooltip:
        "Impounds for pets seized during cruelty cases or for protective custody. This does not include pets relinquished by their owners.",
    }),
    setRow({
      rowLabel: "Other intakes",
      keySubString: "OtherIntakesCount",
      tooltip:
        "Pets born while in care, and other types of admission not captured above.",
    }),
  ];

  const { mutate: updateAnimalIntakes } = useMutation(
    (attributes) =>
      apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_intakes/${dataId}`,
        method: "PATCH",
        body: {
          data: {
            id: dataId,
            type: "animal_intakes",
            attributes: transformAttributesForPayload(attributes),
          },
        },
      }),
    {
      onSuccess: (response) => {
        handleSuccess({
          message: `Updated the ${speciesMap[
            attributes.species
          ].label.toLocaleLowerCase()} live intakes`,
        });

        // API returns updated table. But cache stores all tables and when you update you lose extra tables.
        return queryClient.setQueryData(
          ["dataEntry", `${locationId}_${month}/${year}`],
          (oldData = {}) => {
            const newData = cloneDeep(oldData);
            if (newData.intakes) {
              const speciesIndex = newData.intakes.data.findIndex(
                (intakeRecord) => {
                  return intakeRecord.id === dataId;
                }
              );
              newData.intakes.data[speciesIndex] = response.data;
            }
            return newData;
          }
        );
      },
      onError: handleError,
    }
  );

  return (
    <Formik
      initialValues={getFormAttributes(attributes)}
      onSubmit={(values) => updateAnimalIntakes(values)}
      innerRef={ref}
    >
      <Form>
        <Table
          data={data}
          columns={formColumns}
          columnAlignments={[null, null, null, null, "text-right"]}
        />
      </Form>
    </Formik>
  );
});

AnimalIntakeForm.defaultProps = {
  attributes: animalIntakes,
  dataId: PropTypes.string.isRequired,
};

AnimalIntakeForm.propTypes = {
  attributes: PropTypes.shape({
    species: PropTypes.string.isRequired,
    adultStrayAtLargeCount: PropTypes.number,
    youthStrayAtLargeCount: PropTypes.number,
    ageUnknownStrayAtLargeCount: PropTypes.number,
    adultRelinquishedByOwnerCount: PropTypes.number,
    youthRelinquishedByOwnerCount: PropTypes.number,
    ageUnknownRelinquishedByOwnerCount: PropTypes.number,
    adultOwnerIntendedEuthanasiaCount: PropTypes.number,
    youthOwnerIntendedEuthanasiaCount: PropTypes.number,
    ageUnknownOwnerIntendedEuthanasiaCount: PropTypes.number,
    adultOtherIntakesCount: PropTypes.number,
    youthOtherIntakesCount: PropTypes.number,
    ageUnknownOtherIntakesCount: PropTypes.number,
    adultTransferredInstateInFromAgencyCount: PropTypes.number,
    youthTransferredInstateInFromAgencyCount: PropTypes.number,
    ageUnknownTransferredInstateInFromAgencyCount: PropTypes.number,
    adultTransferredOutOfStateInFromAgencyCount: PropTypes.number,
    youthTransferredOutOfStateInFromAgencyCount: PropTypes.number,
    ageUnknownTransferredOutOfStateInFromAgencyCount: PropTypes.number,
    adultTransferredInternationallyInFromAgencyCount: PropTypes.number,
    youthTransferredInternationallyInFromAgencyCount: PropTypes.number,
    ageUnknownTransferredInternationallyInFromAgencyCount: PropTypes.number,
    adultSeizedOrImpoundedCount: PropTypes.number,
    youthSeizedOrImpoundedCount: PropTypes.number,
    ageUnknownSeizedOrImpoundedCount: PropTypes.number,
    totalStrayAtLargeCount: PropTypes.number,
    totalRelinquishedByOwnerCount: PropTypes.number,
    totalOwnerIntendedEuthanasiaCount: PropTypes.number,
    totalTransferredInstateInFromAgencyCount: PropTypes.number,
    totalTransferredOutOfStateInFromAgencyCount: PropTypes.number,
    totalTransferredInternationallyInFromAgencyCount: PropTypes.number,
    totalOtherIntakesCount: PropTypes.number,
    totalAdultLiveIntakeCount: PropTypes.number,
    totalYouthLiveIntakeCount: PropTypes.number,
    totalAgeUnknownLiveIntakeCount: PropTypes.number,
    totalSeizedOrImpoundedCount: PropTypes.number,
    totalLiveIntakeCount: PropTypes.number,
  }),
};

export default AnimalIntakeForm;
