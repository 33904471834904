import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import AnimalCountForm from "./AnimalCountForm";
import { speciesMap } from "utils";

const AnimalCount = forwardRef(
  (
    {
      dataId,
      species,
      beginningAnimalCount,
      endingAnimalCount,
      previousEndingFosterAnimalCount,
      previousEndingAnimalCount,
      setRecalculate,
      beginningFosterAnimalCount,
      endingFosterAnimalCount,
      isEditing,
    },
    ref
  ) => {
    return (
      <div className="mt-12">
        <div className="flex items-center justify-between space-x-4 mb-4">
          <h3 className="text-2xl flex items-center">
            <img src={speciesMap[species].icon} alt="" className="mr-4" />
            {speciesMap[species].label}
          </h3>
        </div>
        <div className="text-sm mb-4">
          To automatically calculate (or recalculate) beginning and ending
          counts, click "Auto Calculate" button, in order of fields. Auto
          Calculate will pull beginning counts (total in care and in foster)
          from previous month’s data. Ending total in care is calculated as
          previous month’s beginning count plus intakes minus outcomes.{" "}
          <span className="font-body-bold">
            Ending foster in care cannot be calculated and requires manual
            entry.
          </span>
        </div>

        <AnimalCountForm
          dataId={dataId}
          isEditing={isEditing}
          species={species}
          beginningAnimalCount={beginningAnimalCount}
          previousEndingAnimalCount={previousEndingAnimalCount}
          previousEndingFosterAnimalCount={previousEndingFosterAnimalCount}
          endingAnimalCount={endingAnimalCount}
          setRecalculate={setRecalculate}
          beginningFosterAnimalCount={beginningFosterAnimalCount}
          endingFosterAnimalCount={endingFosterAnimalCount}
          ref={ref}
        />
      </div>
    );
  }
);

AnimalCount.propTypes = {
  dataId: PropTypes.string.isRequired,
  species: PropTypes.string,
  beginningAnimalCount: PropTypes.number,
  endingAnimalCount: PropTypes.number,
};

export default AnimalCount;
