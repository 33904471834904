import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import { get } from "lodash";
import { Menu, MenuItem, FocusableItem, MenuButton } from "@szhsin/react-menu";
import { toast } from "react-toastify";
import {
  apiFetch,
  getTotalPages,
  usePermissions,
  ROUTE_GUARDS,
  camelToTitle,
} from "utils";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import Table from "components/Table";
import Pagination from "components/Pagination";
import ActionsMenu from "components/ActionsMenu";
import Button from "components/Button";
import AlertModal from "components/AlertModal";
import ConfirmationModal from "components/ConfirmationModal";

const Members = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const { orgId } = useParams();
  const permissions = usePermissions();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalError, setModalError] = useState(null);
  const { isAdmin } = useUserState();

  const getOrgMembers = async ({ page = 1 }) => {
    const response = await apiFetch({
      token,
      dispatch,
      endpoint: `/api/v1/users?filter[${
        isAdmin ? "organization_id_for_admin" : "organization_id"
      }][eq]=${orgId}&include=user_memberships&page[number]=${page}&stats[total]=count&sort=full_name`,
      includeMeta: true,
    });
    return {
      data: response.data.map((member) => {
        const membership = response.included.find(
          (inc) =>
            String(inc.attributes.userId) === member.id &&
            String(inc.attributes.resourceId) === orgId
        );
        return Object.assign(member, {
          membership,
        });
      }),
      meta: response.meta,
    };
  };

  // Initial query
  const {
    data: response = { data: [], included: [] },
    error,
    isLoading,
  } = useQuery(
    ["organizationMembers", { orgId, page }],
    () => getOrgMembers({ page }),
    {
      keepPreviousData: true,
    }
  );

  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["organizationMembers", { orgId, page: page + 1 }],
        () => getOrgMembers({ page: page + 1 })
      );
    }
  }, [response, page, queryClient]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalMembers = get(response, "meta.stats.total.count") || 0;
    const totalPages = getTotalPages({
      totalCount: totalMembers,
    });
    setTotalPages(totalPages);
    setTotalMembers(totalMembers);
  }, [response]);

  const columns = [
    {
      accessor: "attributes.fullName",
      Header: "Full Name",
    },
    {
      accessor: "attributes.email",
      Header: "Email",
    },
    {
      accessor: "attributes.confirmedAt",
      Header: "Status",
      Cell: ({ cell }) => (cell.value ? "Confirmed" : "Unconfirmed"),
    },
    {
      accessor: "membership.attributes.role",
      Header: "Role",
      Cell: ({ cell }) => {
        const [isEdited, setEdited] = useState(false);
        const [role, setRole] = useState(cell.value);

        const handleRoleChange = async () => {
          const user = cell.row.original;
          user.membership.attributes.role = role;
          updateMember(user);
        };

        const editOrSave = (e) => {
          if (isEdited === true) {
            e.stopPropagation();
            setEdited(false);
            handleRoleChange();
          }
        };

        const changeRole = (newRole) => {
          if (newRole === role) {
            return;
          } else {
            setRole(newRole);
            setEdited(true);
          }
        };

        return (
          <div className="flex justify-between">
            <span className="self-center">{camelToTitle(role) || ""}</span>

            {permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() && (
              <div className="w-20">
                <Menu
                  menuButton={
                    <MenuButton
                      className="actions-menu__trigger btn-sm"
                      data-testid="actions-menu-button"
                      index={cell.row.id}
                    >
                      <span onClick={(e) => editOrSave(e)}>
                        {!isEdited ? "Edit" : "Save"}
                      </span>
                    </MenuButton>
                  }
                  className="actions-menu"
                  align="end"
                >
                  <MenuItem onClick={() => changeRole("organization_viewer")}>
                    Organization Viewer
                  </MenuItem>
                  <MenuItem onClick={() => changeRole("organization_manager")}>
                    Organization Manager
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
        );
      },
    },
    {
      accessor: "id",
      Header: "",
      Cell: ({ row: { original } }) =>
        permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() && (
          <DetailsMenu
            member={original}
            orgId={orgId}
            triggerRemoveMemberModal={openModal}
            totalMembers={totalMembers}
          />
        ),
    },
  ];

  const getResourceId = (memberId) => {
    if (!response || !response.data) return null;

    const member = response.data.find((member) => {
      return member.id === memberId;
    });

    return get(member, "membership.id") || null;
  };

  const openModal = (member) => {
    setMemberToRemove(member);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalError(null);
    setMemberToRemove(null);
  };

  const { mutate: removeMember, isLoading: isLoadingRemoveMember } =
    useMutation(
      async ({ id }) => {
        const membershipId = getResourceId(id);
        return await apiFetch({
          token,
          dispatch,
          method: "DELETE",
          endpoint: `/api/v1/user_memberships/${membershipId}`,
          body: {
            data: {
              id: membershipId,
              type: "user_memberships",
            },
          },
        });
      },
      {
        onSuccess: () => {
          toast.success(
            `Removed ${memberToRemove.attributes.fullName} successfully`,
            {
              autoClose: 3000,
            }
          );
          // Refresh the org members queries
          queryClient.invalidateQueries(["organizationMembers", { orgId }], {
            refetchActive: true,
          });
          closeModal();
        },
        onError: setModalError,
      }
    );

  const { mutate: updateMember } = useMutation(
    async (user) => {
      const membershipId = getResourceId(user.id);
      return await apiFetch({
        token,
        dispatch,
        method: "PATCH",
        endpoint: `/api/v1/user_memberships/${membershipId}`,
        body: {
          data: {
            id: membershipId,
            type: "user_memberships",
            attributes: {
              role: user.membership.attributes.role,
            },
          },
        },
      });
    },
    {
      onSuccess: (res) => {
        toast.success(
          `Updated member ${res.data.attributes.userId} to ${res.data.attributes.role} successfully`,
          {
            autoClose: 3000,
          }
        );
        // Refresh the org members queries
        queryClient.invalidateQueries(["organizationMembers", { orgId }], {
          refetchActive: true,
        });
      },
      onError: (err) => {
        toast.error(`Failed to update user to new role!`, {
          autoClose: 3000,
        });
      },
    }
  );

  return (
    <>
      <div className="mb-8">
        <h2>Members</h2>

        {permissions[ROUTE_GUARDS.ORGANIZATION_MANAGER]() && (
          <div className="flex space-x-2">
            <Button
              to={`/organizations/${orgId}/members/add`}
              data-testid="add-member-btn"
            >
              Invite Member
            </Button>
          </div>
        )}
      </div>

      <Table
        data={response.data || []}
        columns={columns}
        columnWidths={[null, null, null, null, "w-16"]}
        columnAlignments={[null, null, null, null, "text-right"]}
        noResultsText="No members found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}

      <AlertModal isModalOpen={isModalOpen} closeModal={closeModal}>
        <ConfirmationModal
          prompt={`Are you sure you want to remove ${get(
            memberToRemove,
            "attributes.fullName"
          )} from the organization?`}
          isLoading={isLoadingRemoveMember}
          error={modalError}
          closeModal={closeModal}
          confirmModal={() => removeMember(memberToRemove)}
        />
      </AlertModal>
    </>
  );
};

export default Members;

const DetailsMenu = ({
  member,
  orgId,
  triggerRemoveMemberModal,
  totalMembers,
}) => (
  <ActionsMenu>
    {/* View */}
    <FocusableItem>
      {({ ref }) => (
        <Link to={`/organizations/${orgId}/members/${member.id}`} ref={ref}>
          View Details
        </Link>
      )}
    </FocusableItem>
    {/* Edit */}
    <FocusableItem>
      {({ ref }) => (
        <Link
          to={`/organizations/${orgId}/members/${member.id}/edit`}
          ref={ref}
        >
          Edit Details
        </Link>
      )}
    </FocusableItem>
    {/* Remove */}
    {totalMembers !== 1 && (
      <MenuItem onClick={() => triggerRemoveMemberModal(member)}>
        Remove Member from Organization
      </MenuItem>
    )}
  </ActionsMenu>
);
