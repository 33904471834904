import { apiFetch } from "utils";

export const getAllDataStatus = async ({
  token,
  dispatch,
  coalitionId,
  year,
  search,
}) => {
  const response = await apiFetch({
    token,
    dispatch,
    endpoint: `/api/v1/organizations?&filter[active_coalition][eq]=${coalitionId}&filter[coalition_membership_status][eq]=active&filter[data_entry_statuses.year][eq]=${year}&filter[community_data_entry_statuses.year][eq]=${year}${
      search ? `&filter[search]=${search}` : ""
    }&include=data_entry_statuses,community_data_entry_statuses&sort=name&page[size]=1000&page[number]=1&stats[total]=count`,
    includeMeta: true,
  });

  await Promise.all(
    response.data.map(async (org) => {
      const intakes = await apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_intakes?filter[organization_id][eq]=${org.id}&sort=-updated_at&page[size]=1&page[number]=0`,
      });

      const services = await apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/animal_services?filter[organization_id][eq]=${org.id}&sort=-updated_at&page[size]=1&page[number]=0`,
      });

      const incStatusRecords = response.included.filter((inc) => {
        return (
          inc.type === "organization_data_entry_statuses" &&
          String(inc.attributes.organizationId) === org.id
        );
      });

      const servicesStatusRecords = response.included.filter((inc) => {
        return (
          inc.type === "organization_community_data_entry_statuses" &&
          String(inc.attributes.organizationId) === org.id
        );
      });

      org.servicesStatuses = {};
      org.dataStatuses = {};
      org.serviceLatestIntake = new Date(1970);
      org.latestIntake = new Date(1970);
      if (intakes.data[0]?.attributes.updatedAt) {
        org.latestIntake = new Date(intakes?.data[0].attributes.updatedAt);
      }
      if (services.data[0]?.attributes.updatedAt) {
        org.serviceLatestIntake = new Date(
          services?.data[0].attributes.updatedAt
        );
      }

      for (let i = 0; i < 12; i++) {
        const month = i + 1;
        const monthRecord = (incStatusRecords || []).find(
          (rec) => rec.attributes.month === month
        );

        const servicesMonthRecord = (servicesStatusRecords || []).find(
          (rec) => rec.attributes.month === month
        );
        org.dataStatuses[month] = monthRecord
          ? monthRecord.attributes.status
          : "unbegun";

        org.servicesStatuses[month] = servicesMonthRecord
          ? servicesMonthRecord.attributes.status
          : "unbegun";
      }

      return org;
    })
  );

  return response;
};
