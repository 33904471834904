import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  USER_CLEAR,
} from "./actionTypes";
import { AUTH_TOKEN_KEY, API_URL, USER_ID_KEY, formatErrors } from "../utils";

export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(loginPayload),
  };

  dispatch({ type: LOGIN_REQUEST });
  const response = await fetch(`${API_URL}/auth/sign_in`, requestOptions);
  const headers = await response.headers;

  try {
    const { data, errors, error } = await response.json();

    if (!response.ok) {
      throw new Error(formatErrors(errors || error));
    }

    const token = {
      accessToken: headers.get("access-token"),
      client: headers.get("client"),
      uid: headers.get("uid"),
      expiry: headers.get("expiry"),
    };

    const userId = data.id;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token, userId },
    });

    return true;
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE, error });
    throw new Error(formatErrors(error));
  }
}

// Allow auth from summa oauth
export async function loginUserWithOAuth(dispatch, tokenPayload) {
  try {
    // Extract necessary fields from the OAuth token payload
    const { accessToken, client, uid, expiry, userId } = tokenPayload;

    // Directly dispatch LOGIN_SUCCESS since no HTTP request is needed
    const token = { accessToken, client, uid, expiry };

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token, userId },
    });

    return true;
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE, error });
    throw new Error(formatErrors(error));
  }
}

export function logout({ dispatch, userDispatch, queryClient, error = null }) {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(USER_ID_KEY);
  dispatch({ type: LOGOUT_REQUEST, error });
  userDispatch({ type: USER_CLEAR });
  queryClient.removeQueries();
}
