import React from "react";
import FormField from "components/FormField";

const ProvinceDropdown = ({ ...props }) => {
  return (
    <FormField as="select" {...props}>
      <option disabled value="" hidden>
        -- Select a Province --
      </option>
      <option value="AB">AB</option>
      <option value="BC">BC</option>
      <option value="MB">MB</option>
      <option value="NB">NB</option>
      <option value="NL">NL</option>
      <option value="NT">NT</option>
      <option value="NS">NS</option>
      <option value="NU">NU</option>
      <option value="ON">ON</option>
      <option value="PE">PE</option>
      <option value="QC">QC</option>
      <option value="SK">SK</option>
      <option value="YT">YT</option>
    </FormField>
  );
};

export default ProvinceDropdown;
