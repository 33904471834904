import React from "react";
import { marketingResources } from "images";

const MarketingResources = () => {
  return (
    <div className=" grid grid-cols-4">
      {marketingResources.map((resource) => (
        <div
          key={resource.id}
          className="flex flex-col items-center gap-3 text-xl"
        >
          <img
            src={resource.icon}
            alt={resource.id}
            className=" bg-transparent"
          />
          <a href={resource.icon} download>
            Download Now
          </a>
        </div>
      ))}
    </div>
  );
};

export default MarketingResources;
