export const animalIntakes = {
  adultStrayAtLargeCount: 0,
  youthStrayAtLargeCount: 0,
  ageUnknownStrayAtLargeCount: 0,
  adultRelinquishedByOwnerCount: 0,
  youthRelinquishedByOwnerCount: 0,
  ageUnknownRelinquishedByOwnerCount: 0,
  adultOwnerIntendedEuthanasiaCount: 0,
  youthOwnerIntendedEuthanasiaCount: 0,
  ageUnknownOwnerIntendedEuthanasiaCount: 0,
  adultOtherIntakesCount: 0,
  youthOtherIntakesCount: 0,
  ageUnknownOtherIntakesCount: 0,
  adultTransferredInstateInFromAgencyCount: 0,
  youthTransferredInstateInFromAgencyCount: 0,
  ageUnknownTransferredInstateInFromAgencyCount: 0,
  adultTransferredOutOfStateInFromAgencyCount: 0,
  youthTransferredOutOfStateInFromAgencyCount: 0,
  ageUnknownTransferredOutOfStateInFromAgencyCount: 0,
  adultTransferredInternationallyInFromAgencyCount: 0,
  youthTransferredInternationallyInFromAgencyCount: 0,
  ageUnknownTransferredInternationallyInFromAgencyCount: 0,
  adultSeizedOrImpoundedCount: 0,
  youthSeizedOrImpoundedCount: 0,
  ageUnknownSeizedOrImpoundedCount: 0,
  totalStrayAtLargeCount: 0,
  totalRelinquishedByOwnerCount: 0,
  totalOwnerIntendedEuthanasiaCount: 0,
  totalTransferredInstateInFromAgencyCount: 0,
  totalTransferredOutOfStateInFromAgencyCount: 0,
  totalTransferredInternationallyInFromAgencyCount: 0,
  totalOtherIntakesCount: 0,
  totalAdultLiveIntakeCount: 0,
  totalYouthLiveIntakeCount: 0,
  totalAgeUnknownLiveIntakeCount: 0,
  totalSeizedOrImpoundedCount: 0,
  totalLiveIntakeCount: 0,
};
