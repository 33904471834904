import IntakeOverviewTableSpecies from "./IntakeOverviewTableSpecies";
import { speciesMap } from "utils";

export const speciesOrder = {
  unbegun: "unbegun", // no records exist
  incomplete: "incomplete", // at least one record exists
  complete: "complete", // all records have complete status
};

export const transformTableData = (response) => {
  const { included = [] } = response;

  const includedRecords = included.filter(
    (inc) => inc.type === "location_ytd_species_totals"
  );

  return Object.keys(speciesMap).reduce((arr, key) => {
    const speciesData =
      includedRecords.find((inc) => inc?.attributes.species === key) || {};
    const { attributes } = speciesData || {};

    arr.push({
      rowHeader: <IntakeOverviewTableSpecies speciesKey={key} />,
      species: key,
      ...attributes,
    });
    return arr;
  }, []);
};

export const transformCoalitionTableData = (response) => {
  const { included = [] } = response;

  let includedRecords = included.filter(
    (inc) => inc.type === "location_ytd_species_totals"
  );

  const aggregatedData = [];

  includedRecords.forEach((item) => {
    const {
      species,
      totalIntake,
      totalLiveOutcomes,
      totalOtherOutcomes,
      totalFieldServices,
      totalVeterinaryServices,
      totalSupportServices,
      totalBehaviorServices,
    } = item.attributes;

    const existingSpecie = aggregatedData.find((i) => i.species === species);

    if (existingSpecie) {
      existingSpecie["totalIntake"] =
        existingSpecie["totalIntake"] + totalIntake;
      existingSpecie["totalLiveOutcomes"] =
        existingSpecie["totalLiveOutcomes"] + totalLiveOutcomes;
      existingSpecie["totalOtherOutcomes"] =
        existingSpecie["totalOtherOutcomes"] + totalOtherOutcomes;
      existingSpecie["totalFieldServices"] =
        existingSpecie["totalFieldServices"] + totalFieldServices;
      existingSpecie["totalVeterinaryServices"] =
        existingSpecie["totalVeterinaryServices"] + totalVeterinaryServices;
      existingSpecie["totalSupportServices"] =
        existingSpecie["totalSupportServices"] + totalSupportServices;
      existingSpecie["totalBehaviorServices"] =
        existingSpecie["totalBehaviorServices"] + totalBehaviorServices;
    } else {
      aggregatedData.push({
        species,
        totalIntake,
        totalLiveOutcomes,
        totalOtherOutcomes,
        totalFieldServices,
        totalVeterinaryServices,
        totalSupportServices,
        totalBehaviorServices,
        rowHeader: <IntakeOverviewTableSpecies speciesKey={species} />,
      });
    }
  });

  return aggregatedData;
};

export const constructEndpoint = ({
  coalitionId,
  organizationId,
  coalitionYear,
  coalitionFilters,
  action,
}) => {
  var orgs = [];
  if (
    coalitionFilters &&
    coalitionFilters.organization_ids &&
    coalitionFilters.organization_ids.length > 0
  ) {
    orgs = coalitionFilters.organization_ids.map((a) => a.value);
  }

  return `/api/v1/locations?&include=ytd_species_totals&filter[coalition_data_sharing]=${coalitionId}:${
    action === "intakes"
      ? "intake_outcome_data_sharing"
      : "community_service_data_sharing"
  }${
    organizationId
      ? `&filter[organization_id][eq]=${organizationId}`
      : orgs.length > 0
      ? `&filter[organization_id][in_ids]=${orgs}`
      : ""
  }&filter[ytd_species_totals.year][eq]=${coalitionYear}&page[size]=1000`;
};
