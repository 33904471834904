import { useMemo } from "react";
import { isValid, isFuture } from "date-fns";
import { get } from "lodash";
import { useUserState } from "context";

export const ROUTE_GUARDS = {
  ADMIN: "isAdmin",
  DATA_MATRIX: "dataMatrix",
  SERVICES_MATRIX: "servicesMatrix",
  ORG_MEMBER: "orgMemberOnly",
  ORGANIZATIONS: "organizations",
  CAN_VIEW_ORG: "canViewOrg",
  HAS_LEGACY_ORG: "hasLegacyOrg",
  HAS_COALITIONS: "hasCoalitions",
  CAN_VIEW_COALITION: "canViewCoalition",
  COALITION_CONTRIBUTOR: "coalitionContributor",
  COALITION_CONTRIBUTOR_ACTIVE: "coalitionContributorActive",
  COALITION_CONTRIBUTOR_PENDING: "coalitionContributorPending",
  COALITION_MANAGER: "coalitionManager",
  COALITION_VIEWER: "coalitionViewer",
  ORGANIZATION_MANAGER: "organizationManager",
  ORGANIZATION_USER: "organizationUser",
};

export function useRouteGuard(match) {
  const { isAdmin, organization, coalitions, isOrgAdmin } = useUserState();
  const { params } = match;
  const { month, year, orgId, coalitionId } = params;

  const guards = useMemo(() => {
    return {
      [ROUTE_GUARDS.ADMIN]: () => (isAdmin ? null : "/"),
      [ROUTE_GUARDS.DATA_MATRIX]: (date) => {
        const { m, y } = date || {};
        return isValidDate({ month: m || month, year: y || year })
          ? null
          : "/data-entry";
      },
      [ROUTE_GUARDS.SERVICES_MATRIX]: (date) => {
        const { m, y } = date || {};
        return isValidDate({ month: m || month, year: y || year })
          ? null
          : "/services";
      },
      [ROUTE_GUARDS.ORG_MEMBER]: () =>
        !isAdmin && !!organization ? null : "/",
      [ROUTE_GUARDS.ORGANIZATIONS]: () =>
        !!organization ? `/organizations/${organization.id}` : null,
      [ROUTE_GUARDS.CAN_VIEW_ORG]: (id) =>
        isAdmin || (id || orgId) === organization?.id ? null : "/",
      [ROUTE_GUARDS.HAS_LEGACY_ORG]: () =>
        get(organization, "attributes.status") === "imported" ? null : "/",
      [ROUTE_GUARDS.HAS_COALITIONS]: () =>
        hasCoalitions({ coalitions, organization }) ? null : "/coalitions",
      [ROUTE_GUARDS.CAN_VIEW_COALITION]: (id) =>
        isAdmin ||
        canViewCoalition({ coalitionId: id || coalitionId, coalitions })
          ? null
          : "/coalitions",
      [ROUTE_GUARDS.COALITION_CONTRIBUTOR]: (id) =>
        isAdmin ||
        isCoalitionContributor({ coalitionId: id || coalitionId, coalitions })
          ? null
          : "/coalitions",
      [ROUTE_GUARDS.COALITION_CONTRIBUTOR_ACTIVE]: (id) =>
        isActiveCoalitionContributor({
          coalitionId: id || coalitionId,
          coalitions,
        })
          ? null
          : "/coalitions",
      [ROUTE_GUARDS.COALITION_CONTRIBUTOR_PENDING]: (id) =>
        isAdmin ||
        isPendingCoalitionContributor({
          coalitionId: id || coalitionId,
          coalitions,
        })
          ? null
          : "/coalitions",
      [ROUTE_GUARDS.COALITION_MANAGER]: (id) =>
        isAdmin ||
        isCoalitionManager({ coalitionId: id || coalitionId, coalitions })
          ? null
          : "/coalitions",
      [ROUTE_GUARDS.COALITION_VIEWER]: (id) =>
        isAdmin ||
        isCoalitionViewer({ coalitionId: id || coalitionId, coalitions })
          ? null
          : "/coalitions",
      [ROUTE_GUARDS.ORGANIZATION_MANAGER]: () => (isOrgAdmin ? null : "/"),
    };
  }, [
    isAdmin,
    organization,
    coalitions,
    month,
    year,
    orgId,
    coalitionId,
    isOrgAdmin,
  ]);

  return guards;
}

export const isValidDate = ({ month, year }) => {
  const date = new Date(`${year}/${month}/1`);
  return isValid(date) && !isFuture(date);
};

export const hasCoalitions = (coalitions) => {
  if (!coalitions) return false;
  return !!Object.keys(coalitions).length;
};

export const canViewCoalition = ({ coalitionId, coalitions }) => {
  return get(coalitions[coalitionId], "userMembership");
};

export const isCoalitionContributor = ({ coalitionId, coalitions }) => {
  return get(coalitions[coalitionId], "orgMembership");
};

export const isActiveCoalitionContributor = ({ coalitionId, coalitions }) => {
  const orgMembership = get(coalitions[coalitionId], "orgMembership");
  return orgMembership && orgMembership.attributes.status === "active";
};

export const isPendingCoalitionContributor = ({ coalitionId, coalitions }) => {
  const orgMembership = get(coalitions[coalitionId], "orgMembership");
  return (
    orgMembership &&
    (orgMembership.attributes.status === "requested" ||
      orgMembership.attributes.status === "reminded")
  );
};

export const isCoalitionManager = ({ coalitionId, coalitions }) => {
  const userMembership = get(coalitions[coalitionId], "userMembership");
  return (
    userMembership &&
    userMembership.attributes.role === "coalition_manager" &&
    userMembership.attributes.dataUseAgreement === true
  );
};

export const isCoalitionViewer = ({ coalitionId, coalitions }) => {
  const userMembership = get(coalitions[coalitionId], "userMembership");
  return (
    userMembership &&
    userMembership.attributes.role === "coalition_viewer" &&
    userMembership.attributes.dataUseAgreement === true
  );
};
