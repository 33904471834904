export const ORGANIZATION_TYPES = {
  ALL: "All Organization Types",
  GOVERNMENT_ANIMAL_SERVICES: "Government Animal Services",
  ANIMAL_SHELTER_WITH_A_GOVERNMENT_CONTRACT:
    "Animal Shelter with a Government Contract",
  ANIMAL_RESCUE_WITH_A_GOVERNMENT_CONTRACT:
    "Animal Rescue with a Government Contract",
  SHELTER_WITHOUT_A_GOVERNMENT_CONTRACT:
    "Shelter without a Government Contract",
  RESCUE_WITHOUT_A_GOVERNMENT_CONTRACT: "Rescue without a Government Contract",
};
