import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import { isValid } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { previousMonth } from "utils";
import { useUserState } from "context";
import { useParams } from "react-router-dom";

const DataEntryOptionsCta = ({ date, isCsv, label, action }) => {
  const [cta, setCta] = useState("");
  const { location, organizationId } = useParams();
  const { isOrgAdmin } = useUserState();

  useEffect(() => {
    setCta(getCta(date, isCsv));
  }, [date, isCsv]);

  const getCta = (date, isCsv) => {
    if (isCsv) {
      if (location) return `/${action}/upload/${location}/${organizationId}`;
      else return `/${action}/upload`;
    }
    if (date && isValid(date)) {
      if (location)
        return `/${action}/${date.getFullYear()}/${
          date.getMonth() + 1
        }/${location}/${organizationId}`;
      else return `/${action}/${date.getFullYear()}/${date.getMonth() + 1}`;
    }
    return "";
  };

  return (
    <div className="flex justify-end">
      <Button
        to={cta}
        disabled={!cta || (isCsv && !isOrgAdmin)}
        className="flex items-center"
        data-testid="data-entry-options__cta"
      >
        {isCsv ? "Select File" : label}
        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
      </Button>
    </div>
  );
};

DataEntryOptionsCta.propTypes = {
  date: PropTypes.instanceOf(Date),
  isCsv: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

DataEntryOptionsCta.defaultProps = {
  date: previousMonth,
  isCsv: false,
};

export default DataEntryOptionsCta;
