import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useQueryClient } from "react-query";
import Table from "components/Table";
import Button from "components/Button";
import Pagination from "components/Pagination";
import LoadingIndicator from "components/LoadingIndicator";
import Error from "components/Error";
import {
  formatEin,
  formatDateTime,
  getTotalPages,
  titleCase,
  formatUiErrors,
  getOrganizations,
} from "utils";
import { useAuthState, useAuthDispatch } from "context";
import { get, uniq } from "lodash";

const UserOrganizations = ({ userId }) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Initial query
  const {
    data: response = { data: [], included: [] },
    error,
    isLoading,
  } = useQuery(
    ["userOrgs", { page, userId }],
    () => getOrganizations({ token, dispatch, page, userId }),
    {
      keepPreviousData: true,
    }
  );

  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(["userOrgs", { page: page + 1, userId }], () =>
        getOrganizations({ token, dispatch, page: page + 1, userId })
      );
    }
  }, [response, page, userId, queryClient]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    {
      accessor: "attributes.incorporatedOn",
      Header: "Date Created",
      Cell: ({ cell }) => formatDateTime(cell.value),
    },
    {
      accessor: "attributes.locationsCount",
      Header: "Number of Locations",
    },
    {
      accessor: "attributes.ein",
      Header: "EIN",
      Cell: ({ cell }) => formatEin(cell.value),
    },
    {
      accessor: "attributes.locationsUsStates",
      Header: "States",
      Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
    },
    {
      accessor: "attributes.status",
      Header: "Status",
      Cell: ({ cell }) => titleCase(cell.value) || "",
    },
    {
      accessor: "id",
      Header: "",
      Cell: ({ cell }) => (
        <Button
          to={`/organizations/${cell.value}`}
          className="btn-sm"
          emphasis="transparent"
        >
          View Organization
        </Button>
      ),
    },
  ];

  if (isLoading) {
    return (
      <LoadingIndicator inline content={<>Loading organizations&hellip;</>} />
    );
  }

  if (error) return <Error>{formatUiErrors(error)}</Error>;

  return (
    <>
      <h2>Organizations</h2>

      <Table
        data={response.data || []}
        columns={columns}
        noResultsText="No organizations found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}
    </>
  );
};

UserOrganizations.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserOrganizations;
