import React from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import { useAuthState, useAuthDispatch, useUserState } from "context";
import {
  getAllRecords,
  formatUiErrors,
  organizationHeaders,
  transformOrganizationsData,
  locationHeaders,
  transformLocationsData,
  userAccountHeaders,
  transformUserAccountsData,
  coalitionHeaders,
  transformCoalitionsData,
  apiFetch,
} from "utils";
import Button from "components/Button";
import LoadingIndicator from "components/LoadingIndicator";
import Error from "components/Error";
import IntakeOverviewGraph from "components/IntakeOverviewGraph";
import IntakeOverviewTable from "components/IntakeOverviewTable";
import OrganizationData from "./OrganizationData";

const mutationConfig = ({ headers, fileNamePrefix, buttonText }) => {
  return {
    onSuccess: (data) => {
      toast.success(
        <DownloadExportLink
          data={data}
          headers={headers}
          fileNamePrefix={fileNamePrefix}
          buttonText={buttonText}
        />,
        {
          closeOnClick: false,
          autoClose: false,
          limit: 1,
        }
      );
    },
    onError: (error) => {
      toast.error(() => formatUiErrors(error), {
        autoClose: 5000,
      });
    },
  };
};

const AdminDashboard = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const {
    attributes: { fullName, locationId },
  } = useUserState();
  const {
    data: response,
    isLoading,
    error,
  } = useQuery(["animalDataExport"], () =>
    apiFetch({
      token,
      dispatch,
      endpoint:
        "/api/v1/data_exports?sort=-created_at&page[number]=1&page[size]=1",
    })
  );

  const { mutate: exportOrganizations, isLoading: orgExportLoading } =
    useMutation(
      async () => {
        try {
          const response = await getAllRecords({
            token,
            dispatch,
            endpoint: "/api/v1/organizations",
          });
          return transformOrganizationsData(response);
        } catch (error) {
          throw error;
        }
      },
      mutationConfig({
        headers: organizationHeaders,
        fileNamePrefix: "sac-organizations-export",
        buttonText: "Download Organizations CSV",
      })
    );

  const { mutate: exportCoalitions, isLoading: coalitionExportLoading } =
    useMutation(
      async () => {
        try {
          const response = await getAllRecords({
            token,
            dispatch,
            endpoint: "/api/v1/coalitions",
          });
          return transformCoalitionsData(response);
        } catch (error) {
          throw error;
        }
      },
      mutationConfig({
        headers: coalitionHeaders,
        fileNamePrefix: "sac-coalitions-export",
        buttonText: "Download Coalitions CSV",
      })
    );

  const { mutate: exportLocations, isLoading: locExportLoading } = useMutation(
    async () => {
      try {
        const response = await getAllRecords({
          token,
          dispatch,
          endpoint: "/api/v1/locations",
          queryParams: ["extra_fields[locations]=us_county_name"],
        });
        return transformLocationsData(response);
      } catch (error) {
        throw error;
      }
    },
    mutationConfig({
      headers: locationHeaders,
      fileNamePrefix: "sac-locations-export",
      buttonText: "Download Locations CSV",
    })
  );

  const { mutate: exportUserAccounts, isLoading: usersExportLoading } =
    useMutation(
      async () => {
        try {
          const response = await getAllRecords({
            token,
            dispatch,
            endpoint: "/api/v1/users",
          });
          return transformUserAccountsData(response);
        } catch (error) {
          throw error;
        }
      },
      mutationConfig({
        headers: userAccountHeaders,
        fileNamePrefix: "sac-user-accounts-export",
        buttonText: "Download User Accounts CSV",
      })
    );

  const getContent = () => {
    if (isLoading) return <LoadingIndicator inline />;

    return (
      <>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="sac-border">
            <h2>Animal Data</h2>

            {getAnimalDataExportContent()}
          </div>
          <div className="sac-border">
            <h2>Organizations</h2>

            <Button to="/organizations" className="w-full mb-4">
              Manage Organizations
            </Button>

            <Button
              onClick={exportOrganizations}
              isLoading={orgExportLoading}
              loadingText={<>Generating your export&hellip;</>}
              emphasis="secondary"
              className="w-full"
            >
              Generate Organizations CSV
            </Button>
          </div>
          <div className="sac-border">
            <h2>Locations</h2>

            <Button
              onClick={exportLocations}
              isLoading={locExportLoading}
              loadingText={<>Generating your export&hellip;</>}
              emphasis="secondary"
              className="w-full"
            >
              Generate Locations CSV
            </Button>
          </div>
          <div className="sac-border">
            <h2>Coalitions</h2>
            <Button to="/coalitions" className="w-full mb-4">
              Manage Coalitions
            </Button>

            <Button
              onClick={exportCoalitions}
              isLoading={coalitionExportLoading}
              loadingText={<>Generating your export&hellip;</>}
              emphasis="secondary"
              className="w-full"
            >
              Generate Coalitions CSV
            </Button>
          </div>
          <div className="sac-border">
            <h2>Users</h2>
            <Button to="/users" className="w-full mb-4">
              Manage User Accounts
            </Button>

            <Button
              onClick={exportUserAccounts}
              isLoading={usersExportLoading}
              loadingText={<>Generating your export&hellip;</>}
              emphasis="secondary"
              className="w-full"
            >
              Generate User Accounts CSV
            </Button>
          </div>
        </div>
        <OrganizationData />
      </>
    );
  };

  const getAnimalDataExportContent = () => {
    if (error) return <Error>{formatUiErrors(error)}</Error>;

    if (!response.data || !response.data.length)
      return <p>The latest animal data CSV is unavailable.</p>;

    return (
      <>
        <Button
          href={response.data[0].attributes.dataCsv}
          emphasis="secondary"
          className="w-full mb-2"
          download
        >
          Download the Latest Animal Data CSV
        </Button>
        <p className="text-sm text-right italic">
          Created{" "}
          {format(
            new Date(response.data[0].attributes.createdAt),
            "MM/dd/yyyy"
          )}
        </p>
      </>
    );
  };

  return (
    <>
      <h1>Welcome{fullName ? `, ${fullName}` : null}!</h1>
      {getContent()}
    </>
  );
};

export default AdminDashboard;

const DownloadExportLink = ({ data, headers, fileNamePrefix, buttonText }) => {
  return (
    <CSVLink
      data={data}
      headers={headers}
      filename={`${fileNamePrefix}_${format(new Date(), "yyyy-MM-dd")}.csv`}
    >
      {buttonText}
    </CSVLink>
  );
};
