import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import Table from "components/Table";
import Button from "components/Button";
import FormField from "components/FormField";
import Pagination from "components/Pagination";
import ActionsMenu from "components/ActionsMenu";
import {
  formatEin,
  formatBusinessNumber,
  formatDateTime,
  getTotalPages,
  getOrganizationsForUsers,
} from "utils";
import { useAuthState, useAuthDispatch } from "context";
import { FocusableItem } from "@szhsin/react-menu";
import { get, uniq, isEqual } from "lodash";
import StateDropdown from "components/StateDropdown/StateDropdown";
import ProvinceDropdown from "components/ProvinceDropdown/ProvinceDropdown";

const AllOrganizations = () => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");

  // Form Filters
  const initialFilters = {
    search: "",
    state: "",
    organizationType: "",
    country: "United States",
  };
  const [filters, setFilters] = useState(initialFilters);
  // Initial query
  const {
    data: response = { data: [], included: [] },
    error,
    isLoading,
  } = useQuery(
    ["allOrganizations", { page, ...filters }],
    () => getOrganizationsForUsers({ token, dispatch, page, ...filters }),
    {
      keepPreviousData: true,
    }
  );
  // Prefetch the next page of organizations
  useEffect(() => {
    if (totalPages > page) {
      queryClient.prefetchQuery(
        ["allOrganizations", { page: page + 1, ...filters }],
        () =>
          getOrganizationsForUsers({
            token,
            dispatch,
            page: page + 1,
            ...filters,
          })
      );
    }
  }, [response, page, filters, queryClient, totalPages]);

  // Update the total pages count from the API/query response
  useEffect(() => {
    const totalPages = getTotalPages({
      totalCount: get(response, "meta.stats.total.count") || 0,
    });
    setTotalPages(totalPages);
  }, [response]);

  const columns = [
    {
      accessor: "attributes.name",
      Header: "Name",
    },
    ...(filters.country === "United States"
      ? [
          {
            accessor: "attributes.locationsUsStates",
            Header: "States",
            Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
          },
        ]
      : []),
    ...(filters.country === "Canada"
      ? [
          {
            accessor: "attributes.locationsProvinces",
            Header: "Province/Territory",
            Cell: ({ cell }) => (cell.value ? uniq(cell.value).join(", ") : ""),
          },
        ]
      : []),
    ...(filters.country === "United States"
      ? [
          {
            accessor: "attributes.ein",
            Header: "EIN",
            Cell: ({ cell }) => formatEin(cell.value),
          },
        ]
      : []),
    ...(filters.country === "Canada"
      ? [
          {
            accessor: "attributes.businessNumber",
            Header: "Business Number",
            Cell: ({ cell }) => formatBusinessNumber(cell.value),
          },
        ]
      : []),
    {
      accessor: "attributes.organizationType",
      Header: "Organization Type",
    },
    {
      accessor: "id",
      Header: "",
      Cell: ({ cell }) => <Menu id={cell.value} />,
    },
  ];

  const onSubmitFilters = (values) => {
    if (!isEqual(values, filters)) {
      setFilters(values);
    }
  };

  const onValidateForm = (values) => {
    const formValues = [
      "search",
      "state",
      "organizationType",
      "country",
    ].reduce((vals, key) => {
      if (values[key]) {
        vals.push(values[key]);
      }
      return vals;
    }, []);
    return !!formValues.length ? {} : { error: "Select at least one filter." };
  };

  return (
    <>
      <h1>All Organizations</h1>
      <div className="sac-border mb-6">
        <Formik
          initialValues={{ ...initialFilters, error: "" }}
          onSubmit={onSubmitFilters}
          validateOnMount
          validate={onValidateForm}
        >
          {({
            resetForm,
            errors,
            touched,
            handleSubmit,
            values: { country },
          }) => (
            <Form>
              <div className="flex items-end justify-between mb-6">
                <div className="w-80">
                  <FormField
                    name="search"
                    label="Search"
                    placeholder="Name, EIN, or Business number"
                  />
                </div>

                <div className="w-80">
                  <FormField name="country" label="Country" as="select">
                    <option disabled value="" hidden>
                      -- Select a Country --
                    </option>
                    <option value="United States">United States</option>
                    <option value="Canada">Canada</option>
                  </FormField>
                </div>

                {country === "Canada" && (
                  <ProvinceDropdown name="state" label="Province/Territory" />
                )}
                {country === "United States" && (
                  <StateDropdown name="state" label="State" />
                )}
                <div className="w-100">
                  <FormField
                    name="organizationType"
                    label="Organization Type"
                    as="select"
                  >
                    <option disabled value="" hidden>
                      -- Select an Organization Type --
                    </option>
                    <option value="Government Animal Services">
                      Government Animal Services
                    </option>
                    <option value="Animal Shelter with a Government Contract">
                      Animal Shelter with a Government Contract
                    </option>
                    <option value="Animal Rescue with a Government Contract">
                      Animal Rescue with a Government Contract
                    </option>
                    <option value="Shelter without a Government Contract">
                      Shelter without a Government Contract
                    </option>
                    <option value="Rescue without a Government Contract">
                      Rescue without a Government Contract
                    </option>
                    <option value="Non-sheltering Service Provider">
                      Non-sheltering Service Provider
                    </option>
                  </FormField>
                </div>
              </div>

              <div className="flex items-center justify-end space-x-2 ml-auto">
                <Button
                  onClick={() => {
                    resetForm();
                    setFilters(initialFilters);
                  }}
                  emphasis="transparent"
                >
                  Clear Search
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                  Search
                </Button>
              </div>
              {errors.error && touched.error && (
                <div className="text-sm text-red mt-1">{errors.error}</div>
              )}
            </Form>
          )}
        </Formik>
      </div>
      {/* <div className="sac-border mb-6">
        <Formik initialValues={{ search }} onSubmit={handleSubmit}>
          {({ setFieldValue }) => (
            <Form className="flex items-end space-x-6">
              <div className="w-80">
                <FormField
                  name="search"
                  label="Search"
                  placeholder="Name, EIN, or Business number"
                />
              </div>

              <div className="flex items-center space-x-2">
                <Button type="submit">Search</Button>
                <Button
                  onClick={() => {
                    setFieldValue("search", "");
                    setSearch("");
                  }}
                  emphasis="transparent"
                >
                  Clear Search
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div> */}

      <Table
        data={response.data || []}
        columns={columns}
        columnWidths={[null, null, null, null, null, null, "w-16"]}
        columnAlignments={[null, null, null, null, null, null, "text-right"]}
        noResultsText="No organizations found"
        isLoading={isLoading}
        error={error}
      />

      {!!(response.data || []).length && (
        <div className="flex justify-end mt-8">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={(page) => setPage(page)}
          />
        </div>
      )}
    </>
  );
};

export default AllOrganizations;

const Menu = ({ id }) => {
  return (
    <ActionsMenu>
      {/* View */}
      <FocusableItem>
        {({ ref }) => (
          <Link to={`/organizations/${id}`} ref={ref}>
            View Details
          </Link>
        )}
      </FocusableItem>
    </ActionsMenu>
  );
};
