import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import fontBold from "../../fonts/Cera-Pro-Bold.woff";
import PDFLogo from "./ExportPDF/PDFLogo";
import TopHeader from "./ExportPDF/TopHeader";
import MainTable from "./ExportPDF/MainTable";
import CustomSpeciesPage from "./ExportPDF/CustomSpeciesPage";
import {
  BEHAVIORAL_DATA,
  FIELD_DATA,
  generateFieldDataServices,
  generateVeternaryServices,
  LIVE_INTAKE_KEYS,
  SUPPORT_DATA,
  VETERINAY_DATA,
} from "./ExportPDF/helpers/PDFTableKeys";
import SideText from "./ExportPDF/Shared/SideText";
import SideTextServices from "./ExportPDF/Shared/SideTextServices";
import CustomSpeciesService from "./ExportPDF/CustomSpeciesService";

Font.register({
  src: fontBold,
  family: "Cera Pro Bold",
});

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 20,
    padding: 20,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  container: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  rightCol: {
    flexDirection: "column",
    flexGrow: 9,
    paddingLeft: 10,
    paddingRight: 20,
    fontSize: 16,
    textTransform: "uppercase",
  },
  container2: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    height: 40,
    paddingTop: 7,
    textAlign: "center",
    flexGrow: 1,
  },
  container3: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    paddingTop: 7,
    textAlign: "center",
  },
  container4: {
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Cera Pro Bold",
    marginTop: "-5px",
    textAlign: "center",
  },
  leftColumn: {
    width: 400,
    paddingRight: 10,
  },
  leftColumn2: {
    width: 350,
    paddingRight: 0,
    paddingLeft: 20,
    fontSize: 15,
    lineHeight: 1.5,
    fontWeight: 900,
  },
  header: {
    color: "#0F6165",
    fontSize: "24px",
    paddingLeft: 10,
    fontFamily: "Cera Pro Bold",
    fontWeight: 900,
  },
});

const PDFExportServices = ({ data, orgName, action, timeframe }) => {
  var totalSpecies = [
    ...data.services.data
      .map((a) => a.attributes.species)
      .filter((x, i, a) => a.indexOf(x) === i),
  ].filter((x, i, a) => a.indexOf(x) === i);
  var liveIntakes = [];
  liveIntakes = generateFieldDataServices(data.services.data, FIELD_DATA);
  var vetData = [];
  vetData = generateFieldDataServices(data.services.data, VETERINAY_DATA);
  var behaviorlData = [];
  behaviorlData = generateFieldDataServices(
    data.services.data,
    BEHAVIORAL_DATA
  );
  var supportData = [];
  supportData = generateFieldDataServices(data.services.data, SUPPORT_DATA);
  return (
    <Document>
      <Page size="A3">
        <View style={styles.section}></View>
        <View style={styles.container}>
          <View style={styles.rightCol}>
            <PDFLogo />
          </View>
          <View style={styles.leftColumn}>
            <TopHeader
              orgName={orgName}
              action={action}
              timeframe={timeframe}
              headerName={"TOTAL"}
            />
          </View>
        </View>
        <Text style={styles.header}>ORGANIZATION TOTALS</Text>
        <View style={styles.container}>
          <View style={styles.rightCol}>
            <MainTable
              styles={styles}
              data={liveIntakes}
              name={"FIELD SERVICES"}
            />
            <Text>{"\n"}</Text>
            <Text>{"\n"}</Text>
            <MainTable
              styles={styles}
              data={vetData}
              name={"VETERINARY SERVICES"}
            />
            <Text>{"\n"}</Text>
            <Text>{"\n"}</Text>
            <MainTable
              styles={styles}
              data={behaviorlData}
              name={"BEHAVIORAL SERVICES"}
            />
            <Text>{"\n"}</Text>
            <Text>{"\n"}</Text>
            <MainTable
              styles={styles}
              data={supportData}
              name={"SUPPORT SERVICES"}
            />
          </View>
          <View style={styles.leftColumn2}>
            <SideTextServices hideOther={false} />
          </View>
        </View>
      </Page>
      {totalSpecies.map((sp) => (
        <CustomSpeciesService
          title={sp.toLocaleUpperCase()}
          sp={sp}
          data={data}
          action={action}
          orgName={orgName}
          styles={styles}
          timeframe={timeframe}
        />
      ))}
    </Document>
  );
};
export default PDFExportServices;
