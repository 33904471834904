import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import AlertModal from "components/AlertModal";
import Button from "components/Button";

const MatrixSettingsModal = ({ isModalOpen, closeModal, confirmModal }) => {
  return (
    <AlertModal isModalOpen={isModalOpen} closeModal={closeModal}>
      <div className="text-center">
        <h3 className="text-2xl mb-4 text-gold">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          Warning!
        </h3>

        <p className="mb-6">
          If you have existing species and remove them you will lose the tables
          that correspond with that species.
        </p>

        <div className="flex items-center justify-center space-x-4">
          <Button
            onClick={closeModal}
            emphasis="secondary"
            data-testid="matrix-settings-modal-cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={confirmModal}
            data-testid="matrix-settings-modal-confirm"
          >
            Confirm
          </Button>
        </div>
      </div>
    </AlertModal>
  );
};

MatrixSettingsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
};

export default MatrixSettingsModal;
