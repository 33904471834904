import { View, Text } from "@react-pdf/renderer";

const SideText = (props) => {
  return (
    <View style={{ marginTop: 30 }}>
      <Text>
        This report is a snapshot of {"\n"} data for all species recorded {"\n"}{" "}
        by your organization, within {"\n"}the timeframe selected for
        {"\n"}the report. See additional {"\n"}pages for age breakouts for{" "}
        {"\n"}each species.{"\n"}
        {"\n"}
      </Text>
      <Text>
        For full definitions, visit {"\n"}ShelterAnimalscount.org/IOD{"\n"}
        {"\n"}
      </Text>
      <Text>LIVE INTAKES:{"\n"}</Text>
      <Text style={{ fontWeight: 400, fontSize: 11 }}>
        1) "Transfer in to agency {"\n"}(undesignated)" represents Transfer{" "}
        {"\n"}In data prior to when agency location {"\n"}break outs (in state,
        out of state,
        {"\n"}international) were added to the {"\n"}database.{"\n"}
        {"\n"}
      </Text>
      <Text>LIVE OUTCOMES:{"\n"}</Text>
      <Text style={{ fontWeight: 400, fontSize: 11 }}>
        2) "Transfer out to agency {"\n"}(undesignated)" represents Transfer{" "}
        {"\n"}Out data prior to when agency
        {"\n"}location break outs (in state, out of {"\n"}state, international)
        were added to the {"\n"}database. {"\n"}
        {"\n"}
      </Text>
      <Text>*Others:{"\n"}</Text>
      <Text style={{ fontWeight: 400, fontSize: 11 }}>
        Rabbits, equines, small
        {"\n"}mammals, farm animals, birds,{"\n"}
        and reptiles & amphibians. {"\n"}
        {"\n"}
      </Text>
    </View>
  );
};
export default SideText;
