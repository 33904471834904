import React from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FORM_MODES, formatUiErrors } from "utils";
import CoalitionForm from "components/CoalitionForm";
import BackLink from "components/BackLink";

const AddCoalition = ({ path = "" }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const handleUpdateSuccess = () => {
    history.push(`${path}/coalitions`);

    toast.success("Saved successfully", {
      autoClose: 3000,
    });

    queryClient.invalidateQueries(["coalitions"], {
      refetchInActive: true,
    });
    queryClient.invalidateQueries(["myCoalitions"], {
      refetchInActive: true,
    });
    queryClient.invalidateQueries(["orgCoalitions"], {
      refetchInActive: true,
    });
  };

  const handleUpdateError = (error) => {
    toast.error(() => formatUiErrors(error), {
      autoClose: 5000,
    });
  };

  return (
    <>
      <div className="mb-8">
        <BackLink
          link={`${path}/coalitions`}
          text="Back to coalitions listing"
        />
        <h2>Create Coalition</h2>
      </div>

      <CoalitionForm
        coalition={{ attributes: {} }}
        formMode={FORM_MODES.ADD}
        cancelRoute={`${path}/coalitions`}
        onUpdateSuccess={handleUpdateSuccess}
        onUpdateError={handleUpdateError}
      />
    </>
  );
};

export default AddCoalition;
