import React, { useEffect, useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isEqual } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useAuthDispatch, useAuthState, useUserState } from "context";
import { speciesMap, apiFetch, useHandleRequestStates } from "utils";
import { generateAnimalSpeciesPayload, updateSpeciesCache } from "../../utils";
import Button from "components/Button";
import SpeciesCheckbox from "components/SpeciesCheckbox";
import MatrixSettingsModal from "components/MatrixSettingsModal";
import EntryStatus from "../common/EntryStatus";

const ServicesMatrixSettings = ({
  data,
  defaultSpecies,
  locationName,
  locationId,
  month,
  year,
  entryStatus,
  selectedSpecies,
  setSpecies,
  setIsEditing,
  isEditing,
  handleSubmitServices,
}) => {
  const { token } = useAuthState();
  const dispatch = useAuthDispatch();
  const { isOrgAdmin } = useUserState();
  const queryClient = useQueryClient();
  const { handleSuccess, handleError } = useHandleRequestStates();
  const [editMode, setEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changedSpecies, setChangedSpecies] = useState({
    added: [],
    removed: [],
  });

  const date = new Date(`${year}/${month}/1`);
  const formattedDate = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
  }).format(date);

  const initialValues = Object.keys(speciesMap).reduce((map, speciesKey) => {
    map[speciesKey] = defaultSpecies.includes(speciesKey);
    return map;
  }, {});

  const getRemovedSpecies = (animalSpecies) => {
    const removedSpecies = Object.keys(initialValues).filter(
      (species) => !!initialValues[species] && !animalSpecies[species]
    );
    return removedSpecies.map((speciesKey) => {
      const servicesRecord = data.find((record) => {
        return record.attributes.species === speciesKey;
      });

      return {
        key: speciesKey,
        id: servicesRecord?.id,
      };
    });
  };

  const getAddedSpecies = (animalSpecies) => {
    return Object.keys(initialValues).filter(
      (species) => !initialValues[species] && !!animalSpecies[species]
    );
  };

  // Fire off our API request to add new species to DB
  const { mutate: setMatrixSpecies } = useMutation(
    () => {
      const payload = generateAnimalSpeciesPayload({
        addedSpecies: changedSpecies.added,
        removedSpecies: changedSpecies.removed,
        locationId,
        year,
        month,
      });

      return apiFetch({
        token,
        dispatch,
        endpoint: `/api/v1/locations/${locationId}`,
        method: "PATCH",
        body: {
          data: {
            id: locationId,
            type: "locations",
            attributes: {},
            relationships: payload.relationships,
          },
          included: payload.included,
        },
      });
    },
    {
      onSettled: () => setEditMode(false),
      onSuccess: (res) => {
        handleSuccess({
          message: "Updated the animal types",
        });
        setIsModalOpen(false);

        return queryClient.setQueryData(
          ["servicesMatrix", `${locationId}_${month}/${year}`],
          (oldData) =>
            updateSpeciesCache({
              oldData,
              response: res,
              changedSpecies,
            })
        );
      },
      onError: handleError,
    }
  );

  const submitSettingsForm = (values) => {
    if (!isEqual(values, initialValues)) {
      const removedSpecies = getRemovedSpecies(values);
      const addedSpecies = getAddedSpecies(values);

      // Update state
      setChangedSpecies({
        removed: removedSpecies, // array of objects
        added: addedSpecies, // array of strings
      });
    } else {
      setEditMode(false);
    }
  };

  useEffect(() => {
    // If changedSpecies is null, we know the form is being shown for
    // the first time and we don't want to do anything
    if (!changedSpecies) {
      return;
    }
    if (!!changedSpecies.removed.length) {
      // If species have been removed, open the modal
      setIsModalOpen(true);
    } else if (!!changedSpecies.added.length) {
      // If species have only been added (and none have been removed),
      // make the API request
      setMatrixSpecies();
    } else {
      // If the species have not changed we can set the form back to it's view mode
      setEditMode(false);
    }
  }, [changedSpecies]);

  return (
    <>
      <div
        className={classnames("sac-border mb-4", { "bg-light-gray": editMode })}
        data-testid="services-matrix-settings"
      >
        <div className="flex">
          {/* Icon */}
          <div>
            {!editMode ? (
              <span className="circle bg-dashboard-medium-gray mr-4">
                <FontAwesomeIcon icon={faCheck} className="text-white" />
              </span>
            ) : (
              <span className="circle bg-dashboard-blue mr-4">1</span>
            )}
          </div>

          {/* Form */}
          <div className="w-full">
            <div className="flex justify-between mb-6">
              <h2 className="text-2xl">Details</h2>

              {!editMode && isOrgAdmin ? (
                <Button onClick={() => setEditMode(true)}>Edit</Button>
              ) : null}
            </div>

            {editMode
              ? editSettings({
                  initialValues,
                  locationName,
                  formattedDate,
                  isModalOpen,
                  setEditMode,
                  setIsModalOpen,
                  submitSettingsForm,
                  setMatrixSpecies,
                })
              : viewSettings({
                  defaultSpecies,
                  locationName,
                  formattedDate,
                  entryStatus,
                })}
          </div>
        </div>
      </div>
      {/** Species selector */}
      <div className="sac-border mb-4">
        <div className="flex justify-between">
          <div>
            <h2 className="text-xl">
              <div>
                1. Click species button below to edit/enter all data for that
                species.
              </div>
              <div>
                2. Click save to save all data for that species. Any empty
                fields will be highlighted as incomplete.
              </div>
              <div>
                3. Click species button again to view data for all species.
              </div>
            </h2>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex gap-1">
            {defaultSpecies.map((species, index) => {
              return (
                <Button
                  emphasis={`${species === selectedSpecies ? "gold" : "teal"}`}
                  key={index}
                  disabled={isEditing || !isOrgAdmin}
                  onClick={() => setSpecies(species)}
                >
                  {speciesMap[species].label}
                </Button>
              );
            })}
          </div>
          {selectedSpecies && (
            <div className="flex gap-1">
              <Button disabled={isEditing} onClick={() => setIsEditing(true)}>
                Edit
              </Button>
              <Button disabled={!isEditing} onClick={handleSubmitServices}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ServicesMatrixSettings.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSpecies: PropTypes.arrayOf(PropTypes.string).isRequired,
  locationName: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  entryStatus: PropTypes.string.isRequired,
  selectedSpecies: PropTypes.string.isRequired,
  setSpecies: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleSubmitServices: PropTypes.func.isRequired,
};

export default ServicesMatrixSettings;

const editSettings = ({
  initialValues,
  locationName,
  formattedDate,
  isModalOpen,
  setEditMode,
  setIsModalOpen,
  submitSettingsForm,
  setMatrixSpecies,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitSettingsForm}
      validate={(values) => {
        const isValid = Object.keys(values).some((key) => {
          return values[key];
        });

        return isValid
          ? {}
          : { invalid: "You must select at least one species" };
      }}
    >
      {({ handleSubmit, values, errors }) => (
        <>
          <Form>
            {/* Location */}
            <div className="mb-8 flex items-center space-x-4">
              <h5 className="w-1/5 font-body-bold">Entering Data For:</h5>
              <p className="mb-0">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="text-deepest-teal mr-1"
                />
                {locationName}
              </p>
            </div>

            {/* Timeframe */}
            <div className="mb-8 flex items-center space-x-4">
              <h5 className="font-body-bold w-1/5">Timeframe:</h5>
              <p className="mb-0">{formattedDate}</p>
            </div>

            {/* Animal Species */}
            <h5 className="font-body-bold">
              What types of animals are you entering for?
            </h5>
            <div className="mt-6 mb-2 grid grid-cols-4 gap-4">
              <FieldArray
                name="species"
                render={() => {
                  return Object.keys(values).map((key) => (
                    <SpeciesCheckbox
                      key={key}
                      species={key}
                      fieldName={key}
                      label={speciesMap[key].label}
                      selected={values[key]}
                    />
                  ));
                }}
              />
            </div>

            {/* Errors */}
            {errors.invalid && (
              <div className="text-sm text-red">{errors.invalid}</div>
            )}

            {/* Buttons */}
            <div className="flex justify-end mt-4">
              <Button
                className="btn ml-4"
                emphasis="transparent"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>

              <Button className="btn ml-4" type="submit" onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </Form>

          <MatrixSettingsModal
            isModalOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            confirmModal={setMatrixSpecies}
          />
        </>
      )}
    </Formik>
  );
};

const viewSettings = ({
  defaultSpecies,
  locationName,
  formattedDate,
  entryStatus,
}) => {
  return (
    <>
      <div className="mb-6 flex space-x-4">
        <h5 className="w-1/5 font-body text-base">Entering Data For:</h5>

        <p className="font-body text-base mb-0">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="text-deepest-teal mr-1"
          />
          {locationName}
        </p>
      </div>

      <div className="mb-6 flex space-x-4">
        <h5 className="w-1/5 font-body text-base">Timeframe:</h5>

        <p className="mb-0">{formattedDate}</p>
      </div>
      <div className="mb-8 flex items-center space-x-4">
        <h5 className="w-1/5 font-body text-base">Services Entry Status:</h5>
        <EntryStatus status={entryStatus} />
      </div>

      <div className="flex space-x-4 mb-6">
        <h5 className="w-1/5 font-body text-base flex-shrink-0">
          Animal Types:
        </h5>
        <ul className="flex flex-wrap">
          {defaultSpecies.map((species) => (
            <li
              key={species}
              className="rounded-full py-1 px-3 bg-pale-blue mr-2 mb-2"
            >
              {speciesMap[species].label}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
